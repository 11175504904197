import { styled, keyframes } from '@mui/material/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Box, Typography } from '@mui/material';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Avatar from '@mui/material/Avatar';
import * as colors from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'MessageStat';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
`;

const hoverScale = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
`;

const classes = {
  root: `${PREFIX}-root`,
  escalationsroot: `${PREFIX}-escalationsroot`,
  followuproot: `${PREFIX}-followuproot`,
  totalroot: `${PREFIX}-totalroot`,
  contactsroot: `${PREFIX}-ontacts`,
  followup: `${PREFIX}-followup`,
  total: `${PREFIX}-total`,
  escalations: `${PREFIX}-escalations`,
  contacts: `${PREFIX}-contacts`,
};

const StyledPaperSolidColor = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    animation: `${fadeIn} 1s forwards`,
    '&.fade-out': {
      animation: `${fadeOut} 1s forwards`,
    },
  },

  [`& .${classes.escalationsroot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: `${colors.deepOrange[500]}cc`,
    color: 'white',
  },

  [`& .${classes.followuproot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: `${colors.amber[500]}cc`,
    color: 'white',
  },

  [`& .${classes.totalroot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: `${colors.green[500]}cc`,
    color: 'white',
  },
  [`& .${classes.contactsroot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: `${colors.teal[500]}cc`,
    color: 'white',
  },
  [`& .${classes.followup}`]: {
    color: 'white',
    padding: theme.spacing(1),
    backgroundColor: colors.amber[500],
  },

  [`& .${classes.total}`]: {
    color: '#fff',
    padding: theme.spacing(1),
    backgroundColor: colors.green[500],
  },

  [`& .${classes.escalations}`]: {
    color: '#fff',
    backgroundColor: colors.deepOrange[500],
    padding: theme.spacing(1),
  },
  [`& .${classes.contacts}`]: {
    color: '#fff',
    backgroundColor: colors.teal[500],
    padding: theme.spacing(1),
  },
}));

const StyledPaperThemeColor = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    animation: `${fadeIn} 1s forwards`,
    '&.fade-out': {
      animation: `${fadeOut} 1s forwards`,
    },
    borderRadius: '10px',
  },

  [`& .${classes.escalationsroot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: `${colors.deepOrange[500]}cc`,
      color: 'white',
    },
  },

  [`& .${classes.followuproot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: `${colors.amber[500]}cc`,
      color: 'white',
    },
  },

  [`& .${classes.totalroot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: `${colors.green[500]}cc`,
      color: 'white',
    },
  },
  [`& .${classes.contactsroot}`]: {
    padding: theme.spacing(1),
    minWidth: '200px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: `${colors.teal[500]}cc`,
      color: 'white',
    },
  },
  [`& .${classes.followup}`]: {
    color: 'white',
    padding: theme.spacing(1),
    backgroundColor: colors.amber[500],
  },

  [`& .${classes.total}`]: {
    color: '#fff',
    padding: theme.spacing(1),
    backgroundColor: colors.green[500],
  },

  [`& .${classes.escalations}`]: {
    color: '#fff',
    backgroundColor: colors.deepOrange[500],
    padding: theme.spacing(1),
  },
  [`& .${classes.contacts}`]: {
    color: '#fff',
    backgroundColor: colors.teal[500],
    padding: theme.spacing(1),
  },
}));

const StyleGridBox = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const titles = {
    total: 'Total Messages',
    escalations: 'Escalations',
    followup: 'Followup',
    contacts: 'Total Contacts',
  };
  const handleIconClick = (type) => {
    switch (type) {
      case 'total':
      case 'escalations':
      case 'followup':
        navigate('/messaging');
        break;
      case 'contacts':
        navigate('/home');
        try {
          if (props?.contactsRef?.current) {
            props?.contactsRef?.current?.scrollIntoView({ behavior: 'smooth' });
          }
        } catch (error) {
          console.error('Error: ', error);
        }
        break;
      case 'default':
        break;
    }
  };
  return (
    <Box
      className={classes[`${props.title}root`]}
      onClick={() => handleIconClick(props.title)}
      sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingLeft: '5px',
          paddingRight: '5px',
        }}
      >
        <Typography
          style={{ color: 'white', fontWeight: '600', fontSize: '18px' }}
          variant="caption"
        >
          {t(titles[props.title]).toUpperCase()}
        </Typography>
        <Typography style={{ color: 'white', fontWeight: '800', fontSize: '18px' }} variant="body1">
          {props.value}
        </Typography>
      </div>
      <Avatar className={classes[props.title]}>
        {props.title === 'escalations' && <ReportProblemIcon />}
        {props.title === 'followup' && <NotificationImportantIcon />}
        {props.title === 'total' && <SpeakerNotesIcon />}
        {props.title === 'contacts' && <ContactsIcon />}
      </Avatar>
    </Box>
  );
};

export default function MessageStat(props) {
  return (
    <>
      {props?.styleType === 'solid_color' && (
        <StyledPaperSolidColor className={classes.root} data-tour={props.datatour} elevation={2}>
          <StyleGridBox {...props} />
        </StyledPaperSolidColor>
      )}
      {props?.styleType === 'theme_color' && (
        <StyledPaperThemeColor className={classes.root} data-tour={props.datatour} elevation={2}>
          <StyleGridBox {...props} />
        </StyledPaperThemeColor>
      )}
    </>
  );
}
