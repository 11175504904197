import { useSelector, useDispatch } from 'react-redux';
import { useTheme, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TelegramIcon from '@mui/icons-material/Telegram';
import {
  getContactList,
  importContacts,
  createContact,
  updateContact,
  deleteContact,
  vpageselector,
  shareContacts,
} from './phoneBookSlice';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import ContactsIcon from '@mui/icons-material/Contacts';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableSortLabel,
  CardHeader,
  Tooltip,
  TablePagination,
  Box,
} from '@mui/material';
import { sendMessage } from '../messages/messageSlice';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ShareIcon from '@mui/icons-material/Share';
import LastPageIcon from '@mui/icons-material/LastPage';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { setPortalSnackInfo } from '../../app/authSlice';
import { Fragment, useState } from 'react';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'PhoneBook';

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableRow: `${PREFIX}-tableRow`,
  small: `${PREFIX}-small`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardActions: `${PREFIX}-cardActions`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.tableContainer}`]: {
    flexGrow: 1,
  },

  [`& .${classes.tableRow}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 14,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PhoneBook = () => {
  const dispatch = useDispatch();
  const contactsList = useSelector(getContactList);
  const vpage = useSelector(vpageselector);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteEntry, setDeleteEntry] = useState(0);
  const [phoneBookEntryError, setPhoneBookEntryError] = useState('');
  const [recipient, setRecipient] = useState('');
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [edit, setEdit] = useState(false);
  const [selEntryId, setSelEntryId] = useState(0);
  const [msgOpen, setMsgOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [type, setType] = useState('RECEIVER');
  const [nameTxt, setNameTxt] = useState('');
  const [valueTxt, setValueTxt] = useState('');
  const [message, setMessage] = useState('');
  const [senderName, setSenderName] = useState('');
  const [senderPhone, setSenderPhone] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [importOpen, setImportOpen] = useState(false);

  const [shareOpen, setShareOpen] = useState(false);
  const [sharedLogin, setSharedLogin] = useState('');

  const [sortKey, setSortKey] = useState('nameTxt');
  const [sortOrder, setSortOrder] = useState('asc');

  const showImportOptions = (event) => {
    setImportOpen(true);
  };

  const showShareOptions = (event) => {
    setShareOpen(true);
  };

  const closeSharePopup = () => {
    setShareOpen(false);
  };

  const confirmShare = () => {
    dispatch(shareContacts(sharedLogin));
    setShareOpen(false);
  };

  const importContactsOtherUser = () => {
    var userinfo = {
      login: login,
      password: password,
    };
    setImportOpen(false);
    dispatch(importContacts(userinfo));
  };

  const handleChange = (event) => {
    switch (event.currentTarget?.name) {
      case 'message':
        setMessage(event.target.value);
        break;
      case 'type':
        setType(event.target.value);
        break;
      case 'nameTxt':
        setNameTxt(event.target.value);
        break;
      case 'valueTxt':
        setValueTxt(event.target.value);
        break;
      case 'senderPhone':
        setSenderPhone(event.target.value);
        break;
      case 'senderName':
        setSenderName(event.target.value);
        break;
      case 'sharedLogin':
        setSharedLogin(event.target.value);
        break;
      case 'recipient':
        if (event.target.value !== '' && isNaN(parseInt(event.target.value))) {
          return;
        }
        setRecipient(event.target.value);
        break;
      default:
        setType(event.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => {
    setType('RECEIVER');
    setPhoneBookEntryError('');
    setNameTxt('');
    setValueTxt('');
    setOpen(true);
    setEdit(false);
  };

  const handleEdit = (event, value) => {
    setPhoneBookEntryError('');
    if (event.target.value === undefined) {
      setSelEntryId(value.id);
      setType(value.typeTxt);
      if (value.typeTxt === 'EMAIL')
        if (
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value.valueTxt,
          )
        ) {
          setPhoneBookEntryError('');
        } else {
          setPhoneBookEntryError('Email format is not correct');
        }
      setNameTxt(value.nameTxt);
      setValueTxt(value.valueTxt);
      setOpen(true);
      setEdit(true);
    } else {
      handleToggle(event.target.value);
    }
  };

  const handleDelete = (event, value) => {
    setDeleteOpen(true);
    setDeleteEntry(value);
  };

  const closeDeletePopup = () => {
    setDeleteOpen(false);
  };

  const confirmDelete = () => {
    dispatch(deleteContact(deleteEntry));
    setDeleteOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const addCustomReceiver = () => {
    if (recipient.length < 2) {
      return;
    }
    const newChecked = [...checked];
    if (newChecked.length <= 5) {
      var checkDups = newChecked.slice().filter((element) => element.valueTxt === recipient);
      if (checkDups.length > 0) {
        dispatch(
          setPortalSnackInfo({
            severity: 'error',
            message: 'Cannot send message to duplicate recipients',
          }),
        );
        return;
      }
      newChecked.push({
        id: recipient,
        typeTxt: 'RECEIVER',
        nameTxt: recipient,
        valueTxt: recipient,
      });
      setChecked(newChecked);
      setRecipient('');
    }
  };

  const saveContact = () => {
    if (type === 'EMAIL') {
      if (
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          valueTxt,
        )
      ) {
        setPhoneBookEntryError('');
        var parts = valueTxt.split('@');

        if (parts[0].endsWith('.') || parts[0].endsWith('-') || parts[0].endsWith('_')) {
          setPhoneBookEntryError('Your username has to end with a letter or a number');
          return;
        }
      } else {
        setPhoneBookEntryError('Email format is not correct');
        return;
      }
    }
    if (type === 'RECEIVER' && isNaN(parseInt(valueTxt))) {
      setPhoneBookEntryError('Receiver format is not correct');
      return;
    }

    if (type === 'RECEIVER' && !isNaN(parseInt(valueTxt)) && valueTxt.length > 10) {
      setPhoneBookEntryError('Receiver cannot have more than 10 digits.');
      return;
    }

    if (type === 'RECEIVER' && !isNaN(parseInt(valueTxt)) && !/^[0-9]{1,10}$/.test(valueTxt)) {
      setPhoneBookEntryError('Receiver format is not correct');
      return;
    }

    if (nameTxt && nameTxt.length <= 0) {
      setPhoneBookEntryError('Name cannot be empty');
      return;
    }
    var valueDuplicateCheck = contactsList.filter(
      (element) => element.valueTxt === valueTxt && element.typeTxt === type,
    );
    if (valueDuplicateCheck.length > 0) {
      if (edit) {
        if (
          contactsList.find((element) => element.nameTxt === nameTxt && element.id !== selEntryId)
        ) {
          setPhoneBookEntryError(`A contact with matching name for ${type} already exists.`);
          return;
        }
      } else {
        setPhoneBookEntryError(`A contact with matching ${type} already exists.`);
        return;
      }
    }
    var entry = { name: nameTxt, value: valueTxt, type: type };
    if (edit) {
      entry.id = selEntryId;
      dispatch(updateContact(entry));
    } else {
      dispatch(createContact(entry));
    }
    setPhoneBookEntryError('');
    setOpen(false);
  };

  const sendMessageToReceiver = () => {
    if (checked.length >= 1) {
      var formattedMsg = '';
      if (vpage) {
        if (senderName) {
          formattedMsg += `Name : ${senderName}CRLF`;
        }

        if (senderPhone) {
          formattedMsg += `Phone : ${senderPhone}CRLF`;
        }
        formattedMsg += `Message : ${message}`;
      } else {
        formattedMsg = message;
      }
      formattedMsg = formattedMsg.replaceAll('\n', 'CRLF');
      checked.forEach((item) => {
        dispatch(sendMessage(formattedMsg, item.valueTxt));
      });
      setMsgOpen(false);
      resetMessageForm();
    }
  };

  const resetMessageForm = () => {
    setChecked([]);
    setRecipient('');
    setSenderName('');
    setSenderPhone('');
    setMessage('');
  };

  const showMessage = () => {
    setMsgOpen(true);
  };

  const closeMessage = () => {
    setMsgOpen(false);
  };

  const createSortHandler = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
    }
    setSortKey(key);
  };

  return (
    <StyledCard className={classes.card}>
      <CardHeader
        avatar={<ContactsIcon />}
        className={classes.cardHeader}
        title={t('Contacts')}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="a dense table" className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" />
                <TableCell align="left" />
                <TableCell align="left">
                  <TableSortLabel
                    active={sortKey === 'nameTxt'}
                    classes={{ active: classes.active, icon: classes.icon, root: classes.sortCell }}
                    direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => createSortHandler('nameTxt')}
                  >
                    {t('Name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">{t('Type')}</TableCell>
                <TableCell align="left">{t('Value')}</TableCell>
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {contactsList &&
                contactsList.length > 0 &&
                contactsList
                  .slice()
                  .sort((a, b) =>
                    sortOrder === 'asc'
                      ? a[sortKey].localeCompare(b[sortKey])
                      : b[sortKey].localeCompare(a[sortKey]),
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value.id}`;
                    return (
                      <TableRow className={classes.tableRow} hover key={value.id}>
                        <TableCell align="left">
                          {value.typeTxt === 'RECEIVER' && (
                            <Checkbox
                              checked={checked.indexOf(value) !== -1}
                              edge="end"
                              inputProps={{ 'aria-labelledby': labelId }}
                              onChange={handleToggle(value)}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          <Avatar className={classes.small}>
                            {value.nameTxt.substring(0, 2).toUpperCase()}
                          </Avatar>{' '}
                        </TableCell>
                        <TableCell align="left" onClick={(event) => handleEdit(event, value)}>
                          {value.nameTxt}
                        </TableCell>
                        <TableCell align="left" onClick={(event) => handleEdit(event, value)}>
                          {value.typeTxt}
                        </TableCell>
                        <TableCell align="left" onClick={(event) => handleEdit(event, value)}>
                          {value.valueTxt}
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          <PortalButton color="secondary">
                            <DeleteIcon onClick={(event) => handleDelete(event, value.id)} />
                          </PortalButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          aria-labelledby="form-dialog-title"
          data-tour="rtCreateContact"
          onClose={handleClose}
          open={open}
        >
          <DialogTitle id="form-dialog-title">Contact Entry</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: 'red' }}>{phoneBookEntryError}</DialogContentText>
            <TextField
              error={!(nameTxt && nameTxt.length > 0)}
              fullWidth
              inputProps={{ maxLength: 100 }}
              label={t('Name')}
              margin="dense"
              name="nameTxt"
              onChange={handleChange}
              required
              type="text"
              value={nameTxt}
              variant="standard"
            />
            <FormControl className={classes.formControl} fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">{t('Type')}</InputLabel>
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                name="type"
                onChange={handleChange}
                value={type}
              >
                <MenuItem value="RECEIVER">Receiver</MenuItem>
                <MenuItem value="EMAIL">Email</MenuItem>
              </Select>
            </FormControl>

            <TextField
              error={
                (type === 'EMAIL' &&
                  !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    valueTxt,
                  )) ||
                (type === 'RECEIVER' &&
                  (isNaN(parseInt(valueTxt)) || !/^[0-9]{1,10}$/.test(valueTxt)))
              }
              fullWidth
              label={type === 'RECEIVER' ? t('Receiver ID') : t('Email Address')}
              margin="dense"
              name="valueTxt"
              onChange={handleChange}
              required
              type="text"
              value={valueTxt}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <PortalButton
              className="rtPBSave"
              color="primary"
              disabled={!(type && nameTxt && valueTxt)}
              onClick={saveContact}
              variant="contained"
            >
              {t('Save')}
            </PortalButton>
            <PortalButton color="secondary" onClick={handleClose} variant="contained">
              {t('Cancel')}
            </PortalButton>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="form-dialog-title"
          data-tour="rtSendNewMessage"
          fullWidth={true}
          maxWidth="sm"
          onClose={closeMessage}
          open={msgOpen}
        >
          <DialogTitle id="form-dialog-title">
            {vpage ? 'Internal Message' : 'Send Message'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {checked.length === 0 && 'Please add at least one recipient to send message to.'}
              {message.length === 0 && 'Message cannot be empty.'}
            </DialogContentText>

            {checked.map(
              (item, index) =>
                item.id && (
                  <Chip
                    color="secondary"
                    deleteIcon={<CancelIcon />}
                    icon={
                      <Avatar
                        sx={{
                          width: (theme) => theme.spacing(3),
                          height: (theme) => theme.spacing(3),
                          fontSize: 10,
                          backgroundColor: 'white',
                        }}
                      >
                        {item.nameTxt.substring(0, 1)}
                      </Avatar>
                    }
                    key={`chip${item.id}`}
                    label={item.nameTxt}
                    onDelete={handleToggle(item)}
                    sx={{ mr: 1 }}
                    value={item}
                  />
                ),
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
              <TextField
                fullWidth
                label={t('Recipients')}
                margin="dense"
                name="recipient"
                onChange={handleChange}
                type="text"
                value={recipient}
                variant="standard"
              />
              <PortalButton
                color="primary"
                disabled={checked.length === 5}
                onClick={addCustomReceiver}
                size="small"
                variant="contained"
              >
                Add
              </PortalButton>
            </Box>

            {vpage && (
              <>
                <TextField
                  fullWidth
                  label={t("Sender's Name")}
                  margin="dense"
                  name="senderName"
                  onChange={handleChange}
                  type="text"
                  value={senderName}
                  variant="standard"
                />

                <TextField
                  fullWidth
                  label={t("Sender's Phone #")}
                  margin="dense"
                  name="senderPhone"
                  onChange={handleChange}
                  type="text"
                  value={senderPhone}
                  variant="standard"
                />
              </>
            )}

            <TextField
              fullWidth
              label={t('Message')}
              margin="dense"
              multiline
              name="message"
              onChange={handleChange}
              required
              rows={4}
              type="text"
              value={message}
              variant="standard"
            />
            <Typography display="block" gutterBottom variant="caption">
              {message.length}
            </Typography>
          </DialogContent>
          <DialogActions>
            <PortalButton
              color="primary"
              disabled={checked.length === 0 || message.length === 0}
              onClick={sendMessageToReceiver}
              variant="contained"
            >
              {t('Send')}
            </PortalButton>
            <PortalButton color="secondary" onClick={closeMessage} variant="contained">
              {t('Cancel')}
            </PortalButton>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="form-dialog-title1"
          fullWidth={true}
          maxWidth="sm"
          onClose={closeSharePopup}
          open={shareOpen}
        >
          <DialogTitle id="form-dialog-title1">Share Contacts</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter user name of user you wish to share contacts
            </DialogContentText>

            <TextField
              autoFocus
              fullWidth
              id="login"
              label="Username"
              margin="dense"
              onChange={(event) => setSharedLogin(event.target.value)}
              required
              type="text"
              value={sharedLogin}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <PortalButton
              color="primary"
              disabled={sharedLogin.length === 0}
              onClick={confirmShare}
              variant="contained"
            >
              {t('Share')}
            </PortalButton>
            <PortalButton color="secondary" onClick={closeSharePopup} variant="contained">
              {t('Cancel')}
            </PortalButton>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="form-dialog-title1"
          fullWidth={true}
          maxWidth="sm"
          onClose={closeDeletePopup}
          open={deleteOpen}
        >
          <DialogTitle id="form-dialog-title1">Delete Entry</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this phone book entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PortalButton color="primary" onClick={confirmDelete} variant="contained">
              {t('Delete')}
            </PortalButton>
            <PortalButton color="secondary" onClick={closeDeletePopup} variant="contained">
              {t('Cancel')}
            </PortalButton>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="form-dialog-title"
          onClose={() => setImportOpen(false)}
          open={importOpen}
        >
          <DialogTitle id="form-dialog-title">Import Contacts</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To import contacts of other users please enter below details:
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              id="login"
              label={t('Username of owner')}
              margin="dense"
              onChange={(event) => setLogin(event.target.value)}
              type="text"
              value={login}
              variant="standard"
            />
            <TextField
              autoFocus
              fullWidth
              id="password"
              label={t('Passcode')}
              margin="dense"
              onChange={(event) => setPassword(event.target.value)}
              type="text"
              value={password}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <PortalButton
              color="primary"
              disabled={login === '' || password === ''}
              onClick={importContactsOtherUser}
              variant="contained"
            >
              {t('Import')}
            </PortalButton>
            <PortalButton
              color="secondary"
              onClick={() => setImportOpen(false)}
              variant="contained"
            >
              {t('Cancel')}
            </PortalButton>
          </DialogActions>
        </Dialog>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div>
          <Tooltip title="Create new phonebook entry">
            <PortalButton className="rtPBAdd" color="primary" sx={{ scale: 1.3 }} title="">
              <AddIcon onClick={handleOpen} />
            </PortalButton>
          </Tooltip>
          <Tooltip title={vpage ? 'Internal Message' : 'Send Message'}>
            <span>
              <PortalButton className="rtPBSendMsg" color="primary">
                <TelegramIcon onClick={showMessage} />
              </PortalButton>
            </span>
          </Tooltip>

          <Tooltip title="Import Contacts from other user">
            <span>
              <PortalButton className="rtPBImportContact" color="primary">
                <ImportContactsIcon onClick={showImportOptions} />
              </PortalButton>
            </span>
          </Tooltip>

          <Tooltip title="Share Contacts to other user">
            <span>
              <PortalButton className="rtPBShareContact" color="primary">
                <ShareIcon onClick={showShareOptions} />
              </PortalButton>
            </span>
          </Tooltip>
        </div>

        <div>
          <TablePagination
            ActionsComponent={TablePaginationActions}
            component="div"
            count={contactsList ? contactsList.length : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          />
        </div>
      </CardActions>
    </StyledCard>
  );
};

export default PhoneBook;
