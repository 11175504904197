import { Button, CircularProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  DialogActions,
  Collapse,
  TextField,
  InputLabel,
  Select,
  TableSortLabel,
  MenuItem,
  Popover,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { messagesRetrievalSelector, searchMessagesAsStaff, loadingSelector } from './staffSlice';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { timezoneSelector } from '../../app/authSlice';
import { PDFDownloadLink, Document, Page, View, Text } from '@react-pdf/renderer';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Fragment, useState } from 'react';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'MessageRetrieval';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  controls: `${PREFIX}-controls`,
  messageGrid: `${PREFIX}-messageGrid`,
  paper: `${PREFIX}-paper`,
  card: `${PREFIX}-card`,
  formDateControl: `${PREFIX}-formDateControl`,
  cardContent: `${PREFIX}-cardContent`,
  cardHeader: `${PREFIX}-cardHeader`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableHead: `${PREFIX}-tableHead`,
  rowRoot: `${PREFIX}-rowRoot`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.controls}`]: {
    width: '100vw',
  },

  [`& .${classes.messageGrid}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.formDateControl}`]: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: '10px',
    flexDirection: 'row',
    alignItems: 'center',
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.cardHeader}`]: {
    margin: 0,
    padding: 10,
    background: '#e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.tableContainer}`]: {
    flextGrow: 1,
  },

  [`& .${classes.tableHead}`]: {
    background: theme.palette.primary.light,
  },

  [`& .${classes.rowRoot}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

const MessageRetrieval = () => {
  const dispatch = useDispatch();
  const [displayType, setDisplayType] = useState('list');
  const [anchorEl, setAnchorEl] = useState(null);
  const [pattern, setPattern] = useState('');
  const [searchType, setSearchType] = useState('');
  const [messageLimit, setMessageLimit] = useState(25);
  const [sequenceNumber, setSequenceNumber] = useState('');
  const msgLoading = useSelector(loadingSelector);
  const open = Boolean(anchorEl);
  const [includeF10, setIncludeF10] = useState(false);
  const id = open ? 'simple-popover' : undefined;
  const messages = useSelector(messagesRetrievalSelector);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const { t } = useTranslation();
  const [followup, setFollowup] = useState('Retrieve All Messages');
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().subtract(7, 'd').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDownloadClose = () => {
    setDownloadOpen(false);
  };

  const handleDownloads = () => {
    setDownloadOpen(true);
  };

  const [accountSortKey, setAccountSortKey] = useState('RECEIVED_DATE');
  const [accountSortOrder, setAccountSortOrder] = useState('desc');

  const defaultTimezone = useSelector(timezoneSelector);
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState({});

  const createSortHandler = (key, type) => {
    if (accountSortKey === key) {
      setAccountSortOrder(accountSortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setAccountSortOrder('asc');
    }
    setAccountSortKey(key);
  };

  const handleExpandClick = (value) => {
    var newExpanded = { ...expanded };
    if (newExpanded[value]) {
      newExpanded[value] = !newExpanded[value];
    } else {
      newExpanded[value] = true;
    }
    setExpanded(newExpanded);
  };

  const handleExpandAll = () => {
    var newExpanded = {};
    if (!expandAll) {
      messages.forEach((element) => {
        newExpanded[element.ID] = true;
      });
      setExpandAll(true);
    } else {
      messages.forEach((element) => {
        newExpanded[element.ID] = false;
      });
      setExpandAll(false);
    }
    setExpanded(newExpanded);
  };

  const exportToCSV = (option) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';
    var formattedMessages = [];
    messages.forEach((element) => {
      var msg = {};
      msg.Sequence = element.SEQUENCE_NUMBER;
      msg.Receiver = element.SHORT_PAGER_NUMBER;
      msg.HolderName = element.HOLDER_NAME;
      msg.Received = moment
        .tz(element.RECEIVED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
        .tz(defaultTimezone)
        .format('MM-DD-YYYY HH:mm:ss z');

      if (option === 2) {
        msg.EscalationStatus = element.ESCALATION_STATUS;
        if (element.ESCALATION_STATUS === 'CLEARED') {
          msg.EscalationClearedDate = moment
            .tz(element.ESCALATION_CLEARED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
            .tz(defaultTimezone)
            .format('MM-DD-YYYY HH:mm:ss z');
          msg.ElapsedTimeInMinutes = (
            moment(element.ESCALATION_CLEARED_DATE, 'YYYYMMDDHHmmss').diff(
              moment(element.RECEIVED_DATE, 'YYYYMMDDHHmmss'),
            ) / 60000
          ).toFixed(1);
          msg.EscalationClearedBy = element.ESCALATION_CLEARED_BY_NAME;
        } else {
          msg.EscalationClearedDate = '';
          msg.ElapsedTimeInMinutes = '';
          msg.EscalationClearedBy = '';
        }
      }
      msg.Notes = '';
      if (element.notes) {
        element.notes.forEach((item) => {
          msg.Notes += `Note by ${item.ADDED_BY} added on ${moment
            .tz(item.ADD_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
            .tz(defaultTimezone)
            .format('MM-DD-YYYY HH:mm:ss z')}:${item.NOTE_TXT}`;
        });
      }
      msg.Message = element.MESSAGE_FORMATTED_TXT;
      formattedMessages.push(msg);
    });
    const ws = XLSX.utils.json_to_sheet(formattedMessages);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Message List${fileExtension}`);
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'pattern':
        setPattern(event.target.value);
        break;
      case 'searchType':
        setSearchType(event.target.value);
        setPattern('');
        break;
      case 'sequenceNumber':
        setSequenceNumber(event.target.value);
        break;
      case 'messageLimit':
        setMessageLimit(event.target.value);
        break;
      case 'includeF10':
        setIncludeF10(event.target.checked);
        break;
      case 'followup':
        setFollowup(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSearch = (event) => {
    if (searchType === 'Answer Line' && pattern === '0000000000') {
      return;
    }

    if (pattern.length === 0) {
      return;
    }
    var query = `queryTimeLimit=45000&includeF10=${
      includeF10 ? 'true' : 'false'
    }&followupRequiredOnly=${followup === 'Retrieve Only Followup Required'}`;
    switch (searchType) {
      case 'Receiver':
        query += `&sequenceNumber=${sequenceNumber}`;
        dispatch(
          searchMessagesAsStaff(
            pattern,
            null,
            null,
            null,
            moment(selectionRange[0].startDate).startOf('day').format('YYYYMMDDHHmmss'),
            moment(selectionRange[0].endDate).endOf('day').format('YYYYMMDDHHmmss'),
            query,
          ),
        );
        break;
      case 'Operator':
        dispatch(
          searchMessagesAsStaff(
            null,
            pattern,
            null,
            null,
            moment(selectionRange[0].startDate).startOf('day').format('YYYYMMDDHHmmss'),
            moment(selectionRange[0].endDate).endOf('day').format('YYYYMMDDHHmmss'),
            query,
          ),
        );
        break;
      case 'Answer Line':
        dispatch(
          searchMessagesAsStaff(
            null,
            null,
            pattern,
            null,
            moment(selectionRange[0].startDate).startOf('day').format('YYYYMMDDHHmmss'),
            moment(selectionRange[0].endDate).endOf('day').format('YYYYMMDDHHmmss'),
            query,
          ),
        );
        break;
      case 'Recording Box':
        dispatch(
          searchMessagesAsStaff(
            null,
            null,
            null,
            pattern,
            moment(selectionRange[0].startDate).startOf('day').format('YYYYMMDDHHmmss'),
            moment(selectionRange[0].endDate).endOf('day').format('YYYYMMDDHHmmss'),
            query,
          ),
        );
        break;
      default:
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges) => {
    var current = selectionRange;
    current[0].startDate = ranges.selection.startDate;
    current[0].endDate = ranges.selection.endDate;
    setSelectionRange(current);
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      setAnchorEl(false);
    }
  };

  return (
    <StyledGrid className={classes.root} container spacing={2}>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="search-label">Query Type*</InputLabel>
          <Select
            id="search"
            labelId="search-label"
            name="searchType"
            onChange={handleChange}
            required
            value={searchType}
          >
            {['Receiver', 'Operator', 'Answer Line', 'Recording Box'].map((value) => (
              <MenuItem key={`mi-${value}`} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="search-label">Follow up</InputLabel>
          <Select
            id="search"
            labelId="search-label"
            name="followup"
            onChange={handleChange}
            value={followup}
          >
            {['Retrieve All Messages', 'Retrieve Only Followup Required'].map((value, index) => (
              <MenuItem key={index} value={value}>
                {t(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} fullWidth>
          <TextField
            fullWidth
            label="Query"
            margin="dense"
            name="pattern"
            onChange={handleChange}
            required
            type="text"
            value={pattern}
            variant="standard"
          />
        </FormControl>

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel htmlFor="date-input">Date Range</InputLabel>
        </FormControl>

        <FormControl className={classes.formDateControl} fullWidth variant="standard">
          <Typography variant="caption">
            {selectionRange[0].startDate.toDateString()} -{' '}
            {selectionRange[0].endDate.toDateString()}
          </Typography>
          <PortalIconButton
            aria-describedby={id}
            aria-label="upload picture"
            className={classes.fab}
            component="button"
            id="date-input"
            onClick={handleClick}
            variant="contained"
          >
            <DateRangeIcon />
          </PortalIconButton>
        </FormControl>

        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          id={id}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DateRangePicker
            inputRanges={[]}
            onChange={handleSelect}
            ranges={selectionRange}
            showMonthAndYearPickers={true}
            showSelectionPreview={true}
          />
        </Popover>

        {searchType === 'Receiver' && (
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              fullWidth
              label="Sequence Number"
              margin="dense"
              name="sequenceNumber"
              onChange={handleChange}
              type="text"
              value={sequenceNumber}
              variant="standard"
            />
          </FormControl>
        )}

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={<Checkbox checked={includeF10} name="includeF10" onChange={handleChange} />}
            label="Include F10"
            labelPlacement="start"
            value="start"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <PortalButton
            color="primary"
            disabled={
              pattern.length === 0 ||
              searchType === '' ||
              (searchType === 'Answer Line' && pattern === '0000000000')
            }
            onClick={handleSearch}
            variant="contained"
          >
            Search
          </PortalButton>
        </FormControl>

        {messages && messages.length > 0 && (
          <FormControl className={classes.formControl}>
            <PortalButton
              color="secondary"
              onClick={handleExpandAll}
              sx={{ ml: 5 }}
              variant="contained"
            >
              {expandAll ? 'Collapse All' : 'Expand All'}
            </PortalButton>
          </FormControl>
        )}
        {messages && messages.length > 0 && (
          <PortalIconButton
            aria-label="download"
            component="span"
            onClick={handleDownloads}
            size="medium"
            sx={{ ml: 5 }}
            variant="contained"
          >
            <Tooltip disableFocusListener title="Download Messages">
              <GetAppIcon />
            </Tooltip>
          </PortalIconButton>
        )}
      </Grid>

      <Grid item xs={12}>
        {msgLoading && <CircularProgress color="secondary" />}
        <Grid className={classes.messageGrid} container spacing={2}>
          <Grid item xs={12}>
            {searchType === 'Receiver' && messages.length > 0 && (
              <div style={{ margin: 10 }}>
                <Typography variant="subtitle2"> Receiver: {messages[0].PAGER_NUMBER}</Typography>
                <Typography variant="subtitle2"> Account : {messages[0].ACCOUNT_NUMBER}</Typography>
                <Typography variant="subtitle2">Holder Name: {messages[0].HOLDER_NAME}</Typography>
                <Typography variant="subtitle2"> Maximum Message: 800</Typography>
              </div>
            )}

            {messages && <Typography>{`Total Messages Retrieved : ${messages.length}`}</Typography>}

            <TableContainer component={Paper}>
              <Table aria-label="a dense table" className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell />
                    <TableCell align="left">
                      <TableSortLabel
                        active={accountSortKey === 'RECEIVED_DATE'}
                        classes={{ active: classes.active, icon: classes.icon }}
                        direction={accountSortOrder === 'asc' ? 'asc' : 'desc'}
                        key="RECEIVED_DATE"
                        onClick={() => createSortHandler('RECEIVED_DATE', 'Account')}
                      >
                        Time Received
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={accountSortKey === 'OPERATOR_NAME'}
                        classes={{ active: classes.active, icon: classes.icon }}
                        direction={accountSortOrder === 'asc' ? 'asc' : 'desc'}
                        key="OPERATOR_NAME"
                        onClick={() => createSortHandler('OPERATOR_NAME', 'Account')}
                      >
                        Agent
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={accountSortKey === 'DURATION'}
                        classes={{ active: classes.active, icon: classes.icon }}
                        direction={accountSortOrder === 'asc' ? 'asc' : 'desc'}
                        key="DURATION"
                        onClick={() => createSortHandler('DURATION', 'Account')}
                      >
                        Duration
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Message</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages &&
                    messages.length > 0 &&
                    messages
                      .slice()
                      .sort((a, b) =>
                        accountSortOrder === 'asc'
                          ? a[accountSortKey] > b[accountSortKey]
                            ? 1
                            : -1
                          : b[accountSortKey] > a[accountSortKey]
                            ? 1
                            : -1,
                      )
                      .map((row) => (
                        <Fragment key={row.ID}>
                          <TableRow className={classes.rowRoot} key={row.ID}>
                            <TableCell>
                              <PortalIconButton
                                aria-expanded={expanded}
                                aria-label="show more"
                                className={clsx(classes.expand, {
                                  [classes.expandOpen]: expanded,
                                })}
                                color="primary"
                                onClick={() => handleExpandClick(row.ID)}
                              >
                                {expanded[row.ID] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </PortalIconButton>
                            </TableCell>
                            <TableCell align="left">
                              {moment
                                .tz(row.RECEIVED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                                .tz(defaultTimezone)
                                .format('MM-DD-YYYY HH:mm:ss z')}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.OPERATOR_NAME}
                            </TableCell>
                            <TableCell align="left">{row.DURATION}</TableCell>
                            <TableCell align="left">
                              {parseInt(row.SEQUENCE_NUMBER) > 0
                                ? `${row.SEQUENCE_NUMBER}) `
                                : 'NA) '}
                              {row.MESSAGE_FORMATTED_TXT}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={6}>
                              <Collapse in={expanded[row.ID]} timeout="auto" unmountOnExit>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <TableContainer
                                      className={classes.tableContainer}
                                      component={Paper}
                                    >
                                      <Table
                                        aria-label="simple dense table"
                                        className={classes.table}
                                        size="small"
                                      >
                                        <TableBody>
                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Agent
                                            </TableCell>
                                            <TableCell align="right">{row.OPERATOR_NAME}</TableCell>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Receiver
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.ORIGINAL_PAGER_NUMBER}
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Account Number
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.ACCOUNT_NUMBER}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Answer Line
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.ANSWER_LINE_NUMBER}
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Last Navigation Line
                                            </TableCell>
                                            <TableCell align="right">{row.LINE_NUMBER}</TableCell>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Time Sent{' '}
                                            </TableCell>
                                            <TableCell align="right">
                                              {moment
                                                .tz(
                                                  row.SENT_DATE,
                                                  'YYYYMMDDHHmmss',
                                                  'America/New_York',
                                                )
                                                .tz(defaultTimezone)
                                                .format('MMM DD hh:mm A z')}
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Primary Flag
                                            </TableCell>
                                            <TableCell align="right">{row.PRIMARY_FLAG}</TableCell>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              IVR ACCESS
                                            </TableCell>
                                            <TableCell align="right">{row.IVR_ACCESS}</TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                              Audio
                                            </TableCell>
                                            <TableCell align="right">
                                              <a
                                                href={`http://mapws.internal/engageFetch/?dnis=${
                                                  row.LINE_NUMBER
                                                }&date=${row.RECEIVED_DATE}`}
                                                rel="noreferrer"
                                                target="_blank"
                                              >
                                                Search Engage
                                              </a>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {' '}
                                            </TableCell>
                                            <TableCell align="right" />
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TableContainer
                                      className={classes.tableContainer}
                                      component={Paper}
                                    >
                                      <Table
                                        aria-label="simple dense table"
                                        className={classes.table}
                                        size="small"
                                      >
                                        <TableHead className={classes.tableHead}>
                                          <TableRow>
                                            <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                              Delivery Details
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell style={{ textAlign: 'center' }}>
                                              Priority
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                              Time Sent
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                              Result
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.deliveryDetails &&
                                            row.deliveryDetails.map((row) => (
                                              <TableRow key={row.id}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{ textAlign: 'center' }}
                                                >
                                                  {row.PRIORITY}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {moment
                                                    .tz(
                                                      row.DT_RECVD,
                                                      'YYYYMMDDHHmmss',
                                                      'America/New_York',
                                                    )
                                                    .tz(defaultTimezone)
                                                    .format('MMM DD hh:mm A z')}
                                                </TableCell>
                                                <TableCell align="center">{row.RESULT}</TableCell>
                                              </TableRow>
                                            ))}
                                          {row.deliveryDetails &&
                                            row.deliveryDetails.length === 0 && (
                                              <TableRow>
                                                <TableCell colSpan={3}>
                                                  {t('no records found')}
                                                </TableCell>
                                              </TableRow>
                                            )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}

                  {messages.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography>{t('no records found')}</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Dialog
          aria-labelledby="simple-dialog-title1"
          className={classes.dialog}
          maxWidth="md"
          onClose={handleDownloadClose}
          open={downloadOpen}
        >
          <DialogTitle id="simple-dialog-title1" sx={{ textAlign: 'center' }}>
            Downloads
          </DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <DialogContentText id="alert-dialog-description1" />

            <PortalButton color="primary" onClick={() => exportToCSV(1)} variant="contained">
              CSV
            </PortalButton>

            <PortalButton color="primary" onClick={() => exportToCSV(2)} variant="contained">
              CSV with Details
            </PortalButton>

            {downloadOpen && (
              <PDFDownloadLink
                document={
                  <Document>
                    <Page size="A4" style={{ padding: 20, fontSize: 10, fontWeight: 'normal' }}>
                      {messages &&
                        messages.length > 0 &&
                        messages.map((item) => (
                          <View key={item.SEQUENCE_NUMBER} style={{ margin: 10 }}>
                            <Text>
                              {parseInt(item.SEQUENCE_NUMBER) > 0
                                ? `${item.SEQUENCE_NUMBER}) ID :`
                                : ''}
                              {`${item.SHORT_PAGER_NUMBER},${item.HOLDER_NAME} Received on ${moment
                                .tz(item.RECEIVED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                                .tz(defaultTimezone)
                                .format('MMM DD hh:mm A z')}`}
                            </Text>
                            <Text style={{ fontSize: 12, fontWeight: 'bolder' }}>
                              {item.MESSAGE_FORMATTED_TXT}
                            </Text>

                            {item.notes &&
                              item.notes.map((element, index) => (
                                <Text key={index} style={{ fontSize: 12, fontWeight: 'bolder' }}>
                                  {' '}
                                  {`Note added on ${moment
                                    .tz(element.ADD_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                                    .tz(defaultTimezone)
                                    .format('MMM DD hh:mm A z')}:${element.NOTE_TXT}`}
                                </Text>
                              ))}

                            <Text>{`Escalation ${item.ESCALATION_STATUS}`}</Text>
                          </View>
                        ))}
                    </Page>
                  </Document>
                }
                fileName="Messages.pdf"
                style={{ textDecoration: 'none' }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <PortalButton color="primary" variant="contained">
                      Pdf
                    </PortalButton>
                  )
                }
              </PDFDownloadLink>
            )}

            <PDFDownloadLink
              document={
                <Document>
                  <Page size="A4" style={{ padding: 20, fontSize: 10, fontWeight: 'normal' }}>
                    {messages &&
                      messages.length > 0 &&
                      messages.map((item, index) => (
                        <View key={index} style={{ margin: 10 }}>
                          <Text>
                            {parseInt(item.SEQUENCE_NUMBER) > 0
                              ? `${item.SEQUENCE_NUMBER} ) ID `
                              : ' '}
                            {`:${item.SHORT_PAGER_NUMBER},${item.HOLDER_NAME} Received on ${moment
                              .tz(item.RECEIVED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                              .format('MMM DD hh:mm A z')}`}
                          </Text>
                          <Text style={{ fontSize: 12, fontWeight: 'bolder' }}>
                            {item.MESSAGE_FORMATTED_TXT}
                          </Text>

                          {item.notes &&
                            item.notes.map((element, index) => (
                              <Text key={index} style={{ fontSize: 12, fontWeight: 'bolder' }}>
                                {' '}
                                {`Note added on ${moment
                                  .tz(element.ADD_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                                  .tz(defaultTimezone)
                                  .format('MMM DD hh:mm A z')}:${element.NOTE_TXT}`}
                              </Text>
                            ))}

                          <Text>{`Escalation ${item.ESCALATION_STATUS}`}</Text>

                          {item.deliveryDetails &&
                            item.deliveryDetails.map((element, index) => (
                              <Text key={index} style={{ fontSize: 12, fontWeight: 'bolder' }}>
                                {' '}
                                {`${element.PRIORITY} ${moment
                                  .tz(element.DT_RECVD, 'YYYYMMDDHHmmss', 'America/New_York')
                                  .tz(defaultTimezone)
                                  .format('MMM DD hh:mm A z')} ${
                                  element.DIRECTORY_NAME ? element.DIRECTORY_NAME : ''
                                } ${element.DIRECTORY_TYPE ? element.DIRECTORY_TYPE : ''} ${
                                  element.RESULT
                                }`}
                              </Text>
                            ))}
                        </View>
                      ))}
                  </Page>
                </Document>
              }
              fileName="Messages.pdf"
              style={{ textDecoration: 'none' }}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <PortalButton color="primary" variant="contained">
                    Pdf with Details
                  </PortalButton>
                )
              }
            </PDFDownloadLink>
          </DialogContent>
          <DialogActions>
            <PortalButton color="secondary" onClick={handleDownloadClose} variant="contained">
              Cancel
            </PortalButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </StyledGrid>
  );
};
export default MessageRetrieval;
