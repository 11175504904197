import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PREFIX = 'Metric';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  widgetHeader: `${PREFIX}-widgetHeader`,
  widgetBody: `${PREFIX}-widgetBody`,
  widgetKey: `${PREFIX}-widgetKey`,
  iconholder: `${PREFIX}-iconholder`,
  icon: `${PREFIX}-icon`,
  content: `${PREFIX}-content`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    borderRadius: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    color: 'white',
    fontWeight: 'bold',
  },

  [`& .${classes.widgetHeader}`]: {
    display: 'flex',
    verticalAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    //overflow:'hidden',
    padding: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    background: theme.palette.primary.light,
  },

  [`& .${classes.widgetBody}`]: {
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    verticalAlign: 'center',
    alignItems: 'stretch',
  },

  [`& .${classes.widgetKey}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.iconholder}`]: {
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'center',
    alignItems: 'stretch',
  },

  [`& .${classes.icon}`]: {
    padding: 10,
  },

  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Metric = (props) => {
  const titles = {
    totalMinutesMetric: 'Total Minutes',
    avgMinutesMetric: 'Avg Minutes',
    totalCallsMetric: 'Total Calls',
  };

  const { t } = useTranslation();

  return (
    <StyledPaper className={classes.root} elevation={2}>
      <div className={classes.widgetHeader}>
        {props.text && titles && (
          <Typography className={classes.title} variant="caption">
            {t(titles[props.text]).toUpperCase()}
          </Typography>
        )}
      </div>

      <div className={classes.widgetBody}>
        <div className={classes.content}>
          <Typography className={classes.widgetKey} color="primary" variant="overline">
            {t('Call Center')}
          </Typography>
          <Typography color="textPrimary" variant="subtitle2">
            {props.text === 'avgMinutesMetric' || props.text === 'totalMinutesMetric'
              ? (props.callCenter / 60).toFixed(2)
              : props.callCenter}
          </Typography>
        </div>
        <div>
          {props.type === 'call' && <CallSplitIcon className={classes.icon} />}
          {props.type === 'time' && <TimelapseIcon className={classes.icon} />}
          {props.type === 'avg' && <CallSplitIcon className={classes.icon} />}
        </div>

        <div className={classes.content}>
          <Typography className={classes.widgetKey} color="primary" variant="overline">
            {t('Automated')}
          </Typography>
          <Typography color="textPrimary" variant="subtitle2">
            {props.text === 'avgMinutesMetric' || props.text === 'totalMinutesMetric'
              ? (props.automated / 60).toFixed(2)
              : props.automated}
          </Typography>
        </div>
      </div>
    </StyledPaper>
  );
};

export default Metric;
