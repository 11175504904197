export const TASK_STATUS = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  COMPLETED: 'COMPLETED',
};

export const TaskStatusColorCode = {
  [TASK_STATUS.ACTIVE]: 'green',
  [TASK_STATUS.PAUSED]: '#ff8c00',
  [TASK_STATUS.COMPLETED]: 'gray',
};

export const TaskTrackerSource = {
  AGENT_ASSIST: 'AGENT_ASSIST',
  AGENT_ESCALATED: 'AGENT_ESCALATED',
  CHAT: 'CHAT',
  CLIENT_CALL: 'CLIENT_CALL',
  CLIENT_EMAIL: 'CLIENT_EMAIL',
  DISPATCHER_ASSIST: 'DISPATCHER_ASSIST',
  DISPATCHER_ESCALATED: 'DISPATCHER_ESCALATED',
  INTERNAL_FIND: 'INTERNAL_FIND',
  OTHER: 'OTHER',
  PORTAL: 'PORTAL',
  WALK_IN: 'WALK_IN',
};

export const DefaultTaskTrackerStatusOrder = {
  [TASK_STATUS.ACTIVE]: 1,
  [TASK_STATUS.PAUSED]: 2,
  [TASK_STATUS.COMPLETED]: 3,
};
