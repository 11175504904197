import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const PortalLightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const PortalBootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const getHtmlTooltipStyles = (theme) => ({
  [tooltipClasses.tooltip]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: 10,
  },
});

export const PortalHtmlTooltip = styled(({ className, styles, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, styles }) => {
  const htmlTooltipStyles = getHtmlTooltipStyles(theme);
  return {
    [`& .${tooltipClasses.tooltip}`]:
      styles && styles[tooltipClasses.tooltip]
        ? styles[tooltipClasses.tooltip]
        : htmlTooltipStyles[tooltipClasses.tooltip],
  };
});
