import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../PortalApi';
import { setSearchAccounts, setSelectedSearchAccount, staffSearch } from '../accounts/accountSlice';
import { getPortalUser, setPortalSnackInfo } from '../../app/authSlice';
import { DefaultTaskTrackerStatusOrder } from '../../utils/taskTracker';

const startState = {
  availableProducts: [],
  tasks: [],
  messages: [],
  lastDispatchMode: '',
  lastDispatchStatus: false,
  createTask: '',
  updateTask: '',
  continueTask: '',
  pauseTask: '',
  stopTask: '',
  overrideTask: '',
  updateUserResponse: '',
  loading: false,
  options: {},
  taxes: {},
  zip: {},
  searchUsers: [],
  createUser: {},
  verifyUserName: '',
  verifyEmail: '',
  chatAffiliations: [],
  selAffiliation: '',
  selAffiliationUsers: [],
  verifiedCallerIds: {},
  twilioNumberByAreaCode: [],
  addVerifyCallerResponse: null,
  updateUserEmailStatus: false,
  callerIDValidationCode: {},
  taskTrackerShortKey: 'creation',
  taskTrackerShortOrder: 'desc',
  taskTrackerStatusOrder: DefaultTaskTrackerStatusOrder,
  brandNewFeatures: [],
  updateBrandNewFeaturesResponse: '',
  notificationHistory: [],
};

export const staffSlice = createSlice({
  name: 'staff',
  initialState: startState,
  reducers: {
    setVerifyUserName: (state, action) => {
      state.verifyUserName = action.payload;
    },
    setCallerIdValidationCode: (state, action) => {
      state.callerIDValidationCode = action.payload;
    },
    setVerifyEmail: (state, action) => {
      state.verifyEmail = action.payload;
    },
    setAvailableProducts: (state, action) => {
      state.availableProducts = action.payload;
    },
    setOptions: (state, action) => {
      state.options = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setCreateUser: (state, action) => {
      state.createUser = action.payload;
    },
    setCreateTask: (state, action) => {
      state.createTask = action.payload;
    },
    setChatAffiliations: (state, action) => {
      state.chatAffiliations = action.payload;
    },
    setSelAffiliation: (state, action) => {
      state.selAffiliation = action.payload;
    },
    setTwilioNumberByAreaCode: (state, action) => {
      state.twilioNumberByAreaCode = action.payload;
    },
    setSelAffiliationUsers: (state, action) => {
      state.selAffiliationUsers = action.payload;
    },
    setUpdateTask: (state, action) => {
      state.updateTask = action.payload;

      if (action.payload.response && action.payload.response.result === 'SUCCESS') {
        var newTasks = state.tasks;
        newTasks.forEach((element) => {
          if (element.id === action.payload.id && action.payload.response) {
            element.caller = action.payload.info.caller;
            element.category = action.payload.info.category;
            element.description = action.payload.info.description;
            element.dueDate = action.payload.info.dueDate;
            element.taskSourceRef = action.payload.info.taskSourceRef;
            element.taskType = action.payload.info.taskType;
            element.companyTxt = action.payload.info.companyValue;
            element.managerLogin = action.payload.info.managerId;
          }
        });
        state.tasks = newTasks;
      }
    },
    setContinueTask: (state, action) => {
      state.continueTask = action.payload;
    },
    setPauseTask: (state, action) => {
      state.pauseTask = action.payload;
    },
    setStopTask: (state, action) => {
      state.stopTask = action.payload;
    },
    setOverrideTask: (state, action) => {
      state.overrideTask = action.payload;
    },
    setLastDispatchMode: (state, action) => {
      state.lastDispatchMode = action.payload;
    },
    setLastDispatchStatus: (state, action) => {
      state.lastDispatchStatus = action.payload;
    },
    setMessageRetrieval: (state, action) => {
      state.messages = action.payload;
    },
    setUpdateUserResponse: (state, action) => {
      state.updateUserResponse = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setZip: (state, action) => {
      state.zip = action.payload;
    },
    setTaxes: (state, action) => {
      // var newTaxes = {...state.taxes}
      // newTaxes[action.payload.id]=action.payload.tax;
      // state.taxes = newTaxes;
      state.taxes = action.payload;
    },
    setSearchUsers: (state, action) => {
      state.searchUsers = action.payload;
    },
    setVerifiedCallerIds: (state, action) => {
      state.verifiedCallerIds = action.payload;
    },
    setAddVerifyCallerResponse: (state, action) => {
      state.addVerifyCallerResponse = action.payload;
    },
    setUpdateUserEmailStatus: (state, action) => {
      state.updateUserEmailStatus = action.payload;
    },
    setTaskTrackerShortKey: (state, action) => {
      state.taskTrackerShortKey = action.payload;
    },
    setTaskTrackerShortOrder: (state, action) => {
      state.taskTrackerShortOrder = action.payload;
    },
    setTaskTrackerStatusOrder: (state, action) => {
      state.taskTrackerStatusOrder = action.payload;
    },
    setBrandNewFeatures: (state, action) => {
      state.brandNewFeatures = action.payload;
    },
    setUpdateBrandNewFeaturesResponse: (state, action) => {
      state.updateBrandNewFeaturesResponse = action.payload;
    },
    setNotificationHistory: (state, action) => {
      state.notificationHistory = action.payload;
    },
  },
});

export const {
  setZip,
  setCallerIdValidationCode,
  setVerifyUserName,
  setVerifyEmail,
  setCreateUser,
  setOptions,
  setLoading,
  setUpdateUserResponse,
  setAvailableProducts,
  setTasks,
  setCreateTask,
  setUpdateTask,
  setContinueTask,
  setPauseTask,
  setStopTask,
  setOverrideTask,
  setLastDispatchMode,
  setLastDispatchStatus,
  setMessageRetrieval,
  setTaxes,
  setSearchUsers,
  setChatAffiliations,
  setSelAffiliation,
  setTwilioNumberByAreaCode,
  setSelAffiliationUsers,
  setManagers,
  setVerifiedCallerIds,
  setAddVerifyCallerResponse,
  setUpdateUserEmailStatus,
  setTaskTrackerShortKey,
  setTaskTrackerShortOrder,
  setTaskTrackerStatusOrder,
  setBrandNewFeatures,
  setUpdateBrandNewFeaturesResponse,
  setNotificationHistory,
} = staffSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const availableProductsSelector = (state) => state.staff.availableProducts;

export const tasksSelector = (state) => state.staff.tasks;
export const optionsSelector = (state) => state.staff.options;
export const createTaskSelector = (state) => state.staff.createTask;
export const updateTaskSelector = (state) => state.staff.updateTask;
export const continueTaskSelector = (state) => state.staff.continueTask;
export const pauseTaskSelector = (state) => state.staff.pauseTask;
export const stopTaskSelector = (state) => state.staff.stopTask;
export const overrideTaskSelector = (state) => state.staff.overrideTask;
export const callerIdValidationCodeSelector = (state) => state.staff.callerIDValidationCode;
export const lastDispatchModeSelector = (state) => state.staff.lastDispatchMode;
export const lastDispatchStatusSelector = (state) => state.staff.lastDispatchStatus;
export const messagesRetrievalSelector = (state) => state.staff.messages;
export const updateUserResponseSelector = (state) => state.staff.updateUserResponse;
export const loadingSelector = (state) => state.staff.loading;
export const taxesSelector = (state) => state.staff.taxes;
export const zipSelector = (state) => state.staff.zip;
export const searchUsersSelector = (state) => state.staff.searchUsers;

export const verifyUserNameSelector = (state) => state.staff.verifyUserName;
export const verifyEmailSelector = (state) => state.staff.verifyEmail;

export const createUserSelector = (state) => state.staff.createUser;

export const chatAffiliationsSelector = (state) => state.staff.chatAffiliations;
export const selAffiliationSelector = (state) => state.staff.selAffiliation;
export const setTwilioNumberByAreaCodeSelector = (state) => state.staff.twilioNumberByAreaCode;
export const selAffiliationUsersSelector = (state) => state.staff.selAffiliationUsers;
export const verifiedCallerIdsSelector = (state) => state.staff.verifiedCallerIds;
export const addVerifyCallerResponseSelector = (state) => state.staff.addVerifyCallerResponse;
export const updateUserEmailStatusSelector = (state) => state.staff.updateUserEmailStatus;

export const taskTrackerSortKeySelector = (state) => state.staff.taskTrackerShortKey;
export const taskTrackerSortOrderSelector = (state) => state.staff.taskTrackerShortOrder;
export const taskTrackerStatusOrderSelector = (state) => state.staff.taskTrackerStatusOrder;
export const brandNewFeaturesSelector = (state) => state.staff.brandNewFeatures;
export const updateBrandNewFeaturesResponseSelector = (state) =>
  state.staff.updateBrandNewFeaturesResponse;

export const notificationHistorySelector = (state) => state.staff.notificationHistory;

export function searchMessagesAsStaff(
  receiverId,
  operator,
  answerLine,
  recordingBoxId,
  startDate,
  endDate,
  query,
) {
  return (dispatch) => {
    dispatch(setMessageRetrieval([]));
    dispatch(setLoading(true));

    try {
      if (operator) {
        query += `&operator=${operator}`;
      }
      if (answerLine) {
        query += `&line=${answerLine}`;
      }
      if (recordingBoxId) {
        query += `&recordingBoxId=${recordingBoxId}`;
      }
      query += `&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;

      PortalApi.call(
        `/message/receiver/${encodeURIComponent(receiverId)}/?${query}`,
        { method: 'get' },
        (response) => {
          dispatch(setMessageRetrieval(response.messages));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setMessageRetrieval([]));
          dispatch(setLoading(true));
        },
      );
    } catch (error) {
      dispatch(setMessageRetrieval([]));
      dispatch(setLoading(true));
    }
  };
}

export function updateUser(user) {
  return (dispatch) => {
    dispatch(setUpdateUserResponse(''));
    try {
      dispatch(setUpdateUserResponse({}));
      PortalApi.call(
        '/staff/user',
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setUpdateUserResponse({ status: stats, user: user }));
          let message = '';
          let severity = '';
          if (stats === 'SUCCESS') {
            message = 'User updated successfully.';
            severity = 'success';
          } else if (stats === 'INVALIDLOGIN') {
            message = `Could not find Employee configuration for account with login ${
              user ? user.login : ''
            }! Cannot change this account to Staff Account as a result`;
            severity = 'error';
          } else if (stats === 'INVALIDERP') {
            message = `Could not find ERP configuration for account with login ${user ? user.login : ''}`;
            severity = 'error';
          }
          if (message && severity) {
            dispatch(setPortalSnackInfo({ severity, message }));
          }
          dispatch(staffSearch(user.email));
        },
        (error, status, content) => {
          dispatch(setUpdateUserResponse(''));
        },
      );
    } catch (error) {
      dispatch(setUpdateUserResponse(''));
    }
  };
}

export function updateBrandNewFeatures(brandNewfeatures) {
  return (dispatch) => {
    dispatch(setUpdateBrandNewFeaturesResponse(''));
    try {
      PortalApi.call(
        '/staff/updateBrandNewFeatures',
        { method: 'post', body: JSON.stringify(brandNewfeatures) },
        (stats) => {
          if (stats === 'success') {
            dispatch(setUpdateBrandNewFeaturesResponse(stats));
            dispatch(getBrandNewFeatures());
            //dispatch(getPortalUser());
          }
        },
        (error, status, content) => {
          dispatch(setUpdateBrandNewFeaturesResponse(''));
        },
      );
    } catch (error) {
      dispatch(setUpdateBrandNewFeaturesResponse(''));
    }
  };
}

export function getBrandNewFeatures() {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setBrandNewFeatures([]));
    try {
      PortalApi.call(
        '/staff/brandNewFeatures',
        { method: 'get' },
        (stats) => {
          console.log('brand data response', stats);
          dispatch(setBrandNewFeatures(stats));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setBrandNewFeatures([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setBrandNewFeatures([]));
      dispatch(setLoading(false));
    }
  };
}

/* get tasks created by the webusers with in last 30 days */
export function getTasks() {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setTasks([]));
    try {
      PortalApi.call(
        '/tasks/list?days=30',
        { method: 'get' },
        (stats) => {
          dispatch(setTasks(stats));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setTasks([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setTasks([]));
      dispatch(setLoading(false));
    }
  };
}

export const getNotificationHistory = (webuserId, type) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/staff/user/${webuserId}/notificationHistory/${type}`,
        { method: 'get' },
        (stats) => {
          dispatch(setNotificationHistory(stats));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setNotificationHistory([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setNotificationHistory([]));
    }
  };
};

/* get tasks dropdown options*/
export function getTasksOptions() {
  return (dispatch) => {
    dispatch(setOptions({}));
    try {
      PortalApi.call(
        '/tasks/helper',
        { method: 'get' },
        (stats) => {
          dispatch(setOptions(stats));
        },
        (error, status, content) => {
          dispatch(setOptions({}));
        },
      );
    } catch (error) {
      dispatch(setOptions({}));
    }
  };
}

export function getAvailableProducts() {
  return (dispatch) => {
    dispatch(setAvailableProducts([]));
    try {
      PortalApi.call(
        '/products/list?company=MAP COMMUNICATIONS',
        { method: 'get' },
        (stats) => {
          dispatch(setAvailableProducts(stats));
        },
        (error, status, content) => {
          dispatch(setAvailableProducts([]));
        },
      );
    } catch (error) {
      dispatch(setAvailableProducts([]));
    }
  };
}

export function getZip(zipCode) {
  return (dispatch) => {
    dispatch(setZip({}));
    try {
      PortalApi.call(
        `/payments/zip/${encodeURIComponent(zipCode)}`,
        { method: 'get' },
        (stats) => {
          dispatch(setZip(stats));
        },
        (error, status, content) => {
          dispatch(setZip({}));
        },
      );
    } catch (error) {
      dispatch(setZip({}));
    }
  };
}

export function getTaxforProduct(productId, zip, quantity, unitPrice) {
  return (dispatch) => {
    try {
      PortalApi.call(
        `/products/tax?productMasterId=${encodeURIComponent(productId)}&zip=${encodeURIComponent(
          zip,
        )}&quantity=${quantity}&unitPrice=${unitPrice}`,
        { method: 'get' },
        (stats) => {
          dispatch(setTaxes({ tax: stats, id: productId }));
        },
        (error, status, content) => {
          console.error('Error', error);
        },
      );
    } catch (error) {
      console.error('Error ', error);
    }
  };
}

export function createTask(task) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setCreateTask(''));
    dispatch(setLastDispatchStatus(false));
    try {
      PortalApi.call(
        '/tasks',
        { method: 'post', body: JSON.stringify(task) },
        (stats) => {
          if (stats === 'SUCCESS') {
            dispatch(getTasks());
            dispatch(setSelectedSearchAccount(''));
            dispatch(setSearchAccounts([]));
          }
          dispatch(
            setPortalSnackInfo({
              severity: stats === 'SUCCESS' ? 'success' : 'error',
              message: stats === 'SUCCESS' ? 'Task created successfully.' : stats,
            }),
          );
          dispatch(setCreateTask(stats));
          dispatch(setLoading(false));
          dispatch(setLastDispatchStatus(true));
        },
        (error, status, content) => {
          dispatch(setCreateTask(''));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setCreateTask(''));
      dispatch(setLoading(false));
    }
  };
}

export function updateTask(id, task, background) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setUpdateTask(''));
    if (!background) {
      dispatch(setLastDispatchStatus(false));
    }

    try {
      PortalApi.call(
        `/tasks/${encodeURIComponent(id)}`,
        { method: 'post', body: JSON.stringify(task) },
        (stats) => {
          dispatch(setUpdateTask({ response: stats, info: task, id: id }));
          dispatch(setLoading(false));
          if (!background) {
            dispatch(setLastDispatchStatus(true));
          }
        },
        (error, status, content) => {
          dispatch(setUpdateTask(''));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setUpdateTask(''));
      dispatch(setLoading(false));
    }
  };
}

export function continueTask(id, type, description) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setContinueTask(''));
    dispatch(setLastDispatchStatus(false));
    try {
      var task = {
        description: description,
      };
      PortalApi.call(
        `/tasks/${encodeURIComponent(id)}/${encodeURIComponent(type)}`,
        { method: 'post', body: JSON.stringify(task) },
        (stats) => {
          dispatch(setContinueTask(stats));
          dispatch(setLoading(false));
          dispatch(setLastDispatchStatus(true));
          dispatch(getTasks());
        },
        (error, status, content) => {
          dispatch(setContinueTask(''));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setContinueTask(''));
      dispatch(setLoading(false));
    }
  };
}

export function pauseTask(id, type, description) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setPauseTask(''));
    dispatch(setLastDispatchStatus(false));
    try {
      var task = {
        description: description,
      };
      PortalApi.call(
        `/tasks/${encodeURIComponent(id)}/${encodeURIComponent(type)}`,
        { method: 'post', body: JSON.stringify(task) },
        (stats) => {
          dispatch(setPauseTask(stats));
          dispatch(setLoading(false));
          dispatch(setLastDispatchStatus(true));
          dispatch(getTasks());
        },
        (error, status, content) => {
          dispatch(setPauseTask(''));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setPauseTask(''));
    }
  };
}

export function stopTask(
  id,
  type,
  description,
  followUpFlag,
  category,
  caller,
  taskType,
  taskSourceRef,
  dueDate,
) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setStopTask(''));
    dispatch(setLastDispatchStatus(false));
    try {
      var task = {
        description: description,
        followUpFlag: followUpFlag,
        category: category,
        caller: caller,
        taskType: taskType,
        taskSourceRef: taskSourceRef,
        dueDate: dueDate,
      };
      PortalApi.call(
        `/tasks/${encodeURIComponent(id)}/${encodeURIComponent(type)}`,
        { method: 'post', body: JSON.stringify(task) },
        (stats) => {
          dispatch(setStopTask(stats));
          dispatch(setLoading(false));
          dispatch(setLastDispatchStatus(true));

          if (stats.result === 'SUCCESS') {
            dispatch(getTasks());
          }
          dispatch(
            setPortalSnackInfo({
              severity: stats.result === 'SUCCESS' ? 'success' : 'error',
              message: stats.result === 'SUCCESS' ? 'Task completed successfully' : stats.result,
            }),
          );
        },
        (error, status, content) => {
          dispatch(setStopTask(''));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setStopTask(''));
      dispatch(setLoading(false));
    }
  };
}

export function overrideTask(id, type, description, manualTimeSecs) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setOverrideTask(''));
    dispatch(setLastDispatchStatus(false));
    try {
      var task = {
        description: description,
        manualTimeSecs: manualTimeSecs,
      };
      PortalApi.call(
        `/tasks/${encodeURIComponent(id)}/${encodeURIComponent(type)}`,
        { method: 'post', body: JSON.stringify(task) },
        (stats) => {
          if (stats.result === 'SUCCESS') {
            dispatch(getTasks());
          }
          dispatch(setOverrideTask(stats));
          dispatch(setLoading(false));
          dispatch(setLastDispatchStatus(true));
          dispatch(
            setPortalSnackInfo({
              severity: stats.result === 'SUCCESS' ? 'success' : 'error',
              message:
                stats.result === 'SUCCESS' ? 'Override time updated successfully' : stats.result,
            }),
          );
        },
        (error, status, content) => {
          dispatch(setOverrideTask(''));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setOverrideTask(''));
      dispatch(setLoading(false));
    }
  };
}

export function findUsers(
  firstName,
  lastName,
  accountNumber,
  username,
  lineNumber,
  lineAlias,
  accountName,
) {
  return (dispatch) => {
    dispatch(setSearchUsers([]));
    dispatch(setLoading(true));
    try {
      var url = '/staff/users?';
      if (firstName) {
        url += `firstName=${encodeURIComponent(firstName)}`;
      }

      if (lastName) {
        url += `&lastName=${encodeURIComponent(lastName)}`;
      }

      if (accountNumber) {
        url += `&accountNumber=${encodeURIComponent(accountNumber)}`;
      }

      if (username) {
        url += `&username=${encodeURIComponent(username.toUpperCase())}`;
      }

      if (lineNumber) {
        url += `&lineNumber=${encodeURIComponent(lineNumber.toUpperCase())}`;
      }

      if (lineAlias) {
        url += `&lineAlias=${encodeURIComponent(lineAlias.toUpperCase())}`;
      }

      if (accountName) {
        url += `&accountName=${encodeURIComponent(accountName.toUpperCase())}`;
      }

      PortalApi.call(
        url,
        { method: 'get' },
        (stats) => {
          dispatch(setSearchUsers(stats.content));
          dispatch(setLoading(false));
          dispatch(setUpdateUserEmailStatus(false));
        },
        (error, status, content) => {
          dispatch(setSearchUsers([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setSearchUsers([]));
      dispatch(setLoading(false));
    }
  };
}

export function searchUsername(username) {
  return (dispatch) => {
    dispatch(setVerifyUserName([]));
    dispatch(setLoading(true));
    try {
      var url = `/users/auth0/username/${username}`;
      PortalApi.call(
        url,
        { method: 'get' },
        (stats) => {
          dispatch(setVerifyUserName(stats.message));
          dispatch(setLoading(false));
        },
        (error, response, content) => {
          if (response.status === 400) {
            dispatch(setVerifyUserName('Username is already used'));
          } else {
            dispatch(setVerifyUserName([]));
          }
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setVerifyUserName([]));
      dispatch(setLoading(false));
    }
  };
}

export function searchEmail(email) {
  return (dispatch) => {
    dispatch(setVerifyEmail([]));
    dispatch(setLoading(true));
    try {
      var url = `/users/auth0/email?email=${encodeURIComponent(email)}`;
      PortalApi.call(
        url,
        { method: 'get' },
        (stats) => {
          dispatch(setVerifyEmail(stats.message));
          dispatch(setLoading(false));
        },
        (error, response, content) => {
          if (response.status === 400) {
            dispatch(setVerifyEmail('Email is already used'));
          } else {
            dispatch(setVerifyEmail([]));
          }
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setVerifyEmail([]));
      dispatch(setLoading(false));
    }
  };
}

export function createPortalUser(user, callback) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        '/users',
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setCreateUser(stats));
          dispatch(setLoading(false));
          dispatch(setVerifyEmail([]));
          dispatch(setVerifyUserName([]));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Portal User created!' }));
          if (callback) callback('success');
        },
        (error, response, content) => {
          dispatch(setLoading(false));
          var errorMessage = {};
          if (response.message && response.message.includes('400 Bad Request:')) {
            errorMessage = JSON.parse(response.message.replaceAll('400 Bad Request: ', ''))[0];
          }
          if (response.status === 400) {
            if (!response.message.includes('400 Bad Request:')) {
              errorMessage.message = response.message;
            }
          } else {
            errorMessage.message = JSON.stringify(response);
          }

          if (response.status === 409) {
            errorMessage.message = 'User already exists';
          }
          dispatch(setPortalSnackInfo({ severity: 'error', message: errorMessage.message }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function updatePortalUserPasswordAsStaff(webuserid, user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/staff/userpassword/${encodeURIComponent(webuserid)}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Password changed!' }));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not update password' }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function updatePortalUserEmailAsStaff(webuserid, user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/staff/useremail/${encodeURIComponent(webuserid)}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Email Updated!' }));
          dispatch(setUpdateUserEmailStatus(true));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not update Email' }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function updatePortalUserMfaAsStaff(webuserid, user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/staff/updateMfa/${encodeURIComponent(webuserid)}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'MFA Updated!' }));
          dispatch(setUpdateUserEmailStatus(true));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not update MFA' }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function resetPortalUserMfaAsStaff(webuserid, user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/staff/resetMfa/${encodeURIComponent(webuserid)}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'MFA reset successfully!' }));
          dispatch(setUpdateUserEmailStatus(true));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not reset MFA' }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function createAuth0UserAsStaff(webuserid, user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/users/auth0/${encodeURIComponent(webuserid)}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'User synced to Auth0' }));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: 'Could not push user to auth0,contact IT',
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function deletePortalUserAsStaff(webuserid, user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/users/auth0/${encodeURIComponent(webuserid)}`,
        { method: 'delete', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Deleted user from ERP + Auth0' }),
          );
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: 'Could not delete user, please contact IT to resolve this issue.',
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function getAvaialbleAffiliations() {
  return (dispatch) => {
    dispatch(setChatAffiliations([]));
    dispatch(setLoading(true));
    try {
      var url = '/chat/affiliation/list';
      PortalApi.call(
        url,
        { method: 'get' },
        (stats) => {
          dispatch(setChatAffiliations(stats));
          dispatch(setLoading(false));
        },
        (error, response, content) => {
          dispatch(setChatAffiliations([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setChatAffiliations([]));
      dispatch(setLoading(false));
    }
  };
}

export function getAffiliationUsers(affiliationId) {
  return (dispatch) => {
    dispatch(setSelAffiliation(affiliationId));
    dispatch(setSelAffiliationUsers([]));
    dispatch(setLoading(true));
    try {
      var url = `/chat/affiliation/${encodeURIComponent(affiliationId)}/users`;
      PortalApi.call(
        url,
        { method: 'get' },
        (stats) => {
          dispatch(setSelAffiliationUsers(stats));
          dispatch(setLoading(false));
        },
        (error, response, content) => {
          dispatch(setSelAffiliationUsers([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setSelAffiliationUsers([]));
      dispatch(setLoading(false));
    }
  };
}

export function createChatAffiliation(name, active) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = { name: name, active: active };
      PortalApi.call(
        '/chat/affiliation',
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Affiliation Group created' }),
          );
          dispatch(getAvaialbleAffiliations());
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: status.message }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function addChatUserToGroup(affiliationId, webUserId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = {};
      PortalApi.call(
        `/chat/affiliation/${encodeURIComponent(affiliationId)}/user/${encodeURIComponent(
          webUserId,
        )}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(getAffiliationUsers(affiliationId));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'User added to Chat Access Group' }),
          );
        },
        (error, content, status) => {
          console.log(status, ' status : content :', content);
          dispatch(setLoading(false));
          if (status === 400) {
            dispatch(setPortalSnackInfo({ severity: 'error', message: content.message }));
          } else {
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Could not add user to Chat Access Group ,contact IT',
              }),
            );
          }
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function deleteChatAffiliation(affliationId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = {};
      PortalApi.call(
        `/chat/affiliation/${encodeURIComponent(affliationId)}`,
        { method: 'delete', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Deleted chat affiliation' }),
          );
          dispatch(getAvaialbleAffiliations());
          dispatch(setSelAffiliation(''));
          dispatch(setSelAffiliationUsers([]));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: 'Could not delete chat affiliation,contact IT',
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function deleteChatAffiliationUser(affliationId, userId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = {};
      PortalApi.call(
        `/chat/affiliation/${encodeURIComponent(affliationId)}/user/${encodeURIComponent(userId)}`,
        { method: 'delete', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Deleted chat affiliation' }),
          );
          dispatch(getAvaialbleAffiliations());
          dispatch(setSelAffiliation(''));
          dispatch(setSelAffiliationUsers([]));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: 'Could not delete chat affiliation,contact IT',
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function getVerifiedCallerIdsList(pageNo, pageSize, sort) {
  return (dispatch) => {
    dispatch(setVerifiedCallerIds({}));
    try {
      PortalApi.call(
        `/twilio/numbers/callerid?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sort}`,
        { method: 'get' },
        (stats) => {
          dispatch(setVerifiedCallerIds(stats));

          //dispatch(setPortalSnackInfo({severity:"success",message:"Number purchased"}));
        },
        (error, status, content) => {
          dispatch(setVerifiedCallerIds({}));
          //dispatch(setPortalSnackInfo({severity:"error",message:"Cannot purchase the number"}));
        },
      );
    } catch (error) {
      dispatch(setVerifiedCallerIds({}));
      //dispatch(setPortalSnackInfo({severity:"error",message:"Cannot purchase the numbe"}));
    }
  };
}

export function getTwilioNumberFromAreaCode(areaCode, type, accountId, echoNumber) {
  return (dispatch) => {
    dispatch(setTwilioNumberByAreaCode({}));
    var twilioNumber = {
      areaCode: areaCode,
      type: type,
      accountId: accountId,
      echoNumber: echoNumber,
    };
    try {
      PortalApi.call(
        '/twilio/numbers/list',
        { method: 'post', body: JSON.stringify(twilioNumber) },
        (stats) => {
          dispatch(setTwilioNumberByAreaCode(stats));
          if (Object.keys(stats).length == 0) {
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Cannot purchase the number with the area code',
              }),
            );
          } else {
            dispatch(setPortalSnackInfo({ severity: 'success', message: 'Number purchased' }));
          }
          // dispatch(setPortalSnackInfo({severity:"success",message:"Number purchased"}));
        },
        (error, status, content) => {
          dispatch(setTwilioNumberByAreaCode([]));
          dispatch(
            setPortalSnackInfo({ severity: 'error', message: 'Cannot purchase the number' }),
          );
        },
      );
    } catch (error) {
      dispatch(setTwilioNumberByAreaCode([]));
      dispatch(setPortalSnackInfo({ severity: 'error', message: 'Cannot purchase the numbe' }));
    }
  };
}

export function testNotification(webUserId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = [];
      PortalApi.call(
        `/staff/user/testNotification/${encodeURIComponent(webUserId)}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'success',
              message: 'Test Notifications sent successfully',
            }),
          );
        },
        (error, content, status) => {
          dispatch(setLoading(false));
          if (status === 400) {
            dispatch(setPortalSnackInfo({ severity: 'error', message: content.message }));
          } else {
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Could not add assign Caller ID to user ,contact IT',
              }),
            );
          }
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function addCallerIdToWebUser(webUserId, phoneNumber) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = {};
      PortalApi.call(
        `/twilio/numbers/assign/${encodeURIComponent(phoneNumber)}/${encodeURIComponent(
          webUserId,
        )}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Callerid added to user' }));
        },
        (error, content, status) => {
          dispatch(setLoading(false));
          if (status === 400) {
            dispatch(setPortalSnackInfo({ severity: 'error', message: content.message }));
          } else {
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Could not add assign Caller ID to user ,contact IT',
              }),
            );
          }
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function addVerifyCallerId(phoneNumber, accountId, chooseNumber) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = {};
      PortalApi.call(
        `/twilio/numbers/callerid/${encodeURIComponent(phoneNumber)}/${encodeURIComponent(
          accountId,
        )}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          dispatch(setAddVerifyCallerResponse(stats));
          getVerifiedCallerIdsList(0, 10, '');
          dispatch(setCallerIdValidationCode(stats));
          //dispatch(setPortalSnackInfo({ severity: "success", message: "Please use " + stats.validationCode + " to verify the caller id" }));
        },
        (error, content, status) => {
          dispatch(setLoading(false));
          if (status === 400) {
            dispatch(setPortalSnackInfo({ severity: 'error', message: content.message }));
          } else {
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Could not add Caller ID,contact IT',
              }),
            );
          }
          setAddVerifyCallerResponse(null);
        },
      );
    } catch (error) {
      dispatch(setAddVerifyCallerResponse(null));
      dispatch(setLoading(false));
    }
  };
}

export function EnableAccountCallerId(accountId, status) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      var user = {};
      PortalApi.call(
        `/twilio/numbers/callerid/status/${encodeURIComponent(accountId)}/${status}`,
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(setLoading(false));
          //dispatch(setAddVerifyCallerResponse(stats))
          //getVerifiedCallerIdsList(0, 10, "")
          //dispatch(setCallerIdValidationCode(stats))
          //dispatch(setPortalSnackInfo({ severity: "success", message: "Please use " + stats.validationCode + " to verify the caller id" }));
        },
        (error, content, status) => {
          dispatch(setLoading(false));
          if (status === 400) {
            dispatch(setPortalSnackInfo({ severity: 'error', message: content.message }));
          } else {
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Could not add caller ID,contact IT',
              }),
            );
          }
          setAddVerifyCallerResponse(null);
        },
      );
    } catch (error) {
      dispatch(setAddVerifyCallerResponse(null));
      dispatch(setLoading(false));
    }
  };
}

export default staffSlice.reducer;
