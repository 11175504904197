import { useDispatch } from 'react-redux';
import PortalAsyncAutocomplete from '../PortalAsyncAutocomplete';
import { getSearchedReceivers } from '../../../messages/messageSlice';
import { getReceiverAutocompleteOptionLabel } from '../../../../utils/receiverUtil';
import { Grid } from '@mui/material';

export default function ReceiverNameSearch({ handleValueChange }) {
  const dispatch = useDispatch();
  const minLength = 4;
  const renderOptionKey = 'id';
  const renderOptionLabelKey = 'holderTxt';
  const orderByField = 'number';

  const fetchOptionsFunction = (request, callback) => {
    const serviceNumOrHolderTxt =
      request?.value && request?.value?.holderTxt ? request.value.holderTxt : request.input;
    dispatch(
      getSearchedReceivers({ receiverName: serviceNumOrHolderTxt }, (data) => {
        if (data && Array.isArray(data) && data?.length > 0) {
          callback(data.sort((a, b) => a[orderByField] - b[orderByField]));
        } else {
          callback([]);
        }
      }),
    );
  };

  const renderOption = (props, option) => {
    const { key, ...optionProps } = props;
    return (
      <li key={option[renderOptionKey]} {...optionProps}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs>
            {getReceiverAutocompleteOptionLabel(option)}
          </Grid>
        </Grid>
      </li>
    );
  };

  return (
    <PortalAsyncAutocomplete
      autoFocus={true}
      autoHighlight={true}
      fetchOptionsFunction={fetchOptionsFunction}
      filterSelectedOptions={true}
      getOptionLabel={getReceiverAutocompleteOptionLabel}
      minLength={minLength}
      noOptionsText="No receivers found"
      onValueChange={handleValueChange}
      renderInputLabel={`Search Receiver by Name (Min ${minLength} chars)`}
      renderOption={renderOption}
      renderOptionKey={renderOptionKey}
      renderOptionLabelKey={renderOptionLabelKey}
    />
  );
}
