import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Message from './Message';
import { useDispatch, useSelector } from 'react-redux';
import {
  lastMsgQuerySelector,
  downloadListSelector,
  messagesSelector,
  msgLoadingSelector,
  selReceiverSelector,
  selRecFeaturesSelector,
  setDownloadList,
  startMessageDownload,
  queryNoteAuthorSelector,
  queryPatternSelector,
  queryPatternCaseSelector,
  setQueryPattern,
  getMessageById,
  getMessageByRosterId,
  customTokenSelector,
  receiversSelector,
  getCustomToken,
  liveUpdatesSelector,
} from './messageSlice';
import {
  Grid,
  Typography,
  ButtonGroup,
  Tooltip,
  DialogActions,
  CircularProgress,
  Box,
} from '@mui/material';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ListIcon from '@mui/icons-material/List';
import TuneIcon from '@mui/icons-material/Tune';
import NoteIcon from '@mui/icons-material/Note';
import InfoIcon from '@mui/icons-material/Info';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import GetAppIcon from '@mui/icons-material/GetApp';
import { portalUserSelector, setPortalSnackInfo, timezoneSelector } from '../../app/authSlice';
import moment from 'moment';
import Hidden from '@mui/material/Hidden';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-virtualized/styles.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import MessageQueryDialog from './MessageQueryDialog';
import { PatternCaseType } from '../../utils/message';
import { exportJsonDataToCSVFile } from '../../utils/fileUtil/fileSaver';
import CustomizedProgressLoader from '../shared/CustomizedProgressLoader';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';
import {
  ref,
  startAt,
  onChildAdded,
  onChildChanged,
  query as dbquery,
  orderByChild,
  off,
} from 'firebase/database';
import { database, auth } from '../../firebaseConfig'; // Adjust the import path as necessary
import { signInWithCustomToken } from 'firebase/auth';

const PREFIX = 'MessageList';

const classes = {
  root: `${PREFIX}-root`,
  buttonGroup: `${PREFIX}-buttonGroup`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: 1,
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(1),
  },

  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    justifyContent: 'start',
    gap: theme.spacing(2),
    '& .MuiButton-root': {
      borderRadius: 5,
    },
  },
}));

export default function MessageList({ type }) {
  const dispatch = useDispatch();
  const messagesFromSelector = useSelector(messagesSelector);
  const pattern = useSelector(queryPatternSelector);
  const patternCase = useSelector(queryPatternCaseSelector);
  const [messages, setMessages] = useState([]);
  const receivers = useSelector(receiversSelector);
  const selReceiver = useSelector(selReceiverSelector);
  const disabledFeatures = useSelector(selRecFeaturesSelector);
  const [displayType, setDisplayType] = useState('list');
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const downloadList = useSelector(downloadListSelector);
  const msgLoading = useSelector(msgLoadingSelector);
  const [expandDDAll, setExpandDDAll] = useState(false);
  const [expandNoteAll, setExpandNoteAll] = useState(true);
  const [expandMessageAll, setExpandMessageAll] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const defaultTimezone = useSelector(timezoneSelector);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [pdfDownload, setPdfDownload] = useState(false);
  const [pdfDownloadDetails, setPdfDownloadDetails] = useState(false);
  const lastMsgQuery = useSelector(lastMsgQuerySelector);
  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState([]);
  const [fetchMessages, setFetchMessages] = useState(false);
  const noteAuthor = useSelector(queryNoteAuthorSelector);
  const [messageWorkStatus, setMessageWorkStatus] = useState(new Map());
  const portalUser = useSelector(portalUserSelector);
  const customToken = useSelector(customTokenSelector);
  const [loadedReceivers, setLoadedReceivers] = useState([]);
  const [loadedMessages, setLoadedMessages] = useState([]);
  const [listenersStatus, setListenersStatus] = useState(false);
  const [receiverRef, setReceiverRef] = useState(null);
  const liveUpdatesAction = useSelector(liveUpdatesSelector);
  const [customAuthentication, setCustomAuthentication] = useState(false);

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    dispatch(setQueryPattern(''));
  }, []);

  useEffect(() => {
    // console.log("receivers check", receivers);
    // console.log("receivers check token", customToken);
    // console.log("receivers check token", (customToken === null));
    if (customToken === null) {
      if (receivers && receivers.length > 0 && !deepEqual(receivers, loadedReceivers)) {
        setLoadedReceivers(receivers);
        const receiverIds = receivers.map((receiver) => String(receiver.number)).join(',');
        // console.log("Comma-separated receiver IDs:", [receiverIds]);
        dispatch(getCustomToken([receiverIds]));
      }
    }
  }, [receivers]);

  const handleCustomAuthentication = async (token, readReceiverRef) => {
    if (token !== null) {
      await signInWithCustomToken(auth, token)
        .then((userCredential) => {
          setCustomAuthentication(true);
          listenForUpdates(readReceiverRef, true);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setCustomAuthentication(false);
          //if authentication failure get new customtoken
          if (receivers && receivers.length > 0) {
            setLoadedReceivers(receivers);
            const receiverIds = receivers.map((receiver) => String(receiver.number)).join(',');
            // console.log("Comma-separated receiver IDs:", [receiverIds]);
            dispatch(getCustomToken([receiverIds]));
          }
        });
    }
  };

  useEffect(() => {
    //console.log("LIVEuPDATES ACTION", liveUpdatesAction)
    if (
      liveUpdatesAction &&
      selReceiver !== 0 &&
      customToken &&
      customToken !== null &&
      (!listenersStatus || selReceiver !== receiverRef) &&
      messages &&
      messages.length > 0 &&
      !deepEqual(loadedMessages, messages)
    ) {
      setListenersStatus(false);
      setLoadedMessages(messages);
      const readReceiverRef = ref(database, `receivers/${selReceiver}/messages/`);

      if (selReceiver !== receiverRef && customAuthentication) {
        off(readReceiverRef);
        setReceiverRef(selReceiver);
        listenForUpdates(readReceiverRef, customAuthentication);
      }

      //before calling listener check if authentication is already done  to avoid duplicate authentication
      if (!customAuthentication) {
        handleCustomAuthentication(customToken, readReceiverRef);
      }

      if (!liveUpdatesAction) {
        const readReceiverRef = ref(database, `receivers/${selReceiver}/messages/`);
        off(readReceiverRef);
      }
    }
  }, [selReceiver, customToken, messages, liveUpdatesAction]);

  const listenMessageUpdates = () => {
    // const db = getDatabase();
    const readReceiverRef = ref(database, `receivers/${selReceiver}/messages/`);
  };

  const listenForUpdates = (readReceiverRef, authenticationStatus) => {
    const getCurrentDateInISOFormat = () => {
      const currentDate = new Date();
      return currentDate.toISOString();
    };
    const getLastOneHourInISOFormat = () => {
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() - 7);
      return currentDate.toISOString();
    };
    if (customToken && customToken.length > 0 && authenticationStatus) {
      // Signed in

      // Function to get current date in ISO format
      const currentDate = getCurrentDateInISOFormat();
      const currentDateMinusOneHour = getLastOneHourInISOFormat();
      const newmessagequery = dbquery(
        readReceiverRef,
        orderByChild('messageUpdates/lastUpdated'),
        startAt(currentDateMinusOneHour),
      );
      onChildAdded(newmessagequery, (snapshot) => {
        const addedMessageKey = snapshot.key;
        const addedMessageValue = snapshot.val();
        console.log('new message added', addedMessageKey);
        if (messages && messages.length > 0) {
          const newMessageFlag = messages.filter((item) => item.ROSTER_ID === addedMessageKey);
          console.log('new message flag', newMessageFlag);
          if (
            addedMessageValue &&
            addedMessageValue.messageUpdates &&
            newMessageFlag &&
            newMessageFlag.length === 0
          ) {
            if (addedMessageValue.messageUpdates && addedMessageValue.messageUpdates.lastUpdated) {
              dispatch(getMessageByRosterId(addedMessageKey, selReceiver));
            }
          }
        }
      });

      const query = dbquery(
        readReceiverRef,
        orderByChild('escalationUpdates/updatedTime'),
        startAt(currentDate),
      );
      onChildAdded(query, (snapshot) => {
        const addedMessageKey = snapshot.key;
        const addedMessageValue = snapshot.val();
        if (addedMessageValue && addedMessageValue.escalationUpdates) {
          const escalationUpdates = addedMessageValue.escalationUpdates;
          if (escalationUpdates.status === 'CLEARED') {
            dispatch(getMessageById(addedMessageKey));
          }
        }
        // if (addedMessageValue && addedMessageValue.messageUpdates) {
        //   if (addedMessageValue.messageUpdates && addedMessageValue.messageUpdates.lastUpdated) {
        //     dispatch(getMessageByRosterId(addedMessageKey));
        //   }
        // }
      });

      const workUpdatequery = dbquery(
        readReceiverRef,
        orderByChild('workUpdates/updatedTime'),
        startAt(currentDate),
      );
      onChildAdded(workUpdatequery, (snapshot) => {
        const addedMessageKey = snapshot.key;
        const addedMessageValue = snapshot.val();
        // console.log("work child added messages" + addedMessageValue.workUpdates.webuserId);
        if (addedMessageValue && addedMessageValue.workUpdates && messages && messages.length > 0) {
          // const activeIds = messages.filter(
          //   (item) =>
          //     item.ID === addedMessageKey &&
          //     portalUser.id !== addedMessageValue.workUpdates.webuserId,
          // );
          //if (activeIds.length > 0) {
          if (portalUser && portalUser.id !== addedMessageValue.workUpdates.webuserId) {
            setMessageWorkStatus((prevMap) =>
              new Map(prevMap).set(addedMessageKey, addedMessageValue.workUpdates.status),
            );
          }
          //}
        }
      });

      onChildChanged(workUpdatequery, (snapshot) => {
        const addedMessageKey = snapshot.key;
        const addedMessageValue = snapshot.val();
        // console.log("work child changed messages " + addedMessageValue.workUpdates.webuserId);
        if (addedMessageValue && addedMessageValue.workUpdates && messages && messages.length > 0) {
          // const activeIds = messages.filter(
          //   (item) =>
          //     item.ID === addedMessageKey &&
          //     portalUser.id !== addedMessageValue.workUpdates.webuserId,
          // );

          // console.log("active ids " + activeIds.length);

          //if (activeIds.length > 0) {
          if (portalUser && portalUser.id !== addedMessageValue.workUpdates.webuserId) {
            setMessageWorkStatus((prevMap) =>
              new Map(prevMap).set(addedMessageKey, addedMessageValue.workUpdates.status),
            );
          }
        }
        //}
      });
      setListenersStatus(true);
    }
  };
  //console.log("message work status" + [...messageWorkStatus.entries()]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     Object.keys(messageRefs.current).forEach((key) => {
  //       if (messageRefs.current[key] && !messageRefs.current[key].contains(event.target)) {
  //         console.log(`Clicked outside of message with key: ${key}`);
  //         // Handle the outside click for the specific message key
  //       }
  //     });
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [messages]);

  useEffect(() => {
    if (noteAuthor && noteAuthor?.length > 0) {
      setMessages(
        messagesFromSelector?.filter((msg) =>
          msg?.notes?.some((note) =>
            patternCase == PatternCaseType.matchCase
              ? note?.ADDED_BY?.includes(noteAuthor)
              : note?.ADDED_BY?.toUpperCase()?.includes(noteAuthor.toUpperCase()),
          ),
        ),
      );
    } else {
      setMessages(messagesFromSelector);
    }
    if (messagesFromSelector && messagesFromSelector.length > 0) {
      // const updateReceiverChange = (value) => {
      // console.log("received receiver data " + selReceiver)
      //console.log("messages load")
      // const db = getDatabase();
      // const readReceiverRef = ref(database, `receivers/${selReceiver}/messages/`);
      // onValue(readReceiverRef, (snapshot) => {
      //   snapshot.forEach((child) => {

      //     const messageKey = snapshot.key;
      //     const messageValue = snapshot.val();
      //     console.log(`on value change keys${child.key}`);
      //     console.log(`messages from selector${messagesFromSelector}`);
      //     //const workUpdatevalue = (child.val())?.workUpdates;
      //     if (messageValue && messageValue.workUpdates && messagesFromSelector && messagesFromSelector.length > 0) {
      //       const activeIds = messagesFromSelector.filter(
      //         (item) => item.ID === child.key && portalUser.id !== workUpdatevalue?.webuserId,
      //       );
      //       console.log('activeIds', activeIds);
      //       if (activeIds.length > 0) {
      //         //workingMessages.set(child.key, value.status)
      //         setMessageWorkStatus((prevMap) => new Map(prevMap).set(child.key, workUpdatevalue?.status));
      //       }
      //     }
      //   });
      //   const data = snapshot.val();
      // });

      const readReceiverRef = ref(database, `receivers/${selReceiver}/messages/`);

      // onChildChanged(readReceiverRef, (snapshot) => {
      //   snapshot.forEach((child) => {

      //     const messageKey = snapshot.key;
      //     const messageValue = snapshot.val();
      //     //console.log(`keys${child.key}`);
      //     const workUpdatevalue = (child.val())?.workUpdates;
      //     const escalationUpdatevalue = (child.val())?.escalationUpdates;
      //     // console.log("workUpdateValue", workUpdatevalue);
      //     // console.log("escalationUpdateValue", escalationUpdatevalue);
      //     // console.log(`values${workUpdatevalue?.status}`);
      //     // console.log('null check', workUpdatevalue != null);
      //     if (workUpdatevalue != null) {
      //       const activeIds = messagesFromSelector.filter(
      //         (item) => item.ID === child.key && portalUser.id !== workUpdatevalue?.webuserId,
      //       );
      //       //console.log('activeIds', activeIds);
      //       if (activeIds.length > 0) {
      //         //workingMessages.set(child.key, value.status)
      //         setMessageWorkStatus((prevMap) => new Map(prevMap).set(child.key, workUpdatevalue?.status));
      //       }
      //     }
      //   });
      //   const data = snapshot.val();

      // });
    }
  }, [messagesFromSelector]);

  const getMoreData = () => {
    if (current.length === messages.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(messages.slice(count.prev + 10, count.next + 10)));
    }, 500);
    setCount({ prev: count.prev + 10, next: count.next + 10 });
  };

  useEffect(() => {
    if (messages) {
      setCount({ prev: 0, next: 10 });
      setCurrent(messages.slice(0, 10));
      setHasMore(true);
    }
  }, [messages, selReceiver]);

  const { t, i18n } = useTranslation();

  const startDownload = (value) => {
    var newMessages = messages.filter((item) => downloadList.indexOf(item.ID) >= 0);
    dispatch(startMessageDownload({ messages: newMessages }, value));
  };

  useEffect(() => {
    if (lastMsgQuery === 'reload') {
      handleSave();
    }
  }, [lastMsgQuery]);

  const handleExpandAll = (type) => {
    if (type === 'delivery') {
      setExpandDDAll(!expandDDAll);
    }

    if (type === 'notes') {
      setExpandNoteAll(!expandNoteAll);
    }

    if (type === 'messages') {
      setExpandMessageAll(!expandMessageAll);
    }
  };

  const handleCheckAll = () => {
    if (checkAll) {
      dispatch(setDownloadList([]));
    } else {
      var newChecked = [];
      messages.forEach((item) => {
        newChecked.push(item.ID);
      });
      dispatch(setDownloadList(newChecked));
    }
    setCheckAll(!checkAll);
  };

  const handleDisplayChange = (value) => {
    setDisplayType(value);
  };

  const handleClose = () => {
    setDownloadOpen(false);
    setPdfDownload(false);
    setPdfDownloadDetails(false);
  };

  const handleFilter = () => {
    setOpenFilterDialog(true);
  };

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
  };

  const handleSave = () => {
    setFetchMessages(true);
  };

  useEffect(() => {
    if (selReceiver !== 0) {
      handleSave();
    }
  }, [selReceiver]);

  const handleDownloads = () => {
    setDownloadOpen(true);
  };

  const exportToCSV = (option, formatting) => {
    try {
      var formattedMessages = [];
      messages.forEach((element) => {
        if (downloadList.indexOf(element.ID) >= 0) {
          var msg = {};
          msg.Sequence = element.SEQUENCE_NUMBER;
          msg.Receiver = element.SHORT_PAGER_NUMBER;
          msg.HolderName = element.HOLDER_NAME;
          msg.Received = moment
            .tz(element.RECEIVED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
            .tz(defaultTimezone)
            .format('MM-DD-YYYY HH:mm:ss z');
          if (option === 2) {
            msg.EscalationStatus = element.ESCALATION_STATUS;
            if (element.ESCALATION_STATUS === 'CLEARED') {
              msg.EscalationClearedDate = moment
                .tz(element.ESCALATION_CLEARED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                .tz(defaultTimezone)
                .format('MM-DD-YYYY HH:mm:ss z');
              msg.ElapsedTimeInMinutes = (
                moment(element.ESCALATION_CLEARED_DATE, 'YYYYMMDDHHmmss').diff(
                  moment(element.RECEIVED_DATE, 'YYYYMMDDHHmmss'),
                ) / 60000
              ).toFixed(1);
              msg.EscalationClearedBy = element.ESCALATION_CLEARED_BY_NAME;
            } else {
              msg.EscalationClearedDate = '';
              msg.ElapsedTimeInMinutes = '';
              msg.EscalationClearedBy = '';
            }
          }
          msg.Notes = '';
          if (element.notes) {
            element.notes.forEach((item) => {
              msg.Notes += `Note by ${item.ADDED_BY} added on ${moment
                .tz(item.ADD_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                .tz(defaultTimezone)
                .format('MM-DD-YYYY HH:mm:ss z')}:${item.NOTE_TXT}`;
            });
          }
          if (formatting === 'regular') {
            msg.Message = element.MESSAGE_FORMATTED_TXT;
          } else {
            element.MESSAGE_TXT.split('CRLF').forEach((element, index) => {
              msg[`A${index}`] = element;
            });
          }
          formattedMessages.push(msg);
        }
      });
      exportJsonDataToCSVFile({ jsonData: formattedMessages, filename: 'Message List' });
    } catch (error) {
      console.error('Error', error);
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, something went wrong while downloading file. Please contact IT.',
        }),
      );
    }
  };

  return (
    <StyledGrid className={classes.root} container>
      <CustomizedProgressLoader disableBackground={true} />
      <Grid item sx={{ mt: 0, mb: 4 }} xs={12}>
        <ButtonGroup className={classes.buttonGroup} color="primary">
          <Hidden smDown>
            <PortalButton
              component="span"
              data-tour="rtQueryMsg"
              onClick={handleFilter}
              startIcon={<TuneIcon />}
              transition={{ type: 'spring', stiffness: 500 }}
              variant="contained"
            >
              {t('Query')}
            </PortalButton>

            <PortalButton
              component="span"
              data-tour="rtQueryMsgRefresh"
              onClick={handleSave}
              startIcon={<RefreshIcon />}
              transition={{ type: 'spring', stiffness: 500 }}
              variant="contained"
            >
              {t('Retrieve Messages')}
            </PortalButton>

            <PortalButton
              component="span"
              data-tour="rtDownloadMsg"
              onClick={handleDownloads}
              startIcon={<GetAppIcon />}
              transition={{ type: 'spring', stiffness: 500 }}
              variant="contained"
            >
              {t('Download')}
            </PortalButton>

            <PortalButton
              component="span"
              data-tour="rtECMessages"
              onClick={() => handleExpandAll('messages')}
              startIcon={<NoteIcon />}
              transition={{ type: 'spring', stiffness: 500 }}
              variant="contained"
            >
              {expandMessageAll ? t('Collapse All Messages') : t('Expand All Messages')}
            </PortalButton>

            <PortalButton
              component="span"
              data-tour="rtECNotes"
              disabled={!expandMessageAll}
              onClick={() => handleExpandAll('notes')}
              startIcon={<NoteIcon />}
              transition={{ type: 'spring', stiffness: 500 }}
              variant="contained"
            >
              {expandNoteAll ? t('Collapse All Notes') : t('Expand All Notes')}
            </PortalButton>

            <PortalButton
              component="span"
              data-tour="rtECDelivery"
              disabled={!expandMessageAll}
              onClick={() => handleExpandAll('delivery')}
              startIcon={<InfoIcon />}
              transition={{ type: 'spring', stiffness: 500 }}
              variant="contained"
            >
              {expandDDAll ? t('Collapse All Delivery Info') : t('Expand All Delivery Info')}
            </PortalButton>

            <PortalButton
              component="span"
              data-tour="rtUCMsgs"
              onClick={handleCheckAll}
              startIcon={<SelectAllIcon />}
              transition={{ type: 'spring', stiffness: 500 }}
              variant="contained"
            >
              {checkAll ? t('Uncheck All') : t('Check All')}
            </PortalButton>
          </Hidden>

          <Hidden mdUp>
            <PortalIconButton
              aria-label="change message query"
              component="span"
              onClick={handleFilter}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title={t('Change Message Query')}>
                <TuneIcon />
              </Tooltip>
            </PortalIconButton>

            <PortalIconButton
              aria-label="Retrieve Messages"
              component="span"
              onClick={handleSave}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title={t('Retrieve Messages')}>
                <RefreshIcon />
              </Tooltip>
            </PortalIconButton>

            <PortalIconButton
              aria-label="download"
              component="span"
              onClick={handleDownloads}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title={t('Download Messages')}>
                <GetAppIcon />
              </Tooltip>
            </PortalIconButton>

            <PortalIconButton
              color={expandMessageAll ? 'secondary' : 'primary'}
              component="span"
              onClick={() => handleExpandAll('notes')}
              size="medium"
              variant="contained"
            >
              <Tooltip
                disableFocusListener
                title={expandMessageAll ? t('Collapse All Messages') : t('Expand All Messages')}
              >
                <NoteIcon />
              </Tooltip>
            </PortalIconButton>

            <PortalIconButton
              color={expandNoteAll ? 'secondary' : 'primary'}
              component="span"
              onClick={() => handleExpandAll('notes')}
              size="medium"
              variant="contained"
            >
              <Tooltip
                disableFocusListener
                title={expandNoteAll ? t('Collapse All Notes') : t('Expand All Notes')}
              >
                <NoteIcon />
              </Tooltip>
            </PortalIconButton>

            <PortalIconButton
              color={expandDDAll ? 'secondary' : 'primary'}
              component="span"
              onClick={() => handleExpandAll('delivery')}
              size="medium"
              variant="contained"
            >
              <Tooltip
                disableFocusListener
                title={
                  expandDDAll ? t('Collapse All Delivery Info') : t('Expand All Delivery Info')
                }
              >
                <InfoIcon />
              </Tooltip>
            </PortalIconButton>

            <PortalIconButton
              color={checkAll ? 'primary' : 'secondary'}
              component="span"
              onClick={handleCheckAll}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title={checkAll ? t('Uncheck All') : t('Check All')}>
                <SelectAllIcon />
              </Tooltip>
            </PortalIconButton>
          </Hidden>

          <PortalIconButton
            aria-label="upload picture"
            className={classes.buttonGroup}
            color={displayType === 'grid' ? 'secondary' : 'primary'}
            component="span"
            onClick={() => handleDisplayChange('grid')}
            size="medium"
            variant="contained"
          >
            <Tooltip disableFocusListener title="Grid">
              <ViewCompactIcon />
            </Tooltip>
          </PortalIconButton>
          <PortalIconButton
            aria-label="upload picture"
            color={displayType === 'list' ? 'secondary' : 'primary'}
            component="span"
            onClick={() => handleDisplayChange('list')}
            size="medium"
            variant="contained"
          >
            <Tooltip disableFocusListener title="List">
              <ListIcon />
            </Tooltip>
          </PortalIconButton>
        </ButtonGroup>
      </Grid>

      {msgLoading && (
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
          }}
          xs={12}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}

      <Grid item xs={12}>
        {messages && messages.length === 0 && !msgLoading && (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="overline">{t('no records found')}</Typography>
          </Box>
        )}
      </Grid>

      <Grid item sx={{ pd: 0 }} xs={12}>
        <Grid container spacing={1}>
          {messages &&
            messages.map((item) => (
              <Message
                content={item}
                disableArchive={disabledFeatures.indexOf('FUNERAL') !== -1}
                disableForwarding={disabledFeatures.indexOf('WBMSGRSTCT') !== -1}
                disableInlineRecording={disabledFeatures.indexOf('INLINERCRD') !== -1}
                expand
                expandDDAll={expandDDAll}
                expandMessageAll={expandMessageAll}
                expandNoteAll={expandNoteAll}
                key={item.ID}
                pattern={pattern ? pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ''}
                patternCase={patternCase}
                size={displayType === 'grid' ? 6 : 12}
                staffMode={false}
                workingMessage={messageWorkStatus.get(item.ID) === 'active'}
              />
            ))}
        </Grid>
      </Grid>

      <MessageQueryDialog
        fetchMessages={fetchMessages}
        openDialog={openFilterDialog}
        propagateOnClose={() => handleCloseFilterDialog(true)}
        type={type}
        unSetFetchMessages={() => setFetchMessages(false)}
      />

      <Dialog
        aria-labelledby="simple-dialog-title1"
        className={classes.dialog}
        data-tour="rtDownloadMsg-parent"
        maxWidth="md"
        onClose={handleClose}
        open={downloadOpen}
      >
        <DialogTitle id="simple-dialog-title1">{t('Downloads')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description1" />

          <PortalButton
            color="primary"
            onClick={() => exportToCSV(1, 'vertical')}
            sx={{ mr: 2 }}
            variant="contained"
          >
            CSV
          </PortalButton>

          <PortalButton
            color="primary"
            onClick={() => exportToCSV(2, 'vertical')}
            sx={{ mr: 2 }}
            variant="contained"
          >
            CSV with Escalation Clearing
          </PortalButton>

          <PortalButton
            color="primary"
            onClick={() => {
              startDownload('N');
            }}
            sx={{ mr: 2 }}
            variant="contained"
          >
            Pdf
          </PortalButton>

          <PortalButton
            color="primary"
            onClick={() => {
              startDownload('Y');
            }}
            sx={{ mr: 1 }}
            variant="contained"
          >
            Pdf with delivery details
          </PortalButton>
        </DialogContent>
        <DialogActions>
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            {t('Cancel')}
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledGrid>
  );
}
