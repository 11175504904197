import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
  TableSortLabel,
  Box,
} from '@mui/material';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  detachReceiver,
  receiversSelector,
  attachReceiver,
  updateReceiver,
  msgLoadingSelector,
} from '../messages/messageSlice';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import PropTypes from 'prop-types';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'ReceiverSettings';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  card: `${PREFIX}-card`,
  cardActions: `${PREFIX}-cardActions`,
  cardHeader: `${PREFIX}-cardHeader`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },

  [`& .${classes.card}`]: {
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ReceiverSettings() {
  const dispatch = useDispatch();
  const receivers = useSelector(receiversSelector);
  const [receiverSortKey, setReceiverSortKey] = useState('id');
  const [receiverSortOrder, setReceiverSortOrder] = useState('asc');
  const [mode, setMode] = useState('add');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteEntry, setDeleteEntry] = useState(0);
  const [open, setOpen] = useState(false);
  const [receiverNum, setReceiverNum] = useState('');
  const [password, setPassword] = useState('');
  const loading = useSelector(msgLoadingSelector);
  const [selAccount, setSelAccount] = useState({});
  const [nickname, setNickname] = useState('');
  const { t } = useTranslation();

  const handleSelect = (value) => {
    setSelAccount(value);
    setNickname(value.nickName ? value.nickName : '');
    setMode('edit');
    setOpen(true);
  };
  const handleChange = (event) => {
    switch (event.currentTarget?.name) {
      case 'receiverNum':
        if (event.target.value !== '' && isNaN(parseInt(event.target.value))) {
          return;
        }
        setReceiverNum(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      case 'nickname':
        if (event.target.value.length > 50) {
          return;
        }
        setNickname(event.target.value);
        break;
      default:
        setPassword(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (mode) => {
    setOpen(true);
    setReceiverNum('');
    setPassword('');
    setMode(mode);
  };

  const updateNickname = () => {
    dispatch(updateReceiver(selAccount.number, nickname, ''));
    setOpen(false);
  };

  const addNewReceiver = () => {
    setOpen(false);
    dispatch(attachReceiver(receiverNum, password));
  };

  const handleDelete = (event, value) => {
    setDeleteOpen(true);
    setDeleteEntry(value);
  };

  const closeDeletePopup = () => {
    setDeleteOpen(false);
  };

  const confirmDelete = () => {
    dispatch(detachReceiver(deleteEntry));
    setDeleteOpen(false);
  };

  const createSortHandler = (key, type) => {
    if (type === 'Receiver') {
      if (receiverSortKey === key) {
        setReceiverSortOrder(receiverSortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setReceiverSortOrder('asc');
      }
      setReceiverSortKey(key);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledGrid item md={6} xs={12}>
      {loading && <CircularProgress />}

      <Card className={classes.card}>
        <CardHeader
          avatar={<AccountBoxIcon />}
          className={classes.cardHeader}
          title={t('Receiver Snapshot')}
        />
        <CardContent>
          <TableContainer dense>
            <Table aria-label="a dense table" className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {[
                    { name: t('Receiver#'), key: 'number' },
                    { name: t('Name'), key: 'holderTxt' },
                    { name: t('Nickname'), key: 'nickName' },
                  ].map((item) => (
                    <TableCell align="left" className={classes.multiColumnHeader} key={item.key}>
                      {' '}
                      <TableSortLabel
                        active={receiverSortKey === item.key}
                        classes={{ active: classes.active, icon: classes.icon }}
                        direction={receiverSortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler(item.key, 'Receiver')}
                      >
                        {item.name}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {receivers &&
                  receivers.length > 0 &&
                  receivers
                    .slice()
                    .sort((a, b) =>
                      receiverSortOrder === 'asc'
                        ? a[receiverSortKey] > b[receiverSortKey]
                          ? 1
                          : -1
                        : b[receiverSortKey] > a[receiverSortKey]
                          ? 1
                          : -1,
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value.id}`;
                      return (
                        <TableRow className={classes.tableRow} hover key={value.id}>
                          <TableCell align="left">{value.number}</TableCell>
                          <TableCell align="left">{value.holderTxt}</TableCell>
                          <TableCell align="left">
                            <PortalButton
                              onClick={() => handleSelect(value)}
                              variant={value.nickName ? 'outlined' : 'outlined'}
                            >
                              {value.nickName ? value.nickName : <EditIcon />}
                            </PortalButton>
                          </TableCell>
                          <TableCell align="left">
                            <PortalButton color="secondary">
                              <DeleteIcon onClick={(event) => handleDelete(event, value.number)} />
                            </PortalButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <PortalButton color="secondary" onClick={() => handleOpen('add')}>
            <AddIcon />
          </PortalButton>

          <div>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              component="div"
              count={receivers ? receivers.length : 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </div>
        </CardActions>
      </Card>

      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="form-dialog-title">
          {mode == 'add' ? t('Attach Receiver') : t('Set Nickname')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText />

          {mode === 'add' && (
            <TextField
              fullWidth
              label={t('Receiver Number')}
              margin="dense"
              name="receiverNum"
              onChange={handleChange}
              type="text"
              value={receiverNum}
            />
          )}

          {mode === 'add' && (
            <TextField
              fullWidth
              label={t('Receiver Password')}
              margin="dense"
              name="password"
              onChange={handleChange}
              type="password"
              value={password}
            />
          )}

          {mode === 'edit' && (
            <TextField
              fullWidth
              label={t('Nickname')}
              margin="dense"
              name="nickname"
              onChange={handleChange}
              type="text"
              value={nickname}
            />
          )}
        </DialogContent>
        <DialogActions>
          {mode === 'add' && (
            <PortalButton
              color="primary"
              disabled={receiverNum.length === 0 || password.length === 0}
              onClick={addNewReceiver}
              variant="contained"
            >
              {t('Attach Receiver')}
            </PortalButton>
          )}
          {mode === 'edit' && (
            <PortalButton color="primary" onClick={updateNickname} variant="contained">
              {t('Save')}
            </PortalButton>
          )}
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            {t('Cancel')}
          </PortalButton>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title1"
        fullWidth={true}
        maxWidth="sm"
        onClose={closeDeletePopup}
        open={deleteOpen}
      >
        <DialogTitle id="form-dialog-title1">{t('Detach Receiver')}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to detach this receiver?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <PortalButton color="primary" onClick={confirmDelete} variant="contained">
            {t('Detach')}
          </PortalButton>
          <PortalButton color="secondary" onClick={closeDeletePopup} variant="contained">
            {t('Cancel')}
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledGrid>
  );
}
