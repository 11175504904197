import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardContent,
  IconButton,
  CardActions,
  Collapse,
  Button,
  FormControl,
  TextField,
  DialogActions,
  Avatar,
  Checkbox,
  List,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import NoteIcon from '@mui/icons-material/Note';
import ClearIcon from '@mui/icons-material/Clear';
import ForwardIcon from '@mui/icons-material/Forward';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArchiveIcon from '@mui/icons-material/Archive';
import Badge from '@mui/material/Badge';
import moment from 'moment-timezone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ReplyIcon from '@mui/icons-material/Reply';
import {
  clearMessageEscalation,
  downloadListSelector,
  sendForwardMessage,
  sendMessage,
  sendNote,
  sendReply,
  setDownloadList,
  updateFollowUp,
  selReceiverSelector,
  forwardResponseSelector,
  msgLoadingSelector,
  updateArchive,
  getRecordingById,
  determineIfTextMatchClearEscalationPhrase,
  textMatchClearEscalationPhraseSelector,
  setTextMatchClearEscalationPhrase,
  setFocusedMessageId,
  focusedMessageIdSelector,
  clearEscalationUpdateSelector,
  setClearEscalationUpdate,
} from './messageSlice';
import Note from './Note';
import Divider from '@mui/material/Divider';
import { portalUserSelector } from '../../app/authSlice';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { domainDefaultsSelector } from '../../brandSlice';
import { getContactList } from '../home/phoneBookSlice';
import { Autocomplete } from '@mui/lab';
import { timezoneSelector } from '../../app/authSlice';
import DOMPurify from 'dompurify';
import Hidden from '@mui/material/Hidden';
import { useTranslation } from 'react-i18next';
import PortalDialog from '../shared/components/PortalDialog';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';
import {
  getDatabase,
  ref,
  set,
  get,
  child,
  update,
  push,
  onChildRemoved,
  onChildAdded,
  remove,
} from 'firebase/database';
import { database, auth } from '../../firebaseConfig';

const PREFIX = 'Message';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  grid: `${PREFIX}-grid`,
  cardTop: `${PREFIX}-cardTop`,
  small: `${PREFIX}-small`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableHeader: `${PREFIX}-tableHeader`,
  active: `${PREFIX}-active`,
  escalation: `${PREFIX}-escalation`,
  replyPending: `${PREFIX}-replyPending`,
  noteHolder: `${PREFIX}-noteHolder`,
  cardBottom: `${PREFIX}-cardBottom`,
  cardBottomHeader: `${PREFIX}-cardBottomHeader`,
  actionsHolder: `${PREFIX}-actionsHolder`,
  actions: `${PREFIX}-actions`,
  item: `${PREFIX}-item`,
  msgBody: `${PREFIX}-msgBody`,
  msgBodyGrid: `${PREFIX}-msgBodyGrid`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    transition: 'all .1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    marginTop: '8px',
    marginBottom: '8px',
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
  },

  [`& .${classes.card}`]: {
    flexGrow: 1,
    padding: 0,
    borderRadius: '16px',
    boxShadow:
      '0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 11%)',
    '&:hover': {
      boxShadow:
        '0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 51%)',
    },
  },

  [`& .${classes.workingCard}`]: {
    // flexGrow: 1,
    // padding: 0,
    // borderRadius: '16px',
    // boxShadow:
    //   '0px 2px 10px -1px rgba(255, 204, 0, 0.2), 0px 1px 1px 0px rgba(255, 204, 0, 0.2), 0px 0px 0px 2px rgb(255 204 0 / 11%)',
    // '&:hover': {
    //   boxShadow:
    //     '0px 2px 10px -1px rgba(255, 204, 0, 0.2), 0px 1px 1px 0px rgba(255, 204, 0, 0.2), 0px 0px 0px 2px rgb(255 204 0 / 51%)',
    // },
  },

  [`& .${classes.cardContent}`]: {
    padding: 0,
    paddingBottom: '0px !important',
  },

  [`& .${classes.grid}`]: {
    padding: 0,
  },

  [`& .${classes.cardTop}`]: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    background: '#01adc200',
    padding: theme.spacing(1),
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.tableContainer}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.tableHeader}`]: {
    background: 'whitesmoke',
  },

  [`& .${classes.active}`]: {
    fontWeight: 700,
  },

  [`& .${classes.escalation}`]: {
    color: 'red',
    paddingRight: theme.spacing(1),
    fontWeight: 700,
  },

  [`& .${classes.replyPending}`]: {
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(1),
    fontWeight: 700,
  },
  [`& .${classes.noteHolder}`]: {
    background: `${theme.palette.primary.main}33`,
    borderRadius: theme.spacing(3),
    paddingRight: theme.spacing(4),
  },

  [`& .${classes.cardBottom}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: 'white',
  },

  [`& .${classes.cardBottomHeader}`]: {
    display: 'flex',
    flexGrow: 1,
    fontStyle: 'italic',
    alignContent: 'baseline',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '4px',
  },

  [`& .${classes.actionsHolder}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    background: '#f9f9f97a',
    justifyContent: 'space-between',
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    background: '#f9f9f97a',
    color: 'black',
  },

  [`& .${classes.item}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

  [`& .${classes.msgBody}`]: {
    fontSize: 18,
    wordBreak: 'break-word',
    padding: theme.spacing(1),
  },

  [`& .${classes.msgBodyGrid}`]: {
    fontSize: 16,
    wordBreak: 'break-word',
    minHeight: '150px',
    maxHeight: '150px',
    overflow: 'auto',
  },
}));

const Message = ({
  content,
  size,
  expandMessageAll,
  expandDDAll,
  expandNoteAll,
  disableForwarding,
  staffMode,
  disableArchive,
  pattern,
  patterncase,
  disableInlineRecording,
  workingMessage,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(portalUserSelector);
  const [expanded, setExpanded] = useState(false);
  const [expandedNote, setExpandedNote] = useState(false);
  const [open, setOpen] = useState(false);
  const contactsList = useSelector(getContactList);
  const [title, setTitle] = useState('');
  const [mode, setMode] = useState('');
  const [contextText, setContextText] = useState('');
  const [contextTextColor, setContextTextColor] = useState('grey');
  const [audioData, setAudioData] = useState('');
  const checked = useSelector(downloadListSelector);
  const [reply, setReply] = useState('');
  const selReceiver = useSelector(selReceiverSelector);
  const brand = useSelector(domainDefaultsSelector);
  const loading = useSelector(msgLoadingSelector);
  const forwardResponse = useSelector(forwardResponseSelector);

  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [email3, setEmail3] = useState('');
  const [subject, setSubject] = useState('');

  const [deliveryInclude, setDeliveryInclude] = useState('N');
  const [forwardTxt, setForwardTxt] = useState('');
  const [forwardType, setForwardType] = useState('email');
  const [forwardComments, setForwardComments] = useState('');

  const [forwardPhoneEntry, setForwardPhoneEntry] = useState('');
  const [forwardPhoneEntryId, setForwardPhoneEntryId] = useState('');
  const [forwardReceiver, setForwardReceiver] = useState('');
  const portalUser = useSelector(portalUserSelector);
  // console.log('props working message', workingMessage);

  const defaultTimezone = useSelector(timezoneSelector);
  const [from, setFrom] = useState('');
  const [note, setNote] = useState('');
  const { t } = useTranslation();
  const [openClearEscalationConfirmDialog, setOpenClearEscalationConfirmDialog] = useState(false);
  const textMatchClearEscalationPhrase = useSelector(textMatchClearEscalationPhraseSelector);
  const [selectedContentID, setSelectedContentID] = useState(null);
  const focusedMessageId = useSelector(focusedMessageIdSelector);
  const clearEsclationUpdate = useSelector(clearEscalationUpdateSelector);
  useEffect(() => {
    resetClearEscalationDialogParams();
    setReply('');
  }, []);

  useEffect(() => {
    if (textMatchClearEscalationPhrase === 'matched') {
      setOpenClearEscalationConfirmDialog(true);
    } else if (textMatchClearEscalationPhrase === 'unmatched') {
      setOpenClearEscalationConfirmDialog(false);
      dispatchMessageReply();
    } else {
      setOpenClearEscalationConfirmDialog(false);
    }
  }, [textMatchClearEscalationPhrase]);

  const determineIfReplyTextMatchClearEscalationPhrase = () => {
    dispatch(determineIfTextMatchClearEscalationPhrase(reply));
  };

  const resetClearEscalationDialogParams = () => {
    setSelectedContentID(null);
    setOpenClearEscalationConfirmDialog(false);
    dispatch(setTextMatchClearEscalationPhrase(null));
  };

  const dispatchMessageReply = () => {
    if (reply) {
      const operator = user?.login?.toLowerCase();
      dispatch(
        sendReply(
          content.ID,
          content.MESSAGE_TXT,
          reply,
          content.PAGER_NUMBER,
          content.ROSTER_ID,
          content.ACCOUNT_NUMBER,
          operator,
        ),
      );
      setOpen(false);
      setReply('');
      resetClearEscalationDialogParams();
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    dispatch(setDownloadList(newChecked));
  };

  const handlePlay = (event) => {
    dispatch(getRecordingById(content.AUDIO_ID));
  };

  useEffect(() => {
    setExpanded(expandDDAll);
  }, [expandDDAll]);

  useEffect(() => {
    setExpandedNote(expandNoteAll);
  }, [expandNoteAll]);

  useEffect(() => {
    // console.log('clear escalation update', clearEsclationUpdate)
    // console.log('clear escalation content ', content.ID)
    // console.log('clear escalation check', String(clearEsclationUpdate) === String(content.ID));
    // console.log('ESCALATION_STATUS', content.ESCALATION_STATUS);
    if (
      clearEsclationUpdate &&
      Object.keys(clearEsclationUpdate).length > 0 &&
      String(clearEsclationUpdate.id) === String(content.ID)
    ) {
      updateEscalationChanges(
        selReceiver,
        portalUser,
        clearEsclationUpdate.id,
        clearEsclationUpdate.status,
      );
      dispatch(setClearEscalationUpdate(''));
    }
  }, [clearEsclationUpdate]);

  const updateEscalationChanges = (receiver, webuser, messageId, status) => {
    //dispatch(setFocusedMessageId(messageId));
    //const db = getDatabase();
    //const messageRef = ref(db, `receivers/${selReceiver}/messages/`);

    // Attach onChildAdded listener
    // onChildAdded(messageRef, (snapshot) => {
    //   const addedMessageKey = snapshot.key;
    //   const addedMessageValue = snapshot.val();
    //   console.log(`Message with key ${addedMessageKey} was added:`, addedMessageValue);
    //   if (addedMessageKey === messageId) {
    //     const updatedMessageRef = ref(db, `receivers/${receiver}/messages/${addedMessageKey}`)
    //     remove(updatedMessageRef)
    //       .then(() => {
    //         console.log(`Message with ID ${messageId} was successfully deleted.`);
    //       })
    //       .catch((error) => {
    //         console.error(`Failed to delete message with ID ${messageId}:`, error);
    //       });
    //   }

    //   // Handle the added message if needed
    // });

    update(ref(database, `receivers/${receiver}/messages/${messageId}/escalationUpdates/`), {
      webuserId: webuser.id,
      loginTxt: webuser.login,
      updatedTime: new Date(),
      status: status,
    });
  };

  // console.log('messageworkingstatus', workingMessage)
  const updateReceiverChanges = (receiver, webuser, messageId, status) => {
    dispatch(setFocusedMessageId(messageId));
    //const db = getDatabase();
    update(ref(database, `receivers/${receiver}/messages/${messageId}/workUpdates/`), {
      webuserId: webuser.id,
      loginTxt: webuser.login,
      updatedTime: new Date(),
      status: status,
    });
  };

  const handleClickOpen = (messageId, choice) => {
    resetClearEscalationDialogParams();
    setSelectedContentID(content.ID);
    setOpen(true);
    setMode(choice);
    // console.log('workupdate click open', messageId, choice)
    updateReceiverChanges(selReceiver, portalUser, messageId, 'active');
    switch (choice) {
      case 'forward':
        setTitle(t('Forward'));
        setContextText('');
        setContextTextColor('grey');
        setForwardTxt('');
        setSubject('');
        break;
      case 'note':
        setTitle(t('Add Note'));
        setContextText('Enter Note and who it is From.');
        setContextTextColor('grey');
        setFrom('');
        setNote('');
        break;
      case 'reply':
        setTitle(t('Reply'));
        setContextText(content.MESSAGE_FORMATTED_TXT);
        setContextTextColor('grey');
        setReply('');
        break;
      case 'archive':
        setTitle(t('Archive'));
        setContextText('Are you sure you want to archive this message ?');
        setContextTextColor('grey');
        break;
      case 'unarchive':
        setTitle(t('Unarchive'));
        setContextText('Are you sure you want to unarchive this message ?');
        setContextTextColor('grey');
        break;
      default:
        break;
    }
  };

  const handleFollowUp = (messageId, followUpFlag) => {
    dispatch(updateFollowUp(messageId, followUpFlag, selReceiver));
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email1':
        setEmail1(event.target.value);
        break;
      case 'email2':
        setEmail2(event.target.value);
        break;
      case 'email3':
        setEmail3(event.target.value);
        break;
      case 'subject':
        setSubject(event.target.value);
        break;
      case 'deliveryInclude':
        setDeliveryInclude(event.target.value);
        break;
      case 'forwardComments':
        setForwardComments(event.target.value);
        break;
      case 'forwardPhoneEntry':
        setForwardPhoneEntryId(event.target.value);
        setForwardPhoneEntry(
          contactsList.filter((element) => element.id === event.target.value)[0],
        );
        break;
      case 'forwardReceiver':
        if (isNaN(parseInt(event.target.value))) {
          return;
        }
        setForwardReceiver(event.target.value);
        break;
      case 'forwardType':
        setForwardType(event.target.value);
        break;
      case 'forwardTxt':
        setForwardTxt(event.target.value);
        break;
      case 'note':
        setNote(event.target.value);
        if (event.target.value.length > 3500) {
          setContextText('Note cannot be greater than 3500 characters.');
          setContextTextColor('red');
        } else {
          setContextText('');
          setContextTextColor('grey');
        }
        break;
      case 'from':
        setFrom(event.target.value);
        break;
      case 'reply':
        setReply(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    // console.log('test close listener');
    setOpen(false);
    setMode('');
    setReply('');
    resetClearEscalationDialogParams();

    updateReceiverChanges(selReceiver, portalUser, focusedMessageId, 'inactive');
    dispatch(setFocusedMessageId(''));
  };

  const clearEscalation = (content) => {
    var operator = user.login.toLowerCase();
    dispatch(clearMessageEscalation(operator.toUpperCase(), content.PORT, content.ID));
  };

  const handleSave = () => {
    updateReceiverChanges(selReceiver, portalUser, focusedMessageId, 'inactive');
    switch (mode) {
      case 'forward':
        var formattedMsg;
        if (forwardType === 'receiver') {
          formattedMsg = `${content.MESSAGE_FORMATTED_TXT} \n ${forwardComments}`.replaceAll(
            '\n',
            'CRLF',
          );
          dispatch(sendMessage(formattedMsg, forwardReceiver));
        } else if (forwardType === 'phonebook' && forwardPhoneEntry.typeTxt === 'RECEIVER') {
          formattedMsg = `${content.MESSAGE_FORMATTED_TXT} \n ${forwardComments}`.replaceAll(
            '\n',
            'CRLF',
          );
          dispatch(sendMessage(formattedMsg, forwardPhoneEntry.valueTxt));
        } else {
          var emailBody = content.MESSAGE_FORMATTED_TXT;
          if (deliveryInclude === 'Y') {
            if (content.deliveryDetails && content.deliveryDetails.length > 0) {
              emailBody += '\n \t Delivery Details';
              content.deliveryDetails.forEach((item) => {
                emailBody += `\n \t ${item.PRIORITY} ${moment
                  .tz(item.DT_RECVD, 'YYYYMMDDHHmmss', 'America/New_York')
                  .tz(defaultTimezone)
                  .format('MM-DD-YYYY HH:mm:ss z')} ${item.DIRECTORY_NAME} ${item.RESULT}`;
              });
            }
          }

          if (content.notes && content.notes.length > 0) {
            emailBody += '\n \t Notes:';
            content.notes.forEach((item) => {
              emailBody += `\n \t Note by ${item.ADDED_BY} , added on ${moment
                .tz(item.ADD_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                .tz(defaultTimezone)
                .format('MM-DD-YYYY HH:mm:ss z')} "${item.NOTE_TXT}"`;
            });
          }

          if (forwardType === 'phonebook' && forwardPhoneEntry.typeTxt === 'EMAIL') {
            dispatch(
              sendForwardMessage(
                `${emailBody} \n\n ${forwardComments}`,
                selReceiver,
                forwardPhoneEntry.valueTxt,
                brand.ccEmail,
                `@${brand.forwardDomain}`,
                subject,
              ),
            );
          }

          if (
            email1.length > 0 &&
            /^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(email1)
          ) {
            dispatch(
              sendForwardMessage(
                `${emailBody} \n\n ${forwardComments}`,
                selReceiver,
                email1,
                brand.ccEmail,
                `@${brand.forwardDomain}`,
                subject,
              ),
            );
          }

          if (
            email2.length > 0 &&
            /^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(email2)
          ) {
            dispatch(
              sendForwardMessage(
                `${emailBody} \n\n ${forwardComments}`,
                selReceiver,
                email2,
                brand.ccEmail,
                `@${brand.forwardDomain}`,
                subject,
              ),
            );
          }

          if (
            email3.length > 0 &&
            /^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(email3)
          ) {
            dispatch(
              sendForwardMessage(
                `${emailBody} \n\n ${forwardComments}`,
                selReceiver,
                email3,
                brand.ccEmail,
                `@${brand.forwardDomain}`,
                subject,
              ),
            );
          }
        }

        setOpen(false);
        break;
      case 'note':
        if (note && new TextEncoder().encode(note).length > 3500) {
          setContextText('Note cannot be greater than 3500 characters.');
          setContextTextColor('red');
          break;
        }
        if (user.staffFlag === 'N' && from.length === 0 && note.length === 0) {
          setContextText('Please fill out the fields below, or press CANCEL.');
          setContextTextColor('red');
          break;
        }
        if (user.staffFlag === 'N' && from.length === 0) {
          setContextText("Please enter your name in the 'From' field.");
          setContextTextColor('red');
          break;
        }
        if (note.length === 0) {
          setContextText("Please enter your note into the 'Notes' field.");
          setContextTextColor('red');
          break;
        }
        setContextTextColor('grey');
        dispatch(
          sendNote(content.ID, user.staffFlag === 'Y' ? user.login : from, note, selReceiver),
        );
        setOpen(false);
        break;
      case 'reply':
        determineIfReplyTextMatchClearEscalationPhrase();
        break;
      case 'archive':
        dispatch(updateArchive(content.ID, content.PAGER_NUMBER, 'Y'));
        setOpen(false);
        break;
      case 'unarchive':
        dispatch(updateArchive(content.ID, content.PAGER_NUMBER, 'N'));
        setOpen(false);
        break;
      default:
        break;
    }
  };

  const handleExpandClick = (value) => {
    setExpanded(!expanded);
  };

  const closeClearEscalationConfirmDialog = () => {
    setOpenClearEscalationConfirmDialog(false);
  };

  const confirmClearEscalationConfirmDialog = () => {
    dispatchMessageReply();
  };

  const formatEscalationClearedString = (content) => {
    const hidePagerNumber = content.ESCALATION_CLEARED_ID?.includes(content.ORIGINAL_PAGER_NUMBER);
    const clearedDate = moment
      .tz(content.ESCALATION_CLEARED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
      .tz(defaultTimezone)
      .format('MM-DD-YYYY hh:mm A z');
    const escalationClearedID = hidePagerNumber ? 'by ' : `by: ${content.ESCALATION_CLEARED_ID}`;
    const clearedByName =
      content.ESCALATION_CLEARED_BY_WEBUSER_NAME !== null &&
      content.ESCALATION_CLEARED_BY_WEBUSER_NAME !== 'null'
        ? hidePagerNumber
          ? content.ESCALATION_CLEARED_BY_WEBUSER_NAME
          : `( ${content.ESCALATION_CLEARED_BY_WEBUSER_NAME} )`
        : content.ESCALATION_CLEARED_BY_NAME !== null &&
            content.ESCALATION_CLEARED_BY_NAME !== 'null'
          ? hidePagerNumber
            ? content.ESCALATION_CLEARED_BY_NAME
            : `( ${content.ESCALATION_CLEARED_BY_NAME} )`
          : ' ';
    return ` ${clearedDate} ${escalationClearedID} ${clearedByName}`;
  };

  const getEscalationStatusText = (content) => {
    if (content?.CLEAR_ESCALATION_REPLY_STATUS === 'PENDING_REPLY') {
      return 'REPLY PENDING';
    } else {
      const escalationText = `ESCALATION ${content.ESCALATION_STATUS}`.toLowerCase().toUpperCase();
      let additionalText = '';
      if (content.ESCALATION_STATUS === 'CLEARED') {
        additionalText = formatEscalationClearedString(content);
      }
      return escalationText + additionalText;
    }
  };

  return (
    <StyledGrid className={classes.root} item md={size} xs={12}>
      <Card className={classes.workingCard}>
        <CardContent className={classes.card}>
          <Grid className={classes.grid} container spacing={0}>
            <Grid className={classes.cardTop} item xs={12}>
              {!staffMode && (
                <Checkbox
                  checked={checked.indexOf(content.ID) !== -1}
                  edge="end"
                  onChange={handleToggle(content.ID)}
                />
              )}
              {parseInt(content.SEQUENCE_NUMBER) > 0 && (
                <Avatar className={classes.small}>{content.SEQUENCE_NUMBER}</Avatar>
              )}
              <Divider
                flexItem
                orientation="vertical"
                style={{ marginRight: '8px', marginLeft: '8px' }}
              />
              <Typography className={classes.item} variant="body1">
                {' '}
                ID : {content.ORIGINAL_PAGER_NUMBER}
              </Typography>
              <Divider
                flexItem
                orientation="vertical"
                style={{ marginRight: '8px', marginLeft: '8px' }}
              />
              <Typography className={classes.item} variant="body1">
                {' '}
                {content.HOLDER_NAME}
              </Typography>
              <Divider
                flexItem
                orientation="vertical"
                style={{ marginRight: '8px', marginLeft: '8px' }}
              />
              <Typography className={classes.item} variant="body1">
                {moment
                  .tz(content.RECEIVED_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                  .tz(defaultTimezone)
                  .format('MMM DD,YYYY hh:mm A z')}
              </Typography>
              {!expandMessageAll && content?.MESSAGE_FORMATTED_HTML && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    style={{ marginRight: '8px', marginLeft: '8px' }}
                  />
                  <div
                    className={size === 12 ? classes.msgBody : classes.msgBodyGrid}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        content?.MESSAGE_FORMATTED_HTML?.substring(0, 94)
                          ?.replace(/(\r\n|\n|\r)/gm, ' ')
                          ?.replace(/<br\s*\/?>/gi, ' '),
                      ),
                    }}
                  />
                </>
              )}
            </Grid>
            {expandMessageAll && (
              <Grid className={classes.cardBottom} item xs={12}>
                <div className={classes.cardBottomHeader}>
                  <div
                    className={size === 12 ? classes.msgBody : classes.msgBodyGrid}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        pattern && pattern.length > 0
                          ? content.MESSAGE_FORMATTED_HTML.replace(
                              new RegExp(pattern, 'gi'),
                              '<span style="background-color:yellow;">$&</span>',
                            )
                          : content.MESSAGE_FORMATTED_HTML,
                      ),
                    }}
                  />
                </div>
                <Collapse in={expandedNote} timeout="auto" unmountOnExit>
                  {content.notes && content.notes.length > 0 && (
                    <>
                      <Typography color="primary" variant="subtitle1">
                        Notes:
                      </Typography>
                      <List className={classes.noteHolder}>
                        {content.notes &&
                          content.notes.map((item, index) => <Note content={item} key={index} />)}
                      </List>
                    </>
                  )}
                </Collapse>

                {content.deliveryDetails && content.deliveryDetails.length > 0 && (
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                      <Table aria-label="simple dense table" className={classes.table} size="small">
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                              {t('Delivery Details')}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ textAlign: 'center' }}>{t('Priority')}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{t('Time Sent')}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {t('Contact Name')}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {t('Contact Method')}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{t('Result')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {content.deliveryDetails &&
                            content.deliveryDetails.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ textAlign: 'center' }}
                                >
                                  {row.PRIORITY}
                                </TableCell>
                                <TableCell align="center">
                                  {moment
                                    .tz(row.DT_RECVD, 'YYYYMMDDHHmmss', 'America/New_York')
                                    .tz(defaultTimezone)
                                    .format('MMM DD,YYYY hh:mm A z')}
                                </TableCell>
                                <TableCell align="center">{row.DIRECTORY_NAME} </TableCell>
                                <TableCell align="center">
                                  {' '}
                                  {row.DIRECTORY_TYPE &&
                                  row.DIRECTORY_TYPE?.toLowerCase() === 'cognisent'
                                    ? 'Secure App'
                                    : row.DIRECTORY_TYPE}
                                </TableCell>
                                <TableCell align="center"> {row.RESULT}</TableCell>
                              </TableRow>
                            ))}
                          {content.deliveryDetails && content.deliveryDetails.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={3}>{t('no records found')}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                )}

                <Divider flexItem orientation="horizontal" />

                {content.ESCALATION_STATUS && (
                  <Typography
                    className={
                      content?.CLEAR_ESCALATION_REPLY_STATUS === 'PENDING_REPLY'
                        ? classes.replyPending
                        : content.ESCALATION_STATUS === 'PENDING'
                          ? classes.escalation
                          : classes.active
                    }
                    variant="overline"
                  >
                    {getEscalationStatusText(content)}
                  </Typography>
                )}

                {content.ESCALATION_STATUS === 'PENDING' && (
                  <>
                    <Hidden smDown>
                      <PortalButton
                        className={classes.button}
                        component="span"
                        disabled={content?.CLEAR_ESCALATION_REPLY_STATUS === 'PENDING_REPLY'}
                        onClick={() => clearEscalation(content)}
                        size="small"
                        sx={{ padding: '0 10px', borderRadius: '10px', mr: '10px' }}
                        variant="contained"
                      >
                        {content?.CLEAR_ESCALATION_REPLY_STATUS === 'PENDING_REPLY'
                          ? t('Not Clearable')
                          : t('Clear Escalation')}
                      </PortalButton>
                    </Hidden>
                    <Hidden mdUp>
                      <PortalIconButton
                        color="primary"
                        onClick={() => clearEscalation(content)}
                        title={
                          content?.CLEAR_ESCALATION_REPLY_STATUS === 'PENDING_REPLY'
                            ? t('Not Clearable')
                            : t('Clear Escalation')
                        }
                      >
                        <ClearIcon />
                      </PortalIconButton>
                    </Hidden>
                  </>
                )}

                {!disableInlineRecording && content && content.AUDIO_ISAM_ID && (
                  <div>
                    {content.AUDIO_AVAILABLE && !content.audio && (
                      <PortalButton color="primary" onClick={handlePlay} variant="outlined">
                        Play
                      </PortalButton>
                    )}

                    {content.AUDIO_AVAILABLE && content.audio && content.audio.length > 0 && (
                      <audio
                        controls
                        controlsList="nodownload noremoteplayback"
                        style={{ width: '100%' }}
                      >
                        <source src={`data:audio/ogg;base64,${content.audio}`} />
                        The “audio” tag is not supported by your browser. Click [here] to download
                        the sound file.
                      </audio>
                    )}
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
        {expandMessageAll && (
          <CardActions className={classes.actions}>
            {!staffMode && (
              <>
                <PortalButton
                  color="primary"
                  onClick={() =>
                    handleFollowUp(content.ID, content.FOLLOWUP_FLAG === 'N' ? 'Y' : 'N')
                  }
                  variant={content.FOLLOWUP_FLAG === 'N' ? 'outlined' : 'contained'}
                >
                  {content.FOLLOWUP_FLAG === 'N'
                    ? t('Follow up not required')
                    : t('Follow Up required')}
                </PortalButton>
                <Divider flexItem orientation="vertical" />

                {!disableForwarding && (
                  <PortalIconButton
                    color="primary"
                    onClick={() => handleClickOpen(content.ID, 'forward')}
                    title={t('Forward')}
                  >
                    <ForwardIcon />
                  </PortalIconButton>
                )}
                {!disableArchive && !content.ARCHIVED_DATE && (
                  <PortalIconButton
                    color="primary"
                    onClick={() => handleClickOpen(content.ID, 'archive')}
                    title={t('Archive')}
                  >
                    <ArchiveIcon />
                  </PortalIconButton>
                )}
                {!disableArchive && content.ARCHIVED_DATE && (
                  <PortalIconButton
                    color="primary"
                    onClick={() => handleClickOpen(content.ID, 'unarchive')}
                    title={t('Unarchive')}
                  >
                    <UnarchiveIcon />
                  </PortalIconButton>
                )}

                <PortalIconButton
                  color={expanded ? 'secondary' : 'primary'}
                  title={t('Toggle Delivery Info')}
                >
                  <Badge
                    badgeContent={content.deliveryDetails ? content.deliveryDetails.length : '0'}
                  >
                    <InfoIcon onClick={handleExpandClick} />
                  </Badge>
                </PortalIconButton>
                {content.ENABLE_REPLY_BUTTON === 'Y' && (
                  <PortalIconButton
                    color={mode === 'reply' ? 'secondary' : 'primary'}
                    onClick={() => handleClickOpen(content.ID, 'reply')}
                    title="Reply"
                  >
                    <ReplyIcon />
                  </PortalIconButton>
                )}
                <PortalIconButton
                  color={mode === 'note' ? 'secondary' : 'primary'}
                  onClick={() => handleClickOpen(content.ID, 'note')}
                  title={t('Add Note')}
                >
                  <NoteIcon />
                </PortalIconButton>
                {workingMessage && (
                  <div>
                    <Divider flexItem orientation="vertical" />
                    <Card style={{ padding: 5, backgroundColor: 'orange' }}>
                      <Typography color="white">Work in Progress</Typography>
                    </Card>{' '}
                  </div>
                )}
              </>
            )}
          </CardActions>
        )}
      </Card>

      <Dialog
        aria-labelledby="simple-dialog-title"
        className={classes.dialog}
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {content?.MESSAGE_FORMATTED_HTML && mode === 'note' && (
            <div style={{ marginBottom: 20 }}>
              <Divider
                flexItem
                orientation="vertical"
                style={{ marginRight: '8px', marginLeft: '8px' }}
              />
              <div
                className={size === 12 ? classes.msgBody : classes.msgBodyGrid}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    content?.MESSAGE_FORMATTED_HTML?.substring(0, 94)
                      ?.replace(/(\r\n|\n|\r)/gm, ' ')
                      ?.replace(/<br\s*\/?>/gi, ' '),
                  ),
                }}
                style={{ fontStyle: 'italic' }}
              />
            </div>
          )}
          <DialogContentText id="alert-dialog-description" style={{ color: contextTextColor }}>
            {contextText}
          </DialogContentText>

          {mode === 'reply' && (
            <FormControl fullWidth>
              <TextField
                fullWidth
                label={t('Reply')}
                margin="dense"
                multiline
                name="reply"
                onChange={handleChange}
                required
                rows={4}
                type="text"
                value={reply}
              />
            </FormControl>
          )}

          {mode === 'note' && (
            <div>
              <FormControl fullWidth>
                <TextField
                  disabled={user.staffFlag === 'Y'}
                  fullWidth
                  label={t('From')}
                  margin="dense"
                  name="from"
                  onChange={handleChange}
                  required
                  type="text"
                  value={user.staffFlag === 'Y' ? user.login : from}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label={t('Note')}
                  margin="dense"
                  multiline
                  name="note"
                  onChange={handleChange}
                  required
                  rows={4}
                  type="text"
                  value={note}
                />
                <Typography style={{ color: 'grey' }} variant="caption">
                  {note && new TextEncoder().encode(note).length}
                </Typography>
              </FormControl>
            </div>
          )}

          {mode === 'forward' && (
            <>
              <FormControl fullWidth>
                <FormLabel component="legend">{t('Forward To')}</FormLabel>
                <RadioGroup
                  aria-label="forwardType"
                  name="forwardType"
                  onChange={handleChange}
                  value={forwardType}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Manually Entered ID"
                    labelPlacement="end"
                    value="receiver"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Email (max 3)"
                    labelPlacement="end"
                    value="email"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Phonebook Entry"
                    labelPlacement="end"
                    value="phonebook"
                  />
                </RadioGroup>
              </FormControl>

              {forwardType === 'receiver' && (
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    label={t('Receiver ID')}
                    margin="dense"
                    name="forwardReceiver"
                    onChange={handleChange}
                    required
                    type="text"
                    value={forwardReceiver}
                  />
                </FormControl>
              )}

              {forwardType === 'phonebook' && (
                <FormControl fullWidth>
                  <Autocomplete
                    className={classes.dropdown}
                    fullWidth
                    getOptionLabel={(option) =>
                      option.nameTxt ? `${option.nameTxt} ${option.valueTxt}` : ''
                    }
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    name="forwardPhoneEntry"
                    onChange={(event, newValue) => {
                      if (newValue && newValue.id) {
                        setForwardPhoneEntryId(newValue.id);
                        setForwardPhoneEntry(newValue);
                      }
                    }}
                    options={
                      contactsList
                        ? contactsList.slice().sort((a, b) => a.nameTxt.localeCompare(b.nameTxt))
                        : []
                    }
                    renderInput={(params) => {
                      if (params) {
                        return (
                          <TextField {...params} label={t('Pick a contact')} variant="outlined" />
                        );
                      }
                      return null;
                    }}
                    style={{ flex: 1, marginRight: '8px', background: 'white' }}
                    value={forwardPhoneEntry}
                  />
                </FormControl>
              )}

              {forwardType === 'email' && (
                <Box
                  sx={{
                    '& .MuiFormControl-root': {
                      marginTop: '3px',
                      marginBottom: '3px',
                    },
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      error={
                        email1.length > 0 &&
                        !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                          email1,
                        )
                      }
                      label={`${t('Email')} 1`}
                      name="email1"
                      onChange={handleChange}
                      type="text"
                      value={email1}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      error={
                        email2.length > 0 &&
                        !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                          email2,
                        )
                      }
                      label={`${t('Email')} 2`}
                      name="email2"
                      onChange={handleChange}
                      type="text"
                      value={email2}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      error={
                        email3.length > 0 &&
                        !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                          email3,
                        )
                      }
                      label={`${t('Email')} 3`}
                      name="email3"
                      onChange={handleChange}
                      type="text"
                      value={email3}
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      inputProps={{ maxLength: 200 }}
                      label={t('Subject')}
                      name="subject"
                      onChange={handleChange}
                      type="text"
                      value={subject}
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel component="legend">{t('Delivery Details')}</FormLabel>
                    <RadioGroup
                      aria-label="deliveryInclude"
                      name="deliveryInclude"
                      onChange={handleChange}
                      style={{}}
                      value={deliveryInclude}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label={t('Do not Include')}
                        labelPlacement="end"
                        value="N"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label={t('Include')}
                        labelPlacement="end"
                        value="Y"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}

              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label={t('Actual Text')}
                  margin="dense"
                  multiline
                  name="fowardTxt"
                  onChange={handleChange}
                  required
                  rows={4}
                  type="text"
                  value={content.MESSAGE_FORMATTED_TXT}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label={t('Additional Comments')}
                  margin="dense"
                  multiline
                  name="forwardComments"
                  onChange={handleChange}
                  rows={4}
                  type="text"
                  value={forwardComments}
                />
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <PortalButton
            color="primary"
            disabled={
              (mode === 'reply' && !reply) ||
              (mode === 'note' && note && (note.length === 0 || note.length > 3500))
            }
            onClick={handleSave}
            variant="contained"
          >
            {mode === 'reply' || mode === 'forward'
              ? t('Send')
              : mode === 'archive' || mode === 'unarchive'
                ? 'Yes'
                : t('Save')}
          </PortalButton>
          <PortalButton autoFocus color="secondary" onClick={handleClose} variant="contained">
            {mode === 'archive' || mode === 'unarchive' ? 'No' : t('Cancel')}
          </PortalButton>
        </DialogActions>
      </Dialog>

      {openClearEscalationConfirmDialog && selectedContentID === content.ID && (
        <PortalDialog
          ComponentToRender={() =>
            `Your reply Acknowledges that you received the Message and that you want this Message’s Escalation to end. Is that correct?`
          }
          actionItems={[
            {
              autoFocus: true,
              onClick: confirmClearEscalationConfirmDialog,
              color: 'primary',
              text: 'Yes - please clear the escalation.',
              variant: 'contained',
            },
            {
              autoFocus: false,
              onClick: closeClearEscalationConfirmDialog,
              color: 'secondary',
              text: 'No - please let me revise my reply.',
              variant: 'contained',
            },
          ]}
          fullScreen={false}
          open={openClearEscalationConfirmDialog}
          propagateOnClose={closeClearEscalationConfirmDialog}
          showAppBar={false}
          title="Clear Escalation"
        />
      )}
    </StyledGrid>
  );
};

export default Message;
