import { styled } from '@mui/material/styles';
import { CircularProgress, Grid } from '@mui/material';
import { loadingSelector } from '../accountSlice';
import { useSelector } from 'react-redux';
import CustomizedProgressLoader from '../../shared/CustomizedProgressLoader';
import { useTranslation } from 'react-i18next';
import VoicemailsByLineNumber from './VoicemailsByLineNumber';
import VoiceMailMainFilter from './VoiceMailMainFilter';

const PREFIX = 'Voicemails';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },
}));

export default function VoiceMails() {
  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);

  return (
    <StyledGrid className={classes.root} container spacing={2}>
      <CustomizedProgressLoader />
      {loading && (
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
          <CircularProgress />
        </Grid>
      )}
      <Grid item xs={12}>
        <VoiceMailMainFilter />
      </Grid>
      <Grid item xs={12}>
        <VoicemailsByLineNumber />
      </Grid>
    </StyledGrid>
  );
}
