import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import PortalButton from '../../shared/components/PortalButton';
import { useState } from 'react';
import PortalDialog from '../../shared/components/PortalDialog';
import {
  alarmLoadingStateSelector,
  importReceiverAlarmFile,
  receiverAlarmImportDryRunStateSelector,
  receiverAlarmImportResponseSelector,
  selectedReceiverSelector,
  setReceiverAlarmImportDryRunState,
  setReceiverAlarmImportResponse,
} from '../alarmSlice';
import AlarmImportDialogView from './AlarmImportDialogView';
import PublishIcon from '@mui/icons-material/Publish';
import { setPortalSnackInfo } from '../../../app/authSlice';
import { getImportAlarmTotalErrorCount } from '../../../utils/alarmUtil';

export default function AlarmImportButton() {
  const dispatch = useDispatch();
  const selectedReceiver = useSelector(selectedReceiverSelector);
  const receiverAlarmImportDryRunState = useSelector(receiverAlarmImportDryRunStateSelector);
  const loadingState = useSelector(alarmLoadingStateSelector);
  const receiverAlarmImportResponse = useSelector(receiverAlarmImportResponseSelector);
  const [selectedFileInput, setSelectedFileInput] = useState(null);
  const [openImportAlarmDialog, setOpenImportAlarmDialog] = useState(false);

  const handleImportAlarmConfirmation = () => {
    if (selectedFileInput === null) {
      dispatch(
        setPortalSnackInfo({
          open: true,
          message: 'Please select a file to import.',
          severity: 'error',
        }),
      );
      return;
    }
    if (getImportAlarmTotalErrorCount(receiverAlarmImportResponse) > 0) {
      dispatch(
        setPortalSnackInfo({
          open: true,
          message: 'Please fix all displayed errors before importing.',
          severity: 'error',
        }),
      );
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFileInput);
    dispatch(
      importReceiverAlarmFile(selectedReceiver?.number, formData, (res) => {
        if (res === 'import_success') {
          setOpenImportAlarmDialog(false);
        }
      }),
    );
  };

  const handleCloseImportAlarmDialog = () => {
    setOpenImportAlarmDialog(false);
    resetInputs();
  };

  const handleOpenCreateAlarmDialog = () => {
    resetInputs();
    setOpenImportAlarmDialog(true);
  };

  const handleFormDataChange = (file) => {
    setSelectedFileInput(file);
    dispatch(setReceiverAlarmImportResponse([]));
    dispatch(setReceiverAlarmImportDryRunState(true));
  };

  const resetInputs = () => {
    setSelectedFileInput(null);
    dispatch(setReceiverAlarmImportResponse([]));
    dispatch(setReceiverAlarmImportDryRunState(true));
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PortalButton color="primary" onClick={handleOpenCreateAlarmDialog} variant="contained">
          Import Alarms <PublishIcon />
        </PortalButton>
      </Box>

      {openImportAlarmDialog && (
        <PortalDialog
          ComponentProps={{
            propagateFormDataChange: handleFormDataChange,
            fileInput: selectedFileInput,
          }}
          ComponentToRender={AlarmImportDialogView}
          actionItems={[
            {
              autoFocus: true,
              onClick: handleImportAlarmConfirmation,
              color: 'primary',
              text: receiverAlarmImportDryRunState
                ? 'Dry Run - Import Alarms'
                : 'Confirm - Import Alarms',
              variant: 'contained',
              disabled:
                loadingState?.loadingImportReceiverAlarmFileDryRun ||
                getImportAlarmTotalErrorCount(receiverAlarmImportResponse) > 0,
            },
            {
              autoFocus: false,
              onClick: handleCloseImportAlarmDialog,
              color: 'secondary',
              text: 'Cancel',
              variant: 'contained',
              disabled: loadingState?.loadingImportReceiverAlarmFileDryRun,
            },
          ]}
          fullScreen={false}
          fullWidth={true}
          maxWidth="xl"
          open={openImportAlarmDialog}
          propagateOnClose={handleCloseImportAlarmDialog}
          showAppBar={false}
          title="Import Alarms"
        />
      )}
    </>
  );
}
