import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';

const PREFIX = 'AssemblyAICallTranscription';

const classes = {
  root: `${PREFIX}-root`,
  tableContainer: `${PREFIX}-tableContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  graphContainer: `${PREFIX}-graphContainer`,
  speaker: `${PREFIX}-speaker`,
  tableContainer2: `${PREFIX}-tableContainer2`,
  notes: `${PREFIX}-notes`,
};
const pageThemColor = '#0097a7';

const StyledRoot = styled('div')({
  [`& .${classes.tableContainer}`]: { boxShadow: '0 1px 1px 0 #4dd0e182', borderRadius: '5px' },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '18px' },
  [`& .${classes.graphContainer}`]: {
    backgroundColor: '#f8fdff',
    padding: '8px',
    borderRadius: '10px',
  },
  [`& .${classes.speaker}`]: {
    fontWeight: 'bold',
    minWidth: '100px',
  },
  [`& .${classes.tableContainer2}`]: {
    maxHeight: 500,
  },
  [`& .${classes.notes}`]: {
    marginTop: '5px',
    paddingLeft: '15px',
  },
});

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: pageThemColor,
  color: theme.palette.common.white,
  padding: '2px',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: '5px 10px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const subTitleSections = {
  start: 'SENTIMENT COLORS: ',
  black: 'NEUTRAL, ',
  green: 'POSTIVE, ',
  red: 'NEGATIVE',
};

export default function AssemblyAICallTranscription({ transcriptList, colorCodePerSpeaker }) {
  const getSegmentText = (segment) => {
    let segmentText = segment?.text ? `<span>${segment.text}</span>` : '';
    try {
      segmentText = `<span style="color: ${
        segment?.sentiment === 'POSITIVE'
          ? 'green'
          : segment?.sentiment === 'NEGATIVE'
            ? 'red'
            : 'black'
      }">${segment.text}</span>`;
    } catch (error) {
      console.error('Error', error);
    }
    return segmentText;
  };

  const getSpeakerName = (speaker) => {
    return `[Speaker ${speaker?.trim()}]`;
  };

  return (
    <StyledRoot>
      {transcriptList && transcriptList?.length > 0 && (
        <>
          <Box className={classes.graphContainer}>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table aria-label="Call Transcription" className={classes.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell colSpan="2">
                      <Typography className={classes.sectionTitle}>Call Transcription </Typography>
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.scrollableTableBody}>
                  {transcriptList.map((segment, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableBodyCell
                        className={classes.speaker}
                        style={{
                          color:
                            colorCodePerSpeaker && colorCodePerSpeaker[segment?.speaker?.trim()]
                              ? colorCodePerSpeaker[segment?.speaker?.trim()]
                              : 'black',
                        }}
                      >
                        {' '}
                        {getSpeakerName(segment?.speaker?.trim())}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        dangerouslySetInnerHTML={{
                          __html: getSegmentText(segment),
                        }}
                      />
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.notes}>
            <Typography component="i" variant="caption">
              <span>{subTitleSections.start}</span>{' '}
              <span style={{ color: 'black' }}>{subTitleSections.black}</span>{' '}
              <span style={{ color: 'green' }}>{subTitleSections.green}</span>{' '}
              <span style={{ color: 'red' }}>{subTitleSections.red}</span>
            </Typography>
          </Box>
        </>
      )}
    </StyledRoot>
  );
}
