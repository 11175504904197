import { IconButton } from '@mui/material';
import { forwardRef } from 'react';

const PortalIconButton = forwardRef(({ sx, ...props }, ref) => {
  return (
    <IconButton
      sx={{
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
        },
        '&:active': {
          transform: 'scale(0.95)',
        },
        ...sx,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default PortalIconButton;
PortalIconButton.displayName = 'PortalIconButton';
