import Paper from '@mui/material/Paper';
import AlarmListTable from './list/AlarmListTable';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import AlarmMainFilter from './filter';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getReceivers } from '../messages/messageSlice';
import { getXXMapTimezones } from '../../app/authSlice';
// import AlarmFirebaseSubscriptionSetup from './list/AlarmFirebaseSubscriptionSetup';
const PREFIX = 'Alarms';

const classes = {
  root: `${PREFIX}-root`,
  gridContainer: `${PREFIX}-gridContainer`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
  },
  [`&. ${classes.gridContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Alarms() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReceivers());
    dispatch(getXXMapTimezones());
  }, []);

  return (
    <StyledPaper className={classes.root}>
      {/* firebase subscription setup for alarm */}
      {/* <AlarmFirebaseSubscriptionSetup /> */}
      <Grid className={classes.gridContainer} container spacing={2}>
        <Grid item xs={12}>
          <AlarmMainFilter />
        </Grid>
        <Grid item xs={12}>
          <AlarmListTable />
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
