import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from './PortalApi';

export const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    domainDefaults: {
      suggestionEmail: 'custsvc@mapcommunications.com',
    },
  },
  reducers: {
    setDomainDefaults: (state, action) => {
      state.domainDefaults = action.payload;
    },
  },
});

export const { setDomainDefaults, setCsEmailResponse } = brandSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const domainDefaultsSelector = (state) => state.brand.domainDefaults;

export function getBrandingDefaults(domainTxt) {
  return (dispatch) => {
    dispatch(setDomainDefaults({}));
    try {
      var host =
        window.location.hostname === 'portal-v2.mapcom.local'
          ? 'acena.com'
          : window.location.hostname.replaceAll('secure2.', '');
      if (domainTxt) {
        host = domainTxt;
      }
      PortalApi.call(
        `/brands?hostName=${host}`,
        { method: 'get' },
        (stats) => {
          dispatch(setDomainDefaults(stats));
        },
        (error, status, content) => {
          dispatch(setDomainDefaults({}));
        },
      );
    } catch (error) {
      dispatch(setDomainDefaults({}));
    }
  };
}

export default brandSlice.reducer;
