import { Box, Button, FormControl, TextField } from '@mui/material';
import { useRef } from 'react';

const DefaultBrowseButton = ({ onClick }) => {
  return (
    <Button
      color="primary"
      onClick={onClick}
      sx={{ paddingLeft: 5, paddingRight: 5, marginTop: 2, marginBottom: 2 }}
      variant="contained"
    >
      Browse
    </Button>
  );
};

const PortalSingleFileInput = ({
  fileInput,
  propagateFileInputChange,
  BrowseButtonComponent,
  browseButtonPosition,
  allowedFileTypes = '',
}) => {
  const fileInputRef = useRef(null);

  const handleBrowseButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    propagateFileInputChange(file);
  };

  const getDefaultBrowseButton = () => {
    return <DefaultBrowseButton onClick={handleBrowseButtonClick} />;
  };

  const getInputProps = () => {
    const adornmentKey = browseButtonPosition === 'end' ? 'endAdornment' : 'startAdornment';
    return {
      [adornmentKey]: BrowseButtonComponent ? (
        <BrowseButtonComponent onClick={handleBrowseButtonClick} />
      ) : (
        getDefaultBrowseButton()
      ),
    };
  };

  return (
    <Box component="form">
      <FormControl>
        <TextField
          InputProps={getInputProps()}
          disabled
          label={fileInput && fileInput?.name ? fileInput?.name : 'Click on Browse to select file'}
          readOnly
          variant="outlined"
        />
        <input
          accept={allowedFileTypes}
          onChange={handleChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
        />
      </FormControl>
    </Box>
  );
};

export default PortalSingleFileInput;
PortalSingleFileInput.displayName = 'PortalSingleFileInput';
