import {
  CircularProgress,
  Grid,
  MenuItem,
  Popover,
  Select,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import DateRangeIcon from '@mui/icons-material/DateRange';
// import { setPortalSnackInfo } from '../../../app/authSlice';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import {
  getScheduleForAccount,
  loadingSelector,
  selAccountInfoSelector,
  selAccountSelector,
  selScheduleInfoSelector,
} from '../accountSlice';
import TimeLineWidget from './TImeLineWidget';
import OncallCalendar from './OncallCalendar';
import EventIcon from '@mui/icons-material/Event';
import TableChartIcon from '@mui/icons-material/TableChart';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  gridProgress: `${PREFIX}-gridProgress`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(0),
  },

  [`& .${classes.gridProgress}`]: {
    color: theme.palette.primary.main,
    zIndex: 1000,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function OnCallSchedules() {
  const dispatch = useDispatch();
  const selAccount = useSelector(selAccountSelector);
  const selScheduleInfo = useSelector(selScheduleInfoSelector);
  const selAccountInfo = useSelector(selAccountInfoSelector);
  const [schedules, setSchedules] = useState([]);
  const [selSchedule, setSelSchedule] = useState('0');
  const [contactMap, setContactMap] = useState({});
  const [legacyInfo, setLegacyInfo] = useState({});
  const [timeLineInfo, setTimeLineInfo] = useState({});
  const [count, setCount] = useState(1);
  const loading = useSelector(loadingSelector);

  const [contactsColorMap, setContactColorMap] = useState({});
  const [legendContact, setLegendContact] = useState('');
  const [styleTable, setStyleTable] = useState(
    localStorage.getItem('styleTable') ? localStorage.getItem('styleTable') : true,
  );

  const [calView, setCalView] = useState(
    localStorage.getItem('calView') ? localStorage.getItem('calView') : false,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
    },
  ]);

  const open = Boolean(anchorEl);
  const [dateRangeError, setDateRangeError] = useState('');

  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseOver = (contactId) => {
    setLegendContact(contactId);
  };

  const handleMouseOut = (contactId) => {
    setLegendContact('');
  };

  const handleChange = (event) => {
    setStyleTable(event.target.checked);
    localStorage.setItem('styleTable', event.target.checked);
  };

  const handleCalendarChange = (value) => {
    setCalView(value);
    localStorage.setItem('calView', value);
  };

  const handleSelect = (ranges) => {
    var current = selectionRange;
    if (moment(ranges.selection.endDate).diff(moment(ranges.selection.startDate), 'days') > 42) {
      setDateRangeError('Cannot load more than 31 days');
      return;
    }
    setDateRangeError('');
    current[0].startDate = ranges.selection.startDate;
    current[0].endDate = ranges.selection.endDate;
    setSelectionRange(current);
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      setAnchorEl(false);
      dispatch(
        getScheduleForAccount(
          selAccount,
          selSchedule,
          moment(selectionRange[0].startDate).format('YYYYMMDD'),
          moment(selectionRange[0].endDate).format('YYYYMMDD'),
        ),
      );
    }
  };

  useEffect(() => {
    if (selAccountInfo && selAccountInfo.onCallSchedules && selAccountInfo.onCallSchedules.string) {
      var schedules = JSON.parse(selAccountInfo.onCallSchedules.string);
      setSchedules(schedules);
      setSelSchedule(schedules[0].id);
    }
  }, [selAccountInfo]);

  useEffect(() => {
    if (selSchedule !== '0' && selAccount && selectionRange && selectionRange.length > 0) {
      dispatch(
        getScheduleForAccount(
          selAccount,
          selSchedule,
          moment(selectionRange[0].startDate).format('YYYYMMDD'),
          moment(selectionRange[0].endDate).format('YYYYMMDD'),
        ),
      );
    }
  }, [dispatch, selSchedule]);

  useEffect(() => {
    if (selScheduleInfo.shifts && selScheduleInfo.shifts.length > 0) {
      var firstDate = moment(selectionRange[0].startDate).startOf('day');
      var currDate = moment(selectionRange[0].startDate).startOf('day');
      var lastDate = moment(selectionRange[0].endDate).add(1, 'day').startOf('day');

      var duration = lastDate.diff(currDate, 'minutes');
      var dateTimeChunks = [];
      var colorArray = [
        '#F94144',
        '#577590',
        '#72ddf7',
        '#f7aef8',
        '#99e2b4',
        '#F9C74F',
        '#F8961E',
        '#43AA8B',
        '#87986a',
        '#8093f1',
        '#b84cff',
        '#00b0e8',
        '#ff477e',
        '#4361EE',
        '#F72585',
      ];

      var contacts = {},
        contactSeries = {};
      var schedules = {};
      var shifts = {};
      var colorIndex = 0;
      var contactColors = {};
      var timeLineInfo = { items: [], groups: [], start: firstDate, end: lastDate };
      selScheduleInfo.contacts.forEach((element) => {
        if (!contacts[element.id] && element.schedulable) {
          contacts[element.id] = element;
        }
        contactSeries[element.id] = { name: element.name, data: [] };
      });

      var gseries = [];
      gseries.push([
        { type: 'string', id: 'Rotation' },
        { type: 'string', id: 'Name' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
      ]);

      contactSeries['Unassigned'] = { name: 'Unassigned', data: [] };

      setContactMap(contacts);

      var selContactColors = {};

      selScheduleInfo.shifts.forEach((element) => {
        if (!schedules[element.rotationTitle]) {
          schedules[element.rotationTitle] = {
            name: element.rotationTitle,
            data: [],
          };
          timeLineInfo.groups.push({
            id: element.rotationTitle,
            title: element.rotationTitle,
            contactLevel: parseInt(element.contactLevel),
          });
        }

        var newShift = { ...element };
        if (moment(element.startDateTime, 'YYYYMMDDHHmm').diff(firstDate, 'minutes') >= 0) {
          newShift.startTime = moment(element.startDateTime, 'YYYYMMDDHHmm');
        } else {
          newShift.startTime = firstDate.clone();
        }

        if (lastDate.diff(moment(element.endDateTime, 'YYYYMMDDHHmm'), 'minutes') >= 0) {
          newShift.endTime = moment(element.endDateTime, 'YYYYMMDDHHmm');
          if (newShift.endTime.format('HHmmss') === '000000') {
            newShift.endTime = newShift.endTime.subtract(1, 'seconds');
          }
        } else {
          newShift.endTime = lastDate.clone();
        }

        newShift.startDateTime = moment(element.startDateTime, 'YYYYMMDDHHmm');
        newShift.endDateTime = moment(element.endDateTime, 'YYYYMMDDHHmm');

        //set color map for this contact
        if (element.contactId && !contactColors[element.contactId]) {
          contactColors[element.contactId] = colorArray[colorIndex];
          colorIndex += 1;
        }

        var bgcolor = 'grey',
          color = 'white';
        if (element.assignable) {
          if (element.contactId && contacts[element.contactId]) {
            color = 'black';
            if (styleTable) {
              bgcolor = contactColors[element.contactId];
              if (!selContactColors[element.contactId]) {
                selContactColors[element.contactId] = bgcolor;
              }
            } else {
              bgcolor = 'deepskyblue';
            }
          } else {
            if (styleTable) {
              color = 'red';
              bgcolor = 'white';
            } else {
              color = 'red';
              bgcolor = 'yellow';
            }
          }
        }

        var opacity = 1;
        if (legendContact !== '') {
          if (element.contactId !== legendContact) {
            opacity = 0.4;
          }
        }

        var timeLineObj = {
          id: `${newShift.localId}|${newShift.id}|${Math.round(10 * Math.random())}`,
          group: element.rotationTitle,
          resourceId: element.rotationTitle,
          title:
            element.contactId && contacts[element.contactId]
              ? contacts[element.contactId].name
              : 'Unassigned',
          start_time: newShift.startTime.valueOf(),
          end_time: newShift.endTime.valueOf(),
          start: newShift.startTime.toDate(),
          end: newShift.endTime.toDate(),
          allDay: false,
          actualStartTime: newShift.startDateTime,
          actualEndTime: newShift.endDateTime,
          backgroundColor: bgcolor,
          textColor: color,

          itemProps: {
            style: {
              background: bgcolor,
              fontWeight: 'bold',
              color: color,
              cursor: element.assignable ? 'pointer' : 'inherit',
              fontSize: '16px',
              opacity: opacity,
            },
          },
        };
        timeLineInfo.items.push(timeLineObj);
        var headerWidth = 100 / dateTimeChunks.length;
        var shiftDuration = newShift.endTime.diff(newShift.startTime, 'minutes');
        newShift.width = (100 * shiftDuration) / duration;
        newShift.colSpan = Math.floor(newShift.width / headerWidth);

        schedules[element.rotationTitle].data.push(newShift);
        shifts[`${element.localId}|${element.id}`] = newShift;

        var shift = { x: element.rotationTitle, y: [], num: parseInt(element.rotationNumber) };
        shift.y.push(newShift.startTime.valueOf());
        shift.y.push(newShift.endTime.valueOf());
        if (contactSeries[element.contactId]) {
          contactSeries[element.contactId].data.push(shift);
        } else {
          contactSeries['Unassigned'].data.push(shift);
        }
      });

      setContactColorMap(selContactColors);

      var legacyInfo = {
        contacts: contacts,
        series: schedules,
        dategrid: dateTimeChunks,
        shifts: shifts,
      };
      setLegacyInfo(legacyInfo);

      timeLineInfo.groups.sort((a, b) => a.contactLevel - b.contactLevel);
      setTimeLineInfo(timeLineInfo);
      setCount(count + 1);
    }
  }, [selScheduleInfo, legendContact, styleTable]);

  const exportToCSV = (option, formatting) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';
    var formattedShifts = [];
    timeLineInfo.items.forEach((element) => {
      formattedShifts.push({
        Rotation: element.group,
        'Shift Start': element.actualStartTime.format('dddd MM-DD-YYYY hh:mm A'),
        'Shift End': element.actualEndTime.format('dddd MM-DD-YYYY hh:mm A'),
        'Oncall Person': element.title,
      });
    });
    formattedShifts.sort((a, b) => {
      if (a.Rotation === b.Rotation) {
        return moment(a['Shift Start']) - moment(b['Shift Start']);
      }
      return a.Rotation.localeCompare(b.Rotation);
    });
    const ws = XLSX.utils.json_to_sheet(formattedShifts);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Oncallschedule${fileExtension}`);
  };

  return (
    <StyledGrid className={classes.root} container spacing={2}>
      <Grid
        item
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
        xs={12}
      >
        <FormControl className={classes.formControl}>
          <Select
            className={classes.dropdown}
            id="demo-simple-select"
            labelId="demo-simple-select-label"
            value={selSchedule}
          >
            <MenuItem disabled value="">
              Select Schedule
            </MenuItem>
            {schedules &&
              schedules.map((item) => (
                <MenuItem key={item.id} onClick={() => setSelSchedule(item.id)} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <div>
          <PortalIconButton
            aria-describedby={id}
            aria-label="upload picture"
            className={classes.fab}
            component="button"
            onClick={handleClick}
            variant="contained"
          >
            <DateRangeIcon />
          </PortalIconButton>
        </div>

        <div>
          <PortalButton
            color="secondary"
            onClick={() => exportToCSV(1, 'vertical')}
            variant="contained"
          >
            CSV
          </PortalButton>
        </div>

        <FormControlLabel
          control={
            <Switch
              checked={styleTable}
              color="primary"
              name="styleTable"
              onChange={handleChange}
            />
          }
          label="Styled"
          style={{ marginLeft: '16px' }}
        />

        <Tooltip title="Calendar allows you to toggle between day,week & month views">
          <PortalIconButton
            aria-label="calendar view"
            className={classes.fab}
            color={calView ? 'primary' : 'default'}
            component="button"
            onClick={() => handleCalendarChange(true)}
            variant="contained"
          >
            <EventIcon />
          </PortalIconButton>
        </Tooltip>

        <Tooltip title="Table view allows you to select a date range less than 31 days">
          <PortalIconButton
            aria-label="table view"
            className={classes.fab}
            color={calView ? 'default' : 'primary'}
            component="button"
            onClick={() => handleCalendarChange(false)}
            variant="contained"
          >
            <TableChartIcon />
          </PortalIconButton>
        </Tooltip>

        <Popover
          PaperProps={{
            style: {
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          id={id}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DateRangePicker
            onChange={handleSelect}
            ranges={selectionRange}
            showMonthAndYearPickers={true}
            showSelectionPreview={true}
          />
          <Typography color="secondary" variant="caption">
            {dateRangeError}
          </Typography>
        </Popover>
      </Grid>

      <Grid item xs={12}>
        {!calView && selSchedule !== '0' && selScheduleInfo && selScheduleInfo.shifts && (
          <TimeLineWidget
            account={selAccount}
            content={timeLineInfo}
            end={moment(selectionRange[0].endDate).format('YYYYMMDD')}
            handleDateSelection={handleSelect}
            legacy={legacyInfo}
            noOfDays={moment(selectionRange[0].endDate).diff(
              moment(selectionRange[0].startDate),
              'd',
            )}
            schedule={selSchedule}
            start={moment(selectionRange[0].startDate).format('YYYYMMDD')}
          />
        )}

        {loading && <CircularProgress className={classes.gridProgress} size={60} />}
      </Grid>

      <Grid item xs={12}>
        {calView && selSchedule !== '0' && selScheduleInfo && selScheduleInfo.shifts && (
          <OncallCalendar
            account={selAccount}
            content={timeLineInfo}
            count={count}
            disabled={loading}
            end={moment(selectionRange[0].endDate).format('YYYYMMDD')}
            handleDateSelection={handleSelect}
            legacy={legacyInfo}
            noOfDays={moment(selectionRange[0].endDate).diff(
              moment(selectionRange[0].startDate),
              'd',
            )}
            schedule={selSchedule}
            start={moment(selectionRange[0].startDate).format('YYYYMMDD')}
          />
        )}
      </Grid>

      <Grid
        item
        style={{
          display: 'flex',
          padding: '16px',
          flex: 1,
          flexGrow: 1,
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
        xs={12}
      >
        {styleTable &&
          contactMap &&
          contactsColorMap &&
          Object.keys(contactsColorMap).map((itemKey) => (
            <div
              key={itemKey}
              onMouseOut={() => handleMouseOut(itemKey)}
              onMouseOver={() => handleMouseOver(itemKey)}
              style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', margin: '8px' }}
            >
              <SvgIcon style={{ background: contactsColorMap[itemKey] }} viewBox="0 0 24 24" />
              <Typography variant="subtitle">{contactMap[itemKey].name}</Typography>
            </div>
          ))}
      </Grid>
    </StyledGrid>
  );
}
