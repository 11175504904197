import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Paper,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
  setSearchStaffAccounts,
  searchStaffAccountsSelector,
  staffSearch,
  accountLoadingSelector,
} from '../accounts/accountSlice';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, updateUserResponseSelector } from './staffSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PortalButton from '../shared/components/PortalButton';

const PREFIX = 'StaffAccounts';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  controlPaper: `${PREFIX}-controlPaper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardHeader: `${PREFIX}-cardHeader`,
  noRecordsRow: `${PREFIX}-noRecordsRow`,
  tableHead: `${PREFIX}-tableHead`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.controlPaper}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    verticalAlign: 'center',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.cardHeader}`]: {
    margin: 0,
    padding: 10,
    background: '#e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.noRecordsRow}`]: {
    textAlign: 'center',
  },

  [`& .${classes.tableHead}`]: {
    background: theme.palette.primary.light,
    color: 'white',
    fontWeight: 'bold',
  },

  [`& .${classes.tableHeadCell}`]: {
    color: '#FFFFFF',
  },
}));

export default function StaffAccounts() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [windowsLogin, setWindowsLogin] = useState('');
  const [erpLogin, setErpLogin] = useState('');
  const loading = useSelector(accountLoadingSelector);
  const [selStaffAccount, setSelStaffAccount] = useState({});
  const staffAccounts = useSelector(searchStaffAccountsSelector);

  const updateUserResponse = useSelector(updateUserResponseSelector);

  const handleTextChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = (event) => {
    dispatch(staffSearch(email));
  };

  const handleEdit = (event) => {
    switch (event.target.name) {
      case 'windowsLogin':
        setWindowsLogin(event.target.value);
        break;
      case 'erpLogin':
        setErpLogin(event.target.value);
        break;
      case 'firstName':
        setFirstName(event.target.value);
        break;
      case 'lastName':
        setLastName(event.target.value);
        break;
      default:
    }
  };

  const handleUserClose = (event) => {
    setUserOpen(false);
  };

  const handleSave = (event) => {
    var user = {
      login: selStaffAccount.login,
      password: null,
      firstName: null,
      lastName: null,
      email: selStaffAccount.email,
      timeZone: null,
      staffFlag: selStaffAccount.staffFlag,
      options: null,
    };
    user.windowsLogin = windowsLogin;
    user.erpLogin = erpLogin;
    user.firstName = firstName ? firstName : user.firstName;
    user.lastName = lastName ? lastName : user.lastName;
    setErpLogin('');
    setWindowsLogin('');
    setFirstName('');
    setLastName('');
    dispatch(updateUser(user));
    handleUserClose();
  };

  const handleConvert = (event) => {
    var user = {
      login: selStaffAccount.login,
      password: null,
      firstName: null,
      lastName: null,
      email: selStaffAccount.email,
      timeZone: null,
      staffFlag: 'Y',
      options: null,
      windowsLogin: windowsLogin,
      erpLogin: erpLogin,
    };
    dispatch(updateUser(user));
  };

  useEffect(() => {
    if (email === '') {
      setErpLogin('');
      setWindowsLogin('');
      setFirstName('');
      setLastName('');
      dispatch(setSearchStaffAccounts(null));
    }
  }, [dispatch, email]);

  useEffect(() => {
    if (updateUserResponse && updateUserResponse.status) {
      setOpen(true);
      setUserOpen(false);
    }
  }, [updateUserResponse]);

  return (
    <StyledRoot className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.controlPaper}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              error={
                email.length !== 0 &&
                !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                  email,
                )
              }
              id="standard-helperText1"
              label="Email"
              name="email"
              onChange={handleTextChange}
              style={{ width: '100%' }}
              value={email}
              variant="standard"
            />

            <PortalButton
              color="primary"
              disabled={
                email.length === 0 ||
                !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                  email,
                )
              }
              onClick={handleClick}
              sx={{ mt: ' 10px', ml: '10px' }}
              variant="contained"
            >
              Search
            </PortalButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {loading && <CircularProgress color="secondary" />}
          {staffAccounts && staffAccounts.content && staffAccounts.content.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="dense table" className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell>Login</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">First Name</TableCell>
                    <TableCell align="center">Last Name</TableCell>
                    <TableCell align="center">Windows Login</TableCell>
                    <TableCell align="center">Erp Login</TableCell>
                    <TableCell align="center">Staff</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffAccounts &&
                    staffAccounts.content &&
                    staffAccounts.content.map((row) => (
                      <TableRow key={row.email}>
                        <TableCell component="th" scope="row">
                          {row.login}
                        </TableCell>
                        <TableCell align="center">{row.email}</TableCell>

                        <TableCell align="center">{row.firstName}</TableCell>

                        <TableCell align="center">{row.lastName}</TableCell>
                        <TableCell align="center">{row.windowsLogin}</TableCell>
                        <TableCell align="center">{row.erpUserName}</TableCell>
                        <TableCell align="center">{row.staffFlag}</TableCell>
                        <TableCell>
                          <PortalButton
                            onClick={() => {
                              setSelStaffAccount(row);
                              setErpLogin(row.erpUserName);
                              setWindowsLogin(row.windowsLogin);
                              setFirstName(row.firstName);
                              setLastName(row.lastName);
                              setUserOpen(true);
                            }}
                          >
                            {row.staffFlag === 'Y' ? 'Edit' : 'Convert'}
                          </PortalButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {staffAccounts &&
            staffAccounts.content &&
            staffAccounts.content.length === 0 &&
            email !== '' && <Typography>No records found</Typography>}

          <Dialog
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
            onClose={handleUserClose}
            open={userOpen}
          >
            <DialogTitle id="form-dialog-title">Edit User Details</DialogTitle>
            {selStaffAccount && (
              <DialogContent>
                <DialogContentText />

                {selStaffAccount.staffFlag === 'Y' ||
                (selStaffAccount.staffFlag === 'N' &&
                  selStaffAccount.erpUserId === null &&
                  selStaffAccount.windowsLogin === null) ? (
                  <TextField
                    fullWidth
                    label="First Name"
                    margin="dense"
                    name="firstName"
                    onChange={handleEdit}
                    type="text"
                    value={firstName}
                    variant="standard"
                  />
                ) : (
                  selStaffAccount.firstName
                )}

                {selStaffAccount.staffFlag === 'Y' ||
                (selStaffAccount.staffFlag === 'N' &&
                  selStaffAccount.erpUserId === null &&
                  selStaffAccount.windowsLogin === null) ? (
                  <TextField
                    fullWidth
                    label="Last Name"
                    margin="dense"
                    name="lastName"
                    onChange={handleEdit}
                    type="text"
                    value={lastName}
                    variant="standard"
                  />
                ) : (
                  selStaffAccount.lastName
                )}

                {selStaffAccount.staffFlag === 'Y' ||
                (selStaffAccount.staffFlag === 'N' &&
                  selStaffAccount.erpUserId === null &&
                  selStaffAccount.windowsLogin === null) ? (
                  <TextField
                    fullWidth
                    label="Windows Login"
                    margin="dense"
                    name="windowsLogin"
                    onChange={handleEdit}
                    type="text"
                    value={windowsLogin}
                    variant="standard"
                  />
                ) : (
                  selStaffAccount.windowsLogin
                )}

                {selStaffAccount.staffFlag === 'Y' ||
                (selStaffAccount.staffFlag === 'N' &&
                  selStaffAccount.erpUserId === null &&
                  selStaffAccount.windowsLogin === null) ? (
                  <TextField
                    fullWidth
                    label="ERP Login"
                    margin="dense"
                    name="erpLogin"
                    onChange={handleEdit}
                    type="text"
                    value={erpLogin}
                    variant="standard"
                  />
                ) : (
                  selStaffAccount.erpUserName
                )}
              </DialogContent>
            )}
            <DialogActions>
              {selStaffAccount.staffFlag === 'Y' && (
                <PortalButton color="primary" onClick={handleSave} variant="contained">
                  Save Edits
                </PortalButton>
              )}

              {selStaffAccount.staffFlag === 'N' &&
                selStaffAccount.erpUserId === null &&
                selStaffAccount.windowsLogin === null && (
                  <PortalButton color="primary" onClick={handleConvert} variant="contained">
                    Convert Account
                  </PortalButton>
                )}
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}
