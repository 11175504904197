import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import { domainDefaultsSelector } from '../../brandSlice';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  oncallJobListSelector,
  getOncallJobs,
  startOncallSummaryDownload,
  createOncallJob,
} from './phoneBookSlice';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  FormControl,
  DialogActions,
  Box,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { timezoneSelector } from '../../app/authSlice';
import moment from 'moment';
import EventIcon from '@mui/icons-material/Event';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'OncallSummaryJob';

const classes = {
  root: `${PREFIX}-root`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardActions: `${PREFIX}-cardActions`,
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.card}`]: {
    spacing: theme.spacing(2),
    marginTop: 10,
    marginRight: 5,
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    background: '#e0e0e0',
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //const socket = useContext(SocketContext);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OncallSummaryJob = () => {
  const dispatch = useDispatch();
  const oncallJobList = useSelector(oncallJobListSelector);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [feedback, setFeedback] = useState('');
  const brand = useSelector(domainDefaultsSelector);
  const { t } = useTranslation();
  const defaultTimezone = useSelector(timezoneSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortKey, setSortKey] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedDate, setSelectedDate] = useState(
    moment().tz(defaultTimezone).startOf('day').format('YYYY-MM-DDTHH:mm'),
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'selectedDate':
        setSelectedDate(event.target.value);
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveOncallJob = () => {
    dispatch(createOncallJob(moment(selectedDate).format('YYYYMMDD')));
    setOpen(false);
  };

  const handleReload = () => {
    dispatch(getOncallJobs(0, 10));
  };

  const createSortHandler = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
    }
    setSortKey(key);
  };

  useEffect(() => {
    dispatch(getOncallJobs(0, 10));
  }, [dispatch]);

  const startDownload = (value) => {
    dispatch(startOncallSummaryDownload(value));
  };

  return (
    <StyledRoot>
      <Card className={classes.root}>
        <CardHeader
          avatar={<EventIcon />}
          className={classes.cardHeader}
          title="Oncall Summary"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <CardContent>
          <TableContainer className={classes.root}>
            <Table aria-label="a dense table" className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Created On</TableCell>
                  <TableCell align="left">{t('Status')}</TableCell>
                  <TableCell align="left">{t('Data')}</TableCell>
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {oncallJobList &&
                  oncallJobList.length > 0 &&
                  oncallJobList.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value.id}`;
                    return (
                      <TableRow className={classes.tableRow} hover key={value.id}>
                        <TableCell align="left">
                          {moment
                            .tz(value.creationDate, 'America/New_York')
                            .tz(defaultTimezone)
                            .format('MMM DD,YYYY hh:mm A z')}
                        </TableCell>
                        <TableCell align="left">{value.status}</TableCell>
                        <TableCell align="left">{value.data}</TableCell>
                        <TableCell align="left">
                          <PortalButton onClick={() => startDownload(value.id)}>
                            Download
                          </PortalButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <div>
            <PortalButton className="rtPBAdd" color="primary" title="">
              <AddIcon onClick={handleOpen} />
            </PortalButton>
            <PortalButton className="rtPBAdd" color="primary" title="">
              <RefreshIcon onClick={handleReload} />
            </PortalButton>
          </div>
          <div>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              component="div"
              count={oncallJobList ? oncallJobList.length : 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </div>
        </CardActions>
      </Card>

      <Dialog
        aria-labelledby="form-dialog-title"
        data-tour="rtCreateContact"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="form-dialog-title">Request Oncall Summary</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'red' }}>{}</DialogContentText>

          <FormControl className={classes.formControl} fullWidth>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              id="date"
              label="Date"
              name="selectedDate"
              onChange={handleChange}
              type="date"
              value={selectedDate}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <PortalButton
            color="primary"
            disabled={!selectedDate}
            onClick={saveOncallJob}
            variant="contained"
          >
            {t('Create')}
          </PortalButton>
          <PortalButton
            className="rtPBSave"
            color="secondary"
            onClick={handleClose}
            variant="contained"
          >
            {t('Cancel')}
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
};

export default OncallSummaryJob;
