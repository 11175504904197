import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Home: 'Home',
      Accounts: 'Accounts',
      Messaging: 'Messaging',
      Staff: 'Staff',
      Help: 'Help',
      Phonebook: 'Phonebook',
      'My Messasges': 'My Messages',
      Name: 'Name',
      Type: 'Type',
      Value: 'Value',
      Contacts: 'Contacts',
      Escalations: 'Escalations',
      'Total Messages': 'Total Messages',
      Followup: 'Followup',
      Usage: 'Usage',
      Statements: 'Statements',
      Payments: 'Payments',
      'Customer Service': 'Customer Service',
      Directory: 'Directory',
      'Oncall Schedules': 'Oncall Schedules',
      Query: 'Query',
      Download: 'Download',
      'Collapse All Notes': 'Collapse All Notes',
      'Expand All Notes': 'Expand All Notes',
      'Collapse All Delivery Info': 'Collapse All Delivery Info',
      'Expand All Delivery Info': 'Expand All Delivery Info',
      'Check All': 'Check All',
      'Uncheck All': 'Uncheck All',
      Messages: 'Messages',
      Reminders: 'Reminders',
      Recordings: 'Recordings',
      Grid: 'Grid',
      List: 'List',
      'Select Receiver': 'Select Receiver',
      'Select Account': 'Select Account',
      'Expand All': 'Expand All',
      'Collapse All': 'Collapse All',
      'Contact Type': 'Contact Type',
      'Contact Value': 'Contact Value',
      Editable: 'Editable',
      'Click To Edit': 'Click To Edit',
      'Total Minutes': 'Total Minutes',
      'Avg Minutes': 'Avg Minutes',
      'Total Calls': 'Total Calls',
      'Call Center': 'Call Center',
      Automated: 'Automated',
      'Time Usage': 'Time Usage',
      'Call Counts': 'Call Counts',
      'Account Summary': 'Account Summary',
      'Live Calls': 'Live Calls',
      'Live Minutes': 'Live Minutes',
      'Average Minutes Per Call': 'Average Minutes Per Call',
      'Ivr Calls': 'Ivr Calls',
      'Ivr Minutes': 'Ivr Mintues',
      'Avg Ivr Minutes': 'Avg Ivr Minutes',
      '% of Minutes': '% of Minutes',
      'no records found': 'no records found',
      'Receiver Summary': 'Receiver Summary',
      'Statement Number': 'Statement Number',
      Date: 'Date',
      'Previous Balance': 'Previous Balance',
      'New Charges': 'New Charges',
      Adjustments: 'Adjustments',
      Balance: 'Balance',
      PDF: 'PDF',
      'Statement of Account': 'Statement of Account',
      'Transaction Number': 'Transaction Number',
      'Post Date': 'Post Date',
      Authorization: 'Authorization',
      Amount: 'Amount',
      'Contact Name': 'Contact Name',
      'Contact Method Count': 'Contact Method Count',
      'Note: Balance does not include pending payments':
        'Note: Balance does not include pending payments',
      'Make Payment': 'Make Payment',
      Sentiment: 'Sentiment',
    },
  },
  fr: {
    translation: {
      Home: 'Accueil',
      Accounts: 'Comptes',
      Messaging: 'Messagerie',
      Staff: 'Personnel',
      Help: 'Aider',
      Phonebook: 'Annuaire téléphonique',
      'My Messages': 'Mes Messages',
      Name: 'Nom',
      Type: 'Type',
      Value: 'Valeur',
      Contacts: 'Contacts',
      Escalations: 'Escalades',
      'Total Messages': 'Nombre total de messages',
      Followup: 'Suivre',
      Usage: 'l’utilisation',
      Statements: 'Relevé',
      Payments: 'Paiements',
      'Customer Service': 'Service à la clientèle',
      Directory: 'Répertoire',
      'Oncall Schedules': 'Horaires de garde',
      Query: 'Requête',
      Download: 'Télécharger',
      'Collapse All Notes': 'Réduire toutes les notes',
      'Expand All Delivery Info': 'élargir toutes les informations de livraison',
      'Check All': 'Sélectionnez tout',
      'Uncheck All': 'Déselectionnez tout',
      Messages: 'Messages',
      Reminders: 'Rappels',
      Recordings: 'Enregistrements',
      Grid: 'Grille',
      List: 'Liste',
      'Select Receiver': 'Sélectionnez le récepteur',
      'Select Account': 'Sélectionnez un compte',
      'Expand All': 'étendre Tout',
      'Collapse All': 'effondrer tout',
      'Contact Type': 'type de contact',
      'Contact Value': 'Valeur de contact',
      Editable: 'Modifiable',
      'Click To Edit': 'cliquez pour modifier',
      'Total Minutes': 'Minutes totales',
      'Avg Minutes': 'minutes en moyenne',
      'Total Calls': "Nombre total d'appels",
      'Call Center': "Centre d'appel",
      Automated: 'automatisé',
      'Time Usage': 'le temps d’utilisation',
      'Call Counts': "Nombre d'appels",
      'Account Summary': 'Sommaire de compte',
      'Live Calls': 'Appels en direct',
      'Live Minutes': 'Minutes en direct',
      'Average Minutes Per Call': 'nombre moyen de minutes par appel',
      'IVR Calls': 'Appels SVI',
      'IVR Minutes': 'Minutes SVI',
      'Avg Ivr Minutes': 'Minutes moyennes du SVI',
      '% of Minutes': '% de minutes',
      'no records found': 'Aucun enregistrement trouvé',
      'Receiver Summary': 'Récapitulatif du récepteur',
      'Statement Number': 'Numéro de relevé',
      Date: 'Date',
      'Previous Balance': 'Solde précédent',
      'New Charges': 'Nouveaux frais',
      Adjustments: 'Ajustements',
      Balance: 'Solde dû',
      PDF: 'PDF',
      'Statement of Account': 'Relevé de compte',
      'Transaction Number': 'Numéro de transaction',
      'Post Date': 'date affichée',
      Authorization: 'Autorisation',
      Amount: 'Montant',

      'Current Balance': 'Solde actuel',
      'Contact Name': 'Nom du contact',
      'Contact Method Count': 'Nombre de méthodes de contact',
      'Note: Balance does not include pending payments':
        "Remarque : Le solde n'inclut pas les paiements en attente",
      'Make Payment': 'Effectuer le paiement',
      'Contact Method': 'Méthode de contact',
      'No reminders Found': 'Aucun rappel trouvé',
      'No Recordings Found': 'Aucun enregistrement trouvé',
      'Delivery Details': 'détails de livraison',
      Priority: 'Priorité',
      'Time Sent': "Heure d'envoi",
      Result: 'Résultat',
      Send: 'Envoyer',
      Save: 'Enregistrer ',
      Change: 'Changer',
      Cancel: 'Annuler',
      Clear: 'dégager',
      Profile: 'Profil',
      Theme: 'Thème',
      Language: 'Langue',
      'Rows per page': 'Lignes par page',
      'Add Note': 'Ajouter une note',
      From: 'De',
      Note: 'Noter',
      Reply: 'Répondre',
      View: 'Vue',
      Request: 'Demander',
      'Change Message Query': 'Modifier la requête de message',
      'Search Type': 'Type de recherche',
      'Message Limit': 'Limite de messages',
      Secure: 'Sécurise',
      'Message Pattern': 'Modèle de message',
      'Create Reminder': 'Créer un rappel',
      Frequency: 'La fréquence',
      'Web Account Info': 'Informations sur le compte Web',
      'First Name': 'Prénom',
      'Last Name': 'Nom de famille',
      Timezone: 'Fuseau horaire',
      Recipients: 'Destinataires',
      "Sender's Name": "Nom de l'expéditeur",
      "Sender's Phone #": "Numéro de téléphone de l'expéditeur",
      'Email or Phone Number': 'Courriel ou numéro de téléphone',
      'Change Password': 'Changer le mot de passe',
      'New Password': 'nouveau mot de passe',
      'Confirm Password': 'Confirmez le mot de passe',
      Suggestion: 'Suggestion',

      Message: 'Un message',
      'Message Size (Limit 600)': 'Taille du message (limite 600)',
      Email: 'E-mail',
      'Follow Up required': 'Suivi requis',
      'Follow up not required': 'Suivi non requis',
      Forward: 'Effrontée',
      'Toggle Delivery Info': 'Basculer les informations de livraison',
      'Forward To': 'Transférer vers',
      'Actual Text': 'Texte réel',
      'Escalation Ended': 'Escalade terminée',
      'Additional Comments': 'Commentaires supplémentaires',
      'Expand All Notes': 'Développer toutes les notes',
      'Collapse All Delivery Info': 'Réduire toutes les informations de livraison',
      'Account Snapshot': 'Instantané du compte',
      'Attach Account': 'Joindre un compte',
      'Receiver Snapshot': 'Instantané du récepteur',
      'Attach Billing Account': 'Joindre un compte de facturation',
      'Attach Receiver': 'Attacher le récepteur',
      Nickname: 'Surnom',
      'Set Nickname': 'Définir le surnom',
      Attach: 'Attacher',
      Detach: 'Détacher',
      'Account Number': 'Numéro de compte',
      'Toll Free Number': 'Numéro sans frais',
      'Receiver Number': 'Numéro de récepteur',
      'Receiver Password': 'Mot de passe du destinataire',
      'Detach Account': 'Détacher le compte',
      'Detach Receiver': 'Détacher le récepteur',
      'Tell us what you think': 'Dites-nous ce que vous pensez',
      'Your Name': 'votre nom',
      'Your Email': 'Votre e-mail',
      'Have a suggestion or feedback?': 'Vous avez une suggestion ou un commentaire ?',
      Sentiment: 'Sentiment',
    },
  },
  es: {
    translation: {
      Home: 'Hogar',
      Accounts: 'Cuentas',
      Messaging: 'Mensajería',
      Staff: 'Personal',
      Help: 'Ayudar',
      Phonebook: 'Directorio telefónico',
      'My Messages': 'Mis mensajes',
      Name: 'Nombre',
      Type: 'Escribe',
      Value: 'Valor',
      Contacts: 'Contactos',
      Escalations: 'Escalamientos',
      'Total Messages': 'Mensajes totales',
      Followup: 'Seguimiento',
      Usage: 'Uso',
      Statements: 'Declaraciones',
      Payments: 'Pagos',
      'Customer Service': 'Servicio al Cliente',
      Directory: 'Directorio',
      'Oncall Schedules': 'Horarios de llamada',
      Query: 'Consulta',
      Download: 'Descargar',
      'Collapse All Notes': 'Contraer todas las notas',
      'Expand All Delivery Info': 'Expandir toda la información de entrega',
      'Check All': 'Comprobar todo',
      'Uncheck All': 'Desmarcar todo',
      Messages: 'Mensajes',
      Reminders: 'Recordatorios',
      Recordings: 'Grabaciones',
      Grid: 'Red',
      List: 'Lista',
      'Select Receiver': 'Seleccionar receptor',

      'Select Account': 'Seleccionar cuenta',
      'Expand All': 'Expandir todo',
      'Collapse All': 'Desplegar todo',
      'Contact Type': 'Tipo de Contacto',
      'Contact Value': 'Valor de contacto',
      Editable: 'Editable',
      'Click To Edit': 'Haz click para editar',
      'Total Minutes': 'Minutos totales',
      'Avg Minutes': 'Minutos promedio',
      'Total Calls': 'Total de llamadas',
      'Call Center': 'Centro de llamadas',
      Automated: 'Automatizado',
      'Time Usage': 'Uso de tiempo',
      'Call Counts': 'Recuentos de llamadas',
      'Account Summary': 'Resumen de la cuenta',
      'Live Calls': 'Llamadas en vivo',
      'Live Minutes': 'Minutos en vivo',
      'Average Minutes Per Call': 'Minutos promedio por llamada',
      'IVR Calls': 'Llamadas IVR',
      'IVR Minutes': 'Minutos IVR',
      'Avg Ivr Minutes': 'Promedio Ivr Minutos',
      '% of Minutes': '% de Minutos',
      'no records found': 'No se encontraron registros',
      'Receiver Summary': 'Resumen del receptor',
      'Statement Number': 'Número de declaración',
      Date: 'Fecha',
      'Previous Balance': 'Balance anterior',
      'New Charges': 'Nuevos cargos',
      Adjustments: 'Ajustes',
      Balance: 'Equilibrio',
      PDF: 'PDF',
      'Statement of Account': 'Estado de cuenta',
      'Transaction Number': 'Número de transacción',
      'Post Date': 'Fecha de publicación',
      Authorization: 'Autorización',
      Amount: 'Monto',
      'Contact Name': 'Nombre de contacto',
      'Contact Method Count': 'Recuento de métodos de contacto',
      'Note: Balance does not include pending payments':
        'Nota: el saldo no incluye los pagos pendientes',
      'Make Payment': 'Hacer el pago',
      Sentiment: 'Sentimiento',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.store.on('added', (lng, ns) => {
  console.log(`new resource added : ${lng} :: ns: ${ns}`);
});

export default i18n;
