import { useEffect, useState } from 'react';
import { Typography, Grid, Button, TextField, Box } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LastPageIcon from '@mui/icons-material/LastPage';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { portalUserSelector } from '../../app/authSlice';
import {
  addVerifyCallerId,
  addVerifyCallerResponseSelector,
  getTwilioNumberFromAreaCode,
  getVerifiedCallerIdsList,
  setTwilioNumberByAreaCodeSelector,
  verifiedCallerIdsSelector,
} from './staffSlice';
import AccountLookup from './AccountLookup';
import { searchAccountsSelector, selectedSearchAccountSelector } from '../accounts/accountSlice';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'CallMasking';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function CallMasking() {
  const dispatch = useDispatch();
  const portalUser = useSelector(portalUserSelector);
  const callerIds = useSelector(verifiedCallerIdsSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [type, setType] = useState('1');
  const [echoNumber, setEchoNumber] = useState('');
  const account = useSelector(selectedSearchAccountSelector);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [selectedAccount, setSelectedAccount] = useState({});
  const [buyOptionVisible, setBuyOptionVisible] = useState(false);
  const [types, setTypes] = useState({
    1: 'Account',
    2: 'User',
    3: 'Company',
  });

  const response = useSelector(addVerifyCallerResponseSelector);
  const twilioNumberByAreaCode = useSelector(setTwilioNumberByAreaCodeSelector);
  const accountSearchResponse = useSelector(searchAccountsSelector);
  const selectedAccountNumber = useSelector(selectedSearchAccountSelector);
  const handleChange = (event) => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'areaCode':
        setAreaCode(value);
        break;
      case 'echoNumber':
        setEchoNumber(value);
        break;
      case 'type':
        setType(value);
        break;
      default:
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const verifyCaller = () => {
    dispatch(addVerifyCallerId(phoneNumber, type));
    setOpen(false);
  };

  const FindTwilioNumberByAreaCode = () => {
    console.log('clciked');
    dispatch(getTwilioNumberFromAreaCode(areaCode, type, selectedAccountId, echoNumber));
  };

  useEffect(() => {
    dispatch(getVerifiedCallerIdsList(page, rowsPerPage, ''));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    if (twilioNumberByAreaCode != null && twilioNumberByAreaCode.length > 0) {
      setPhoneNumber(twilioNumberByAreaCode[0].phoneNumber.endpoint);
    }
  }, [twilioNumberByAreaCode]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    console.log(`account selection${!account}`);
    if (account && accountSearchResponse && accountSearchResponse.length > 0) {
      var currentAccount = accountSearchResponse.filter((element) => element.ACNT_NUM === account);
      if (currentAccount.length === 1) {
        setSelectedAccountId(currentAccount[0].ACCOUNT_ID);
        console.log('account change ', currentAccount);
        setSelectedAccount(currentAccount[0]);
        setBuyOptionVisible(true);
      } else {
        setBuyOptionVisible(false);
      }
    } else {
      setBuyOptionVisible(false);
    }
  }, [account]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    console.log(`purchased number${callerIds}`);
  }, [callerIds]);

  // useEffect(()=>{
  //   console.log("selected account number"+selectedAccountNumber)
  // },[selectedAccountNumber])

  return (
    <StyledRoot className={classes.root}>
      {/* <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="table">
          <TableHead>
            <TableCell>Number</TableCell>
            <TableCell>OTP</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Type</TableCell>
          </TableHead>
          <TableBody>
            {callerIds &&
              callerIds.content &&
              callerIds.content.map((row) => (
                <TableRow>
                  <TableCell scope="row">{row.phoneNumber}</TableCell>
                  <TableCell scope="row">{row.otp}</TableCell>
                  <TableCell scope="row">{row.status}</TableCell>
                  <TableCell scope="row">{types[row.type]}</TableCell>
                </TableRow>
              ))}

            {callerIds &&
              callerIds.content &&
              callerIds.content.length === 0 && (
                <Typography> No records found</Typography>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={callerIds.totalElements ? callerIds.totalElements : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      /> */}

      <Typography align="center" variant="h5">
        Purchase a Number
      </Typography>

      {/* <PortalButton color="primary" onClick={FindTwilioNumberByAreaCode}>
              Find
            </PortalButton> */}
      {/* <TextField
              label="Phone Number"
              value={phoneNumber}
              name="phoneNumber"
              onChange={handleChange}
              fullWidth
            /> */}
      {/* <InputLabel id="demo-mutiple-name-label">Type</InputLabel>
            <Select
              labelId="vc-type-label"
              id="vc-type-labelselector"
              name="type"
              label="Type"
              value={type}
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value="1">Account</MenuItem>
              <MenuItem value="3">Company</MenuItem>
            </Select> */}
      <AccountLookup mode="callmask" />
      {/* {(!buyOptionVisible && selectedAccount !=null && selectedAccount.ACNT_NUM !=null && account) &&  <Typography style={{display:'flex',justifyContent:'center'}}>Masking number exists for this account.</Typography>} */}
      {buyOptionVisible && account && (
        <div>
          {' '}
          <Grid
            columns={{ xs: 4, sm: 8, md: 12 }}
            container
            spacing={{ xs: 2, md: 4 }}
            style={{ padding: 16, justifyContent: 'center' }}
          >
            <Grid item md={3} sm={4} xs={2}>
              <TextField
                helperText={selectedAccount.CMPNY_NAME_TXT}
                name="selectedAccountNumberField"
                placeholder="Account Number"
                readonly
                value={selectedAccount.ACNT_NUM}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item xs={2} sm={4} md={3}>
            <Typography>{selectedAccount.CMPNY_NAME_TXT}</Typography>
            </Grid> */}
            <Grid item md={3} sm={4} xs={2}>
              <TextField
                inputProps={{ maxLength: 3 }}
                label="Enter Area code"
                name="areaCode"
                onChange={handleChange}
                value={areaCode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={2}>
              <TextField
                label="Echo Forwarding Number"
                name="echoNumber"
                onChange={handleChange}
                value={echoNumber}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item xs={2} sm={4} md={3} style={{display:"flex",justifyContent:"start"}}>
            <PortalButton variant="outlined" color="secondary" onClick={FindTwilioNumberByAreaCode}>
              Buy
            </PortalButton>
            </Grid> */}
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PortalButton
              color="secondary"
              onClick={FindTwilioNumberByAreaCode}
              variant="contained"
            >
              Purchase
            </PortalButton>
          </div>
        </div>
      )}

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Verified Caller ID"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <FormControl className={classes.formControl}>
            <TextField
              label="Phone Number"
              value={phoneNumber}
              name="phoneNumber"
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Select
              labelId="vc-type-label"
              id="vc-type-labelselector"
              name="type"
              value={type}
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value="1">1 - Account</MenuItem>
              <MenuItem value="2">2- User</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <PortalButton color="primary" onClick={verifyCaller}>
            Save
          </PortalButton>
          <PortalButton color="primary" onClick={handleClose}>
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog> */}
    </StyledRoot>
  );
}
