import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const PortalDateTimePicker = ({
  dateValue,
  label = 'DateTime Picker',
  propagateDateChange,
  disabled,
  disablePast,
  required,
  maxDate,
  minDate,
  viewRenderers,
  closeOnSelect = false,
  timezone = null,
}) => {
  const handleDateChange = (newValue) => {
    propagateDateChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        closeOnSelect={closeOnSelect}
        disablePast={disablePast || false}
        disabled={disabled}
        label={`${label} ${required ? '*' : ''}`}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChange}
        timezone={timezone}
        value={dateValue ? moment(dateValue) : null}
        viewRenderers={viewRenderers}
      />
    </LocalizationProvider>
  );
};

export default PortalDateTimePicker;
PortalDateTimePicker.displayName = 'PortalDateTimePicker';
