import { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selAccountSelector,
  funeralServicesSelector,
  getFuneralServicesList,
  createFuneralService,
  updateFuneralService,
  deleteFuneralService,
} from '../accountSlice';
import {
  TablePagination,
  Checkbox,
  Typography,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import * as XLSX from 'xlsx';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { portalUserSelector, timezoneSelector } from '../../../app/authSlice';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FuneralService from './FuneralService';
import { PDFDownloadLink, Document, Page, View, Text } from '@react-pdf/renderer';
import * as FileSaver from 'file-saver';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'FuneralServiceList';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
    display: 'flex',
    marginTop: theme.spacing(0),
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function FuneralServiceList() {
  const dispatch = useDispatch();
  const selAccount = useSelector(selAccountSelector);
  const portalUser = useSelector(portalUserSelector);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [mode, setMode] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('personName');
  const [pageNumber, setPageNumber] = useState(1);

  const [serviceId, setServiceId] = useState('');
  const [personName, setPersonName] = useState('');
  const [serviceStatus, setServiceStatus] = useState('Pending');
  const [serviceDate, setServiceDate] = useState(new Date());
  const [serviceLocation, setServiceLocation] = useState('');
  const [visitationDate, setVisitationDate] = useState(new Date());
  const [visitation, setVisitation] = useState(false);
  const [visitationLocation, setVisitationLocation] = useState('');
  const [visitation2Date, setVisitation2Date] = useState(new Date());
  const [visitation2, setVisitation2] = useState(false);
  const [visitation2Location, setVisitation2Location] = useState('');
  const [disposition, setDisposition] = useState('Not Disclosed');
  const [dispositionLocation, setDispositionLocation] = useState('');
  const [dispositionAddress, setDispositionAddress] = useState('');
  const [addtInformation, setAddtInformation] = useState('');
  const [flowers, setFlowers] = useState('');
  const [donations, setDonations] = useState('');
  const [limos, setLimos] = useState('');
  const [hearses, setHearses] = useState('');

  const [serviceTime, setServiceTime] = useState('');
  const [visitationTime, setVisitationTime] = useState('');
  const [visitation2Time, setVisitation2Time] = useState('');

  const [delOpen, setDelOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const funeralServices = useSelector(funeralServicesSelector);
  const [printServicesList, setPrintServicesList] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [printType, setPrintType] = useState('collapsed');
  const [printOpen, setPrintOpen] = useState(false);

  const defaultTimezone = useSelector(timezoneSelector);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    if (selAccount) {
      dispatch(getFuneralServicesList(selAccount, page, rowsPerPage, sortBy));
    }
  }, [dispatch, selAccount, page, rowsPerPage, sortBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'personName':
        setPersonName(event.target.value);
        break;
      case 'serviceStatus':
        setServiceStatus(event.target.value);
        break;
      case 'serviceLocation':
        setServiceLocation(event.target.value);
        break;
      case 'visitationLocation':
        setVisitationLocation(event.target.value);
        break;
      case 'visitation2Location':
        setVisitation2Location(event.target.value);
        break;
      case 'visitation':
        setVisitation(event.target.checked);
        break;
      case 'visitation2':
        setVisitation2(event.target.checked);
        break;
      case 'serviceDate':
        setServiceDate(event.target.value);
        break;
      case 'visitationDate':
        setVisitationDate(event.target.value);
        break;
      case 'visitation2Date':
        setVisitation2Date(event.target.value);
        break;
      case 'disposition':
        setDisposition(event.target.value);
        break;
      case 'dispositionLocation':
        setDispositionLocation(event.target.value);
        break;
      case 'dispositionAddress':
        setDispositionAddress(event.target.value);
        break;
      case 'addtInformation':
        setAddtInformation(event.target.value);
        break;
      case 'flowers':
        setFlowers(event.target.value);
        break;
      case 'donations':
        setDonations(event.target.value);
        break;
      case 'limos':
        setLimos(event.target.value);
        break;
      case 'hearses':
        setHearses(event.target.value);
        break;
      case 'sortBy':
        setSortBy(event.target.value);
        break;
      case 'visitationTime':
        setVisitationTime(event.target.value);
        break;
      case 'visitation2Time':
        setVisitation2Time(event.target.value);
        break;
      case 'serviceTime':
        setServiceTime(event.target.value);
        break;
      default:
        setValue(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createService = () => {
    dispatch(
      createFuneralService(
        selAccount,
        {
          personName,
          status: serviceStatus,
          serviceDate: serviceDate,
          serviceLocation,
          visitationDate: visitationDate,
          visitation: visitation ? 'Y' : 'N',
          visitationLocation,
          visitation2Date: visitation2Date,
          visitation2Location,
          visitation2: visitation2 ? 'Y' : 'N',
          disposition,
          dispositionLocation,
          dispositionAddress,
          addtInformation,
          flowers,
          donations,
          limos,
          hearses,
          serviceTime,
          visitationTime,
          visitation2Time,
        },
        page,
        rowsPerPage,
        sortBy,
      ),
    );
    setOpen(false);
  };

  const updateService = () => {
    dispatch(
      updateFuneralService(
        serviceId,
        selAccount,
        {
          personName,
          status: serviceStatus,
          serviceDate: serviceDate,
          serviceLocation,
          visitationDate: visitationDate,
          visitation: visitation ? 'Y' : 'N',
          visitationLocation,
          visitation2Date: visitation2Date,
          visitation2Location,
          visitation2: visitation2 ? 'Y' : 'N',
          disposition,
          dispositionLocation,
          dispositionAddress,
          addtInformation,
          flowers,
          donations,
          limos,
          hearses,
          serviceTime,
          visitationTime,
          visitation2Time,
        },
        page,
        rowsPerPage,
        sortBy,
      ),
    );
    setOpen(false);
  };

  const deleteMe = (info) => {
    setDelOpen(true);
    setDeleteId(info.id);
  };

  const handleOk = () => {
    dispatch(deleteFuneralService(deleteId, selAccount, page, rowsPerPage, sortBy));
    setDelOpen(false);
  };

  const handleCancel = () => {
    setDelOpen(false);
  };

  const handleAdd = () => {
    setOpen(true);
    setPersonName('');
    setServiceStatus('Pending');
    setServiceDate('');
    setServiceTime('');
    setServiceLocation('');
    setVisitationDate('');
    setVisitationTime('');
    setVisitation(false);
    setVisitationLocation('');
    setVisitation2Date('');
    setVisitation2Time('');
    setVisitation2(false);
    setVisitation2Location('');

    setDisposition('Not Disclosed');
    setDispositionLocation('');
    setDispositionAddress('');
    setAddtInformation('');
    setFlowers('');
    setDonations('');
    setLimos('');
    setHearses('');
    setMode('add');
  };

  const edit = (info) => {
    setOpen(true);
    setServiceId(info.id);
    setPersonName(info.personName);
    setServiceDate(info.serviceDate ? moment.utc(info.serviceDate).format('YYYY-MM-DD') : '');
    setServiceLocation(info.serviceLocation);
    setVisitationDate(
      info.visitationDate ? moment.utc(info.visitationDate).format('YYYY-MM-DD') : '',
    );
    setVisitation(info.visitation === 'Y');
    setVisitationLocation(info.visitationLocation);
    setVisitation2Date(
      info.visitation2Date ? moment.utc(info.visitation2Date).format('YYYY-MM-DD') : '',
    );
    setVisitation2(info.visitation2 === 'Y');
    setVisitation2Location(info.visitation2Location);
    setDisposition(info.disposition);
    setDispositionLocation(info.dispositionLocation);
    setDispositionAddress(info.dispositionAddress);
    setAddtInformation(info.addtInformation);
    setServiceStatus(info.status);
    setFlowers(info.flowers);
    setDonations(info.donations);
    setLimos(info.limos);
    setHearses(info.hearses);
    setServiceTime(info.serviceTime);
    setVisitationTime(info.visitationTime);
    setVisitation2Time(info.visitation2Time);
    setMode('edit');
  };

  const printServices = (type) => {
    var arr = [];
    arr = [...funeralServices.content];
    setPrintServicesList(arr);
    setPrintType(type);
    setPrintOpen(true);
  };

  const printMe = (info) => {
    var arr = [];
    arr.push(info);
    setPrintServicesList(arr);
    setPrintType('expanded');
    setPrintOpen(true);
  };

  const exportToCSV = (option) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xls';
    var formattedMessages = [];
    funeralServices.content.forEach((element) => {
      formattedMessages.push({
        'Deceased Name': element.personName,
        'Service Date': element.serviceDate ? moment.utc(element.serviceDate).format('DD-MMM') : '',
        'Service Time': element.serviceTime ? element.serviceTime : '',
        'Service Location': element.serviceLocation,
        'Service Status': element.serviceStatus,
        Disposition: element.disposition,
        'Disposition Location': element.dispositionLocation,
        'Disposition Address': element.dispositionAddress,
        Note: element.note,
        'Visitation Date': element.visitationDate
          ? moment.utc(element.visitationDate).format('DD-MMM')
          : '',
        'Visitation Time': element.visitationTime ? element.visitationTime : '',
        'Visitation Location': element.visitationLocation,
        'Visitation Date #2': element.visitation2Date
          ? moment.utc(element.visitation2Date).format('DD-MMM')
          : '',
        'Visitation Time #2': element.visitation2Time ? element.visitation2Time : '',
        'Visitation Location #2': element.visitation2Location,
        Limo: element.limos,
        Hearses: element.hearses,
        Flowers: element.flowers,
        Donations: element.donations,
      });
    });

    const ws = XLSX.utils.json_to_sheet(formattedMessages);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Services${fileExtension}`);
  };

  const handleExpandAll = () => {
    var newExpanded = {};
    if (!expandAll) {
      funeralServices.content.forEach((element) => {
        newExpanded[element.id] = true;
      });
      setExpandAll(true);
    } else {
      funeralServices.content.forEach((element) => {
        newExpanded[element.id] = false;
      });
      setExpandAll(false);
    }
    setExpanded(newExpanded);
  };

  return (
    <StyledGrid className={classes.root} container spacing={2}>
      <Grid item sx={{ mb: 4, display: 'flex', gap: 1 }} xs={12}>
        <PortalButton onClick={handleExpandAll} variant="outlined">
          {expandAll ? 'Collapse All' : 'Expand All'}
        </PortalButton>
        {selAccount !== 0 && (
          <PortalButton onClick={handleAdd} variant="outlined">
            Add
          </PortalButton>
        )}
        <PortalButton onClick={() => printServices('expanded')} variant="outlined">
          Print Expanded
        </PortalButton>
        <PortalButton onClick={() => printServices('collapsed')} variant="outlined">
          Print Collapsed
        </PortalButton>
        <PortalButton onClick={exportToCSV} variant="outlined">
          Export to Excel
        </PortalButton>
      </Grid>
      <Dialog aria-labelledby="confirmation-dialog-title" open={delOpen}>
        <DialogTitle id="confirmation-dialog-title">Are you sure you want to delete?</DialogTitle>
        <DialogContent />
        <DialogActions>
          <PortalButton color="primary" onClick={handleOk} variant="contained">
            YES
          </PortalButton>
          <PortalButton autoFocus color="secondary" onClick={handleCancel} variant="contained">
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setPrintOpen(false)}
        open={printOpen}
      >
        <DialogTitle>Click to download pdf</DialogTitle>
        <DialogContent style={{ padding: '16px' }}>
          <PDFDownloadLink
            document={
              <Document>
                <Page size="A4" style={{ padding: 20, fontSize: 10, fontWeight: 'normal' }}>
                  {printServicesList &&
                    printServicesList.map((info) => (
                      <View key={info.id} style={{ margin: 10 }}>
                        <Text> Deceased Name : {info.personName}</Text>
                        <Text>
                          {' '}
                          Service Date : {moment.utc(info.serviceDate).format('MMM DD,YYYY')}
                        </Text>
                        <Text> Service Time : {info.serviceTime ? info.serviceTime : 'NA'}</Text>
                        <Text> Service status : {info.status}</Text>

                        {printType === 'expanded' && (
                          <View style={{ margin: 10 }}>
                            <Text> Service Information</Text>
                            <Text> Service Location : {info.serviceLocation}</Text>
                            <Text> Disposition : {info.disposition}</Text>
                            <Text> Disposition Address : {info.dispositionAddress}</Text>
                            <Text> Additional Information : {info.addtInformation}</Text>
                            <Text> Flowers : {info.flowers}</Text>
                            <Text> Donations : {info.donations}</Text>
                            <Text> Limos Required : {info.limos}</Text>
                            <Text> Hearses Required : {info.hearses}</Text>
                            <Text> Visitation Information</Text>
                            <Text>
                              {' '}
                              Visitation Date :{' '}
                              {moment.utc(info.visitationDate).format('MMM DD,YYYY')}
                            </Text>
                            <Text>
                              {' '}
                              Visitation Time : {info.visitationTime ? info.visitationTime : 'NA'}
                            </Text>
                            <Text> Visitation Location : {info.addtInformation}</Text>
                            <Text>
                              {' '}
                              2nd Visitation Date :{' '}
                              {moment.utc(info.visitation2Date).format('MMM DD,YYYY')}
                            </Text>
                            <Text>
                              {' '}
                              2nd Visitation Time :{' '}
                              {info.visitation2Time ? info.visitation2Time : 'NA'}
                            </Text>
                            <Text> 2nd Visitation Location: {info.visitation2Location} </Text>
                          </View>
                        )}
                      </View>
                    ))}
                </Page>
              </Document>
            }
            fileName="Services.pdf"
            style={{ textDecoration: 'none' }}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <CircularProgress color="primary" />
              ) : (
                <PortalButton color="secondary" variant="contained">
                  Download
                </PortalButton>
              )
            }
          </PDFDownloadLink>
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="form-dialog-title">
          {mode === 'add' ? 'Add Service Information' : 'Edit Service Information'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText />

          <TextField
            autoFocus
            fullWidth
            label="Name of Deceased"
            margin="dense"
            name="personName"
            onChange={handleChange}
            required
            type="text"
            value={personName}
            variant="standard"
          />

          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Service is:</InputLabel>
            <Select
              id="demo-simple-select"
              labelId="demo-simple-select-label"
              name="serviceStatus"
              onChange={handleChange}
              value={serviceStatus}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Confirmed">Confirmed</MenuItem>
              <MenuItem value="Private">Private</MenuItem>
              <MenuItem value="No Service">No Service</MenuItem>
              <MenuItem value="Shiva">Shiva</MenuItem>
            </Select>
          </FormControl>

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            defaultValue="2017-05-24"
            id="datetime-local"
            label="Service Date"
            name="serviceDate"
            onChange={handleChange}
            type="date"
            value={serviceDate}
            variant="standard"
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            defaultValue=""
            label="Service Time"
            name="serviceTime"
            onChange={handleChange}
            type="text"
            value={serviceTime}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Service Location"
            margin="dense"
            name="serviceLocation"
            onChange={handleChange}
            type="text"
            value={serviceLocation}
            variant="standard"
          />

          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox checked={visitation} color="primary" name="visitation" />}
              label="There is no visitation"
              labelPlacement="start"
              onChange={handleChange}
              value="start"
            />
          </FormControl>

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            id="datetime-local1"
            label="Visitation Date"
            name="visitationDate"
            onChange={handleChange}
            type="date"
            value={visitationDate}
            variant="standard"
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            defaultValue=""
            label="Visitation Time"
            name="visitationTime"
            onChange={handleChange}
            type="text"
            value={visitationTime}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Visitation Location"
            margin="dense"
            name="visitationLocation"
            onChange={handleChange}
            type="text"
            value={visitationLocation}
            variant="standard"
          />

          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visitation2}
                  color="primary"
                  name="visitation2"
                  onChange={handleChange}
                />
              }
              fullWidth
              label="There is no 2nd visitation"
              labelPlacement="start"
              value="start"
            />
          </FormControl>

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            defaultValue="2017-05-24"
            id="datetime-local2"
            label="2nd Visitation Date"
            name="visitation2Date"
            onChange={handleChange}
            type="date"
            value={visitation2Date}
            variant="standard"
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            defaultValue=""
            label="Visitation2 Time"
            name="visitation2Time"
            onChange={handleChange}
            type="text"
            value={visitation2Time}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="2nd Visitation Location"
            margin="dense"
            name="visitation2Location"
            onChange={handleChange}
            type="text"
            value={visitation2Location}
            variant="standard"
          />

          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label1">Disposition</InputLabel>
            <Select
              id="demo-simple-select1"
              labelId="demo-simple-select-label1"
              name="disposition"
              onChange={handleChange}
              value={disposition}
            >
              <MenuItem value="Not Disclosed">Not Disclosed</MenuItem>
              <MenuItem value="Burial">Burial</MenuItem>
              <MenuItem value="Cremation">Cremation</MenuItem>
              <MenuItem value="Entombment">Entombment</MenuItem>
            </Select>
          </FormControl>

          <TextField
            autoFocus
            fullWidth
            label="Disposition Location"
            margin="dense"
            name="dispositionLocation"
            onChange={handleChange}
            type="text"
            value={dispositionLocation}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Disposition Address"
            margin="dense"
            name="dispositionAddress"
            onChange={handleChange}
            type="text"
            value={dispositionAddress}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Additional Information"
            margin="dense"
            multiline
            name="addtInformation"
            onChange={handleChange}
            rows={4}
            type="text"
            value={addtInformation}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Flowers"
            margin="dense"
            name="flowers"
            onChange={handleChange}
            type="text"
            value={flowers}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Donations"
            margin="dense"
            name="donations"
            onChange={handleChange}
            type="text"
            value={donations}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Limos Required"
            margin="dense"
            name="limos"
            onChange={handleChange}
            type="number"
            value={limos}
            variant="standard"
          />

          <TextField
            autoFocus
            fullWidth
            label="Hearses Required"
            margin="dense"
            name="hearses"
            onChange={handleChange}
            type="number"
            value={hearses}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <PortalButton
            color="primary"
            disabled={personName === ''}
            onClick={mode === 'add' ? createService : updateService}
            variant="contained"
          >
            {' '}
            Save
          </PortalButton>
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            {' '}
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>

      <TablePagination
        ActionsComponent={TablePaginationActions}
        component="div"
        count={funeralServices.totalElements ? funeralServices.totalElements : 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]}
      />
      <FormControl sx={{ ml: 3 }} variant="standard">
        <InputLabel id="demo-simple-select-label">Sort By:</InputLabel>
        <Select
          id="demo-simple-select1"
          labelId="demo-simple-select-label1"
          name="sortBy"
          onChange={handleChange}
          value={sortBy}
        >
          <MenuItem value="personName">Deceased Name</MenuItem>
          <MenuItem value="serviceDate">Service Date</MenuItem>
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table aria-label="table" className={classes.table} size="small">
          <TableHead />
          <TableBody>
            {funeralServices &&
              funeralServices.content &&
              funeralServices.content.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Grid item key={row.id} md={12} xs={12}>
                      <FuneralService
                        account={selAccount}
                        deleteMe={deleteMe}
                        edit={edit}
                        expanded={expanded[row.id]}
                        info={row}
                        printMe={printMe}
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}

            {funeralServices && funeralServices.content && funeralServices.content.length === 0 && (
              <TableCell colSpan={12}>
                <Typography sx={{ textAlign: 'center' }}> No records found</Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGrid>
  );
}
