import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { portalUserSelector } from '../../../app/authSlice';
import {
  getStatementsForAccount,
  statementsSelector,
  getStatementPdf,
  selStatementPdfSelector,
  sendRequestSoa,
  requestSOAResponseSelector,
  loadingSelector,
} from '../accountSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  TablePagination,
  DialogActions,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'Statements';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  tableHead: `${PREFIX}-tableHead`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  downloadBtn: `${PREFIX}-downloadBtn`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.tableHead}`]: {
    background: theme.palette.primary.light,
  },

  [`& .${classes.tableHeadCell}`]: {
    color: '#FFFFFF',
  },

  [`& .${classes.downloadBtn}`]: {
    textDecoration: 'none',
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Statements = (props) => {
  const defaultRowsPerPage = 6;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const portalUser = useSelector(portalUserSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [statementNum, setStatementNum] = useState(0);
  const statements = useSelector(statementsSelector);
  const statementPdf = useSelector(selStatementPdfSelector);
  const [pageNumber, setPageNumber] = useState(1);
  const requestSOAResponse = useSelector(requestSOAResponseSelector);
  const loading = useSelector(loadingSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.account) {
      dispatch(getStatementsForAccount(props.account, page, rowsPerPage));
    }
  }, [dispatch, props.account, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (requestSOAResponse === 'SUCCESS') {
      setSnackOpen(true);
    }
  }, [requestSOAResponse]);

  const handlePdf = (statementNum) => {
    dispatch(getStatementPdf(statementNum));
    setStatementNum(statementNum);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onLoadError = (error) => {
    console.log('');
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(numPages);
  };

  const requestSOA = (date) => {
    dispatch(sendRequestSoa(props.account, date.replaceAll('-', '')));
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  return (
    <StyledGrid className={classes.root} container spacing={2}>
      {loading && (
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
          <CircularProgress />
        </Grid>
      )}

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="table" className={classes.table} size="small">
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell className={classes.tableHeadCell} sx={{ pl: 4 }}>
                  {t('Statement Number')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Date')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Previous Balance')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('New Charges')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Adjustments')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Payments')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Balance')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('PDF')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Statement of Account')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements && statements.content && statements.content.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12} scope="row" sx={{ textAlign: 'center' }}>
                    <Typography variant="overline">{t('no records found')}</Typography>
                  </TableCell>
                </TableRow>
              )}

              {statements &&
                statements.content &&
                statements.content.length > 0 &&
                statements.content.map((row) => (
                  <TableRow hover key={row.number}>
                    <TableCell component="th" scope="row" sx={{ pl: 4 }}>
                      {row.number}
                    </TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale={true}
                        prefix="$"
                        thousandSeparator={true}
                        value={row.prevBalance}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale={true}
                        prefix="$"
                        thousandSeparator={true}
                        value={row.newCharges}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale={true}
                        prefix="$"
                        thousandSeparator={true}
                        value={row.adjustments}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale={true}
                        prefix="$"
                        thousandSeparator={true}
                        value={row.payments}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale={true}
                        prefix="$"
                        thousandSeparator={true}
                        value={row.balance}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {' '}
                      <PortalButton onClick={() => handlePdf(row.number)}>View</PortalButton>
                    </TableCell>
                    <TableCell align="center">
                      {' '}
                      <PortalButton onClick={() => requestSOA(row.date)}>Request</PortalButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          ActionsComponent={TablePaginationActions}
          component="div"
          count={statements?.totalElements ? statements.totalElements : 0}
          onPageChange={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[6]}
        />

        <Dialog
          aria-labelledby="simple-dialog-title1"
          className={classes.dialog}
          maxWidth="md"
          onClose={handleClose}
          open={open}
        >
          <DialogTitle id="simple-dialog-title1">Statement - {statementNum}</DialogTitle>
          <DialogContent>
            {statementPdf && statementPdf.length > 0 && (
              <Document
                file={`data:application/pdf;base64,${statementPdf}`}
                onLoadError={onLoadError}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            )}
          </DialogContent>
          <DialogActions>
            {statementPdf && statementPdf.length > 0 && (
              <PortalButton
                className={classes.downloadBtn}
                color="primary"
                component="a"
                download="statement.pdf"
                href={`data:application/pdf;base64,${statementPdf}`}
                variant="contained"
              >
                DOWNLOAD
              </PortalButton>
            )}
            <PortalButton color="secondary" onClick={handleClose} variant="contained">
              Cancel
            </PortalButton>
          </DialogActions>
        </Dialog>

        <Snackbar autoHideDuration={6000} onClose={handleSnackClose} open={snackOpen}>
          <Alert
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleSnackClose}
            severity="success"
          >
            The report request has been submitted. It should arrive at {portalUser.email} in 10 to
            15 minutes.
          </Alert>
        </Snackbar>
      </Grid>
    </StyledGrid>
  );
};

export default Statements;
