/* global pendo */
import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  setAuth0,
  setUser,
  setUserMetadata,
  setUserToken,
  getPortalUser,
  portalUserSelector,
  setLoading,
  setDefaultLanguage,
  defaultLanguageSelector,
  updatePortalUser,
  updatePortalUserPassword,
  setPortalSnackInfo,
} from '../app/authSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Select,
  Drawer,
  List,
  ListItem,
  Hidden,
  TextField,
  Snackbar,
  FormControl,
} from '@mui/material';
import { getBrandingDefaults } from '../brandSlice';
import { getLinkedDeliveryContacts, getReceivers } from './messages/messageSlice';
import { getAccountsByUser } from './accounts/accountSlice';
import { getContacts } from './home/phoneBookSlice';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Alert } from '@mui/lab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { isLocalOrDevEnvironment, isLocalhost } from '../utils/urls';
import PortalButton from './shared/components/PortalButton';
import PortalIconButton from './shared/components/PortalIconButton';
import PropTypes from 'prop-types';

const PREFIX = 'CustomAppBar';

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  menulink: `${PREFIX}-menulink`,
  link: `${PREFIX}-link`,
  selected: `${PREFIX}-selected`,
  show: `${PREFIX}-show`,
  hide: `${PREFIX}-hide`,
  appbar: `${PREFIX}-appbar`,
  appbarNoLogin: `${PREFIX}-appbarNoLogin`,
  logo: `${PREFIX}-logo`,
  loginBtn: `${PREFIX}-loginBtn`,
  list: `${PREFIX}-list`,
  textField: `${PREFIX}-textField`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    textAlign: 'left',
  },
  [`& .${classes.menulink}`]: {
    color: '#000000',
    textDecoration: 'none',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: 'white',
    background: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    '& .MuiButton-root': {
      fontWeight: 500,
    },
    '& :hover': {
      color: theme.palette.secondary.main,
    },
  },
  [`& .${classes.selected}`]: {
    textDecorationStyle: 'solid 2px',
    textDecoration: 'overline',
    color: theme.palette.secondary.main,
    '& .MuiButton-root': {
      fontWeight: 800,
    },
  },

  [`& .${classes.show}`]: {
    transform: 'translateY(0)',
    transition: 'transform .5s',
  },

  [`& .${classes.hide}`]: {
    transform: 'translateY(-110%)',
    transition: 'transform .5s',
  },

  [`& .${classes.appbar}`]: {
    background: `linear-gradient(90deg,${theme.palette.primary.main}8a 5%,${theme.palette.primary.main} 36%,${
      theme.palette.primary.main
    } 80%)`,
  },

  [`& .${classes.appbarNoLogin}`]: {
    background: `linear-gradient(90deg,${theme.palette.primary.main}8a 5%,${theme.palette.primary.main} 36%,${
      theme.palette.primary.main
    } 80%)`,
  },

  [`& .${classes.logo}`]: {
    width: 200,
    height: 75,
    objectFit: 'contain',
    backgroundImage: theme.portalDefaults.logo,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },

  [`& .${classes.loginBtn}`]: {
    color: 'white',
    background: theme.palette.secondary.main,
    borderRadius: theme.spacing(3),
    padding: '5px 30px',
    fontWeight: 600,
    '& :hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  [`& .${classes.list}`]: {
    background: theme.palette.primary.main,
  },

  [`& .${classes.textField}`]: {
    margin: 10,
  },
}));

const StyledBadge = (props) => (
  <Badge
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    color="success"
    overlap="circular"
    variant="dot"
    {...props}
  />
);
const CustomAppBar = ({ myauth }) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [authState, setAuthState] = useState(false);
  const portalUser = useSelector(portalUserSelector);
  const defaultLanguage = useSelector(defaultLanguageSelector);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(false);
  const [userTheme, setUserTheme] = useState(
    localStorage.getItem('theme') ? localStorage.getItem('theme') : 'blue',
  );
  const open = Boolean(anchorEl);
  const [pwdOpen, setPwdOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordComplexFlag1, setPasswordComplexFlag1] = useState(false);
  const [passwordComplexFlag2, setPasswordComplexFlag2] = useState(false);
  const [passwordComplexFlag3, setPasswordComplexFlag3] = useState(false);
  const [mfaSnackbar, setMfaSnackbar] = useState(false);
  const [showNewFeatures, setShowNewFeatures] = useState(true);

  const checkPasswordStrength = (pwd) => {
    let hasLower = false,
      hasUpper = false,
      hasDigit = false,
      specialChar = false;
    const specialChars = /[(!@#$%^&*)]/;

    if (pwd.toUpperCase() != pwd) hasLower = true;
    if (pwd.toLowerCase() != pwd) hasUpper = true;
    if (/[0-9]/.test(pwd)) hasDigit = true;
    if (specialChars.test(pwd)) specialChar = true;

    if (hasDigit && hasLower && hasUpper) {
      setPasswordComplexFlag1(true);
    } else {
      setPasswordComplexFlag1(false);
    }
    if (specialChar) {
      setPasswordComplexFlag2(true);
    } else {
      setPasswordComplexFlag2(false);
    }
    if (pwd.length > 7) {
      setPasswordComplexFlag3(true);
    } else {
      setPasswordComplexFlag3(false);
    }
  };

  const handlePasswordSave = () => {
    var userObj = {
      password: password,
      firstName: null,
      lastName: null,
      email: portalUser.email,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: null,
    };
    var options = JSON.parse(portalUser.optionsJson);
    userObj.options = JSON.stringify(options);
    userObj.firstName = portalUser.firstName;
    userObj.lastName = portalUser.lastName;
    userObj.timeZone = portalUser.timeZone;
    dispatch(updatePortalUserPassword(userObj));
    setPassword('');
    setConfirmPassword('');
    setPasswordComplexFlag1(false);
    setPasswordComplexFlag2(false);
    setPasswordComplexFlag3(false);
  };

  const handlePasswordCancel = () => {
    setPassword('');
    setConfirmPassword('');
  };

  const handlePasswordChange = (event) => {
    switch (event.currentTarget?.name) {
      case 'password':
        checkPasswordStrength(event.target.value);
        setPassword(event.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(event.target.value);
        break;
      default:
        setPassword(event.target.value);
    }
  };

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
    dispatch(setDefaultLanguage(event.target.value));
    var userObj = {
      password: null,
      firstName: null,
      lastName: null,
      email: null,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: null,
    };
    var options = portalUser.optionsJson ? JSON.parse(portalUser.optionsJson) : {};
    options.userlanguage = event.target.value;
    userObj.options = JSON.stringify(options);
    userObj.firstName = portalUser.firstName;
    userObj.lastName = portalUser.lastName;
    userObj.timeZone = portalUser.timeZone;
    dispatch(updatePortalUser(userObj));
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setLoading(true));
      localStorage.setItem(
        'user',
        JSON.stringify({ user: user, auth0: isAuthenticated, token: 'accessToken' }),
      );

      const getUserMetadata = async () => {
        const domain = isLocalOrDevEnvironment()
          ? 'mapauth.us.auth0.com'
          : 'thecallcenter.us.auth0.com';
        try {
          const accessToken = await getAccessTokenSilently();

          localStorage.setItem('access_token', accessToken);

          const userDetailsByIdUrl = `https://${domain}/userinfo`;
          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const { user_metadata } = await metadataResponse.json();
          dispatch(getContacts(0, 200));
          dispatch(getLinkedDeliveryContacts());
          dispatch(setUserMetadata(user_metadata));
          dispatch(setUserToken(accessToken));
          setAuthState(true);
          dispatch(getPortalUser());
          dispatch(getBrandingDefaults());
          dispatch(setUser(user));
          dispatch(setAuth0(isAuthenticated));
          dispatch(setLoading(false));
          dispatch(getAccountsByUser());
          dispatch(getReceivers());
        } catch (e) {
          console.log(e.message);
          dispatch(setLoading(false));
        }
      };
      getUserMetadata();
    }
  }, [dispatch, isAuthenticated, user, getAccessTokenSilently]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    var userObj = {
      password: null,
      firstName: null,
      lastName: null,
      email: null,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: null,
    };
    var options = portalUser.optionsJson ? JSON.parse(portalUser.optionsJson) : {};
    options.theme = value;
    userObj.options = JSON.stringify(options);
    userObj.firstName = portalUser.firstName;
    userObj.lastName = portalUser.lastName;
    userObj.timeZone = portalUser.timeZone;
    dispatch(updatePortalUser(userObj));
    setUserTheme(value);
  };

  useEffect(() => {
    if (portalUser && portalUser.optionsJson) {
      var options = portalUser.optionsJson ? JSON.parse(portalUser.optionsJson) : {};
      if (options.theme) {
        setUserTheme(options.theme);
      }
      if (options.userlanguage) {
        i18n.changeLanguage(options.userlanguage);
        dispatch(setDefaultLanguage(options.userlanguage));
      }

      if (portalUser.password && portalUser.password === 'TEMP') {
        setPwdOpen(true);
      } else {
        setPwdOpen(false);
      }

      if (
        portalUser.email &&
        (portalUser.email.toLowerCase().includes('noemail.com') ||
          portalUser.email.toLowerCase().includes('nodelivery.com'))
      ) {
        dispatch(
          setPortalSnackInfo({
            severity: 'error',
            message: 'Please update your email address to a valid email address !',
          }),
        );
      }
      if (
        portalUser &&
        portalUser.passwordPolicy &&
        portalUser.passwordPolicy.length > 0 &&
        portalUser.passwordPolicy[0].mfaPolicyStartDate &&
        portalUser.passwordPolicy[0].mfaPolicyEndDate &&
        moment(portalUser.passwordPolicy[0].mfaPolicyStartDate).diff(moment(), 'days') <= 0 &&
        moment(portalUser.passwordPolicy[0].mfaPolicyEndDate).diff(moment(), 'days') >= 0 &&
        portalUser.mfa !== 'true'
      ) {
        setMfaSnackbar(true);
      } else {
        if (portalUser && portalUser.mfa !== 'true') {
          setMfaSnackbar(true);
        }
      }
    }
  }, [portalUser, dispatch, i18n]);

  useEffect(() => {
    if (portalUser && portalUser.login && pendo && pendo.initialize) {
      pendo.initialize({
        visitor: {
          id: portalUser.id,
          email: portalUser.email,
          role: portalUser.staffFlag,
          firstName: portalUser.firstName,
          lastName: portalUser.lastName,
          mfa: portalUser.mfa,
          login: portalUser.login,
          timeZone: portalUser.timeZone,
        },

        account: {
          id: window.location.host,
        },
      });
    }
    if (
      portalUser &&
      !portalUser.passwordStrength &&
      window.location.host === 'devportal-v2.mapcom.local'
    ) {
      setPwdOpen(true);
    } else {
      setPwdOpen(false);
    }
  }, [portalUser]);

  useEffect(() => {
    console.log(pwdOpen);
  }, [pwdOpen]);

  const handlePwdClose = () => {
    setPwdOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (openAnchor) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setAnchor(openAnchor);
  };

  const initiateLogin = () => {
    dispatch(setLoading(true));
    loginWithRedirect();
  };

  const initiateLogout = () => {
    dispatch({ type: 'USER_LOGOUT' });
    localStorage.setItem('access_token', '');
    sessionStorage.setItem('xauth', '');
    localStorage.setItem('user', {});
    localStorage.setItem('user', {});
    localStorage.setItem('lastSelAccount', '');
    localStorage.setItem('lastSelReceiver', '');
    localStorage.setItem('lastSelReceiverName', '');
    dispatch(setLoading(false));
    logout({
      logoutParams: {
        returnTo: `${isLocalhost() ? 'http' : 'https'}://${window.location.host}/`,
      },
    });
    window.location.reload();
  };

  const getLinkClassName = (isActive) => {
    return isActive ? classes.selected : classes.link;
  };

  return (
    <StyledRoot className={classes.root}>
      <AppBar className={!authState ? classes.appbarNoLogin : classes.appbar} position="static">
        <Toolbar variant="regular">
          <Hidden mdUp>
            <PortalIconButton
              aria-label="menu"
              className={classes.menuButton}
              color="inherit"
              edge="start"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </PortalIconButton>
          </Hidden>
          <div className={classes.logo} />

          <Typography className={classes.title} variant="h6" />
          {!myauth?.auth0 && window.location.host === 'secure2.bcanswer.com' ? (
            <Typography style={{ marginRight: 10 }} variant="h6">
              To setup a new login call Customer Service at 866-601-6115
            </Typography>
          ) : null}
          {!myauth?.auth0 ? (
            <PortalButton
              className={classes.loginBtn}
              onClick={initiateLogin}
              size="large"
              variant="contained"
            >
              Login
            </PortalButton>
          ) : null}

          <Hidden smDown>
            {myauth?.auth0 ? (
              <>
                <NavLink className={({ isActive }) => getLinkClassName(isActive)} to="/home">
                  <PortalButton color="inherit" disableRipple>
                    {t('Home')}
                  </PortalButton>
                </NavLink>
                <NavLink
                  className={({ isActive }) => getLinkClassName(isActive)}
                  data-tour="rtAccountsNav"
                  to="/accounts"
                >
                  <PortalButton color="inherit" disableRipple>
                    {t('Accounts')}
                  </PortalButton>
                </NavLink>
                <NavLink
                  className={({ isActive }) => getLinkClassName(isActive)}
                  data-tour="rtMessagingNav"
                  to="/messaging"
                >
                  <PortalButton color="inherit" disableRipple>
                    {t('Messaging')}
                  </PortalButton>
                </NavLink>
                {portalUser.staffFlag === 'Y' ? (
                  <NavLink className={({ isActive }) => getLinkClassName(isActive)} to="/staff">
                    <PortalButton color="inherit" disableRipple>
                      {t('Staff')}
                    </PortalButton>
                  </NavLink>
                ) : null}
                <a
                  className={classes.link}
                  href="https://rise.articulate.com/share/6dNb9-oR6_HTNP6UNuz5mSTkGP8PhCin#/lessons/XxMkM5fdfcQFd2__qf8zBu1KAqO7l5RK"
                  rel="noreferrer"
                  target="_blank"
                >
                  <PortalButton color="inherit" data-tour="rtHelpNav">
                    {t('Help')}
                  </PortalButton>
                </a>
              </>
            ) : null}
          </Hidden>
          {myauth?.auth0 ? (
            <PortalIconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="secondary"
              data-tour="rtProfileNav"
              onClick={handleMenu}
            >
              <StyledBadge>
                <Avatar
                  alt={
                    sessionStorage.getItem('xauth')
                      ? sessionStorage.getItem('xauth')
                      : myauth?.user?.name
                  }
                  src={
                    sessionStorage.getItem('xauth')
                      ? sessionStorage.getItem('xauth')
                      : myauth?.user?.picture
                  }
                  sx={{
                    color: (theme) => theme.palette.getContrastText(theme.palette.secondary.main),
                    background: (theme) => theme.palette.secondary.main,
                  }}
                />
              </StyledBadge>
            </PortalIconButton>
          ) : null}

          {myauth?.auth0 && portalUser ? (
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              id="menu-appbar"
              keepMounted
              onClose={handleClose}
              open={open}
              sx={{}}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem sx={{ justifyContent: 'center' }}>
                <StyledBadge>
                  <Avatar
                    alt={
                      sessionStorage.getItem('xauth')
                        ? sessionStorage.getItem('xauth')
                        : portalUser.email
                    }
                    src={
                      sessionStorage.getItem('xauth')
                        ? sessionStorage.getItem('xauth')
                        : myauth?.user?.picture
                    }
                    sx={{
                      width: (theme) => theme.spacing(12),
                      height: (theme) => theme.spacing(12),
                    }}
                  />
                </StyledBadge>
              </MenuItem>

              <MenuItem sx={{ justifyContent: 'center' }}>{portalUser.email}</MenuItem>

              <MenuItem sx={{ justifyContent: 'center' }}>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.selected : classes.menulink)}
                  to="/settings"
                >
                  <PortalButton
                    onClick={handleClose}
                    sx={{ borderRadius: '5em', textTransform: 'inherit' }}
                    variant="outlined"
                  >
                    {t('Profile')}
                  </PortalButton>
                </NavLink>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleClose}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography>{`${t('Theme')} : `}</Typography>
                <FormControl variant="standard">
                  <Select
                    id="theme-simple-select"
                    labelId="theme-simple-select-label"
                    onChange={handleChange}
                    value={userTheme}
                  >
                    {['Blue', 'Red', 'Teal', 'Pink', 'BlueGrey', 'Dark'].map((item) => (
                      <MenuItem key={item} value={item.toLowerCase()}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
              <Divider />
              <Divider />
              <MenuItem
                onClick={handleClose}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography>{`${t('Language')} : `}</Typography>
                <FormControl variant="standard">
                  <Select
                    id="demo-simple-select1"
                    labelId="demo-simple-select-label1"
                    onChange={handleLanguageChange}
                    value={defaultLanguage}
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="fr">French</MenuItem>
                    <MenuItem value="es">Spanish</MenuItem>
                  </Select>
                </FormControl>
              </MenuItem>
              <Divider />
              {user && user?.sub && user?.sub?.includes('samlp') ? (
                <MenuItem
                  color="inherit"
                  onClick={initiateLogout}
                  sx={{ justifyContent: 'center' }}
                >
                  <PortalButton
                    color="primary"
                    sx={{ textTransform: 'inherit' }}
                    variant="contained"
                  >
                    {t('Logout')}
                  </PortalButton>
                </MenuItem>
              ) : (
                portalUser &&
                user &&
                portalUser.email &&
                user.email &&
                portalUser.email.toLowerCase() === user.email.toLowerCase() && (
                  <MenuItem
                    color="inherit"
                    onClick={initiateLogout}
                    sx={{ justifyContent: 'center' }}
                  >
                    <PortalButton
                      color="primary"
                      sx={{ textTransform: 'inherit' }}
                      variant="contained"
                    >
                      {t('Logout')}
                    </PortalButton>
                  </MenuItem>
                )
              )}
            </Menu>
          ) : null}
        </Toolbar>
      </AppBar>

      <Hidden mdUp>
        <Drawer anchor="left" onClose={toggleDrawer(false)} open={anchor}>
          {myauth?.auth0 ? (
            <List className={classes.list}>
              <ListItem>
                <NavLink className={({ isActive }) => getLinkClassName(isActive)} to="/home">
                  <PortalButton color="inherit" onClick={toggleDrawer(false)}>
                    {t('Home')}
                  </PortalButton>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  className={({ isActive }) => getLinkClassName(isActive)}
                  data-tour="rtAccountsNav"
                  to={`/accounts${
                    localStorage.getItem('lastSelAccount')
                      ? `/${localStorage.getItem('lastSelAccount')}`
                      : ''
                  }`}
                >
                  <PortalButton color="inherit" onClick={toggleDrawer(false)}>
                    {t('Accounts')}
                  </PortalButton>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  className={({ isActive }) => getLinkClassName(isActive)}
                  data-tour="rtMessagingNav"
                  to="/messaging"
                >
                  <PortalButton color="inherit" onClick={toggleDrawer(false)}>
                    {t('Messaging')}
                  </PortalButton>
                </NavLink>
              </ListItem>
              {portalUser.staffFlag === 'Y' ? (
                <ListItem>
                  <NavLink className={({ isActive }) => getLinkClassName(isActive)} to="/staff">
                    <PortalButton color="inherit" onClick={toggleDrawer(false)}>
                      {t('Staff')}
                    </PortalButton>
                  </NavLink>
                </ListItem>
              ) : null}
              <ListItem>
                <a
                  className={classes.link}
                  data-tour="rtHelpNav"
                  href="https://rise.articulate.com/share/6dNb9-oR6_HTNP6UNuz5mSTkGP8PhCin#/lessons/XxMkM5fdfcQFd2__qf8zBu1KAqO7l5RK"
                  rel="noreferrer"
                  target="_blank"
                >
                  <PortalButton color="inherit">{t('Help')}</PortalButton>
                </a>
              </ListItem>
            </List>
          ) : null}
        </Drawer>
      </Hidden>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handlePwdClose();
          }
        }}
        open={
          !!(
            portalUser &&
            portalUser.passwordStrength === false &&
            portalUser.passwordPolicy &&
            portalUser.passwordPolicy.length > 0 &&
            portalUser.passwordPolicy[0].policyEndDate &&
            moment(portalUser.passwordPolicy[0].policyEndDate).diff(moment(), 'days') < 0
          )
        }
      >
        <DialogTitle id="form-dialog-title">Please change your password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>New Password Policy</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemIcon>
                  {passwordComplexFlag1 ? (
                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                  ) : (
                    <HighlightOffIcon style={{ color: 'red' }} />
                  )}
                </ListItemIcon>
                <ListItemText primary="Minimum of one lower case (a-z), one upper case (A-Z) and one number (0-9)" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordComplexFlag2 ? (
                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                  ) : (
                    <HighlightOffIcon style={{ color: 'red' }} />
                  )}
                </ListItemIcon>
                <ListItemText primary="Minimum of one special character (!@#$%^&*)" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordComplexFlag3 ? (
                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                  ) : (
                    <HighlightOffIcon style={{ color: 'red' }} />
                  )}
                </ListItemIcon>
                <ListItemText primary="Must have 8 characters in length (Max length supported is 50 characters)" />
              </ListItem>
            </List>
            <Typography style={{ color: 'red' }}>
              {password !== confirmPassword ? 'New password & confirm password should match' : ''}
            </Typography>
          </DialogContentText>

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            fullWidth
            inputProps={{ maxLength: 50 }}
            label={t('New Password')}
            name="password"
            onChange={handlePasswordChange}
            type="password"
            value={password}
            variant="outlined"
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            fullWidth
            inputProps={{ maxLength: 50 }}
            label={t('Confirm Password')}
            name="confirmPassword"
            onChange={handlePasswordChange}
            type="password"
            value={confirmPassword}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <PortalButton
            className={classes.btn}
            color="primary"
            disabled={
              password === '' ||
              confirmPassword === '' ||
              confirmPassword !== password ||
              !passwordComplexFlag1 ||
              !passwordComplexFlag2 ||
              !passwordComplexFlag3
            }
            onClick={handlePasswordSave}
            variant="contained"
          >
            {t('Save')}
          </PortalButton>
          <PortalButton
            className={classes.btn}
            color="secondary"
            onClick={handlePasswordCancel}
            variant="contained"
          >
            {t('Clear')}
          </PortalButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        message=""
        open={
          !!(
            portalUser &&
            portalUser.passwordStrength === false &&
            portalUser.passwordPolicy &&
            portalUser.passwordPolicy.length > 0 &&
            portalUser.passwordPolicy[0].policyStartDate &&
            moment(portalUser.passwordPolicy[0].policyStartDate).diff(moment(), 'days') <= 0
          )
        }
      >
        <Alert
          action={
            <NavLink style={{ textDecoration: 'none' }} to="/settings">
              {' '}
              <PortalButton color="secondary" size="small">
                Update
              </PortalButton>
            </NavLink>
          }
          severity="warning"
        >
          Password is weak. Please update your password.
        </Alert>
      </Snackbar>
      {!window.location.pathname.includes('features') && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={6000}
          message=""
          open={mfaSnackbar}
        >
          <Alert
            action={
              <>
                <NavLink style={{ textDecoration: 'none' }} to="/settings">
                  {' '}
                  <PortalButton color="secondary" size="small">
                    Click Here
                  </PortalButton>
                </NavLink>
                <PortalIconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => setMfaSnackbar(false)}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </PortalIconButton>
              </>
            }
            severity="info"
          >
            Multi-Factor Authentication Available
          </Alert>
        </Snackbar>
      )}
      {portalUser &&
        portalUser.showNewFeatures &&
        !window.location.pathname.includes('features') &&
        showNewFeatures && (
          <Alert
            icon={false}
            severity="info"
            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
          >
            <div style={{ alignItems: 'center', display: 'flex', overflow: 'hidden' }}>
              <Typography>New Features Available! </Typography>
              <Link
                color="primary"
                href="/features"
                style={{ marginLeft: 10, marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
                target="_blank"
              >
                View
              </Link>
              {/* <NavLink style={{ textDecoration: 'none' }} to="/settings">
            {' '}
            <PortalButton color="secondary" size="small">
              View
            </PortalButton>
          </NavLink> */}
              <PortalIconButton
                aria-label="close"
                color="inherit"
                onClick={() => setShowNewFeatures(false)}
                size="small"
              >
                <CancelIcon fontSize="small" />
              </PortalIconButton>
            </div>
          </Alert>
        )}
    </StyledRoot>
  );
};

const mapStateToProps = (state) => {
  return {
    myauth: state.auth,
  };
};

CustomAppBar.propTypes = {
  myauth: PropTypes.shape({
    auth0: PropTypes.bool,
    user: PropTypes.shape({
      sub: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      picture: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(CustomAppBar);
