import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PortalButton from './PortalButton';
import PortalIconButton from './PortalIconButton';

const PREFIX = 'PortalDialog';

const classes = {
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  closeButton: `${PREFIX}-closeButton`,
  dialogActions: `${PREFIX}-dialogActions`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    position: 'relative',
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    minHeight: '25px',
  },

  [`& .${classes.closeButton}`]: {
    color: 'orange',
  },
  [`& .${classes.dialogActions}`]: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});
Transition.displayName = 'Transition';

export default function PortalDialog({
  open,
  maxWidth = 'md',
  fullScreen = false,
  fullWidth = false,
  title,
  hideDialogTitle,
  showAppBar = false,
  propagateOnClose,
  ComponentToRender,
  ComponentProps = {},
  actionItems,
}) {
  const handleClose = () => {
    if (propagateOnClose) propagateOnClose(true);
  };

  const doNothing = () => {
    console.log('');
  };

  return (
    <StyledDialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      key="dialog_id"
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
    >
      {showAppBar && (
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <PortalIconButton
              aria-label="close"
              className={classes.closeButton}
              color="inherit"
              edge="start"
              onClick={handleClose}
            >
              <CloseIcon />
            </PortalIconButton>
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
            <PortalButton
              autoFocus
              className={classes.closeButton}
              color="inherit"
              onClick={handleClose}
            >
              Close
            </PortalButton>
          </Toolbar>
        </AppBar>
      )}
      {title && !hideDialogTitle && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {ComponentToRender ? <ComponentToRender {...ComponentProps} /> : null}
      </DialogContent>
      {actionItems && actionItems.length >= 0 && (
        <DialogActions className={classes.dialogActions}>
          {actionItems.map((aItem, index) => (
            <PortalButton
              autoFocus={aItem.autoFocus || false}
              color={aItem.color || 'primary '}
              disabled={aItem.disabled || false}
              key={index}
              onClick={aItem.onClick || doNothing()}
              sx={{ marginRight: '5px' }}
              variant={aItem.variant || 'contained'}
            >
              {aItem.text}
            </PortalButton>
          ))}
        </DialogActions>
      )}
    </StyledDialog>
  );
}
