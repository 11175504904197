import { createSlice } from '@reduxjs/toolkit';

export const customizedProgressLoaderSlice = createSlice({
  name: 'customizedProgressLoader',
  initialState: {
    loading: false,
  },
  reducers: {
    setCustomizedProgressLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setCustomizedProgressLoading } = customizedProgressLoaderSlice.actions;
export const CustomizedProgressLoadingSelector = (state) => state.customizedProgressLoader.loading;

export default customizedProgressLoaderSlice.reducer;
