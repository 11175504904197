import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { isLocalOrDevEnvironment } from './utils/urls';
import { StrictMode } from 'react';

const root = createRoot(document.getElementById('root'));
const renderApp = () => {
  root.render(
    <StrictMode>
      <Provider store={store}>
        <Auth0Provider
          authorizationParams={{
            redirect_uri: `${window.location.origin}/home`,
            audience: isLocalOrDevEnvironment()
              ? 'https://portal-v2.mapcom.local/'
              : 'https://secure2.mapcommunications.com/',
            scope:
              'openid profile email offline_access read:openid read:profile read:email read:users read:accounts mapcom',
          }}
          cacheLocation="localstorage"
          clientId={
            isLocalOrDevEnvironment()
              ? 'Sv9cszGtyxL5AvR5oWKFR5YBr6iJ8Ljt'
              : 'KJN1Ob6k13P5uWw3IW8tJJYmoEQCSoxS'
          }
          domain={isLocalOrDevEnvironment() ? 'mapauth.us.auth0.com' : 'thecallcenter.us.auth0.com'}
          useRefreshTokens={true}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Auth0Provider>
      </Provider>
    </StrictMode>,
  );
};

renderApp();

if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    renderApp();
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
