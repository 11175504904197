import { Box, CircularProgress } from '@mui/material';
import { alarmLoadingStateSelector } from '../alarmSlice';
import { useSelector } from 'react-redux';
import PortalSingleFileInput from '../../shared/components/PortalSingleFileInput';
import AlarmImportResponseView from './AlarmImportResponseView';
import { getImportAlarmAcceptedFileTypes } from '../../../utils/alarmUtil';

const AlarmImportDialogView = ({ fileInput, propagateFormDataChange }) => {
  const loadingState = useSelector(alarmLoadingStateSelector);

  const handleFileInputChange = (file) => {
    propagateFormDataChange(file);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '20px' }}>
      <PortalSingleFileInput
        allowedFileTypes={getImportAlarmAcceptedFileTypes()}
        fileInput={fileInput}
        propagateFileInputChange={handleFileInputChange}
      />
      {loadingState?.loadingImportReceiverAlarmFile ||
      loadingState?.loadingImportReceiverAlarmFileDryRun ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}
      <AlarmImportResponseView />
    </Box>
  );
};

export default AlarmImportDialogView;
