import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../PortalApi';
import { setPortalSnackInfo } from '../../app/authSlice';
import { getURLSearchParamsString } from '../../utils/query';
import { ReceiverNoSettingsCode } from '../../utils/receiverUtil/constants';
import { getParsedAndStringifiedAlarmErrorMessage } from '../../utils/alarmUtil';

export const alarmSlice = createSlice({
  name: 'alarm',
  initialState: {
    receiverAlarmList: [],
    alarmDetailsById: null,
    receiverAlarmSettings: null,
    selectedAccount: null,
    selectedReceiver: null,
    selectedSearchBy: null,
    loadingState: {
      loadingReceiverAlarmList: false,
      loadingGetAlarmDetailsById: false,
      loadingReceiverAlarmSettings: false,
      loadingCreateReceiverAlarm: false,
      loadingEditAlarmById: false,
      loadingDeleteAlarmById: false,
      loadingImportReceiverAlarmFileDryRun: false,
      loadingImportReceiverAlarmFile: false,
    },
    receiverAlarmImportResponse: [],
    receiverAlarmImportDryRunState: true,
    refetchReceiverAlarmList: false,
    receiverAlarmRealtimeEditInProgressDocDataList: [],
    receiverAlarmRealtimeUpdatedDocData: null,
  },
  reducers: {
    setReceiverAlarmList: (state, action) => {
      state.receiverAlarmList = action.payload;
    },
    setAlarmDetailsById: (state, action) => {
      state.alarmDetailsById = action.payload;
    },
    setReceiverAlarmSettings: (state, action) => {
      state.receiverAlarmSettings = action.payload;
    },
    setLoadingState: (state, action) => {
      state.loadingState = { ...state.loadingState, ...action.payload };
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setSelectedReceiver: (state, action) => {
      state.selectedReceiver = action.payload;
    },
    setSelectedSearchBy: (state, action) => {
      state.selectedSearchBy = action.payload;
    },
    setRefetchReceiverAlarmList: (state, action) => {
      state.refetchReceiverAlarmList = action.payload;
    },
    setReceiverAlarmImportResponse: (state, action) => {
      state.receiverAlarmImportResponse = action.payload;
    },
    setReceiverAlarmImportDryRunState: (state, action) => {
      state.receiverAlarmImportDryRunState = action.payload;
    },
    setReceiverAlarmRealtimeEditInProgressDocDataList: (state, action) => {
      state.receiverAlarmRealtimeEditInProgressDocDataList = action.payload;
    },
    setReceiverAlarmRealtimeUpdatedDocData: (state, action) => {
      state.receiverAlarmRealtimeUpdatedDocData = action.payload;
    },
  },
});

export const {
  setReceiverAlarmList,
  setAlarmDetailsById,
  setReceiverAlarmSettings,
  setLoadingState,
  setSelectedAccount,
  setSelectedReceiver,
  setSelectedSearchBy,
  setRefetchReceiverAlarmList,
  setReceiverAlarmImportResponse,
  setReceiverAlarmImportDryRunState,
  setReceiverAlarmRealtimeEditInProgressDocDataList,
  setReceiverAlarmRealtimeUpdatedDocData,
} = alarmSlice.actions;

export const receiverAlarmListSelector = (state) => state.alarm.receiverAlarmList;
export const alarmDetailsByIdSelector = (state) => state.alarm.alarmDetailsById;
export const receiverAlarmSettingsSelector = (state) => state.alarm.receiverAlarmSettings;
export const alarmLoadingStateSelector = (state) => state.alarm.loadingState;
export const selectedAccountSelector = (state) => state.alarm.selectedAccount;
export const selectedReceiverSelector = (state) => state.alarm.selectedReceiver;
export const selectedSearchBySelector = (state) => state.alarm.selectedSearchBy;
export const refetchReceiverAlarmListSelector = (state) => state.alarm.refetchReceiverAlarmList;
export const receiverAlarmImportDryRunStateSelector = (state) =>
  state.alarm.receiverAlarmImportDryRunState;
export const receiverAlarmImportResponseSelector = (state) =>
  state.alarm.receiverAlarmImportResponse;
export const receiverAlarmRealtimeEditInProgressDocDataListSelector = (state) =>
  state.alarm.receiverAlarmRealtimeEditInProgressDocDataList;
export const receiverAlarmRealtimeUpdatedDocDataSelector = (state) =>
  state.alarm.receiverAlarmRealtimeUpdatedDocData;

export function getReceiverAlarmList(
  receiverId,
  { pending, createdStart, createdEnd, triggerStart, triggerEnd },
  resetExistingList = true,
) {
  return (dispatch) => {
    if (resetExistingList) dispatch(setReceiverAlarmList([]));
    dispatch(setLoadingState({ loadingReceiverAlarmList: true }));
    try {
      const paramString = getURLSearchParamsString({
        pending,
        createdStart,
        createdEnd,
        triggerStart,
        triggerEnd,
      });
      PortalApi.call(
        `/alarms/receiver/${receiverId}/?${paramString}`,
        { method: 'GET' },
        (response) => {
          dispatch(setReceiverAlarmList(response));
          dispatch(setLoadingState({ loadingReceiverAlarmList: false }));
        },
        (error, status, content) => {
          if (resetExistingList) dispatch(setReceiverAlarmList([]));
          dispatch(setLoadingState({ loadingReceiverAlarmList: false }));
        },
      );
    } catch (error) {
      if (resetExistingList) dispatch(setReceiverAlarmList([]));
      dispatch(setLoadingState({ loadingReceiverAlarmList: false }));
    }
  };
}

export function getReceiverAlarmSettings(receiverId) {
  return (dispatch) => {
    dispatch(setLoadingState({ loadingReceiverAlarmSettings: true }));
    try {
      PortalApi.call(
        `/alarms/receiver/settings/${receiverId}`,
        { method: 'GET' },
        (response) => {
          dispatch(setReceiverAlarmSettings(response));
          dispatch(setLoadingState({ loadingReceiverAlarmSettings: false }));
        },
        (error, status, content) => {
          dispatch(setReceiverAlarmSettings(ReceiverNoSettingsCode));
          dispatch(setLoadingState({ loadingReceiverAlarmSettings: false }));
        },
      );
    } catch (error) {
      dispatch(setReceiverAlarmSettings(ReceiverNoSettingsCode));
      dispatch(setLoadingState({ loadingReceiverAlarmSettings: false }));
    }
  };
}

export function getAlarmDetailsById(alarmId, callback) {
  return (dispatch) => {
    dispatch(setAlarmDetailsById(null));
    dispatch(setLoadingState({ loadingGetAlarmDetailsById: true }));
    try {
      PortalApi.call(
        `/alarms/${alarmId}`,
        { method: 'GET' },
        (response) => {
          dispatch(setAlarmDetailsById(response));
          dispatch(setLoadingState({ loadingGetAlarmDetailsById: false }));
          if (callback) {
            callback(response);
          }
        },
        (error, status, content) => {
          dispatch(setAlarmDetailsById(null));
          dispatch(setLoadingState({ loadingGetAlarmDetailsById: false }));
        },
      );
    } catch (error) {
      dispatch(setAlarmDetailsById(null));
      dispatch(setLoadingState({ loadingGetAlarmDetailsById: false }));
    }
  };
}

export function getAlarmDetailsByIdInBackground(alarmId, callback) {
  return (dispatch) => {
    try {
      PortalApi.call(
        `/alarms/${alarmId}`,
        { method: 'GET' },
        (response) => {
          if (callback) {
            callback(response);
          }
        },
        (error, status, content) => {
          console.error(error, status, content);
        },
      );
    } catch (error) {
      console.error('Error in getAlarmDetailsByIdInBackground', error);
    }
  };
}

export function createReceiverAlarm(receiverId, payload, callback) {
  return (dispatch) => {
    dispatch(setLoadingState({ loadingCreateReceiverAlarm: true }));
    try {
      PortalApi.call(
        `/alarms/receiver/${receiverId}`,
        { method: 'POST', body: JSON.stringify(payload) },
        (response) => {
          dispatch(setRefetchReceiverAlarmList(true));
          dispatch(setLoadingState({ loadingCreateReceiverAlarm: false }));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Alarm created successfully.' }),
          );
          if (callback) {
            callback(response);
          }
        },
        (error, status, content) => {
          dispatch(setLoadingState({ loadingCreateReceiverAlarm: false }));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: getParsedAndStringifiedAlarmErrorMessage(
                status,
                'Oops, alarm could not be created. Please try again!',
              ),
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoadingState({ loadingCreateReceiverAlarm: false }));
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, alarm could not be created. Please try again!',
        }),
      );
    }
  };
}

export function editAlarmById(alarmId, payload, callback) {
  return (dispatch) => {
    dispatch(setLoadingState({ loadingEditAlarmById: true }));
    try {
      PortalApi.call(
        `/alarms/${alarmId}`,
        { method: 'PUT', body: JSON.stringify(payload) },
        (response) => {
          dispatch(setRefetchReceiverAlarmList(true));
          dispatch(setLoadingState({ loadingEditAlarmById: false }));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Alarm edited successfully.' }),
          );
          if (callback) {
            callback('success');
          }
        },
        (error, status, content) => {
          dispatch(setLoadingState({ loadingEditAlarmById: false }));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: getParsedAndStringifiedAlarmErrorMessage(
                status,
                'Oops, alarm could not be edited. Please try again!',
              ),
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoadingState({ loadingEditAlarmById: false }));
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, alarm could not be edited. Please try again!',
        }),
      );
    }
  };
}

export function deleteAlarmById(alarmId, callback) {
  return (dispatch) => {
    dispatch(setLoadingState({ loadingDeleteAlarmById: true }));
    try {
      PortalApi.call(
        `/alarms/${alarmId}`,
        { method: 'DELETE' },
        (response) => {
          dispatch(setLoadingState({ loadingDeleteAlarmById: false }));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Alarm deleted successfully.' }),
          );
          if (callback) {
            callback('success');
          }
        },
        (error, status, content) => {
          dispatch(setLoadingState({ loadingDeleteAlarmById: false }));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: getParsedAndStringifiedAlarmErrorMessage(
                status,
                'Oops, alarm could not be deleted. Please try again!',
              ),
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoadingState({ loadingDeleteAlarmById: false }));
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, alarm could not be deleted. Please try again!',
        }),
      );
    }
  };
}

export function importReceiverAlarmFile(receiverId, formData, callback) {
  return (dispatch, state) => {
    const dryRunState = state().alarm?.receiverAlarmImportDryRunState;
    const loadingStateKey = dryRunState
      ? 'loadingImportReceiverAlarmFileDryRun'
      : 'loadingImportReceiverAlarmFile';
    dispatch(setLoadingState({ [`${loadingStateKey}`]: true }));
    try {
      const queryParamsString = getURLSearchParamsString({
        dryRun: dryRunState,
        showSkips: dryRunState,
      });
      PortalApi.call(
        `/alarms/import/receiver/${receiverId}/?${queryParamsString}`,
        {
          method: 'POST',
          body: formData,
        },
        (response) => {
          dispatch(setLoadingState({ [`${loadingStateKey}`]: false }));
          dispatch(setReceiverAlarmImportResponse(response));
          dispatch(setReceiverAlarmImportDryRunState(false));
          if (dryRunState) {
            if (callback) {
              callback('dry_run_success');
            }
          } else {
            dispatch(setRefetchReceiverAlarmList(true));
            if (callback) {
              callback('import_success');
            }
          }
        },
        (error, status, content) => {
          dispatch(setLoadingState({ [`${loadingStateKey}`]: false }));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: getParsedAndStringifiedAlarmErrorMessage(
                status,
                'Oops, importing alarms failed. Please try again!',
              ),
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoadingState({ [`${loadingStateKey}`]: false }));
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, something went wrong. Please try again!',
        }),
      );
    }
  };
}

export default alarmSlice.reducer;
