import {
  CardActions,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  Avatar,
  TextField,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControl,
} from '@mui/material';
import { selReceiverSelector, updateReminder, deleteReminder } from '../messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import EventIcon from '@mui/icons-material/Event';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { portalUserSelector, setPortalSnackInfo, timezoneSelector } from '../../../app/authSlice';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'Reminder';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  grid: `${PREFIX}-grid`,
  cardTop: `${PREFIX}-cardTop`,
  small: `${PREFIX}-small`,
  cardBottom: `${PREFIX}-cardBottom`,
  cardBottomHeader: `${PREFIX}-cardBottomHeader`,
  cardBottomBody: `${PREFIX}-cardBottomBody`,
  actions: `${PREFIX}-actions`,
  item: `${PREFIX}-item`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    transition: 'all .1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    padding: theme.spacing(2),
  },

  [`& .${classes.card}`]: {
    flexGrow: 1,
    padding: 0,
    borderRadius: '16px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 11%)',
    '&:hover': {
      boxShadow:
        '0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 51%)',
    },
  },

  [`& .${classes.cardContent}`]: {
    padding: 0,
    paddingBottom: '0px !important',
  },

  [`& .${classes.grid}`]: {
    padding: 0,
  },

  [`& .${classes.cardTop}`]: {
    minHeight: theme.spacing(6),
    maxHeight: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    background: '#01adc200',
    padding: theme.spacing(2),
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.cardBottom}`]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#edf5f6',
  },

  [`& .${classes.cardBottomHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.cardBottomBody}`]: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.item}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
}));

export default function Reminder({ content, size }) {
  const defaultTimezone = useSelector(timezoneSelector);
  const dispatch = useDispatch();
  const selReceiver = useSelector(selReceiverSelector);
  const [mode, setMode] = useState('edit');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('Single');
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toLocaleString());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date().toLocaleString());
  const [checked, setChecked] = useState(false);
  const user = useSelector(portalUserSelector);

  const handleClickOpen = (choice) => {
    setMode(choice);
    handleOpen();
  };

  useEffect(() => {
    if (content && content.id) {
      setDescription(content.messageText);
      setType(content.frequency.charAt(0).toUpperCase() + content.frequency.slice(1));
      if (content.endDateTime != null && content.endDateTime.length == 0) {
        setChecked(true);
      }
      setSelectedDate(
        moment
          .tz(content.startDateTime, 'YYYYMMDDHHmm', 'America/New_York')
          .tz(defaultTimezone)
          .format('YYYY-MM-DDTHH:mm'),
      );
      setSelectedEndDate(
        moment
          .tz(content.endDateTime, 'YYYYMMDDHHmm', 'America/New_York')
          .tz(defaultTimezone)
          .format('YYYY-MM-DDTHH:mm'),
      );
    }
  }, [content]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    if (content.endDateTime != null && content.endDateTime.length == 0) {
      setChecked(true);
    }
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'description':
        setDescription(event.target.value);
        break;
      case 'type':
        setType(event.target.value);
        break;
      case 'checked':
        setChecked(event.target.checked);
        setSelectedEndDate('');
        break;
      default:
        setType(event.target.value);
    }
  };

  const handleDateChange = (event) => {
    switch (event.target.name) {
      case 'startDate':
        setSelectedDate(event.target.value);
        break;
      case 'endDate':
        setSelectedEndDate(event.target.value);
        break;
      default:
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    if (mode === 'delete') {
      dispatch(deleteReminder(selReceiver, content, user.login));
    } else {
      var newContent = { ...content };
      newContent.frequency = type.toLowerCase();
      newContent.messageText = description;
      newContent.messageHtml = description;
      newContent.startDateTime = moment
        .tz(selectedDate, defaultTimezone)
        .tz('America/New_York')
        .format('YYYYMMDDHHmm')
        .toString();

      if (
        moment
          .tz(selectedDate, defaultTimezone)
          .tz('America/New_York')
          .format('YYYYMMDDHHmm')
          .toString() === 'Invalid date'
      ) {
        dispatch(setPortalSnackInfo({ severity: 'error', message: 'Invalid Start date' }));
        return;
      }

      if (type.toLowerCase() !== 'single') {
        if (!checked) {
          if (
            moment
              .tz(selectedEndDate, defaultTimezone)
              .tz('America/New_York')
              .format('YYYYMMDDHHmm')
              .toString() === 'Invalid date'
          ) {
            dispatch(setPortalSnackInfo({ severity: 'error', message: 'Invalid End Date' }));
            return;
          }

          if (moment(selectedEndDate).diff(moment(selectedDate), 'minute') <= 0) {
            //setDateRangeError("End date cannot be less than or equal to start date");
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'End date cannot be less than or equal to start date',
              }),
            );
            return;
          }

          newContent.endDateTime = moment
            .tz(selectedEndDate, defaultTimezone)
            .tz('America/New_York')
            .format('YYYYMMDDHHmm')
            .toString();
        } else {
          delete newContent.endDateTime;
        }
      }

      dispatch(updateReminder(selReceiver, newContent, user.login));
    }
    setOpen(false);
  };

  return (
    <StyledGrid className={classes.root} item md={size} xs={12}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid className={classes.grid} container spacing={0}>
            <Grid className={classes.cardTop} item xs={12}>
              <Avatar className={classes.small}>
                {content.frequency.substring(0, 1).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid className={classes.cardBottom} item xs={12}>
              <div className={classes.cardBottomHeader}>
                <Typography className={classes.item} variant="subtitle1">
                  {' '}
                  {content.messageText}
                </Typography>
                {/* <Typography variant="subtitle1" className={classes.item} > {content.MESSAGE_FORMATTED_TXT}</Typography> */}
              </div>
              <div className={classes.cardBottomBody}>
                <div>
                  <Typography className={classes.item} variant="subtitle1">
                    {' '}
                    Interval :{' '}
                    {content.frequency.charAt(0).toUpperCase() + content.frequency.slice(1)}
                  </Typography>
                </div>
                <Typography className={classes.item} variant="body">
                  {' '}
                  <EventIcon /> Start :{' '}
                  {moment
                    .tz(content.startDateTime, 'YYYYMMDDHHmm', 'America/New_York')
                    .tz(defaultTimezone)
                    .format('MMM DD,YYYY hh:mm A z')
                    .toLocaleString()}
                </Typography>
                {content.frequency !== 'single' && (
                  <Typography className={classes.item} variant="body">
                    {' '}
                    <EventIcon /> End :{' '}
                    {content.endDateTime && content.endDateTime !== ''
                      ? moment
                          .tz(content.endDateTime, 'YYYYMMDDHHmm', 'America/New_York')
                          .tz(defaultTimezone)
                          .format('MMM DD,YYYY hh:mm A z')
                          .toLocaleString()
                      : 'Never Expires'}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <PortalIconButton color="primary" title="Edit">
            <EditIcon onClick={() => handleClickOpen('edit')} />
          </PortalIconButton>
          <PortalIconButton color="primary" title="Delete">
            <DeleteIcon onClick={() => handleClickOpen('delete')} />
          </PortalIconButton>
        </CardActions>
      </Card>

      <Dialog
        aria-labelledby="simple-dialog-title"
        className={classes.dialog}
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle color="secondary" id="simple-dialog-title">
          {mode === 'edit' ? 'Update Reminder' : 'Delete Reminder'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {mode === 'edit'
              ? 'schedules a reminder based on frequency which may repeat until end date'
              : 'Are you sure you want to delete this reminder ?'}
          </DialogContentText>

          {mode === 'edit' && (
            <div>
              <FormControl className={classes.formControl} fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
                <Select
                  id="demo-simple-select"
                  labelId="demo-simple-select-label"
                  name="type"
                  onChange={handleChange}
                  value={type}
                >
                  {['Single', 'Hourly', 'Daily', 'Weekly', 'Workday', 'Monthly', 'Annually'].map(
                    (value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  id="datetime-local"
                  label="Start Time"
                  name="startDate"
                  onChange={handleDateChange}
                  type="datetime-local"
                  value={selectedDate}
                  variant="standard"
                />
              </FormControl>

              {type !== 'Single' && (
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textField}
                    disabled={checked}
                    id="datetime-local2"
                    label="End Time"
                    name="endDate"
                    onChange={handleDateChange}
                    type="datetime-local"
                    value={selectedEndDate}
                    variant="standard"
                  />
                </FormControl>
              )}

              {type !== 'Single' && (
                <FormControlLabel
                  control={<Checkbox checked={checked} name="checked" onChange={handleChange} />}
                  label="Never Expires"
                />
              )}

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="Message Text"
                  multiline
                  name="description"
                  onChange={handleChange}
                  required
                  rows={4}
                  value={description}
                  variant="standard"
                />
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <PortalButton color="primary" onClick={handleSave} variant="contained">
            {mode === 'edit' ? 'Save' : 'Yes'}
          </PortalButton>
          <PortalButton autoFocus color="secondary" onClick={handleCancel} variant="contained">
            {mode === 'edit' ? 'Cancel' : 'No'}
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledGrid>
  );
}
