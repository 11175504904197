import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  useTheme,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { portalUserSelector, updatePortalUser, auth0Loading } from '../../app/authSlice';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PortalButton from '../shared/components/PortalButton';

const PREFIX = 'CustomizeLabels';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  error: `${PREFIX}-error`,
  card: `${PREFIX}-card`,
  cardActions: `${PREFIX}-cardActions`,
  cardHeader: `${PREFIX}-cardHeader`,
  textField: `${PREFIX}-textField`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },

  [`& .${classes.error}`]: {
    color: 'red',
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    margin: theme.spacing(1),
    justifyContent: 'center',
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
    // background: theme.palette.primary.main,
  },

  [`& .${classes.textField}`]: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizeLabels() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const portalUser = useSelector(portalUserSelector);
  const loading = useSelector(auth0Loading);
  const [error, setError] = useState('');
  const [words, setWords] = useState({
    'escalation pending': '',
    Escalations: '',
    Followup: '',
    'Clear Escalation': '',
    'Follow up not required': '',
    'Follow Up required': '',
    'Retrieve Only Pending Escalations': '',
    'Retrieve Only Followup Required': '',
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (portalUser && portalUser.login && portalUser.optionsJson) {
      var options = JSON.parse(portalUser.optionsJson);
      if (options.customwords) {
        var newWords = { ...words };
        Object.keys(options.customwords).forEach((itemKey) => {
          newWords[itemKey] = options.customwords[itemKey];
        });
        setWords(newWords);
      }
    }
  }, [portalUser]);

  const handleSave = () => {
    var user = {
      password: null,
      firstName: null,
      lastName: null,
      email: null,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: null,
    };
    var options = portalUser.optionsJson ? JSON.parse(portalUser.optionsJson) : {};

    options.customwords = words;
    user.options = JSON.stringify(options);
    dispatch(updatePortalUser(user));
  };

  const handleChange = (event) => {
    if (!event.target.value.match(/[%<>\\$'"]/)) {
      setError('');
    } else {
      setError('Forbidden character: %<>$\'"');
    }
    var newWords = { ...words };
    newWords[event.target.name] = event.target.value;
    setWords(newWords);
  };

  return (
    <StyledRoot>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardHeader
              avatar={<AccountCircleIcon />}
              className={classes.cardHeader}
              subheader={<Typography className={classes.error}>{error}</Typography>}
              title={t('Custom Labels')}
            />
            <CardContent>
              {loading && <CircularProgress />}
              <TableContainer dense>
                <Table aria-label="a dense table" className={classes.table} size="small">
                  <TableBody>
                    {words &&
                      Object.keys(words).map((itemKey) => (
                        <TableRow key={itemKey}>
                          <TableCell align="center" className={classes.form}>
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={classes.textField}
                              fullWidth
                              inputProps={{ maxLength: 100 }}
                              label={itemKey.toUpperCase()}
                              name={itemKey}
                              onChange={handleChange}
                              type="text"
                              value={words[itemKey]}
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <PortalButton
                color="primary"
                disabled={error && error.length > 0}
                onClick={handleSave}
                variant="contained"
              >
                {t('Save')}
              </PortalButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}
