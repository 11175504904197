import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../PortalApi';
import { setPortalSnackInfo } from '../../app/authSlice';
import { saveAs } from 'file-saver';

const startState = {
  contactList: [],
  createEntry: '',
  updateEntry: '',
  deleteEntry: '',
  csEmailResponse: '',
  suggestionEmail: '',
  vpageFeature: false,
  importResponse: {},
  shareContactResponse: {},
  createOncallJobResponse: {},
  oncallJobList: [],
  loading: false,
};

export const phoneBookSlice = createSlice({
  name: 'phonebook',
  initialState: startState,
  reducers: {
    setContactList: (state, action) => {
      state.contactList = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOncallJobList: (state, action) => {
      state.oncallJobList = action.payload;
    },
    setCreateEntry: (state, action) => {
      state.createEntry = action.payload;
    },
    setUpdateEntry: (state, action) => {
      state.updateEntry = action.payload;
    },
    setdeleteEntry: (state, action) => {
      state.deleteEntry = action.payload;
    },
    setCsEmailResponse: (state, action) => {
      state.csEmailResponse = action.payload;
    },
    setVpageFeature: (state, action) => {
      state.vpageFeature = action.payload;
    },
    setSuggestionEmail: (state, action) => {
      state.suggestionEmail = action.payload;
    },
    setImportResponse: (state, action) => {
      state.importResponse = action.payload;
    },
    setShareContactResponse: (state, action) => {
      state.shareContactResponse = action.payload;
    },
    setCreateOncallJobResponse: (state, action) => {
      state.createOncallJobResponse = action.payload;
    },
  },
});

export const {
  setShareContactResponse,
  setImportResponse,
  setSuggestionEmail,
  setContactList,
  setCreateEntry,
  setUpdateEntry,
  setdeleteEntry,
  setCsEmailResponse,
  setVpageFeature,
  setOncallJobList,
  setLoading,
  setCreateOncallJobResponse,
} = phoneBookSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getContactList = (state) => state.phonebook.contactList;

export const csEmailResponseSelector = (state) => state.phonebook.csEmailResponse;

export const deleteEntrySelector = (state) => state.phonebook.deleteEntry;

export const vpageselector = (state) => state.phonebook.vpageFeature;

export const suggestionEmailSelector = (state) => state.phonebook.suggestionEmail;

export const importResponseSelector = (state) => state.phonebook.importResponse;

export const shareContactResponseSelector = (state) => state.phonebook.shareContactResponse;

export const oncallJobListSelector = (state) => state.phonebook.oncallJobList;

export const phonebookLoading = (state) => state.phonebook.loading;

export const createOncallJobResponseSelector = (state) => state.phonebook.createOncallJobResponse;

export function getOncallJobs(pageNum, size) {
  return (dispatch) => {
    dispatch(setOncallJobList([]));
    try {
      PortalApi.call(
        `/job/list?pageNum=${pageNum}&size=${size}`,
        { method: 'get' },
        (stats) => {
          dispatch(setOncallJobList(stats.content));
        },
        (error, status, content) => {
          dispatch(setOncallJobList([]));
        },
      );
    } catch (error) {
      dispatch(setOncallJobList([]));
    }
  };
}

export function getContacts(pageNum, size) {
  return (dispatch) => {
    dispatch(setContactList([]));
    try {
      PortalApi.call(
        `/phonebook/list?pageNum=${pageNum}&size=${size}`,
        { method: 'get' },
        (stats) => {
          dispatch(setContactList(stats.content));
        },
        (error, status, content) => {
          dispatch(setContactList([]));
        },
      );
    } catch (error) {
      dispatch(setContactList([]));
    }
  };
}

export function startOncallSummaryDownload(id) {
  return (dispatch) => {
    //dispatch(setRecordings([]))
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/job/download/${encodeURIComponent(id)}`,
        { method: 'get' },
        (response) => {
          //let data = s2ab(response);
          saveAs(
            new Blob([response], { type: 'text/csv;charset=utf-8;' }),
            'OncallInformation.csv',
            {
              autoBom: false,
            },
          );
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          //dispatch(setRecordings([]))
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      //dispatch(setRecordings([]))
      dispatch(setLoading(false));
    }
  };
}

export function createOncallJob(selectedDate) {
  return (dispatch) => {
    try {
      dispatch(setShareContactResponse({}));
      PortalApi.call(
        `/job/bulkoncall?startDate=${encodeURIComponent(selectedDate)}&endDate=${encodeURIComponent(
          selectedDate,
        )}`,
        { method: 'post', body: JSON.stringify({}) },
        (stats) => {
          dispatch(setCreateOncallJobResponse({ stats }));
          dispatch(
            setPortalSnackInfo({
              severity: 'success',
              message: 'You will notified once summary is ready',
              autoHideDuration: 60000,
            }),
          );
        },
        (error, status, content) => {
          dispatch(setCreateOncallJobResponse({}));
          dispatch(setPortalSnackInfo({ severity: 'error', message: '' }));
        },
      );
    } catch (error) {
      dispatch(setCreateOncallJobResponse({}));
    }
  };
}

export function importContacts(userinfo) {
  return (dispatch) => {
    try {
      dispatch(setImportResponse({}));
      PortalApi.call(
        '/phonebook/import',
        { method: 'post', body: JSON.stringify(userinfo) },
        (stats) => {
          dispatch(setImportResponse({}));
          dispatch(getContacts(0, 200));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Contacts imported !' }));
        },
        (error, status, content) => {
          dispatch(setImportResponse({}));
          dispatch(
            setPortalSnackInfo({ severity: 'error', message: `${status.message} : ${error}` }),
          );
        },
      );
    } catch (error) {
      dispatch(setImportResponse({}));
    }
  };
}

export function shareContacts(userinfo) {
  return (dispatch) => {
    try {
      dispatch(setShareContactResponse({}));
      PortalApi.call(
        `/users/shareContacts/${encodeURIComponent(userinfo)}`,
        { method: 'post', body: JSON.stringify({}) },
        (stats) => {
          dispatch(setShareContactResponse({ stats }));
          dispatch(
            setPortalSnackInfo({
              severity: 'success',
              message: `Ask the user to use this code: ${stats.passcode} when importing contacts `,
              autoHideDuration: 60000,
            }),
          );
        },
        (error, status, content) => {
          dispatch(setShareContactResponse({}));
          dispatch(setPortalSnackInfo({ severity: 'error', message: status.message }));
        },
      );
    } catch (error) {
      dispatch(setShareContactResponse({}));
    }
  };
}

export function createContact(contact) {
  return (dispatch) => {
    try {
      dispatch(setCreateEntry(''));
      PortalApi.call(
        '/phonebook',
        { method: 'post', body: JSON.stringify(contact) },
        (stats) => {
          dispatch(setCreateEntry(stats));
          dispatch(getContacts(0, 200));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'New contact added !' }));
        },
        (error, status, content) => {
          dispatch(setCreateEntry(''));
          dispatch(setPortalSnackInfo({ severity: 'error', message: `${status} : ${error}` }));
        },
      );
    } catch (error) {
      dispatch(setCreateEntry(''));
    }
  };
}

export function updateContact(contact) {
  return (dispatch) => {
    try {
      dispatch(setUpdateEntry(''));
      PortalApi.call(
        '/phonebook',
        { method: 'PUT', body: JSON.stringify(contact) },
        (stats) => {
          dispatch(setUpdateEntry(stats));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Updated contact !' }));
          dispatch(getContacts(0, 200));
        },
        (error, status, content) => {
          dispatch(setUpdateEntry(''));
        },
      );
    } catch (error) {
      dispatch(setUpdateEntry(''));
    }
  };
}

export function deleteContact(phoneid) {
  return (dispatch) => {
    try {
      dispatch(setdeleteEntry(''));
      PortalApi.call(
        `/phonebook/${encodeURIComponent(phoneid)}`,
        { method: 'DELETE' },
        (stats) => {
          dispatch(setdeleteEntry(stats));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Contact deleted' }));
          dispatch(getContacts(0, 200));
        },
        (error, status, content) => {
          dispatch(setdeleteEntry('Error deleting phonebook entry'));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not delete contact' }));
        },
      );
    } catch (error) {
      dispatch(setdeleteEntry(''));
    }
  };
}

export function sendSuggestionEmail(message, toAddress, domainName, reference) {
  return (dispatch) => {
    dispatch(setSuggestionEmail(''));
    try {
      var body = {
        message: message,
        toAddress: toAddress,
        domainName: domainName,
        reference: reference,
      };
      PortalApi.call(
        '/users/suggestion',
        { method: 'post', body: JSON.stringify(body) },
        (stats) => {
          dispatch(setSuggestionEmail(stats));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Thank you for your suggestion!' }),
          );
        },
        (error, status, content) => {
          dispatch(setSuggestionEmail(''));
          dispatch(
            setPortalSnackInfo({
              severity: 'success',
              message: 'Could not send suggestion, try again!',
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setSuggestionEmail(''));
    }
  };
}

export function sendCustomerServiceEmail(message, toAddress, domainName, reference, referenceType) {
  return (dispatch) => {
    dispatch(setCsEmailResponse(''));
    try {
      const body = {
        message: message,
        toAddress: toAddress,
        domainName: domainName,
        reference: reference,
        referenceType: referenceType,
      };
      PortalApi.call(
        '/users/email',
        { method: 'post', body: JSON.stringify(body) },
        (stats) => {
          dispatch(setCsEmailResponse(stats));
          dispatch(
            setPortalSnackInfo({
              severity: 'success',
              message: `Customer service email sent to ${toAddress}`,
            }),
          );
        },
        (error, status, content) => {
          dispatch(setCsEmailResponse(''));
        },
      );
    } catch (error) {
      dispatch(setCsEmailResponse(''));
    }
  };
}

export default phoneBookSlice.reducer;
