import React, { useState } from 'react';
import { Autocomplete, TextField, Grid } from '@mui/material';

export default function PortalAutocomplete({
  autoHighlight = false,
  autoComplete = false,
  autoFocus = false,
  autoSelect = false,
  filterSelectedOptions = false,
  includeInputInList = false,
  renderOptionKey = 'id',
  renderOptionLabelKey = 'name',
  renderInputLabel = 'Select',
  renderInput,
  sx,
  noOptionsText = 'No results',
  helperText = '',
  onValueChange,
  fullWidth = true,
  getOptionLabel,
  renderOption,
  options = [],
}) {
  const [value, setValue] = useState(null);

  const defaultGetOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    return option[renderOptionLabelKey]?.toString();
  };

  const defaultRenderOption = (props, option) => {
    const { key, ...optionProps } = props;
    return (
      <li key={option[renderOptionKey]} {...optionProps}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs>
            {option[renderOptionLabelKey]}
          </Grid>
        </Grid>
      </li>
    );
  };

  const defaultRenderInput = (params) => {
    return (
      <TextField
        {...params}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        helperText={helperText}
        label={renderInputLabel}
      />
    );
  };

  return (
    <Autocomplete
      autoComplete={autoComplete}
      autoHighlight={autoHighlight}
      autoSelect={autoSelect}
      filterSelectedOptions={filterSelectedOptions}
      fullWidth={fullWidth}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      id="portal-autocomplete"
      includeInputInList={includeInputInList}
      noOptionsText={noOptionsText}
      onChange={(event, newValue) => {
        setValue(newValue);
        if (onValueChange) {
          onValueChange(newValue);
        }
      }}
      options={options}
      renderInput={renderInput || defaultRenderInput}
      renderOption={renderOption || defaultRenderOption}
      sx={sx ? { ...sx } : {}}
    />
  );
}
