import { styled, keyframes } from '@mui/material/styles';
import MessageStat from './MessageStat';
import { Fragment, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getContactList } from './phoneBookSlice';
import { useSelector } from 'react-redux';

const scaleAnimation = keyframes`
  0% { transform: scale(0.95); }
  50% { transform: scale(1); }
  100% { transform: scale(0.95); }
`;

const AnimatedGridItem = styled(Grid)(({ theme }) => ({
  animation: `${scaleAnimation} 20s ease-in-out infinite`,
  backfaceVisibility: 'hidden',
  willChange: 'transform',
}));

export const MessageStatList = ({ messageStats, messageStatsOptions, contactsRef, gridSize }) => {
  const contactsList = useSelector(getContactList);
  const [styleType, setStyleType] = useState(null);

  useEffect(() => {
    console.log('Welcome to Portal with Alarms!');
    if (!styleType) {
      setStyleType('theme_color');
    }
  }, []);

  return (
    <>
      {messageStatsOptions.map((itemKey, index) => (
        <AnimatedGridItem
          item
          key={index}
          md={gridSize || Math.floor(12 / messageStatsOptions?.length)}
          sm={12}
        >
          <MessageStat
            contactsRef={contactsRef || null}
            datatour={`rt${itemKey}`}
            key={`${itemKey}ms`}
            styleType={styleType}
            title={itemKey}
            value={itemKey === 'contacts' ? contactsList?.length || 0 : messageStats[itemKey]}
          />
        </AnimatedGridItem>
      ))}
    </>
  );
};
