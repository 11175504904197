import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import { Tooltip, CardHeader } from '@mui/material';
import { receiversSelector, selReceiverSelector } from '../messageSlice';
import { accountSelector, selAccountSelector } from '../../accounts/accountSlice';
import { csEmailResponseSelector, sendCustomerServiceEmail } from '../../home/phoneBookSlice';
import { domainDefaultsSelector, getBrandingDefaults } from '../../../brandSlice';
import { useTranslation } from 'react-i18next';
import PortalButton from '../../shared/components/PortalButton';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  cardHeader: `${PREFIX}-cardHeader`,
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
    background: '#f7f7f7',
  },

  [`& .${classes.card}`]: {
    spacing: theme.spacing(2),
    marginTop: 10,
    marginRight: 5,
  },

  [`& .${classes.title}`]: {
    background: '#e0e0e0',
  },
}));

export default function CustomerServiceForm({ type }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([1]);
  const [feedback, setFeedback] = useState('');
  const [from, setFrom] = useState('');
  const [reference, setReference] = useState('');
  const receiver = useSelector(selReceiverSelector);
  const account = useSelector(selAccountSelector);
  const accounts = useSelector(accountSelector);
  const receivers = useSelector(receiversSelector);

  const [open, setOpen] = useState(false);
  const brand = useSelector(domainDefaultsSelector);
  const { t } = useTranslation();
  const csEmailResponse = useSelector(csEmailResponseSelector);

  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailFieldError, setEmailFieldError] = useState('');
  const [feedbackError, setFeedbackError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');

  useEffect(() => {
    if (dispatch && type && account && receiver && accounts && receivers) {
      if (type === 'account') {
        var currentAccount = accounts.find((element) => element.number === parseInt(account));
        if (!currentAccount) {
          currentAccount = accounts[0];
        }
        dispatch(getBrandingDefaults(currentAccount?.domainTxt ? currentAccount.domainTxt : null));
      } else if (type === 'receiver') {
        var currentReceiver = receivers.find((element) => element.number === receiver);
        if (!currentReceiver) {
          currentReceiver = currentReceiver[0];
        }
        dispatch(
          getBrandingDefaults(currentReceiver?.domainTxt ? currentReceiver.domainTxt : null),
        );
      }
    }
  }, [dispatch, type, account, receiver]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value);
        if (event.target.value !== '') {
          setEmailFieldError('');
        }
        break;
      case 'firstName':
        setFirstName(event.target.value);
        if (event.target.value !== '') {
          setFirstNameError('');
        }
        break;
      case 'lastName':
        setLastName(event.target.value);
        break;
      case 'feedback':
        if (event.target.value !== '') {
          setFeedbackError('');
        }
        if (event.target.value > 600) {
          return;
        }
        setFeedback(event.target.value);
        break;
    }
  };

  const sendEmail = () => {
    if (name === '') {
      setEmailFieldError('Required');
    }
    if (feedback === '') {
      setFeedbackError('Required');
    }
    if (firstName === '') {
      setFirstNameError('Required');
    }
    if (name !== '' && feedback !== '' && firstName !== '') {
      const firstNameValue = `First Name: ${firstName} \n `;
      var lastNameValue = '';
      if (lastName !== '') {
        lastNameValue = `Last Name: ${lastName} \n `;
      }
      const emailOrPhone = `Email or Phone Number: ${name} \n `;
      const messageValue = `Message: ${feedback}`;
      var message = firstNameValue + lastNameValue + emailOrPhone + messageValue;
      var host =
        window.location.hostname === 'portal-v2.mapcom.local'
          ? 'acena.com'
          : window.location.hostname.replaceAll('secure2.', '');
      message += `\n Domain : ${host} \n`;

      if (type === 'account') {
        dispatch(sendCustomerServiceEmail(message, brand.ccEmail, brand.name, account, type));
      } else {
        dispatch(sendCustomerServiceEmail(message, brand.ccEmail, brand.name, receiver, type));
      }
    }
  };

  useEffect(() => {
    if (csEmailResponse.length > 0) {
      cancelEmail();
      setOpen(true);
    }
  }, [csEmailResponse]);

  const handleClose = (event) => {
    setOpen(false);
  };

  const cancelEmail = () => {
    setFeedback('');
    setName('');
    setFirstName('');
    setLastName('');
  };

  return (
    <StyledCard className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        subheader="Have a suggestion or feedback?"
        title="Tell us what you think."
      />
      <CardContent>
        <TextField
          error={emailFieldError}
          fullWidth
          label={t('Email or Phone Number')}
          name="name"
          onChange={handleChange}
          placeholder=""
          required
          value={name}
          variant="standard"
        />
        <TextField
          error={firstNameError}
          fullWidth
          label={t('First Name')}
          name="firstName"
          onChange={handleChange}
          placeholder=""
          required
          value={firstName}
          variant="standard"
        />
        <TextField
          fullWidth
          label={t('Last Name')}
          name="lastName"
          onChange={handleChange}
          placeholder=""
          value={lastName}
          variant="standard"
        />
        <TextField
          disabled
          fullWidth
          id="standard-multiline-static"
          label={t('Reference')}
          value={type === 'account' ? `Reference ${account}` : `Reference ${receiver}`}
          variant="standard"
        />
        <TextField
          error={feedbackError}
          fullWidth
          id="standard-multiline-static"
          label={t('Message')}
          multiline
          name="feedback"
          onChange={handleChange}
          placeholder="Write feedback here"
          required
          rows={4}
          value={feedback}
          variant="standard"
        />

        <TextField
          disabled
          fullWidth
          id="standard-multiline-static"
          label={t('Message Size (Limit 600)')}
          rows={4}
          value={feedback.length}
          variant="standard"
        />
      </CardContent>
      <CardActions>
        <Tooltip title={t('Send Email')}>
          <PortalButton color="primary">
            <SendIcon onClick={sendEmail} />
          </PortalButton>
        </Tooltip>
        <Tooltip title={t('Clear')}>
          <PortalButton onClick={cancelEmail}>{t('Clear')}</PortalButton>
        </Tooltip>
      </CardActions>
    </StyledCard>
  );
}
