import { useRef, useCallback, useState, useEffect } from 'react';
import update from 'immutability-helper';
import { useDrag, useDrop } from 'react-dnd';

const defaultContainerStyle = {
  width: 400,
};

const defaultCardStyle = {
  border: '1px solid gray',
  borderRadius: '10px',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

const ItemTypes = {
  CARD: 'card',
};

export const Card = ({ id, text, index, moveCard, style }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      data-handler-id={handlerId}
      ref={ref}
      style={{ ...(style ? style : defaultCardStyle), opacity }}
    >
      {text}
    </div>
  );
};

export const CustomizedReactDnd = ({
  itemList,
  cardStyle,
  containerStyle,
  propagateCardChange,
}) => {
  {
    const [cards, setCards] = useState(itemList);
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      );
    }, []);

    const renderCard = useCallback((card, index) => {
      return (
        <Card
          id={card.id}
          index={index}
          key={card.id}
          moveCard={moveCard}
          style={cardStyle}
          text={card.text}
        />
      );
    }, []);

    useEffect(() => {
      propagateCardChange(cards);
    }, [cards]);

    return (
      <div style={containerStyle ? containerStyle : defaultContainerStyle}>
        {cards.map((card, i) => renderCard(card, i))}
      </div>
    );
  }
};
