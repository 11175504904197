import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../PortalApi';
import { setVpageFeature } from '../home/phoneBookSlice';
import { setPortalSnackInfo } from '../../app/authSlice';
import { setCustomizedProgressLoading } from '../../slices/customizedProgressLoaderSlice';
import { getURLSearchParamsString } from '../../utils/query';

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    accounts: [],
    selAccount: 0,
    selAccountInfo: {},
    selAccountFeature: [],
    selStatement: 0,
    selStatementPdf: '',
    usage: {},
    statements: [],
    payments: {},
    paymentCards: [],
    makePaymentResponse: {},
    searchAccounts: [],
    selectedSearchAccount: '',
    requestSOAResponse: '',
    searchStaffAccounts: null,
    scheduleInfo: {},
    shiftAssignment: {},
    directory: [],
    updateContactMethodResponse: {},
    loading: false,
    createFuneralResponse: {},
    attachAccountResponse: '',
    detachAccountResponse: '',
    updateNickNameResponse: '',
    funeralServices: {},
    chatUrl: '',
    activeChats: [],
    apiSuccess: false,
  },
  reducers: {
    setUpdateNickNameResponse: (state, action) => {
      state.updateNickNameResponse = action.payload;
    },
    setDetachAccountResponse: (state, action) => {
      state.detachAccountResponse = action.payload;
    },
    setAttachAccountResponse: (state, action) => {
      state.attachAccountResponse = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setFuneralServices: (state, action) => {
      state.funeralServices = action.payload;
    },
    setCreateFuneralResponse: (state, action) => {
      state.createFuneralResponse = action.payload;
    },
    setChatUrl: (state, action) => {
      state.chatUrl = action.payload;
    },
    setActiveChats: (state, action) => {
      state.activeChats = action.payload;
    },
    setSelAccount: (state, action) => {
      state.selAccount = action.payload;
    },
    setSelAccountInfo: (state, action) => {
      state.selAccountInfo = action.payload;
    },
    setUsage: (state, action) => {
      state.usage = action.payload;
    },
    setStatements: (state, action) => {
      state.statements = action.payload;
    },
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
    setSelStatment: (state, action) => {
      state.selStatement = action.payload;
    },
    setSelStatmentPdf: (state, action) => {
      state.selStatementPdf = action.payload;
    },
    setSearchAccounts: (state, action) => {
      state.searchAccounts = action.payload;
    },
    setSearchStaffAccounts: (state, action) => {
      state.searchStaffAccounts = action.payload;
    },
    setSelectedSearchAccount: (state, action) => {
      state.selectedSearchAccount = action.payload;
    },
    setDirectory: (state, action) => {
      state.directory = action.payload;
    },
    setScheduleInfo: (state, action) => {
      state.scheduleInfo = action.payload;
    },
    setUpdateContactMethodResponse: (state, action) => {
      state.updateContactMethodResponse = action.payload;
      var newDirectory = [...state.directory];
      newDirectory.forEach((item) => {
        item.contactMethods.map((element) => {
          if (element.id === action.payload.id) {
            return (element.value = action.payload.value);
          }
          return element;
        });
      });
      state.directory = newDirectory;
    },
    setRequestSOAResponse: (state, action) => {
      state.requestSOAResponse = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPaymentCards: (state, action) => {
      state.paymentCards = action.payload;
    },
    setMakePaymentResponse: (state, action) => {
      state.makePaymentResponse = action.payload;
    },
    setShiftAssignment: (state, action) => {
      state.shiftAssignment = action.payload;
    },
    setApiSuccess: (state, action) => {
      state.apiSuccess = action.payload;
    },
  },
});

export const {
  setCreateFuneralResponse,
  setFuneralServices,
  setUpdateNickNameResponse,
  setAttachAccountResponse,
  setDetachAccountResponse,
  setShiftAssignment,
  setMakePaymentResponse,
  setPaymentCards,
  setLoading,
  setPayments,
  setRequestSOAResponse,
  setSelStatment,
  setSelStatmentPdf,
  setAccounts,
  setSelAccount,
  setSelAccountInfo,
  setUsage,
  setStatements,
  setSearchAccounts,
  setSearchStaffAccounts,
  setSelectedSearchAccount,
  setDirectory,
  setScheduleInfo,
  setUpdateContactMethodResponse,
  setChatUrl,
  setActiveChats,
  setApiSuccess,
  setIvrMessages,
  setSelectedVoicemailLineNumber,
  setVoicemailGridDisplayType,
  setSelectedVoicemailMailbox,
  setVoicemailSortKey,
  setVoicemailSortOrder,
} = accountSlice.actions;

// A selector
export const accountSelector = (state) => state.account.accounts;

export const selAccountSelector = (state) => state.account.selAccount;

export const selAccountInfoSelector = (state) => state.account.selAccountInfo;

export const usageSelector = (state) => state.account.usage;

export const statementsSelector = (state) => state.account.statements;

export const selStatmentSelector = (state) => state.account.selStatement;

export const selStatementPdfSelector = (state) => state.account.selStatementPdf;

export const searchAccountsSelector = (state) => state.account.searchAccounts;

export const searchStaffAccountsSelector = (state) => state.account.searchStaffAccounts;

export const selectedSearchAccountSelector = (state) => state.account.selectedSearchAccount;

export const directorySelector = (state) => state.account.directory;

export const selAccountFeatureSelector = (state) => state.account.selAccountFeature;

export const selScheduleInfoSelector = (state) => state.account.scheduleInfo;

export const updateContactMethodResponseSelector = (state) =>
  state.account.updateContactMethodResponse;

export const requestSOAResponseSelector = (state) => state.account.requestSOAResponse;

export const paymentsSelector = (state) => state.account.payments;

export const accountLoadingSelector = (state) => state.account.loadingSelector;

export const paymentCardsSelector = (state) => state.account.paymentCards;

export const makePaymentResponseSelector = (state) => state.account.makePaymentResponse;

export const loadingSelector = (state) => state.account.loading;

export const shiftAssignmentSelector = (state) => state.account.shiftAssignment;

export const accountAttachResponseSelector = (state) => state.account.accountAttachResponse;

export const detachAccountResponseSelector = (state) => state.account.detachAccountResponse;

export const updateNickNameResponseSelector = (state) => state.account.updateNickNameResponse;

export const funeralServicesSelector = (state) => state.account.funeralServices;

export const chatUrlSelector = (state) => state.account.chatUrl;

export const activeChatsSelector = (state) => state.account.activeChats;
export const apiSuccessSelector = (state) => state.account.apiSuccess;

// Asynchronous thunk action
export function getAccountsByUser() {
  return (dispatch) => {
    dispatch(setAccounts([]));
    try {
      PortalApi.call(
        '/account',
        { method: 'get' },
        (stats) => {
          dispatch(setAccounts(stats));
          var vpage = false;
          if (stats.length > 0) {
            stats.forEach((account) => {
              account.features.forEach((feature) => {
                if (
                  feature.masterFeature.featureTypeRef === 'VPAGE' &&
                  feature.status === 'ENABLE'
                ) {
                  vpage = true;
                }
              });
            });
            dispatch(setVpageFeature(vpage));
          }
        },
        (error, status, content) => {
          dispatch(setAccounts([]));
        },
      );
    } catch (error) {
      dispatch(setAccounts([]));
    }
  };
}

export function getFuneralServicesList(account, pageNo, pageSize, sort) {
  return (dispatch) => {
    dispatch(setFuneralServices({}));
    try {
      PortalApi.call(
        `/funeral/list/${encodeURI(account)}?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sort}`,
        { method: 'get' },
        (stats) => {
          dispatch(setFuneralServices(stats));
        },
        (error, status, content) => {
          dispatch(setFuneralServices({}));
        },
      );
    } catch (error) {
      dispatch(setFuneralServices({}));
    }
  };
}

export function createFuneralService(account, service, page, size, sortBy) {
  return (dispatch) => {
    dispatch(setCreateFuneralResponse({}));
    dispatch(setCustomizedProgressLoading(true));
    try {
      PortalApi.call(
        `/funeral/${encodeURI(account)}`,
        { method: 'post', body: JSON.stringify(service) },
        (stats) => {
          dispatch(setCreateFuneralResponse(stats));
          dispatch(getFuneralServicesList(account, page, size, sortBy));
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          dispatch(setCreateFuneralResponse({}));
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setCreateFuneralResponse({}));
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export function updateFuneralService(id, account, service, page, size, sortBy) {
  return (dispatch) => {
    dispatch(setCreateFuneralResponse({}));
    dispatch(setCustomizedProgressLoading(true));
    try {
      PortalApi.call(
        `/funeral/${encodeURI(id)}`,
        { method: 'put', body: JSON.stringify(service) },
        (stats) => {
          dispatch(setCreateFuneralResponse(stats));
          dispatch(getFuneralServicesList(account, page, size, sortBy));
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          dispatch(setCreateFuneralResponse({}));
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setCreateFuneralResponse({}));
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export function deleteFuneralService(id, account, page, size, sortBy) {
  return (dispatch) => {
    dispatch(setCustomizedProgressLoading(true));
    try {
      PortalApi.call(
        `/funeral/${encodeURI(id)}`,
        { method: 'delete' },
        (stats) => {
          dispatch(getFuneralServicesList(account, 0, 10, sortBy));
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export function getUsageForAccount(account, startDate, endDate) {
  return (dispatch) => {
    dispatch(setUsage({}));
    try {
      PortalApi.call(
        `/account/${account}/usage?start=${startDate}&end=${endDate}`,
        { method: 'get' },
        (stats) => {
          dispatch(setUsage(stats));
          // if((stats.metrics.totalMinutesMetric.values.calls/60) > 540){
          //   dispatch(setPortalSnackInfo({severity:"warning",message:"Account usage is at 90% of your base plan"}));
          // }
        },
        (error, status, content) => {
          dispatch(setUsage({}));
        },
      );
    } catch (error) {
      dispatch(setUsage({}));
    }
  };
}

export function getDirectoryForAccount(account, depth) {
  return (dispatch) => {
    dispatch(setDirectory([]));
    try {
      PortalApi.call(
        `/account/${account}/directory?depth=${encodeURIComponent(depth)}`,
        { method: 'get' },
        (stats) => {
          dispatch(setDirectory(stats));
        },
        (error, status, content) => {
          dispatch(setDirectory([]));
        },
      );
    } catch (error) {
      dispatch(setDirectory([]));
    }
  };
}

export function updateContactMethod(contactMethod) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      dispatch(setUpdateContactMethodResponse({}));
      PortalApi.call(
        '/account/directory',
        { method: 'post', body: JSON.stringify(contactMethod) },
        (stats) => {
          if (stats.result === 'SUCCESS') {
            dispatch(
              setUpdateContactMethodResponse({ id: contactMethod.id, value: contactMethod.value }),
            );
            dispatch(
              setPortalSnackInfo({ severity: 'success', message: 'Contact method updated !' }),
            );
          } else {
            dispatch(
              setPortalSnackInfo({ severity: 'error', message: 'Could not update contact method' }),
            );
          }

          dispatch(setLoading(false));

          // dispatch(getDirectoryForAccount(contactMethod.accountNumber,10))
        },
        (error, status, content) => {
          dispatch(setUpdateContactMethodResponse({}));
        },
      );
    } catch (error) {
      dispatch(setPortalSnackInfo({ severity: 'success', message: error.message }));
      dispatch(setLoading(false));
      dispatch(setUpdateContactMethodResponse({}));
    }
  };
}

export function updateAccount(accountNumber, nickname) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      dispatch(setUpdateNickNameResponse(''));
      PortalApi.call(
        `/account/${encodeURIComponent(accountNumber)}/updateAttach?nickName=${encodeURIComponent(
          nickname,
        )}`,
        { method: 'get' },
        (stats) => {
          dispatch(setUpdateNickNameResponse(stats));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Nickname updated !' }));
          dispatch(getAccountsByUser());
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setUpdateNickNameResponse(''));
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Issue updating nickname' }));
        },
      );
    } catch (error) {
      dispatch(setUpdateNickNameResponse(''));
      dispatch(setLoading(false));
    }
  };
}

export function attachAccount(
  accountNumber,
  tollFreeNumber,
  statementNumber,
  billingZip,
  billingLastName,
  billingPhone,
) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      dispatch(setAttachAccountResponse(''));
      var url = `/account/attach?tollFreeNumber=${encodeURIComponent(tollFreeNumber)}`;
      if (accountNumber) {
        url += `&accountNumber=${encodeURIComponent(accountNumber)}`;
      }
      if (statementNumber) {
        url += `&statementNumber=${encodeURIComponent(statementNumber)}`;
      }
      if (billingZip) {
        url += `&billingZip=${encodeURIComponent(billingZip)}`;
      }
      if (billingLastName) {
        url += `&billingLastName=${encodeURIComponent(billingLastName)}`;
      }
      if (billingPhone) {
        url += `&billingPhone=${encodeURIComponent(billingPhone)}`;
      }

      PortalApi.call(
        url,
        { method: 'get' },
        (stats) => {
          dispatch(setAttachAccountResponse(stats));
          dispatch(setLoading(false));
          if (stats.status === 'ALREADYATTACHED') {
            dispatch(
              setPortalSnackInfo({ severity: 'warning', message: 'Account already attached ' }),
            );
          } else {
            dispatch(
              setPortalSnackInfo({
                severity: 'success',
                message: `Account ${accountNumber} attached`,
              }),
            );
            dispatch(getAccountsByUser());
          }
        },
        (error, status, content) => {
          dispatch(setAttachAccountResponse('ERROR'));
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Invalid Account' }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setAttachAccountResponse('ERROR'));
    }
  };
}

export function detachAccount(accountNumber) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      dispatch(setDetachAccountResponse(''));
      PortalApi.call(
        `/account/${encodeURIComponent(accountNumber)}/deattach`,
        { method: 'delete', body: {} },
        (stats) => {
          dispatch(setDetachAccountResponse(stats));
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'success',
              message: `Account ${accountNumber} detached`,
            }),
          );
          dispatch(getAccountsByUser());
        },
        (error, status, content) => {
          dispatch(setDetachAccountResponse(''));
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: status.message }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setDetachAccountResponse(''));
    }
  };
}

export function getPaymentsForAccount(account, pageNo, pageSize) {
  return (dispatch) => {
    //dispatch(setPayments({}))
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/payments/list/${account}?pageNo=${pageNo}&pageSize=${pageSize}`,
        { method: 'get' },
        (stats) => {
          dispatch(setPayments(stats));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setPayments({}));
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: `${status}: could not load payments`,
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setPayments({}));
    }
  };
}

export function createPayment(account, payment, accountId) {
  return (dispatch) => {
    try {
      dispatch(setMakePaymentResponse(''));
      PortalApi.call(
        `/payments/${account}/current`,
        { method: 'post', body: JSON.stringify(payment) },
        (stats) => {
          dispatch(setMakePaymentResponse(stats));
          dispatch(getPaymentsForAccount(accountId, 0, 10));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Payment successfully submitted ' }),
          );
        },
        (error, status, content) => {
          dispatch(setMakePaymentResponse(''));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Issue processing payment' }));
        },
      );
    } catch (error) {
      dispatch(setMakePaymentResponse(''));
    }
  };
}

export function createNewPayment(account, payment, accountId) {
  return (dispatch) => {
    try {
      dispatch(setMakePaymentResponse(''));

      PortalApi.call(
        `/payments/${account}/new`,
        { method: 'post', body: JSON.stringify(payment) },
        (stats) => {
          dispatch(setMakePaymentResponse(stats));
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Payment successfully submitted ' }),
          );
          dispatch(getPaymentsForAccount(accountId, 0, 10));
        },
        (error, status, content) => {
          dispatch(setMakePaymentResponse(''));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Issue processing payment' }));
        },
      );
    } catch (error) {
      dispatch(setMakePaymentResponse(''));
    }
  };
}

export function getPaymentCardsForAccount(account) {
  return (dispatch) => {
    dispatch(setPaymentCards([]));
    try {
      PortalApi.call(
        `/payments/list/cards/${account}`,
        { method: 'get' },
        (stats) => {
          dispatch(setPaymentCards(stats));
        },
        (error, status, content) => {
          dispatch(setPaymentCards([]));
        },
      );
    } catch (error) {
      dispatch(setPaymentCards([]));
    }
  };
}

export function getStatementsForAccount(account, pageNo, pageSize) {
  return (dispatch) => {
    // dispatch(setSelStatmentPdf([]))
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/account/${account}/statements?pageNo=${pageNo}&pageSize=${pageSize}`,
        { method: 'get' },
        (stats) => {
          dispatch(setStatements(stats));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setStatements([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setStatements([]));
      dispatch(setLoading(false));
    }
  };
}

export function getStatementPdf(statementNum) {
  return (dispatch) => {
    dispatch(setSelStatmentPdf(''));
    try {
      PortalApi.call(
        `/statements/pdf/${statementNum}`,
        { method: 'get' },
        (stats) => {
          dispatch(setSelStatmentPdf(stats));
        },
        (error, status, content) => {
          dispatch(setSelStatmentPdf(''));
        },
      );
    } catch (error) {
      dispatch(setSelStatmentPdf(''));
    }
  };
}

export function sendRequestSoa(account, date) {
  return (dispatch) => {
    dispatch(setRequestSOAResponse(''));
    try {
      PortalApi.call(
        `/account/${account}/soa?date=${date}`,
        { method: 'get' },
        (stats) => {
          dispatch(setRequestSOAResponse('SUCCESS'));
        },
        (error, status, content) => {
          dispatch(setRequestSOAResponse('ERROR'));
        },
      );
    } catch (error) {
      dispatch(setRequestSOAResponse('ERROR'));
    }
  };
}

export function accountSearch(name, number, tollFree, affiliate, receiver) {
  return (dispatch) => {
    dispatch(setSearchAccounts([]));
    dispatch(setLoading(true));
    dispatch(setApiSuccess(false));
    try {
      PortalApi.call(
        `/staff/search/customer?accountNum=${number}&accountName=${name}&tollFreeNum=${
          tollFree
        }&affiliate=${affiliate}&receiverID=${receiver}`,
        { method: 'get' },
        (stats) => {
          dispatch(setSearchAccounts(stats));
          dispatch(setLoading(false));
          dispatch(setApiSuccess(true));
        },
        (error, status, content) => {
          dispatch(setSearchAccounts([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setSearchAccounts([]));
      dispatch(setLoading(false));
    }
  };
}

export function accountSearchForTaskTracker(name, number, tollFree) {
  return (dispatch) => {
    dispatch(setSearchAccounts([]));
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        `/staff/search/customer?accountNum=${number}&accountName=${name}&tollFreeNum=`,
        { method: 'get' },
        (stats) => {
          dispatch(setSearchAccounts(stats));
          if (stats.length === 0) {
            PortalApi.call(
              `/staff/search/customer?accountNum=&accountName=${name}&tollFreeNum=${tollFree}`,
              { method: 'get' },
              (stats) => {
                dispatch(setSearchAccounts(stats));
                dispatch(setLoading(false));
              },
              (error, status, content) => {
                dispatch(setSearchAccounts([]));
                dispatch(setLoading(false));
              },
            );
          }
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setSearchAccounts([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setSearchAccounts([]));
      dispatch(setLoading(false));
    }
  };
}

export function staffSearch(email) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setSearchStaffAccounts(null));
    try {
      PortalApi.call(
        `/staff/search?email=${email}`,
        { method: 'get' },
        (stats) => {
          dispatch(setSearchStaffAccounts(stats));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setSearchStaffAccounts(null));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setSearchStaffAccounts(null));
      dispatch(setLoading(false));
    }
  };
}

export function getScheduleForAccount(account, schedule, startDate, endDate) {
  return (dispatch) => {
    dispatch(setLoading(true));
    //dispatch(setScheduleInfo({}))
    try {
      PortalApi.call(
        `/account/${account}/schedule/${schedule}?startDate=${startDate}&endDate=${endDate}`,
        { method: 'get' },
        (stats) => {
          dispatch(setScheduleInfo(JSON.parse(stats.string)));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setScheduleInfo({}));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setScheduleInfo({}));
      dispatch(setLoading(false));
    }
  };
}

export function updateShiftAssignment(shiftId, shift, account, schedule, startDate, endDate) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      dispatch(setShiftAssignment({}));
      PortalApi.call(
        `/oncallSchedules/${shiftId}`,
        { method: 'post', body: JSON.stringify(shift) },
        (stats) => {
          dispatch(
            setPortalSnackInfo({ severity: 'success', message: 'Shift assignment updated.' }),
          );
          dispatch(setShiftAssignment({ response: stats, shiftId: shiftId, shift: shift }));
          dispatch(getScheduleForAccount(account, schedule, startDate, endDate));
        },
        (error, status, content) => {
          dispatch(setShiftAssignment({}));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setShiftAssignment({}));
      dispatch(setLoading(false));
    }
  };
}

export function getDCLiveChatUrl(dc2goAccountId) {
  return (dispatch) => {
    dispatch(setChatUrl({}));
    try {
      PortalApi.call(
        `/account/dcchat/${encodeURIComponent(dc2goAccountId)}`,
        { method: 'get' },
        (stats) => {
          dispatch(setChatUrl(`https://qphoneapi.dchoice.com${stats}`));
          //dispatch(setChatUrl(stats))
        },
        (error, status, content) => {
          dispatch(setChatUrl({}));
        },
      );
    } catch (error) {
      dispatch(setChatUrl({}));
    }
  };
}

export function getDCActiveChats(info) {
  return (dispatch) => {
    dispatch(setActiveChats([]));
    try {
      fetch('https://qphoneapi.dchoice.com/Chat.svc/ListChats', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(info),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log('data ** list chats ', data);
          dispatch(setActiveChats([]));
        })
        .catch(() => {
          dispatch(setActiveChats([]));
        });
      //  PortalApi.call('/account/dcchat/'+en),{ method: 'post','body':JSON.stringify(info)},(stats) =>{
      //   dispatch(setActiveChats(stats))
      // },(error, status, content) =>{
      //   dispatch(setActiveChats([]))
      // });
    } catch (error) {
      dispatch(setActiveChats([]));
    }
  };
}

export function getSearchedAccounts({ accountNumber, accountName }, callback) {
  return (dispatch) => {
    try {
      const queryParams = getURLSearchParamsString({ accountNumber, accountName });
      PortalApi.call(
        `/account/search/?${queryParams}`,
        { method: 'get' },
        (stats) => {
          if (callback) callback(stats);
        },
        (error, status, content) => {
          console.error('Error', error, status, content);
          if (callback) callback([]);
        },
      );
    } catch (error) {
      console.error('Error', error);
      if (callback) callback([]);
    }
  };
}

export default accountSlice.reducer;
