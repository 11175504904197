import { useSelector } from 'react-redux';
import { portalUserSelector } from '../app/authSlice';
import { Navigate, Outlet } from 'react-router-dom';

const StaffProtectedRoutes = () => {
  const portalUser = useSelector(portalUserSelector);
  if (!portalUser) {
    return null;
  }
  return portalUser?.staffFlag === 'Y' ? <Outlet /> : <Navigate to="/home" />;
};
export default StaffProtectedRoutes;
