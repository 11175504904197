import { Box } from '@mui/material';
import { AlarmObjectStruct, formatAlarmTimestamp } from '../../../utils/alarmUtil';

const DeleteAlarmConfirmationView = ({ selectedRow, timezone }) => {
  const getAlarmDetailDescription = () => {
    try {
      return `CreatedAt: ${formatAlarmTimestamp(selectedRow[AlarmObjectStruct.created_timestamp], timezone)} 
       <br/>   Original Timezone: ${selectedRow[AlarmObjectStruct.original_timezone]}
       <br/>   Trigger Timestamp: 
                ${formatAlarmTimestamp(selectedRow[AlarmObjectStruct.trigger_timestamp], timezone)}
                Or  ${formatAlarmTimestamp(selectedRow[AlarmObjectStruct.trigger_timestamp], selectedRow[AlarmObjectStruct.original_timezone])}
       <br/>   Dispatch Timestamp: ${formatAlarmTimestamp(selectedRow[AlarmObjectStruct.dispatch_timestamp], timezone)}
       <br/>   Message: ${selectedRow[AlarmObjectStruct.message]}
      `;
    } catch (error) {
      console.error('Error:', error);
    }
    return '';
  };

  return (
    <Box>
      <p>Are you sure you want to delete this alarm?</p>
      <p
        dangerouslySetInnerHTML={{
          __html: getAlarmDetailDescription(),
        }}
      />
    </Box>
  );
};

export default DeleteAlarmConfirmationView;
