import { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Switch,
  FormControlLabel,
  Chip,
  Avatar,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  tasksSelector,
  getTasks,
  createTask,
  updateTask,
  continueTask,
  pauseTask,
  stopTask,
  overrideTask,
  lastDispatchStatusSelector,
  getTasksOptions,
  optionsSelector,
  loadingSelector,
  stopTaskSelector,
  setTaskTrackerShortKey,
  setTaskTrackerShortOrder,
  taskTrackerSortKeySelector,
  taskTrackerSortOrderSelector,
  taskTrackerStatusOrderSelector,
} from '../staffSlice';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import UpdateIcon from '@mui/icons-material/Update';
import { PlayArrow } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment-timezone';
import SortIcon from '@mui/icons-material/Sort';
import AccountLookup from '../AccountLookup';
import {
  selectedSearchAccountSelector,
  searchAccountsSelector,
  setSelAccount,
  setSelectedSearchAccount,
  accountSearchForTaskTracker,
  setSearchAccounts,
} from '../../accounts/accountSlice';
import Popover from '@mui/material/Popover';
import { ButtonGroup, Tooltip } from '@mui/material';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ListIcon from '@mui/icons-material/List';
import { setPortalSnackInfo, timezoneSelector } from '../../../app/authSlice';
import DOMPurify from 'dompurify';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { styled } from '@mui/material/styles';
import {
  TASK_STATUS,
  TaskStatusColorCode,
  TaskTrackerSource,
  DefaultTaskTrackerStatusOrder,
} from '../../../utils/taskTracker';
import TaskTypeFilter from './TaskTypeFilter';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import TaskTrackerSort from './TaskTrackerSort';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'TaskTracker';

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  dropdown: `${PREFIX}-dropdown`,
  controls: `${PREFIX}-controls`,
  activePaper: `${PREFIX}-activePaper`,
  paper: `${PREFIX}-paper`,
  tableContainer: `${PREFIX}-tableContainer`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  mainCardHeader: `${PREFIX}-mainCardHeader`,
  subHeaderTitle: `${PREFIX}-subHeaderTitle`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardHeaderWrap: `${PREFIX}-cardHeaderWrap`,
  futureTaskHeaderStatus: `${PREFIX}-futureTaskHeaderStatus`,
  activeTaskHeaderStatus: `${PREFIX}-activeTaskHeaderStatus`,
  pausedTaskHeaderStatus: `${PREFIX}-pausedTaskHeaderStatus`,
  completedTaskHeaderStatus: `${PREFIX}-completedTaskHeaderStatus`,
  subheader: `${PREFIX}-subheader`,
  activeStatus: `${PREFIX}-activeStatus`,
  completedStatus: `${PREFIX}-completedStatus`,
  pausedStatus: `${PREFIX}-pausedStatus`,
  taskDescription: `${PREFIX}-taskDescription`,
  chipHolder: `${PREFIX}-chipHolder`,
  disableTextArea: `${PREFIX}-disableTextArea`,
  taskTypeFilter: `${PREFIX}-taskTypeFilter`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.root2}`]: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.controls}`]: {
    width: '100vw',
  },

  [`& .${classes.activePaper}`]: {
    borderRadius: theme.spacing(2),
    background: '#ffd2c4',
    cursor: 'pointer',
  },

  [`& .${classes.paper}`]: {
    borderRadius: theme.spacing(2),
    cursor: 'pointer',
  },

  [`& .${classes.tableContainer}`]: {
    maxHeight: '200px',
    overflow: 'auto',
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.mainCardHeader}`]: {
    margin: 0,
    padding: 10,
    background: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'flex-start',
  },

  [`& .${classes.subHeaderTitle}`]: {
    color: 'white',
  },

  [`& .${classes.cardHeader}`]: {
    margin: 0,
    padding: 10,
    background: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  [`& .${classes.cardHeaderWrap}`]: {
    width: '100%',
    wordBreak: 'break-word',
  },

  [`& .${classes.futureTaskHeaderStatus}`]: {
    margin: 0,
    padding: 10,
    background: '#6495ed',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  [`& .${classes.activeTaskHeaderStatus}`]: {
    margin: 0,
    padding: 10,
    background: TaskStatusColorCode[TASK_STATUS.ACTIVE],
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  [`& .${classes.pausedTaskHeaderStatus}`]: {
    margin: 0,
    padding: 10,
    background: TaskStatusColorCode[TASK_STATUS.PAUSED],
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  [`& .${classes.completedTaskHeaderStatus}`]: {
    margin: 0,
    padding: 10,
    background: TaskStatusColorCode[TASK_STATUS.COMPLETED],
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  [`& .${classes.subheader}`]: {
    color: 'red',
  },

  [`& .${classes.activeStatus}`]: {
    color: TaskStatusColorCode[TASK_STATUS.ACTIVE],
    fontWeight: 'bold',
  },

  [`& .${classes.completedStatus}`]: {
    color: '#303030',
    fontWeight: 'bold',
  },

  [`& .${classes.pausedStatus}`]: {
    color: '#e2725b',
    fontWeight: 'bold',
  },

  [`& .${classes.taskDescription}`]: {
    maxHeight: '200px',
    overflow: 'scroll',
    padding: theme.spacing(2),
    wordBreak: 'break-word',
  },

  [`& .${classes.chipHolder}`]: {
    display: 'none',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },

  [`& .${classes.disableTextArea}`]: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
  },

  [`& .${classes.taskTypeFilter}`]: {
    margin: 'auto',
  },
}));

const TaskTracker = () => {
  const dispatch = useDispatch();

  const tasks = useSelector(tasksSelector);
  const options = useSelector(optionsSelector);
  const loading = useSelector(loadingSelector);
  const taskTrackerShortKey = useSelector(taskTrackerSortKeySelector);
  const taskTrackerShortOrder = useSelector(taskTrackerSortOrderSelector);
  const taskTrackerStatusOrder = useSelector(taskTrackerStatusOrderSelector);
  const [sortKey, setSortKey] = useState('creation');
  const [sortOrder, setSortOrder] = useState('desc');

  const disptachStatus = useSelector(lastDispatchStatusSelector);

  const [selectedTask, setSelectedTask] = useState({});
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [managerId, setManagerId] = useState('');
  const [quickDescription, setQuickDescription] = useState('');

  const [overrideTime, setOverrideTime] = useState('');
  const [overrideHelperText, setOverrideHelperText] = useState('');

  const [category, setCategory] = useState('');
  const [taskSourceRef, setTaskSourceRef] = useState('');
  const [caller, setCaller] = useState('');
  const [company, setCompany] = useState('');

  const [followUpFlag, setFollowUpFlag] = useState(false);
  const account = useSelector(selectedSearchAccountSelector);
  const accountSearchResponse = useSelector(searchAccountsSelector);
  const [selectedAccountName, setSelectedAccountName] = useState('');

  const [manualAccount, setManualAccount] = useState('');
  const [type, setType] = useState('');
  const [scope, setScope] = useState('');
  const [findCollapse, setFindCollapse] = useState(false);
  const defaultTimezone = useSelector(timezoneSelector);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Create Task');
  const [mode, setMode] = useState('add');
  const [sentiScore, setSentiScore] = useState('add');

  const [contextText, setContextText] = useState('Enter task details to create a new task');
  const stopTaskResponse = useSelector(stopTaskSelector);
  const [displayType, setDisplayType] = useState('list');
  const [showFutureTasks, setShowFutureTasks] = useState(false);
  const [accountRequired, setAccountRequired] = useState(false);
  const handleDisplayChange = (value) => {
    setDisplayType(value);
  };

  const [selectedTaskStatuses, setSelectedTaskStatuses] = useState({
    [TASK_STATUS.ACTIVE]: true,
    [TASK_STATUS.PAUSED]: true,
    [TASK_STATUS.COMPLETED]: true,
  });

  const [statusOrder, setStatusOrder] = useState(DefaultTaskTrackerStatusOrder);

  const [dueDate, setDueDate] = useState(null);

  useEffect(() => {
    dispatch(getTasksOptions());
  }, [dispatch]);

  useEffect(() => {
    setSortKey(taskTrackerShortKey);
  }, [taskTrackerShortKey]);

  useEffect(() => {
    setSortOrder(taskTrackerShortOrder);
  }, [taskTrackerShortOrder]);

  useEffect(() => {
    setStatusOrder(taskTrackerStatusOrder);
  }, [taskTrackerStatusOrder]);

  useEffect(() => {
    if (account && accountSearchResponse && accountSearchResponse.length > 0) {
      var currentAccount = accountSearchResponse.filter((element) => element.ACNT_NUM === account);
      if (currentAccount.length === 1) {
        setSelectedAccountName(currentAccount[0].CMPNY_NAME_TXT);
      }
    }
  }, [account]);

  useEffect(() => {
    if (accountSearchResponse && accountSearchResponse?.length === 1) {
      dispatch(setSelectedSearchAccount(accountSearchResponse[0].ACNT_NUM));
    } else {
      dispatch(setSelectedSearchAccount());
      setSelectedAccountName('');
    }
  }, [dispatch, accountSearchResponse]);

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      if (selectedTask && selectedTask.id) {
        var newSelTaskInfo = tasks.filter((a) => a.id === selectedTask.id);
        if (newSelTaskInfo.length > 0) {
          setSelectedTask(newSelTaskInfo[0]);
        }
      }
    }
  }, [tasks]);

  useEffect(() => {
    if (
      disptachStatus &&
      mode === 'complete' &&
      stopTaskResponse &&
      stopTaskResponse !== '' &&
      stopTaskResponse.result === 'SUCCESS'
    ) {
      setOpen(false);
      setMode('override');
      setTitle('Do you want to Override Actual Time');
      setDescription(selectedTask.description ? selectedTask.description : '');
      setOverrideTime('');
      setOverrideHelperText('Time format should be HH:MM:SS or HH:MM');
      setContextText('');
    } else if (disptachStatus) {
      setOpen(false);
      setMode('add');
      setTitle('Create Task');
      setContextText('Enter task details to create a new task');
      setType('');
      setScope('');
      setManagerId('');
      setCaller('');
      dispatch(setSelectedSearchAccount(''));
      setManualAccount('');
      setCategory('');
      setCompany('');
      setDescription('');
      setDueDate(null);
      setTaskSourceRef('');
      setNotes('');
    }
  }, [disptachStatus, stopTaskResponse]);

  const handleClose = () => {
    setOpen(false);
    setMode('add');
    setTitle('Create Task');
    setContextText('Enter task details to create a new task');
    setType('');
    setScope('');
    setManagerId('');
    setCaller('');
    setManualAccount('');
    setCategory('');
    setDescription('');
    setQuickDescription('');
    setDueDate(null);
    setTaskSourceRef('');
    dispatch(setSearchAccounts([]));
    dispatch(setSelectedSearchAccount(''));
  };

  const toHHMMSS = (value) => {
    value = isNaN(value) ? 0 : value;
    var sec_num = parseInt(Math.round(value), 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  };

  const setStatusClass = (status) => {
    switch (status) {
      case TASK_STATUS.ACTIVE:
        return classes.activeStatus;
      case TASK_STATUS.PAUSED:
        return classes.pausedStatus;
      case TASK_STATUS.COMPLETED:
        return classes.completedStatus;
      default:
        return classes.activeStatus;
    }
  };

  const setTaskHeaderClass = (task) => {
    switch (task.taskStatus) {
      case TASK_STATUS.ACTIVE:
        return classes.activeTaskHeaderStatus;
      case TASK_STATUS.PAUSED:
        return classes.pausedTaskHeaderStatus;
      case TASK_STATUS.COMPLETED:
        return classes.completedTaskHeaderStatus;
      default:
        return classes.futureTaskHeaderStatus;
    }
  };

  const MyTextArea = (props) => {
    const [value, setValue] = useState(props.initialValue);
    return (
      <>
        <InputLabel
          htmlFor="taskDescriptionField"
          required={mode === 'complete'}
          style={{ color: '#0000008a !important', margin: '10px 0px' }}
        >
          Description
        </InputLabel>
        <TextareaAutosize
          error={getCleanDescription(value).length > 4000 ? 'Max 4000 characters allowed ' : ''}
          helperText={getCleanDescription(value).length}
          id="taskDescriptionField"
          label="Description"
          multiline
          name="description"
          onBlur={(event) => {
            setDescription(event.target.value);
            setValue(event.target.value);
          }}
          onChange={(event) => setValue(event.target.value)}
          style={{
            maxWidth: '100%',
            minWidth: '100%',
            fontSize: 'large',
            minHeight: '200px',
            overflow: 'scroll',
          }}
          type="text"
          value={getCleanDescription(value)}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
          <span>{getCleanDescription(value).length}</span>{' '}
          <span style={{ color: 'red' }}>
            {getCleanDescription(value).length > 4000 ? 'Max 4000 characters allowed ' : ''}
          </span>{' '}
        </div>
      </>
    );
  };

  const NotesReason = (props) => {
    const [value, setValue] = useState(props.initialValue);

    return (
      <>
        <InputLabel
          htmlFor="taskNotesReason"
          style={{ color: '#0000008a !important', margin: '10px 0px' }}
        >
          Add Notes for task type change
        </InputLabel>
        <TextareaAutosize
          error={getCleanNotes(value).length > 4000 ? 'Max 4000 characters allowed ' : ''}
          helperText={getCleanNotes(value).length}
          id="taskNotesReason"
          label="notes"
          maxRows={20}
          multiline
          name="notes"
          onBlur={(event) => {
            setNotes(event.target.value);
            setValue(event.target.value);
          }}
          onChange={(event) => setValue(event.target.value)}
          rows={6}
          style={{
            maxWidth: '100%',
            minWidth: '100%',
            fontSize: 'large',
            minHeight: '200px',
            overflow: 'scroll',
          }}
          type="text"
          value={getCleanNotes(value)}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
          <span>{getCleanNotes(value).length}</span>{' '}
          <span style={{ color: 'red' }}>
            {getCleanNotes(value).length > 4000 ? 'Max 4000 characters allowed ' : ''}
          </span>{' '}
        </div>
      </>
    );
  };

  const AccountField = (props) => {
    const [value, setValue] = useState(props.initialValue);
    setAccountRequired(props.required === 'Y');

    return (
      <TextField
        disabled={props.visible === 'N'}
        fullWidth
        helperText={selectedAccountName}
        label="Account#"
        margin="dense"
        name="manualAccount"
        onBlur={(event) => {
          const eValue = event.target.value;
          findAccountDetails(eValue);
          setValue(eValue);
        }}
        onChange={(event) => {
          const eValue = event.target.value;
          if (eValue && eValue.length >= 10) {
            findAccountDetails(eValue);
          }
          setValue(eValue);
        }}
        required={props.required === 'Y'}
        type="text"
        value={value}
        variant="standard"
      />
    );
  };

  const checkTaskTypeCategoryMapping = (category, type) => {
    if (type !== 'GENERAL') {
      const mapped = options.accountCategories.filter((name) => {
        return (
          (name.DEFAULT_TASK_BILLING_TYPE_REF === null ||
            name.DEFAULT_TASK_BILLING_TYPE_REF === type) &&
          name.CATEGORY_REF.toUpperCase() === category
        );
      });
      return mapped.length;
    } else {
      const mapped = options.generalCategories.filter((name) => {
        return (
          name.DEFAULT_TASK_BILLING_TYPE_REF === type &&
          name.CATEGORY_REF.toUpperCase() === category
        );
      });
      return mapped.length;
    }
  };

  const callerIsRequired = () => {
    return !(
      type === 'GENERAL' &&
      scope === 'HR/PAYROLL' &&
      (taskSourceRef === TaskTrackerSource.CLIENT_EMAIL || taskSourceRef === TaskTrackerSource.CHAT)
    );
  };

  const managerIsRequired = () => {
    return type === 'GENERAL';
  };

  const isInvalidCreateOrEditForm = () => {
    const invalid = description.length > 4000;
    if (type !== 'GENERAL') {
      const accountNumber =
        account && account.length >= 1
          ? account?.replaceAll('-', '')
          : manualAccount?.replaceAll('-', '');
      return (
        invalid ||
        category === '' ||
        taskSourceRef === '' ||
        (callerIsRequired() && caller === '') ||
        (managerIsRequired() && managerId === '') ||
        (accountNumber === '' && accountRequired) ||
        type === ''
      );
    } else if (type === 'GENERAL') {
      return (
        invalid ||
        (callerIsRequired() && caller === '') ||
        category === '' ||
        (managerIsRequired() && managerId === '')
      );
    }
  };

  const isInvalidTaskFormForComplete = () => {
    return description.length > 4000 || category === '' || description === '';
  };

  const handleSave = () => {
    var task = {};
    const fflag = followUpFlag ? 'Y' : 'N';
    switch (mode) {
      case 'add':
        task = {
          taskType: type,
          category: category,
          taskSourceRef: taskSourceRef,
          dueDate: dueDate ? moment(dueDate).format('DD-MMM-YY hh:mm:ss').toUpperCase() : null,
          accountNumber:
            account && account.length >= 1
              ? account?.replaceAll('-', '')
              : manualAccount?.replaceAll('-', ''),
          description: description,
          caller: caller,
          managerId: managerId,
          taskScope: scope,
        };
        if (type !== 'GENERAL') {
          if (task.category === '') {
            setContextText('Category is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Category information is needed to submit a task',
              }),
            );
            return;
          } else if (task.taskSourceRef === '') {
            setContextText('Source is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Source information is needed to submit a task',
              }),
            );
            return;
          }
          if (callerIsRequired() && caller === '') {
            setContextText('Caller is required');
            dispatch(setPortalSnackInfo({ severity: 'error', message: 'Caller is required' }));
            return;
          } else if (task.accountNumber === '' && accountRequired) {
            setContextText('Account information is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Account information is needed to submit a task',
              }),
            );
            return;
          } else if (type === '') {
            setContextText('Task Type is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Task Type information is needed to submit a task',
              }),
            );
            return;
          }
        } else if (type === 'GENERAL') {
          if (callerIsRequired() && caller === '') {
            setContextText('Caller is required');
            dispatch(setPortalSnackInfo({ severity: 'error', message: 'Caller is required' }));
            return;
          }
        }
        if (description.length > 4000) {
          setContextText('Description cannot be more than 4000 characters');
          return;
        }

        dispatch(createTask(task));
        setType('');
        setScope('');
        setManualAccount('');
        setCompany('');
        setDescription('');
        setSelAccount('');
        setTaskSourceRef('');
        setManagerId('');
        setDueDate(null);
        break;
      case 'edit':
        task = {
          category: category,
          description: DOMPurify.sanitize(getCleanDescription(description)),
          caller: caller,
          dueDate: dueDate ? moment(dueDate).format('DD-MMM-YY hh:mm:ss').toUpperCase() : null,
          taskType: type,
          accountNumber:
            account && account.length >= 1
              ? account?.replaceAll('-', '')
              : manualAccount
                ? manualAccount?.replaceAll('-', '')
                : '',
          notes: notes,
          taskSourceRef: taskSourceRef,
          managerId: managerId,
        };

        if (type !== 'GENERAL') {
          if (task.category === '') {
            setContextText('Category is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Category information is needed to submit a task',
              }),
            );
            return;
          } else if (task.taskSourceRef === '' || task.taskSourceRef === null) {
            setContextText('Source is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Source information is needed to submit a task',
              }),
            );
            return;
          }
          if (callerIsRequired() && caller === '') {
            setContextText('Caller is required');
            dispatch(setPortalSnackInfo({ severity: 'error', message: 'Caller is required' }));
            return;
          } else if (task.accountNumber === '' && accountRequired) {
            setContextText('Account information is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Account information is needed to submit a task',
              }),
            );
            return;
          } else if (type === '') {
            setContextText('Task Type is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Task Type information is needed to submit a task',
              }),
            );
            return;
          } else if (mode === 'edit' && type !== selectedTask.taskType && notes === '') {
            setContextText('Notes is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Please add notes for changing the task type',
              }),
            );
            return;
          }
        } else if (type === 'GENERAL') {
          if (task.category === '') {
            setContextText('Category is required');
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'Category information is needed to submit a task',
              }),
            );
            return;
          }
          if (callerIsRequired() && caller === '') {
            setContextText('Caller is required');
            dispatch(setPortalSnackInfo({ severity: 'error', message: 'Caller is required' }));
            return;
          }
        }
        if (description.length > 4000) {
          setContextText('Description cannot be more than 4000 characters');
          return;
        }

        if (mode === 'edit' && type !== selectedTask.taskType && notes === '') {
          setContextText('Add Notes for Task Type Change');
          return;
        }

        dispatch(updateTask(selectedTask.id, task, false));
        break;
      case 'continue':
        if (description.length > 4000) {
          setContextText('Description cannot be more than 4000 characters');
          return;
        }
        dispatch(continueTask(selectedTask.id, 'start', description));
        break;
      case 'pause':
        if (description.length > 4000) {
          setContextText('Description cannot be more than 4000 characters');
          return;
        }
        dispatch(pauseTask(selectedTask.id, 'pause', description));
        break;
      case 'complete':
        if (description === '' || description === null) {
          setContextText('Description is required');
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Description is required' }));
          return;
        }
        if (category === null || category === '') {
          setContextText('Category  are required');
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Category are required' }));
          return;
        }
        if (description.length > 4000) {
          setContextText('Description cannot be more than 4000 characters');
          return;
        }
        dispatch(
          stopTask(
            selectedTask.id,
            'stop',
            description,
            fflag,
            category,
            caller,
            type,
            taskSourceRef,
            dueDate,
          ),
        );
        break;
      case 'override':
        if (description.length > 4000) {
          setContextText('Description cannot be more than 4000 characters');
          return;
        }
        var atime, seconds;
        if (/^\d?\d:\d{2}:\d{2}$/.test(overrideTime)) {
          atime = overrideTime.split(':');
          seconds = parseInt(atime[0]) * 3600 + parseInt(atime[1]) * 60 + parseInt(atime[2]);
          if (seconds <= parseInt(selectedTask.actualTimeSecs)) {
            dispatch(overrideTask(selectedTask.id, 'overrideTime', description, seconds));
            setOverrideHelperText('');
          } else {
            setOverrideHelperText('override time cannot be more than actual time');
          }
        } else if (/^\d?\d:\d{2}$/.test(overrideTime)) {
          atime = overrideTime.split(':');
          seconds = parseInt(atime[0]) * 3600 + parseInt(atime[1]) * 60;
          if (seconds <= parseInt(selectedTask.actualTimeSecs)) {
            dispatch(overrideTask(selectedTask.id, 'overrideTime', description, seconds));
            setOverrideHelperText('');
          } else {
            setOverrideHelperText('override time cannot be more than actual time');
          }
        } else {
          setOverrideHelperText('Time format should be HH:MM:SS or HH:MM');
        }
        break;
      case 'sort':
        setOpen(false);
        break;
      default:
        break;
    }
  };

  const poperOpen = Boolean(anchorEl);

  const chipClickConfirmation = () => {
    setDescription(quickDescription);
    setAnchorEl(null);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
    setQuickDescription('');
  };

  const chipClick = (event, value) => {
    if (description.length > 0) {
      setQuickDescription(value);
      setAnchorEl(event.currentTarget);
    } else {
      setQuickDescription(value);
      setDescription(value);
    }
  };

  const handleChange = (event) => {
    const typeVal = options.allCategories.filter(
      (item) => item.CATEGORY_REF === event.target.value,
    );
    switch (event.target.name) {
      case 'sortKey':
        dispatch(setTaskTrackerShortKey(event.target.value));
        break;
      case 'sortOrder':
        dispatch(setTaskTrackerShortOrder(event.target.value));
        break;
      case 'futureTask':
        setShowFutureTasks(!showFutureTasks);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'overrideTime':
        setOverrideTime(event.target.value);
        var atime, seconds;
        if (/^\d?\d:\d{2}:\d{2}$/.test(event.target.value)) {
          atime = event.target.value.split(':');
          seconds = parseInt(atime[0]) * 3600 + parseInt(atime[1]) * 60 + parseInt(atime[2]);
          if (seconds <= parseInt(selectedTask.actualTimeSecs)) {
            setOverrideHelperText('');
          } else {
            setOverrideHelperText('override time cannot be more than actual time');
          }
        } else if (/^\d?\d:\d{2}$/.test(event.target.value)) {
          atime = event.target.value.split(':');
          seconds = parseInt(atime[0]) * 3600 + parseInt(atime[1]) * 60;
          if (seconds <= parseInt(selectedTask.actualTimeSecs)) {
            setOverrideHelperText('');
          } else {
            setOverrideHelperText('override time cannot be more than actual time');
          }
        } else {
          setOverrideHelperText('Time format should be HH:MM:SS or HH:MM');
        }
        break;
      case 'type':
        setType(event.target.value);
        if (
          event.target.value !== 'ACCOUNT BILLABLE' &&
          event.target.value !== 'ACCOUNT UNBILLABLE'
        ) {
          dispatch(setSearchAccounts([]));
          dispatch(setSelectedSearchAccount(''));
        }
        break;
      case 'scope':
        setScope(event.target.value);
        // we reset category and task type on scope change
        setCategory('');
        setType('');
        break;
      case 'manager':
        setManagerId(event.target.value);
        break;
      case 'callerTxt':
        setCaller(event.target.value);
        break;
      case 'taskSourceRef':
        setTaskSourceRef(event.target.value);
        break;
      case 'category':
        setCategory(event.target.value);
        if (typeVal.length > 0) {
          setType(typeVal[0].DEFAULT_TASK_BILLING_TYPE_REF);
        }
        break;
      case 'company':
        setCompany(event.target.value);
        break;
      case 'followup':
        setFollowUpFlag(event.target.checked);
        break;
      case 'manualAccount':
        setManualAccount(event.target.value);
        dispatch(setSelectedSearchAccount(''));
        break;
      default:
        setType(event.target.value);
    }
  };

  const findAccountDetails = (accountNumber) => {
    if (accountNumber?.length >= 1) {
      dispatch(
        accountSearchForTaskTracker(
          '',
          accountNumber?.replaceAll('-', ''),
          accountNumber?.replaceAll('-', ''),
        ),
      );
    } else {
      setSelectedAccountName('');
      dispatch(setSelectedSearchAccount(''));
    }
  };

  const handleClickOpen = (choice, newTask) => {
    dispatch(setSelectedSearchAccount(''));
    var value = { ...newTask };
    if (
      mode === 'edit' &&
      choice !== 'sort' &&
      selectedTask &&
      selectedTask.id &&
      selectedTask.taskStatus !== TASK_STATUS.COMPLETED
    ) {
      var task = {
        category: category,
        description: DOMPurify.sanitize(getCleanDescription(description)),
        caller: caller,
        taskSourceRef: type === 'GENERAL' ? '' : taskSourceRef,
        dueDate: dueDate ? moment(dueDate).format('DD-MMM-YY hh:mm:ss').toUpperCase() : null,
        taskType: type,
        accountNumber:
          type === 'GENERAL'
            ? ''
            : account && account.length >= 1
              ? account?.replaceAll('-', '')
              : manualAccount
                ? manualAccount.replaceAll('-', '')
                : '',
        notes: notes,
        managerId: managerId,
      };

      if (description.length > 4000) {
        // setContextText("Description cannot be more than 4000 characters");
        //    return;
      } else if (task.category === '' || checkTaskTypeCategoryMapping(category, type) === 0) {
        // setContextText("Category is required");
        // dispatch(setPortalSnackInfo({severity:"error",message:"Category information is needed to submit a task"}));
        // return;
      } else if (task.taskSourceRef === '') {
        // setContextText("Source is required");
        // dispatch(setPortalSnackInfo({severity:"error",message:"Source information is needed to submit a task"}));
        // return;
      } else if (caller === '') {
        // setContextText("Caller is required");
        // dispatch(setPortalSnackInfo({severity:"error",message:"Caller is required"}));
        // return;
      } else if (task.accountNumber === '') {
        // setContextText("Account information is required");
        // dispatch(setPortalSnackInfo({severity:"error",message:"Account information is needed to submit a task"}));
        // return;
      } else if (type === '') {
        // setContextText("Task Type is required");
        // dispatch(setPortalSnackInfo({severity:"error",message:"Task Type information is needed to submit a task"}));
        // return;
      } else {
        dispatch(updateTask(selectedTask.id, task, true));
      }

      if (selectedTask.id === value.id) {
        value.category = category;
        value.caller = caller ? caller : '';
        value.description = DOMPurify.sanitize(description);
        value.taskSourceRef = taskSourceRef;
        value.dueDate = dueDate;
        value.taskType = type;
        value.accountNumber = task.accountNumber;
        value.scope = task.scope;
        value.managerId = task.managerId;
      }
    }
    setOpen(true);
    setMode(choice);
    setSelectedTask(value);
    const fflag = value.followUpFlag === 'Y';
    switch (choice) {
      case 'add':
        setTitle('Create Task');
        setCategory('');
        setType('');
        setScope('');
        setManualAccount('');
        setCompany('');
        setDescription('');
        setSelAccount('');
        setManagerId('');
        setCaller('');
        setTaskSourceRef('');
        setDueDate(null);
        setSelectedAccountName('');
        setContextText('Enter task details to create a new task');
        break;
      case 'edit':
        setTitle('Edit Task');
        setType(value.taskType);
        setScope(value.scope);
        setContextText('');
        setDescription(value.description ? value.description : '');
        setCaller(value.caller);
        setCategory(value.category);
        setQuickDescription('');
        setTaskSourceRef(value.taskSourceRef);
        setDueDate(value.dueDate);
        setManualAccount(value.accountNumber);
        setScope(value.taskScope);
        setManagerId(value.managerLogin);
        break;
      case 'pause':
        setTitle('Pause Task');
        setDescription(value.description ? value.description : '');
        setContextText('Do you wish to pause the task?');
        break;
      case 'continue':
        setTitle('Resume Task');
        setDescription(value.description ? value.description : '');
        setContextText('Do you wish to resume the task?');
        break;
      case 'complete':
        setType(value.taskType);
        setScope(value.scope);
        setTitle('Finish');
        setDescription(value.description ? value.description : '');
        setCaller(value.caller);
        setCategory(value.category);
        setFollowUpFlag(fflag);
        setContextText('Do you want to end this task?');
        setTaskSourceRef(value.taskSourceRef);
        setDueDate(value.dueDate);
        setManualAccount(value.accountNumber);
        setScope(value.taskScope);
        setManagerId(value.managerLogin);
        break;
      case 'override':
        setTitle('Override Actual Time');
        setDescription(value.description ? value.description : '');
        setOverrideTime('');
        setOverrideHelperText('Time format should be HH:MM:SS or HH:MM');
        setContextText('');
        break;
      case 'sort':
        setTitle('Sorting Options');
        setContextText('Choose sort by and order to sort tasks');
        break;
      default:
        break;
    }
  };

  const [expanded, setExpanded] = useState({});
  const [taskExpanded, setTaskExpanded] = useState({});

  const getSaveLabel = () => {
    if (mode === 'add') {
      return 'Create Task';
    } else if (mode === 'complete') {
      return 'Complete';
    } else if (mode === 'pause') {
      return 'Pause';
    } else if (mode === 'continue') {
      return 'Continue';
    } else {
      return 'Save';
    }
  };

  const handleClick = (event) => {
    setFindCollapse(true);
    dispatch(setSelectedSearchAccount(''));
  };

  const handleExpandClick = (value) => {
    var newExpanded = { ...expanded };
    if (newExpanded[value]) {
      newExpanded[value] = !newExpanded[value];
    } else {
      newExpanded[value] = true;
    }
    setExpanded(newExpanded);
  };

  const handleTaskExpandClick = (value) => {
    var newExpanded = { ...taskExpanded };
    if (newExpanded[value]) {
      newExpanded[value] = !newExpanded[value];
    } else {
      newExpanded[value] = true;
    }
    setTaskExpanded(newExpanded);
  };

  const getCleanDescription = (value) => {
    var txt = '';
    try {
      txt = decodeURIComponent(value);
    } catch (e) {
      txt = value;
    }
    return txt;
  };

  const getCleanNotes = (value) => {
    var txt = '';
    try {
      txt = decodeURIComponent(value);
    } catch (e) {
      txt = value;
    }
    return txt;
  };

  const getDescSubString = (value) => {
    var txt = '';
    try {
      txt = `${decodeURIComponent(value).substr(0, 100)}...`;
    } catch (e) {
      txt = `${value.substr(0, 100)}...`;
    }
    return txt;
  };

  useEffect(() => {
    dispatch(getTasks());
  }, [dispatch]);

  const handleFilterByTaskStatusType = (values) => {
    setSelectedTaskStatuses(values);
  };

  return (
    <StyledRoot className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonGroup color="primary">
                <PortalIconButton
                  aria-label="upload picture"
                  className={classes.buttonGroup}
                  color={displayType === 'grid' ? 'secondary' : 'primary'}
                  component="span"
                  onClick={() => handleDisplayChange('grid')}
                  size="medium"
                  variant="contained"
                >
                  <Tooltip disableFocusListener title="Grid">
                    <ViewCompactIcon />
                  </Tooltip>
                </PortalIconButton>
                <PortalIconButton
                  aria-label="upload picture"
                  color={displayType === 'list' ? 'secondary' : 'primary'}
                  component="span"
                  onClick={() => handleDisplayChange('list')}
                  size="medium"
                  variant="contained"
                >
                  <Tooltip disableFocusListener title="List">
                    <ListIcon />
                  </Tooltip>
                </PortalIconButton>
                <PortalIconButton aria-label="settings">
                  <Tooltip disableFocusListener title="Create New Task">
                    <AddIcon onClick={() => handleClickOpen('add', '')} />
                  </Tooltip>
                </PortalIconButton>

                <PortalIconButton aria-label="sort">
                  <Tooltip disableFocusListener title="Sort">
                    <SortIcon onClick={() => handleClickOpen('sort', '')} />
                  </Tooltip>
                </PortalIconButton>

                <PortalButton
                  href="https://oncallschedulemgmt.mapcom.local/home"
                  target="_blank"
                  variant="text"
                >
                  Oncall Tool
                </PortalButton>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <CardHeader
              subheader={contextText}
              subheaderTypographyProps={{ className: classes.subheader }}
              title={title}
            />
            <CardContent>
              <div>
                {mode === 'sort' && (
                  <TaskTrackerSort
                    classes={classes}
                    existingTaskStatusOrder={taskTrackerStatusOrder}
                    handleChange={handleChange}
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                  />
                )}

                {mode !== 'sort' && mode !== 'continue' && mode !== 'pause' && (
                  <FormControl className={classes.formControl} fullWidth variant="standard">
                    <InputLabel id="demo-simple-select-label" required>
                      Task Scope
                    </InputLabel>
                    <Select
                      disabled={mode !== 'add'}
                      id="demo-simple-select"
                      inputProps={{ readOnly: true }}
                      labelId="demo-simple-select-label"
                      name="scope"
                      native
                      onChange={handleChange}
                      value={scope}
                    >
                      <option value="" />
                      {options &&
                        options.scopes &&
                        options.scopes.map((element) => {
                          return (
                            <option key={element.CODE} value={element.CODE}>
                              {element.CODE}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}

                {(mode === 'add' ||
                  (mode === 'edit' && selectedTask.taskStatus !== TASK_STATUS.COMPLETED) ||
                  mode === 'complete') && (
                  <div>
                    <FormControl className={classes.formControl} fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label1" required>
                        Category
                      </InputLabel>
                      <Select
                        disabled={scope === ''}
                        id="demo-simple-select1"
                        labelId="demo-simple-select-label1"
                        name="category"
                        native
                        onChange={handleChange}
                        value={category}
                      >
                        <option value="" />

                        {options &&
                          options.allCategories &&
                          options.allCategories
                            .filter(
                              (name) => name.TASK_SCOPE === scope && name.DEPRECATED_FL !== 'Y',
                            )
                            .map((element) => {
                              return (
                                element.DESCRIPTION_TXT !== 'Other' && (
                                  <option value={element.CATEGORY_REF}>
                                    {element.DESCRIPTION_TXT.toUpperCase()}
                                  </option>
                                )
                              );
                            })}
                      </Select>
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label" required={mode === 'edit'}>
                        Task Type
                      </InputLabel>
                      <Select
                        disabled={mode !== 'edit'}
                        id="demo-simple-select"
                        inputProps={{ readOnly: true }}
                        labelId="demo-simple-select-label"
                        name="type"
                        native
                        onChange={handleChange}
                        value={type}
                      >
                        <option value="" />

                        {mode === 'add' &&
                          ['ACCOUNT BILLABLE', 'ACCOUNT UNBILLABLE', 'GENERAL'].map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}

                        {mode !== 'add' && type !== 'GENERAL'
                          ? ['ACCOUNT BILLABLE', 'ACCOUNT UNBILLABLE'].map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))
                          : ['GENERAL'].map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                      </Select>
                    </FormControl>

                    {type === 'GENERAL' && (
                      <FormControl className={classes.formControl} fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label1" required>
                          Managers
                        </InputLabel>
                        <Select
                          id="demo-simple-select1"
                          labelId="demo-simple-select-label1"
                          name="manager"
                          native
                          onChange={handleChange}
                          value={managerId}
                        >
                          <option value="" />
                          {options &&
                            options.managers &&
                            options.managers &&
                            options.managers.map((item) => {
                              return (
                                <option key={item.samAccountName} value={item.samAccountName}>
                                  {item.displayName}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    )}

                    {mode === 'edit' && type !== selectedTask.taskType && (
                      <NotesReason initialValue={notes} />
                    )}

                    {(type !== 'GENERAL' || scope === 'HR/PAYROLL') && (
                      <FormControl className={classes.formControl} fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label1" required>
                          Source
                        </InputLabel>
                        <Select
                          id="demo-simple-select1"
                          labelId="demo-simple-select-label1"
                          name="taskSourceRef"
                          native
                          onChange={handleChange}
                          value={taskSourceRef}
                        >
                          <option value="" />

                          {options &&
                            options.sources &&
                            options.sources.map((element) => {
                              return (
                                element.TASK_SOURCE_LABEL !== 'Other' && (
                                  <option value={element.TASK_SOURCE_VALUE}>
                                    {element.TASK_SOURCE_LABEL}
                                  </option>
                                )
                              );
                            })}
                          {options &&
                            options.sources &&
                            options.sources.map((element) => {
                              return (
                                element.TASK_SOURCE_LABEL === 'Other' && (
                                  <option value={element.TASK_SOURCE_VALUE}>
                                    {element.TASK_SOURCE_LABEL}
                                  </option>
                                )
                              );
                            })}
                        </Select>
                      </FormControl>
                    )}

                    <FormControl className={classes.formControl} fullWidth variant="standard">
                      <TextField
                        error={caller?.length > 250}
                        helperText="Max length : 250"
                        id="taskCallerField"
                        inputProps={{ maxLength: 250 }}
                        label={type === 'GENERAL' ? 'Agent Name' : 'Caller'}
                        name="callerTxt"
                        native
                        onChange={(event) => {
                          setCaller(event.target.value);
                        }}
                        required={callerIsRequired()}
                        type="text"
                        value={caller}
                        variant="standard"
                      />
                    </FormControl>
                  </div>
                )}

                {(mode === 'add' ||
                  (mode === 'edit' &&
                    selectedTask.taskStatus !== TASK_STATUS.COMPLETED &&
                    type !== 'GENERAL')) && (
                  <div>
                    <FormControl className={classes.formControl} fullWidth variant="standard">
                      {options &&
                        options.allCategories &&
                        options.allCategories
                          .filter(
                            (name) => name.CATEGORY_REF === category && name.TASK_SCOPE === scope,
                          )
                          .map((element, index) => {
                            return (
                              <AccountField
                                initialValue={account ? account : manualAccount}
                                key={index}
                                required={element.REQUIRES_ACNT_NUM_FL}
                                visible={element.ACNT_VISIBLE}
                              />
                            );
                          })}
                      {options &&
                        options.allCategories &&
                        options.allCategories
                          .filter(
                            (name) => name.CATEGORY_REF === category && name.TASK_SCOPE === scope,
                          )
                          .map((element, index) => {
                            return (
                              <PortalButton
                                color="primary"
                                disabled={element.ACNT_VISIBLE === 'N'}
                                key={index}
                                onClick={handleClick}
                                variant="contained"
                              >
                                Find
                              </PortalButton>
                            );
                          })}

                      {type !== 'GENERAL' && (
                        <Collapse in={findCollapse} timeout="auto" unmountOnExit>
                          <AccountLookup
                            mode="search"
                            showCancelledAccountDefaultValue={false}
                            showFilterCancelledAccountsCheckbox={true}
                          />
                        </Collapse>
                      )}
                    </FormControl>
                  </div>
                )}

                {(mode === 'add' || mode === 'edit' || mode === 'complete') && (
                  <>
                    <div style={{ width: '100%' }}>
                      <MyTextArea initialValue={description} />
                    </div>

                    {sentiScore && sentiScore > 1 && <EmojiEmotionsIcon />}
                    {sentiScore && sentiScore < -1 && <SentimentVeryDissatisfiedIcon />}

                    <div className={classes.chipHolder}>
                      {[
                        'Add Special Instructions/Temp Notes',
                        'Updating On Call',
                        'Agent Account Assistance',
                        'Directory Update',
                        'Client Complaint',
                        'Billing - Payment Call',
                        'Account Update',
                        'Account Research',
                        'Client Concern Follow-Up',
                        'Other',
                      ].map((element) => (
                        <Chip
                          avatar={<Avatar>{element.substr(0, 1)}</Avatar>}
                          color={element === quickDescription ? 'secondary' : 'primary'}
                          key={`chip${element}`}
                          label={element}
                          name="quickDescription"
                          onClick={(event) => chipClick(event, element)}
                          sx={{ mr: 1, mb: 1 }}
                          variant={element === quickDescription ? 'default' : 'outlined'}
                        />
                      ))}
                    </div>
                    <Popover
                      PaperProps={{ style: { padding: '16px' } }}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      onClose={handlePopperClose}
                      open={poperOpen}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Typography className={classes.typography}>
                        {' '}
                        Are you sure you want to replace ?{' '}
                      </Typography>
                      <PortalButton
                        color="primary"
                        onClick={chipClickConfirmation}
                        variant="filled"
                      >
                        Yes
                      </PortalButton>
                      <PortalButton color="secondary" onClick={handlePopperClose} variant="filled">
                        No
                      </PortalButton>
                    </Popover>
                  </>
                )}
              </div>

              {mode === 'override' && (
                <div>
                  <FormControl className={classes.formControl} fullWidth variant="standard">
                    <TextField
                      disabled
                      fullWidth
                      label="Actual Time"
                      margin="dense"
                      type="text"
                      value={toHHMMSS(selectedTask.actualTimeSecs)}
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} fullWidth variant="standard">
                    <TextField
                      fullWidth
                      helperText={<Typography color="secondary">{overrideHelperText}</Typography>}
                      label="Override Time"
                      margin="dense"
                      name="overrideTime"
                      onChange={handleChange}
                      type="text"
                      value={overrideTime}
                      variant="standard"
                    />
                  </FormControl>
                </div>
              )}

              {mode === 'complete' && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={followUpFlag}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      name="followup"
                      onChange={handleChange}
                    />
                  }
                  label="Follow Up"
                  variant="standard"
                />
              )}
            </CardContent>
            <CardActions disableSpacing>
              {mode !== 'sort' && (
                <PortalButton
                  color="primary"
                  disabled={
                    (mode == 'override' && overrideHelperText !== '') ||
                    ((mode === 'add' || mode === 'edit') && isInvalidCreateOrEditForm()) ||
                    (mode === 'complete' && isInvalidTaskFormForComplete())
                  }
                  onClick={handleSave}
                  sx={{ mr: 3 }}
                  variant="contained"
                >
                  {getSaveLabel()}
                </PortalButton>
              )}
              <PortalButton color="secondary" onClick={handleClose} variant="contained">
                {mode === 'sort' ? 'Close' : 'Cancel'}
              </PortalButton>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={4}>
          {loading && <CircularProgress />}
          <Grid container spacing={2} style={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <Box className={classes.taskTypeFilter}>
              <TaskTypeFilter
                propagateCheckboxStateChange={handleFilterByTaskStatusType}
                selectedTaskStatuses={selectedTaskStatuses}
              />
            </Box>

            {Object.entries(statusOrder)
              ?.sort((a, b) => a[1] - b[1])
              .map(([taskStatus], index) => {
                return (
                  <Fragment key={taskStatus + index}>
                    {tasks &&
                      tasks
                        .slice()
                        .filter((element) =>
                          showFutureTasks
                            ? element.dueDate !== null && element.taskStatus === 'NEW'
                            : element.dueDate === null || element.dueDate !== null,
                        )
                        .filter((element) =>
                          selectedTaskStatuses[TASK_STATUS.ACTIVE] === false
                            ? element.taskStatus !== TASK_STATUS.ACTIVE
                            : true,
                        )
                        .filter((element) =>
                          selectedTaskStatuses[TASK_STATUS.PAUSED] === false
                            ? element.taskStatus !== TASK_STATUS.PAUSED
                            : true,
                        )
                        .filter((element) =>
                          selectedTaskStatuses[TASK_STATUS.COMPLETED] === false
                            ? element.taskStatus !== TASK_STATUS.COMPLETED
                            : true,
                        )
                        .filter((element) => element.taskStatus === taskStatus)
                        .sort((a, b) =>
                          sortOrder === 'asc'
                            ? a[sortKey] > b[sortKey]
                              ? 1
                              : -1
                            : b[sortKey] > a[sortKey]
                              ? 1
                              : -1,
                        )
                        .map((item) => (
                          <Grid item key={item.id} lg={displayType === 'grid' ? 4 : 12} xs={12}>
                            <Card
                              className={
                                selectedTask.id === item.id ? classes.activePaper : classes.paper
                              }
                            >
                              <CardHeader
                                action={
                                  <PortalIconButton
                                    aria-expanded={taskExpanded}
                                    aria-label="show more"
                                    className={clsx(classes.expand, {
                                      [classes.expandOpen]: taskExpanded,
                                    })}
                                    classes={{
                                      root: classes.root,
                                    }}
                                    color="primary"
                                    onClick={() => handleTaskExpandClick(item.id)}
                                    sx={{ color: '#fff' }}
                                  >
                                    <ExpandMoreIcon style={{ width: '50px' }} />
                                  </PortalIconButton>
                                }
                                avatar={<AssignmentIcon style={{ width: '50px' }} />}
                                className={setTaskHeaderClass(item)}
                                classes={{ content: classes.cardHeaderWrap }}
                                onClick={() => handleClickOpen('edit', item)}
                                subheader={
                                  item.description ? getDescSubString(item.description) : ''
                                }
                                subheaderTypographyProps={{ className: classes.subHeaderTitle }}
                                title={
                                  item.taskType === 'GENERAL'
                                    ? options &&
                                      options.managers &&
                                      options.managers &&
                                      options.managers
                                        .filter((name) => name.samAccountName === item.managerLogin)
                                        .map((val) => {
                                          let managerString = val.displayName;
                                          if (item.caller) {
                                            managerString += ` - ${item.caller}`;
                                          }
                                          managerString += ` - ${item.category}`;
                                          return managerString;
                                        })
                                    : item.companyTxt
                                      ? item.companyTxt
                                      : item.companyName
                                        ? `${item.companyName} (${item.accountNumber})`
                                        : item.taskType
                                }
                              />
                              <Collapse in={taskExpanded[item.id]} timeout="auto" unmountOnExit>
                                <CardContent onClick={() => handleClickOpen('edit', item)}>
                                  <TableContainer>
                                    <Table
                                      aria-label="a dense table"
                                      className={classes.table}
                                      size="small"
                                    >
                                      <TableRow>
                                        <TableCell>Task Id </TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">{item.id}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Toll Free Number </TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">
                                            {item.tollFreeNumber}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">{item.taskType}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Category</TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">{item.category}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      {item.taskType === 'GENERAL' && (
                                        <TableRow>
                                          <TableCell>Company </TableCell>
                                          <TableCell>
                                            {' '}
                                            <Typography variant="body2">
                                              {item.taskType === 'GENERAL'
                                                ? item.companyTxt
                                                  ? item.companyTxt
                                                  : item.companyName
                                                : `${
                                                    item.companyTxt
                                                      ? item.companyTxt
                                                      : item.companyName
                                                  }( ${item.accountNumber})`}{' '}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      <TableRow>
                                        <TableCell>
                                          {item.taskType === 'GENERAL' ? 'Agent Name' : 'Caller'}
                                        </TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">{item.caller}</Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Status</TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography
                                            className={setStatusClass(item.taskStatus)}
                                            variant="body2"
                                          >
                                            {item.taskStatus}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Created</TableCell>
                                        <TableCell>
                                          <Typography variant="body2">
                                            {' '}
                                            {moment
                                              .utc(item.creation)
                                              .tz(defaultTimezone)
                                              .format('MMM D YYYY hh:mm A z')}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Completed</TableCell>
                                        <TableCell>
                                          {item.completed && (
                                            <Typography variant="body2">
                                              {' '}
                                              {moment
                                                .utc(item.completed)
                                                .tz(defaultTimezone)
                                                .format('MMM D YYYY hh:mm A z')}
                                            </Typography>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Actual/Override Time</TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">
                                            {' '}
                                            {toHHMMSS(item.actualTimeSecs)}/
                                            {item.manualTimeSecs
                                              ? toHHMMSS(item.manualTimeSecs)
                                              : ''}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Follow Required</TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">
                                            {item.followUpFlag}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Source</TableCell>
                                        <TableCell>
                                          {' '}
                                          <Typography variant="body2">
                                            {options &&
                                              options.sources &&
                                              options.sources
                                                .filter(
                                                  (source) =>
                                                    source.TASK_SOURCE_VALUE === item.taskSourceRef,
                                                )
                                                .map((sourceLabel) =>
                                                  sourceLabel.TASK_SOURCE_LABEL.toUpperCase(),
                                                )}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      {item && item.dueDate && (
                                        <TableRow>
                                          <TableCell>Due Date</TableCell>
                                          <TableCell>
                                            {' '}
                                            <Typography variant="body2">
                                              {moment(item.dueDate)
                                                .format('MM/DD/YYYY')
                                                .toUpperCase()}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Table>
                                  </TableContainer>
                                </CardContent>
                                <CardActions>
                                  <PortalIconButton
                                    color="primary"
                                    onClick={() => handleClickOpen('edit', item)}
                                    title="Edit Task"
                                  >
                                    <EditIcon />
                                  </PortalIconButton>
                                  <PortalIconButton
                                    color="primary"
                                    disabled={
                                      item.taskStatus === TASK_STATUS.COMPLETED ||
                                      item.taskStatus === TASK_STATUS.ACTIVE
                                    }
                                    onClick={() => handleClickOpen('continue', item)}
                                    title="Continue"
                                  >
                                    <PlayArrow />
                                  </PortalIconButton>
                                  <PortalIconButton
                                    color="primary"
                                    disabled={
                                      item.taskStatus === TASK_STATUS.COMPLETED ||
                                      item.taskStatus === TASK_STATUS.PAUSED
                                    }
                                    onClick={() => handleClickOpen('pause', item)}
                                    title="Pause"
                                  >
                                    <PauseCircleFilledIcon />
                                  </PortalIconButton>
                                  <PortalIconButton
                                    color="primary"
                                    disabled={item.taskStatus === TASK_STATUS.COMPLETED}
                                    onClick={() => handleClickOpen('complete', item)}
                                    title="Complete"
                                  >
                                    <CheckCircleIcon />
                                  </PortalIconButton>
                                  <PortalIconButton
                                    color="primary"
                                    disabled={
                                      (item.taskStatus === TASK_STATUS.COMPLETED &&
                                        moment() > moment(item.completed).endOf('day')) ||
                                      item.taskStatus === TASK_STATUS.ACTIVE ||
                                      item.taskStatus === TASK_STATUS.PAUSED
                                    }
                                    onClick={() => handleClickOpen('override', item)}
                                    title="Override Actual Time"
                                  >
                                    <UpdateIcon />
                                  </PortalIconButton>

                                  <PortalIconButton
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                    className={clsx(classes.expand, {
                                      [classes.expandOpen]: expanded,
                                    })}
                                    color="primary"
                                    onClick={() => handleExpandClick(item.id)}
                                  >
                                    <ExpandMoreIcon />
                                  </PortalIconButton>
                                </CardActions>
                                <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
                                  <CardContent>
                                    <TextField
                                      className={classes.disableTextArea}
                                      disabled
                                      fullWidth
                                      multiline
                                      value={getCleanDescription(item.description)}
                                      variant="standard"
                                    />

                                    <TableContainer
                                      className={classes.tableContainer}
                                      component={Paper}
                                    >
                                      <Table aria-label="simple table" className={classes.table}>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Event Type</TableCell>
                                            <TableCell align="right">Time Stamp</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {item.events &&
                                            item.events.map((row) => (
                                              <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                  {row.eventType}
                                                </TableCell>
                                                <TableCell align="right">
                                                  {moment(row.creation)
                                                    .tz(defaultTimezone)
                                                    .format('MMM D YYYY hh:mm A z')}
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </CardContent>
                                </Collapse>
                              </Collapse>
                            </Card>
                          </Grid>
                        ))}
                  </Fragment>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};
export default TaskTracker;
