import { Button, CardHeader, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Checkbox } from '@mui/material';
import moment from 'moment';
import { timezoneSelector } from '../../../app/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRecordingById,
  recordingDownloadListSelector,
  setRecordingDownloadList,
} from '../messageSlice';
import { Fragment, useState } from 'react';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'Recording';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  grid: `${PREFIX}-grid`,
  cardTop: `${PREFIX}-cardTop`,
  small: `${PREFIX}-small`,
  cardBottom: `${PREFIX}-cardBottom`,
  cardBottomHeader: `${PREFIX}-cardBottomHeader`,
  cardBottomBody: `${PREFIX}-cardBottomBody`,
  actions: `${PREFIX}-actions`,
  item: `${PREFIX}-item`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },

  [`& .${classes.card}`]: {
    flexGrow: 1,
    padding: 0,
    borderRadius: '16px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 11%)',
  },

  [`& .${classes.cardContent}`]: {
    padding: theme.spacing(1),
    paddingBottom: '0px !important',
  },

  [`& .${classes.grid}`]: {
    padding: 0,
  },

  [`& .${classes.cardTop}`]: {
    minHeight: theme.spacing(6),
    maxHeight: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    background: '#01adc200',
    padding: theme.spacing(2),
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.cardBottom}`]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#edf5f6',
  },

  [`& .${classes.cardBottomHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.cardBottomBody}`]: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.item}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
}));

export default function Recording({ content, size, downloadble }) {
  const dispatch = useDispatch();
  const defaultTimezone = useSelector(timezoneSelector);
  const checked = useSelector(recordingDownloadListSelector);

  const handlePlay = (event) => {
    dispatch(getRecordingById(content.id));
  };

  const toHHMMSS = (value) => {
    value = isNaN(value) ? 0 : value;
    var sec_num = parseInt(Math.round(value), 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (hours === '00') {
      return `${minutes}:${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (newChecked.length >= 100 && currentIndex === -1) {
      return;
    }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    dispatch(setRecordingDownloadList(newChecked));
    console.log('*** selected items ***', newChecked);
  };

  return (
    <StyledGrid className={classes.root} item md={size} xs={12}>
      {content.audioAvailable && (
        <Card className={classes.card}>
          <CardHeader
            avatar={
              downloadble ? (
                <Checkbox
                  checked={checked.indexOf(content.id) !== -1}
                  edge="end"
                  inputProps={`aria-labelledby${content.id}`}
                  onChange={handleToggle(content.id)}
                />
              ) : null
            }
            subheader={
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <Typography variant="caption">
                  {moment(content.callOfferTime)
                    .tz(defaultTimezone)
                    .format('MMM DD,YYYY hh:mm A z')}
                </Typography>

                <Typography variant="caption">({toHHMMSS(content.durationSeconds)})</Typography>
              </div>
            }
            title={content.ani ? content.ani : content.line}
          />

          <CardContent className={classes.cardContent}>
            {content.audioAvailable && <PortalButton onClick={handlePlay}>Play</PortalButton>}

            {content.audioAvailable && content.audio && content.audio.length > 0 && (
              <audio controls controlsList="nodownload noremoteplayback" style={{ width: '100%' }}>
                <source src={`data:audio/ogg;base64,${content.audio}`} />
                The “audio” tag is not supported by your browser. Click [here] to download the sound
                file.
              </audio>
            )}
          </CardContent>
          {/* <CardActions className={classes.actions}>
                    <PortalIconButton color="primary" title="Edit"><EditIcon onClick={() => handleClickOpen("edit")} /></PortalIconButton>
                    <PortalIconButton color="primary" title="Delete"><DeleteIcon  onClick={() => handleClickOpen("delete")} /></PortalIconButton>
            </CardActions> */}
        </Card>
      )}
    </StyledGrid>
  );
}
