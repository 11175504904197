import { Box } from '@mui/material';
import PortalAccountLevelReceiverFilter from '../../shared/components/portalAccountLevelReceiverFilter';

const AlarmSelectReceiverDialog = ({
  handleAccountChange,
  handleReceiverChange,
  handleSearchByChange,
}) => {
  return (
    <Box sx={{ paddingTop: 5 }}>
      <PortalAccountLevelReceiverFilter
        handleAccountChange={handleAccountChange}
        handleReceiverChange={handleReceiverChange}
        propagateSearchByChange={handleSearchByChange}
      />
    </Box>
  );
};

export default AlarmSelectReceiverDialog;
