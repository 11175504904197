import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../PortalApi';
import { setPortalSnackInfo } from '../../app/authSlice';
import { saveAs } from 'file-saver';

const startState = {
  selCallerIdAccount: {},
};

export const accountVerifiedCallerIdSlice = createSlice({
  name: 'accountVerifiedCallerId',
  initialState: startState,
  reducers: {
    setSelAccountVerifiedCallerId: (state, action) => {
      state.selCallerIdAccount = action.payload;
    },
  },
});

export const { setSelAccountVerifiedCallerId } = accountVerifiedCallerIdSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selCallerIdAccountSelector = (state) =>
  state.accountVerifiedCallerId.selCallerIdAccount;

export default accountVerifiedCallerIdSlice.reducer;
