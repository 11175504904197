import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import PortalButton from '../../shared/components/PortalButton';
import { useState } from 'react';
import PortalDialog from '../../shared/components/PortalDialog';
import AlarmCreateEditOrView from './AlarmCreateEditOrView';
import AddIcon from '@mui/icons-material/Add';
import { alarmLoadingStateSelector, createReceiverAlarm } from '../alarmSlice';
import { setPortalSnackInfo } from '../../../app/authSlice';
import {
  AlarmObjectStruct,
  syncReceiverAlarmCreateToFirestore,
  validateAlarmCreateOrEditForm,
} from '../../../utils/alarmUtil';
import { isLocalOrDevEnvironment } from '../../../utils/urls';

export default function AlarmCreateButton() {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({});
  const [openCreateAlarmDialog, setOpenCreateAlarmDialog] = useState(false);
  const loadingState = useSelector(alarmLoadingStateSelector);

  const handleCreateAlarmConfirmation = () => {
    const { isValid, errorMessage } = validateAlarmCreateOrEditForm(payload);
    if (isValid) {
      dispatch(
        createReceiverAlarm(payload[AlarmObjectStruct.receiver_id], payload, (res) => {
          if (res && res?.id) {
            setOpenCreateAlarmDialog(false);
            //TODO: remove this from client side and put it in backend
            if (isLocalOrDevEnvironment()) {
              syncReceiverAlarmCreateToFirestore({
                receiverId: payload[AlarmObjectStruct.receiver_id],
                alarmId: res.id,
              });
            }
          }
        }),
      );
    } else {
      dispatch(setPortalSnackInfo({ severity: 'error', message: errorMessage }));
    }
  };

  const handleCloseCreateAlarmDialog = () => {
    setOpenCreateAlarmDialog(false);
  };

  const handleOpenCreateAlarmDialog = () => {
    setOpenCreateAlarmDialog(true);
  };

  const handleFormDataChange = (data) => {
    setPayload(data);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PortalButton color="primary" onClick={handleOpenCreateAlarmDialog} variant="contained">
          Create Alarm <AddIcon />
        </PortalButton>
      </Box>

      {openCreateAlarmDialog && (
        <PortalDialog
          ComponentProps={{
            propagateFormDataChange: handleFormDataChange,
            createEditOrViewType: 'create',
          }}
          ComponentToRender={AlarmCreateEditOrView}
          actionItems={[
            {
              autoFocus: true,
              onClick: handleCreateAlarmConfirmation,
              color: 'primary',
              text: 'Create Alarm',
              variant: 'contained',
              disabled: loadingState?.loadingCreateReceiverAlarm,
            },
            {
              autoFocus: false,
              onClick: handleCloseCreateAlarmDialog,
              color: 'secondary',
              text: 'Cancel',
              variant: 'contained',
              disabled: loadingState?.loadingCreateReceiverAlarm,
            },
          ]}
          fullScreen={false}
          fullWidth={true}
          maxWidth="lg"
          open={openCreateAlarmDialog}
          propagateOnClose={handleCloseCreateAlarmDialog}
          showAppBar={false}
          title="Create Alarm"
        />
      )}
    </>
  );
}
