import { useEffect, useState } from 'react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { styled } from '@mui/material/styles';
import ReactWordcloud from 'react-wordcloud';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  wordCloudPatternSelector,
  setWordCloudPattern,
  wordCloudHiddenWordsSelector,
} from '../sentimentMessageSlice';

const PREFIX = 'SentimentWordClouds';

const classes = {
  root: `${PREFIX}-root`,
  speaker: `${PREFIX}-speaker`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    fontWeight: 'bold',
    minWidth: '100px',
  },
  [`& .${classes.speaker}`]: {
    fontWeight: 'bold',
    minWidth: '100px',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    fontWeight: 'bold',
    padding: '5px 10px',
  },
  body: {
    fontSize: 14,
    padding: '5px 10px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export default function SentimentWordClouds({
  words,
  messages,
  msgKey,
  displayNote,
  displayTranscription,
  colorCodePerSpeaker,
}) {
  const dispatch = useDispatch();
  const pattern = useSelector(wordCloudPatternSelector);
  const wordCloudHiddenWords = useSelector(wordCloudHiddenWordsSelector);
  const [filteredWordClouds, setFilteredWordClouds] = useState(words);

  useEffect(() => {
    try {
      if (wordCloudHiddenWords && Array.isArray(wordCloudHiddenWords)) {
        const toHideWords = wordCloudHiddenWords.map((item) => item?.Word?.toLowerCase());
        const wordClouds = words.filter((item) => !toHideWords.includes(item?.text?.toLowerCase()));
        setFilteredWordClouds(wordClouds);
      } else {
        setFilteredWordClouds(words);
      }
    } catch (error) {
      console.error('Error', error);
      setFilteredWordClouds(words);
    }
  }, [words, wordCloudHiddenWords]);

  useEffect(() => {
    dispatch(setWordCloudPattern(''));
  }, []);

  const callbacks = {
    onWordClick: (word) => {
      dispatch(setWordCloudPattern(word.text));
    },
    getWordTooltip: (word) => `${word.text} (${word.value})`,
  };

  const getSpeakerName = (speaker) => {
    return `[Speaker ${speaker?.trim()}]`;
  };

  return (
    <StyledRoot className={classes.root}>
      {filteredWordClouds && filteredWordClouds?.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <ReactWordcloud
              callbacks={callbacks}
              maxWords={50}
              options={{
                colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
                rotations: 1,
                rotationAngles: [0],
                fontSizes: [15, 80],
                transitionDuration: 1000,
                scale: 'sqrt',
                spiral: 'archimedean',
                enableTooltip: true,
                deterministic: true,
                fontFamily: 'impact',
                fontStyle: 'normal',
                fontWeight: 'normal',
              }}
              words={filteredWordClouds}
            />
          </Grid>
          {displayTranscription &&
            displayNote &&
            filteredWordClouds &&
            filteredWordClouds?.length &&
            messages &&
            msgKey &&
            messages?.length > 0 && (
              <Grid item xs>
                <Typography component="i" style={{ marginLeft: '10px' }} variant="caption">
                  Note: Click on word to highlight it at messages below.
                </Typography>{' '}
              </Grid>
            )}
          {displayTranscription && messages && msgKey && messages?.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="Call Transcription" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Speaker</StyledTableCell>
                    <StyledTableCell>Text</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages.map((msg, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        className={classes.speaker}
                        style={{
                          color:
                            colorCodePerSpeaker && colorCodePerSpeaker[msg?.speaker?.trim()]
                              ? colorCodePerSpeaker[msg?.speaker?.trim()]
                              : 'black',
                        }}
                      >
                        {' '}
                        {getSpeakerName(msg?.speaker?.trim())}{' '}
                      </StyledTableCell>
                      <StyledTableCell
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            pattern && pattern.length > 0
                              ? msg[msgKey].replace(
                                  new RegExp(pattern, 'gi'),
                                  '<span style="background-color:yellow;font-weight:bold;font-size:14">$&</span>',
                                )
                              : msg[msgKey],
                          ),
                        }}
                      />
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      )}
    </StyledRoot>
  );
}
