export const getEncodedQueryParamString = (obj) => {
  const queryParams = [];
  for (const [key, value] of Object.entries(obj)) {
    if (key && (value != null || value != undefined))
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }
  return queryParams.join('&');
};

export const getURLSearchParamsString = (obj) => {
  const definedParamObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (key && (value != null || value != undefined)) definedParamObj[key] = value;
  }
  return new URLSearchParams(definedParamObj);
};
