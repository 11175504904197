import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { FileMimeTypes, XLSXWritingOptionBookType, XLSXWritingOptionType } from './constants';

export const exportJsonDataToCSVFile = ({
  jsonData,
  filename,
  fileExtension,
  fileMimeType,
  charset,
}) => {
  saveDataAsFile(XLSXWritingOptionBookType.csv, {
    jsonData,
    filename,
    fileExtension: fileExtension ? fileExtension : 'csv',
    fileMimeType: fileMimeType
      ? fileMimeType
      : FileMimeTypes.applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet,
    charset,
  });
};

export const saveDataAsFile = (
  bookType,
  { jsonData, filename, fileExtension, fileMimeType, charset },
) => {
  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType, type: XLSXWritingOptionType.array });
  const data = new Blob([excelBuffer], {
    type: `${fileMimeType};${charset ? charset : 'charset=UTF-8'}`,
  });
  saveAs(data, `${filename}.${fileExtension}`);
};
