import { styled } from '@mui/material/styles';
import TableChart from '../../ui/TableChart';

const PREFIX = 'CallCountChart';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },
}));

const CallCountChart = (props) => {
  return (
    <StyledRoot className={classes.root}>
      {props.chartData && props.chartData.series && props.chartData.series.length > 0 && (
        <TableChart content={props.chartData} heading="Time Usage" />
      )}
    </StyledRoot>
  );
};

export default CallCountChart;
