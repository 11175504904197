import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Grid, ButtonGroup, IconButton, CircularProgress } from '@mui/material';
import Chart from 'react-apexcharts';
import Tooltip from '@mui/material/Tooltip';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'TableChart';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
  multiColumnHeader: `${PREFIX}-multiColumnHeader`,
  yearColumn: `${PREFIX}-yearColumn`,
  tableHolder: `${PREFIX}-tableHolder`,
  table: `${PREFIX}-table`,
  link: `${PREFIX}-link`,
  controlBar: `${PREFIX}-controlBar`,
  paper: `${PREFIX}-paper`,
  switchBase: `${PREFIX}-switchBase`,
  keyVals: `${PREFIX}-keyVals`,
  tooltip: `${PREFIX}-tooltip`,
  tooltipHolder: `${PREFIX}-tooltipHolder`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  title: `${PREFIX}-title`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.container}`]: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 60,
    maxWidth: 200,
  },

  [`& .${classes.multiColumnHeader}`]: {
    background: theme.palette.primary.main,
    color: 'white',
    border: '1px solid rgba(224, 224, 224, 1)',
  },

  [`& .${classes.yearColumn}`]: {
    background: theme.palette.primary.main,
    color: 'white',
    border: '1px solid rgba(224, 224, 224, 1)',
  },

  [`& .${classes.tableHolder}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    borderCollapse: 'unset',
    maxWidth: '100vw',
    overflow: 'auto',
  },

  [`& .${classes.link}`]: {
    color: 'white',
    fontStyle: 'italic',
    padding: theme.spacing(3),
    textDecoration: 'underline',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.controlBar}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  [`&.${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: theme.spacing(2),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.switchBase}`]: {
    color: '#ff0000 !important',
  },

  [`& .${classes.keyVals}`]: {
    color: 'white',
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),
    textDecoration: 'none',
    textAlign: 'left',
    fontWeight: 800,
  },

  [`& .${classes.tooltip}`]: {
    padding: theme.spacing(1),
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
  },

  [`& .${classes.tooltipHolder}`]: {
    padding: theme.spacing(3),
    textAlign: 'center',
    height: 'auto',
    '&:hover': {
      color: '#45b6c3',
    },
  },

  [`& .${classes.buttonGroup}`]: {
    border: 0,
  },

  [`& .${classes.title}`]: {
    fontSize: '14px',
    fontWeight: 600,
  },
}));

export default function TableChart(props) {
  const [chartToggle, setChartToggle] = useState(false);
  const [count, setCount] = useState(1);
  const [chartType, setChartType] = useState('area');
  const [content, setContent] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (props.content) {
      const data = {
        series: [],
        options: {
          colors: ['#546E7A', '#E91E63'],
          chart: {
            height: 150,
            stacked: chartType === 'bar',
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              export: {
                png: {
                  filename: props.heading,
                },
                svg: {
                  filename: props.heading,
                },
                csv: {
                  filename: props.heading,
                },
              },
            },
          },
          xaxis: {
            type: 'category',
            labels: {
              formatter: function (value, timestamp) {
                if (value) {
                  return moment.tz(new Date(value), 'America/New_York').format('DD MMM');
                }
              },
            },
            tickPlacement: 'on',
            categories: [],
          },
          legend: {
            position: 'bottom',
          },
          fill: {
            opacity: 1,
          },
        },
      };
      props.content.series.forEach((element) => {
        var newSeries = { ...element };
        newSeries.name = t(element.name);
        data.series.push(newSeries);
      });
      data.options.xaxis.categories = props.content.categories;
      if (chartType === 'bar') {
        data.options.plotOptions = {
          bar: {
            horizontal: false,
            hideOverflowingLabels: true,
          },
        };
      }

      data.categories = props.content.categories;
      setContent(data);
    }
  }, [props.content, chartType]);

  const handleChartChange = (value) => {
    setChartType(value);
    if (value === 'table') {
      setChartToggle(true);
    } else {
      setChartToggle(false);
      setCount(count + 1);
    }
  };

  return (
    <StyledPaper className={classes.paper} elevation={2}>
      <div className={classes.controlBar}>
        <Typography className={classes.title} color="primary" variant="overline">
          {t(props.heading)}
        </Typography>
        <div style={{ display: 'flex', verticalAlign: 'center', flexWrap: 'wrap' }}>
          <ButtonGroup color="primary">
            <PortalIconButton
              aria-label="upload picture"
              className={classes.buttonGroup}
              color={chartType === 'bar' ? 'secondary' : 'primary'}
              component="span"
              onClick={() => handleChartChange('bar')}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title="Bar Chart">
                <BarChartIcon />
              </Tooltip>
            </PortalIconButton>
            <PortalIconButton
              aria-label="upload picture"
              color={chartType === 'area' ? 'secondary' : 'primary'}
              component="span"
              onClick={() => handleChartChange('area')}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title="Area Chart">
                <MultilineChartIcon />
              </Tooltip>
            </PortalIconButton>
            <PortalIconButton
              aria-label="upload picture"
              color={chartType === 'line' ? 'secondary' : 'primary'}
              component="span"
              onClick={() => handleChartChange('line')}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title="Line Chart">
                <ShowChartIcon />
              </Tooltip>
            </PortalIconButton>

            <PortalIconButton
              aria-label="upload picture"
              color={chartType === 'table' ? 'secondary' : 'primary'}
              component="span"
              onClick={() => handleChartChange('table')}
              size="medium"
              variant="contained"
            >
              <Tooltip disableFocusListener title="Table">
                <TableChartIcon />
              </Tooltip>
            </PortalIconButton>
          </ButtonGroup>
        </div>
      </div>

      {!content.categories && <CircularProgress />}

      {chartToggle && content.categories && content.categories.length > 0 && (
        <TableContainer className={classes.tableHolder} component={Paper}>
          <Table aria-label="table" className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.yearColumn}>{t('Date')}</TableCell>
                {content.series.map((item) => (
                  <TableCell align="center" className={classes.multiColumnHeader} key={item.name}>
                    {t(item.name)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {content &&
                content.categories &&
                content.categories.map((year, index) => (
                  <TableRow hover key={year}>
                    <TableCell component="th" scope="row">
                      {moment(new Date(year)).format('DD MMM')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {content.series[0].data[index]}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {content.series[1].data[index]}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Grid container spacing={2}>
        {!chartToggle && content && content.series && content.categories.length > 0 && (
          <Grid item lg={12} xs={12}>
            <Chart
              height={300}
              key={`myApexChart${count}`}
              options={content.options}
              series={content.series}
              type={chartType}
            />
          </Grid>
        )}
      </Grid>

      {content && content.series && content.categories.length === 0 && (
        <Typography variant="overline">{t('no records found')}</Typography>
      )}
    </StyledPaper>
  );
}
