import { styled } from '@mui/material/styles';

import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AccountNameSearch from './AccountNameSearch';
import AccountNumberSearch from './AccountNumberSearch';
import ReceiverNameSearch from './ReceiverNameSearch';
import ReceiverNumberSearch from './ReceiverNumberSearch';
import { selectedAccountSelector } from '../../../alarms/alarmSlice';
import AccountReceiverSearch from './AccountReceiverSearch';
import {
  AccountLevelReceiverSearchOptions,
  AccountLevelReceiverSearchOptionValues,
} from '../../../../utils/accountUtil/constants';
const PREFIX = 'PortalAccountLevelReceiverFilter';

const classes = {};

const StyledGrid = styled(Grid)(({ theme }) => ({}));

export default function PortalAccountLevelReceiverFilter({
  propagateSearchByChange,
  handleAccountChange,
  handleReceiverChange,
  sx,
}) {
  const [searchBy, setSearchBy] = useState(AccountLevelReceiverSearchOptionValues.receiverNumber);
  const selectedAccount = useSelector(selectedAccountSelector);

  const handleSearchByChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setSearchBy(value);
    } else {
      setSearchBy(AccountLevelReceiverSearchOptionValues.receiverNumber);
    }
  };

  useEffect(() => {
    propagateSearchByChange(searchBy);
  }, [searchBy]);

  const defaultSx = {
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  };

  return (
    <Box sx={sx || defaultSx}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="searchBy">Search By</InputLabel>
        <Select id="searchBy" label="Search By" onChange={handleSearchByChange} value={searchBy}>
          {AccountLevelReceiverSearchOptions.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {searchBy === AccountLevelReceiverSearchOptionValues.accountName ? (
        <AccountNameSearch handleValueChange={handleAccountChange} />
      ) : null}

      {searchBy === AccountLevelReceiverSearchOptionValues.accountNumber ? (
        <AccountNumberSearch handleValueChange={handleAccountChange} />
      ) : null}

      {(searchBy === AccountLevelReceiverSearchOptionValues.accountName ||
        searchBy === AccountLevelReceiverSearchOptionValues.accountNumber) &&
      selectedAccount !== null ? (
        <AccountReceiverSearch
          handleValueChange={handleReceiverChange}
          selectedAccount={selectedAccount}
        />
      ) : null}

      {searchBy === AccountLevelReceiverSearchOptionValues.receiverName ? (
        <ReceiverNameSearch handleValueChange={handleReceiverChange} />
      ) : null}

      {searchBy === AccountLevelReceiverSearchOptionValues.receiverNumber ? (
        <ReceiverNumberSearch handleValueChange={handleReceiverChange} />
      ) : null}
    </Box>
  );
}
