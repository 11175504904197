import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, TableSortLabel, useTheme, Box } from '@mui/material';
import PropTypes from 'prop-types';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'AccountSummary';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-root`,
  title: `${PREFIX}-root`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  [`& .${classes.table}`]: {
    minWidth: 650,
  },
  [`& .${classes.title}`]: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function AccountSummary(props) {
  const [receiverSortKey, setReceiverSortKey] = useState('id');
  const [receiverSortOrder, setReceiverSortOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const { t } = useTranslation();

  const createSortHandler = (key, type) => {
    if (type === 'Receiver') {
      if (receiverSortKey === key) {
        setReceiverSortOrder(receiverSortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setReceiverSortOrder('asc');
      }
      setReceiverSortKey(key);
    }
  };

  return (
    <StyledPaper className={classes.root} elevation={2}>
      <Typography className={classes.title} variant="overline">
        {t('Account Summary')}
      </Typography>
      <TableContainer>
        <Table aria-label="table" className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {[
                { name: `${t('Account')}#`, key: 'name' },
                { name: t('Live Calls'), key: 'calls' },
                { name: t('Live Minutes'), key: 'minutes' },
                { name: t('Average Minutes Per Call'), key: 'avgMinutes' },
                { name: t('IVR Calls'), key: 'ivrCalls' },
                { name: t('IVR Minutes'), key: 'ivrMinutes' },
                { name: t('Avg IVR Minutes'), key: 'avgIvrMintues' },
              ].map((item) => (
                <TableCell align="center" className={classes.multiColumnHeader} key={item.key}>
                  {' '}
                  <TableSortLabel
                    active={receiverSortKey === item.key}
                    classes={{ active: classes.active, icon: classes.icon }}
                    direction={receiverSortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => createSortHandler(item.key, 'Receiver')}
                  >
                    {item.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.summary && props.summary.length === 0 && (
              <TableRow>
                <TableCell colSpan={12} scope="row" sx={{ textAlign: 'center' }}>
                  <Typography variant="overline">{t('no records found')}</Typography>
                </TableCell>
              </TableRow>
            )}

            {props.summary &&
              props.summary.length > 0 &&
              props.summary
                .slice()
                .sort((a, b) =>
                  receiverSortOrder === 'asc'
                    ? a[receiverSortKey] > b[receiverSortKey]
                      ? 1
                      : -1
                    : b[receiverSortKey] > a[receiverSortKey]
                      ? 1
                      : -1,
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="center" component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.calls}</TableCell>
                    <TableCell align="center">{row.minutes / 60}</TableCell>
                    <TableCell align="center">{(row.avgMinutes / 60).toFixed(2)}</TableCell>
                    <TableCell align="center">{row.ivrCalls}</TableCell>
                    <TableCell align="center">{row.ivrMinutes / 60}</TableCell>
                    <TableCell align="center">{(row.avgIvrMinutes / 60).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
}
