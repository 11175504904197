import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { timezoneSelector } from '../../../app/authSlice';
import moment from 'moment';
import { Box, ButtonGroup, Collapse, Paper } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import PortalButton from '../../shared/components/PortalButton';

const PREFIX = 'FuneralService';

const classes = {
  root: `${PREFIX}-root`,
  subheader: `${PREFIX}-subheader`,
  tableCell: `${PREFIX}-tableCell`,
  tableContainer: `${PREFIX}-tableContainer`,
  visitationHeader: `${PREFIX}-visitationHeader`,
  subTableHeader: `${PREFIX}-subTableHeader`,
  subTableCell: `${PREFIX}-subTableCell`,
  rowExpandedRoot: `${PREFIX}-rowExpandedRoot`,
  rowRoot: `${PREFIX}-rowRoot`,
  pending: `${PREFIX}-pending`,
  private: `${PREFIX}-private`,
  noservice: `${PREFIX}-noservice`,
  shiva: `${PREFIX}-shiva`,
  confirmed: `${PREFIX}-confirmed`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: '#ffffffb3',
    padding: theme.spacing(1),
    boxShadow: '#033d43 0px 1px 3px 0px',
    borderRadius: theme.spacing(2),
  },

  [`& .${classes.subheader}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.tableCell}`]: {
    //padding:'0px 0px 0px 8px',
  },

  [`& .${classes.tableContainer}`]: {
    flexGrow: 1,
  },

  [`& .${classes.visitationHeader}`]: {
    background: theme.palette.secondary.light,
  },

  [`& .${classes.subTableHeader}`]: {
    background: theme.palette.secondary.light,
  },

  [`& .${classes.subTableCell}`]: {
    flex: 1,
    width: '25%',
    wordWrap: 'pre',
  },

  [`& .${classes.rowExpandedRoot}`]: {
    background: theme.palette.primary.light,
    '& > *': {
      borderBottom: 'unset',
    },
    padding: theme.spacing(1),
  },

  [`& .${classes.rowRoot}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
    padding: theme.spacing(1),
  },

  [`& .${classes.pending}`]: {
    color: 'orange',
  },

  [`& .${classes.private}`]: {
    color: 'darkblue',
  },

  [`& .${classes.noservice}`]: {
    color: 'grey',
  },

  [`& .${classes.shiva}`]: {
    color: 'lightblue',
  },

  [`& .${classes.confirmed}`]: {
    color: 'green',
  },
}));

export default function FuneralService({ account, info, expanded, edit, printMe, deleteMe }) {
  const dispatch = useDispatch();
  const defaultTimezone = useSelector(timezoneSelector);
  const [expand, setExpand] = useState(false);

  const getClassByStatus = (status) => {
    if (status === 'Pending') {
      return classes.pending;
    } else if (status === 'Private') {
      return classes.private;
    } else if (status === 'Confirmed') {
      return classes.confirmed;
    } else if (status === 'No Service') {
      return classes.noservice;
    } else if (status === 'shiva') {
      return classes.shiva;
    }
    return classes['pending'];
  };

  return (
    <StyledCard className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="primary" gutterBottom>
          Deceased Name : {info.personName}
        </Typography>
        <Box className={classes.subheader}>
          <Typography align="left">
            Service Date :{' '}
            {info.serviceDate ? moment.utc(info.serviceDate).format('dddd MMM DD,YYYY') : 'NA'}
          </Typography>
          <Typography align="left">
            Service Time : {info.serviceTime ? info.serviceTime : 'NA'}
          </Typography>
          <Typography align="left" className={getClassByStatus(info.status)}>
            Service Status : {info.status}
          </Typography>
        </Box>

        <Collapse in={expanded || expand} timeout="auto" unmountOnExit>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="a dense table" className={classes.table} size="small">
              <TableHead className={classes.subTableHeader}>
                <TableRow>
                  <TableCell align="left" className={classes.subTableCell} colSpan={4}>
                    Service Information
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.subTableCell}>
                    Service Location
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.serviceLocation}
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    Disposition
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.disposition}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.subTableCell}>
                    Disposition Address
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.dispositionAddress}
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    Additional Information
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.addtInformation}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.subTableCell}>
                    Flowers
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.flowers}
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    Donations
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.donations}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.subTableCell}>
                    Limos Required
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.limos}
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    Hearses Required
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.hearses}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.visitationHeader}>
                  <TableCell align="left" className={classes.subTableCell} colSpan={4}>
                    Visitation Information
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.subTableCell}>
                    Visitation Date
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.visitationDate
                      ? moment.utc(info.visitationDate).format('dddd MMM DD,YYYY')
                      : 'NA'}{' '}
                    - {info.visitationTime ? info.visitationTime : 'NA'}
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    Visitation Location
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.visitationLocation}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.subTableCell}>
                    2nd Visitation Date
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.visitation2Date
                      ? moment.utc(info.visitation2Date).format('dddd MMM DD,YYYY')
                      : 'NA'}{' '}
                    - {info.visitation2Time ? info.visitation2Time : 'NA'}{' '}
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    2nd Visitation Location
                  </TableCell>
                  <TableCell align="left" className={classes.subTableCell}>
                    {info.visitation2Location}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </CardContent>
      <CardActions>
        <ButtonGroup>
          <PortalButton onClick={() => setExpand(!expand)} size="small">
            Details
          </PortalButton>
          <PortalButton onClick={() => edit(info)} size="small">
            Edit
          </PortalButton>
          <PortalButton onClick={() => deleteMe(info)} size="small">
            Delete
          </PortalButton>
          <PortalButton onClick={() => printMe(info)} size="small">
            Print
          </PortalButton>
        </ButtonGroup>
      </CardActions>
    </StyledCard>
  );
}
