import 'react-big-calendar/lib/css/react-big-calendar.css';

import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Fragment, useEffect, useState } from 'react';
import { loadingSelector, selAccountSelector, updateShiftAssignment } from '../accountSlice';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { portalUserSelector } from '../../../app/authSlice';
import DownloadOnCallScheduleContacts from './DownloadContacts';
import PortalButton from '../../shared/components/PortalButton';

const PREFIX = 'OncallCalendar';

const classes = {
  active: `${PREFIX}-active`,
  normal: `${PREFIX}-normal`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.active}`]: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },

  [`& .${classes.normal}`]: {
    color: 'black',
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
}));

moment.locale('en-GB');
const localizer = momentLocalizer(moment);

const RotationResource = ({ label }) => {
  return <Box sx={{ background: 'black', color: 'white' }}>{label}</Box>;
};

export default function OncallCalendar({
  account,
  schedule,
  start,
  end,
  content,
  legacy,
  count,
  handleDateSelection,
  noOfDays,
}) {
  const loading = useSelector(loadingSelector);

  const dispatch = useDispatch();
  const selAccount = useSelector(selAccountSelector);
  const [open, setOpen] = useState(false);

  const [openTour, setOpenTour] = useState(false);
  const [selShift, setSelShift] = useState({});
  const [assignedContact, setAssignedContact] = useState(
    legacy.contacts && legacy.contacts.length > 0 ? legacy.contacts[0].id : '',
  );
  const portalUser = useSelector(portalUserSelector);

  const defaultViewByNoOfDays = (value) => {
    if (value <= 1) {
      return 'day';
    } else if (value > 1 && value <= 7) {
      return 'week';
    } else {
      return 'month';
    }
  };

  const [selView, setSelView] = useState(defaultViewByNoOfDays(noOfDays));
  const [defaultStartDate, setDefaultStartDate] = useState(moment(start, 'YYYYMMDD').toDate());

  useEffect(() => {
    if (noOfDays >= 0) {
      setSelView(defaultViewByNoOfDays(noOfDays));
    }
  }, [start]);

  const handleClose = () => {
    setOpen(false);
    setAssignedContact('');
  };

  const handleChange = (event) => {
    setAssignedContact(event.target.value);
  };

  const handleSave = () => {
    if (assignedContact && assignedContact === 'U') {
      dispatch(
        updateShiftAssignment(
          selShift.id,
          { requestorName: `WEB:${portalUser.login}`, rotationNumber: selShift.rotationNumber },
          account,
          schedule,
          start,
          end,
        ),
      );
    } else {
      dispatch(
        updateShiftAssignment(
          selShift.id,
          {
            requestorName: `WEB:${portalUser.login}`,
            contactId: assignedContact,
            rotationNumber: selShift.rotationNumber,
          },
          account,
          schedule,
          start,
          end,
        ),
      );
    }
    setOpen(false);
  };

  const handleClick = (data, e, time) => {
    setOpen(!open);
    var ids = data.id.split('|');
    setSelShift(legacy.shifts[`${ids[0]}|${ids[1]}`]);
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    return {
      className: 'accepted',
      style: event.itemProps ? event.itemProps.style : {},
    };
  };

  const MyEvent = ({ event }) => {
    if (selView === 'month') {
      return <span>{`${event.title} - ${event.group}`}</span>;
    }
    return <span>{event.title}</span>;
  };

  const handlePrevNextChange = (value) => {
    var currentSelection;
    if (value === 'prev') {
      if (selView === 'day') {
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').subtract(1, 'days').startOf('day').toDate(),
            endDate: moment(start, 'YYYYMMDD').subtract(1, 'days').endOf('day').toDate(),
            key: 'selection',
          },
        };
      } else if (selView === 'week') {
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').subtract(1, 'weeks').startOf('week').toDate(),
            endDate: moment(start, 'YYYYMMDD').subtract(1, 'weeks').endOf('week').toDate(),
            key: 'selection',
          },
        };
      } else {
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').subtract(1, 'months').startOf('month').toDate(),
            endDate: moment(start, 'YYYYMMDD').subtract(1, 'months').endOf('month').toDate(),
            key: 'selection',
          },
        };
      }
    } else {
      if (selView === 'day') {
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').add(1, 'days').startOf('day').toDate(),
            endDate: moment(start, 'YYYYMMDD').add(1, 'days').endOf('day').toDate(),
            key: 'selection',
          },
        };
      } else if (selView === 'week') {
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').add(1, 'weeks').startOf('week').toDate(),
            endDate: moment(start, 'YYYYMMDD').add(1, 'weeks').endOf('week').toDate(),
            key: 'selection',
          },
        };
      } else {
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').add(1, 'months').startOf('month').toDate(),
            endDate: moment(start, 'YYYYMMDD').add(1, 'months').endOf('month').toDate(),
            key: 'selection',
          },
        };
      }
    }
    handleDateSelection(currentSelection);
  };

  const handleViewChange = (value) => {
    var currentSelection;
    switch (value) {
      case 'today':
        break;
      case 'day':
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').startOf('day').toDate(),
            endDate: moment(start, 'YYYYMMDD').endOf('day').toDate(),
            key: 'selection',
          },
        };
        setSelView('day');
        handleDateSelection(currentSelection);
        break;
      case 'week':
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').startOf('week').toDate(),
            endDate: moment(start, 'YYYYMMDD').endOf('week').toDate(),
            key: 'selection',
          },
        };
        setSelView('week');
        handleDateSelection(currentSelection);
        break;
      case 'month':
        currentSelection = {
          selection: {
            startDate: moment(start, 'YYYYMMDD').startOf('month').toDate(),
            endDate: moment(start, 'YYYYMMDD').endOf('month').toDate(),
            key: 'selection',
          },
        };
        setSelView('month');
        handleDateSelection(currentSelection);
        break;
      default:
        console.log(value);
    }
  };

  return (
    <StyledRoot>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', padding: '16px' }}
      >
        <ButtonGroup aria-label="outlined primary button group" color="primary">
          <Button onClick={() => handlePrevNextChange('prev')}>Prev</Button>
          <Button onClick={() => handlePrevNextChange('next')}>Next</Button>
        </ButtonGroup>
        <Typography variant="h6">
          {moment(start, 'YYYYMMDD').toDate().toDateString()} -{' '}
          {moment(end, 'YYYYMMDD').toDate().toDateString()}
        </Typography>
        <ButtonGroup aria-label="outlined primary button group" color="primary">
          <Button
            className={clsx(
              selView === 'day' ? classes.active : classes.normal,
              'active-range-btn',
            )}
            onClick={() => handleViewChange('day')}
          >
            Day
          </Button>
          <Button
            className={clsx(
              selView === 'week' ? classes.active : classes.normal,
              'active-range-btn',
            )}
            onClick={() => handleViewChange('week')}
          >
            Week
          </Button>
          <Button
            className={clsx(
              selView === 'month' ? classes.active : classes.normal,
              'active-range-btn',
            )}
            onClick={() => handleViewChange('month')}
          >
            Month
          </Button>
        </ButtonGroup>
      </Box>

      {content && content.items && content.items.length > 0 && (
        <Calendar
          components={{
            resourceHeader: RotationResource,
            event: MyEvent,
          }}
          date={moment(start, 'YYYYMMDD').toDate()}
          endAccessor="end"
          eventPropGetter={eventPropGetter}
          events={content.items}
          localizer={localizer}
          onNavigate={(value) => {
            console.log('navigate', value);
          }}
          onRangeChange={(values) => {
            console.log('ranges', values);
            if (values.start && values.end) {
              handleDateSelection({
                selection: {
                  startDate: moment(values.start).toDate(),
                  endDate: moment(values.end).toDate(),
                },
              });
            } else {
              if (values.length === 1) {
                handleDateSelection({
                  selection: {
                    startDate: moment(values[0]).toDate(),
                    endDate: moment(values[0]).toDate(),
                  },
                });
              } else if (values.length === 7) {
                handleDateSelection({
                  selection: {
                    startDate: moment(values[0]).toDate(),
                    endDate: moment(values[6]).toDate(),
                  },
                });
              }
            }
          }}
          onSelectEvent={handleClick}
          popup
          resourceAccessor="group"
          resourceIdAccessor="id"
          resourceTitleAccessor="title"
          resources={content.groups}
          showMultiDayTimes={true}
          startAccessor="start"
          step={30}
          style={{ height: 'auto', minHeight: '750px', width: '100%' }}
          timeslots={4}
          toolbar={false}
          view={selView}
          views={['day', 'week', 'month']}
        />
      )}

      <Dialog
        aria-labelledby="simple-dialog-title"
        className={classes.dialog}
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Shift Details</span>
            <DownloadOnCallScheduleContacts contacts={legacy.contacts} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selShift && selShift.assignable
              ? 'Make a new assignment'
              : 'Shift assignment is disabled'}
          </DialogContentText>

          {selShift && selShift.id && (
            <>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled
                  fullWidth
                  label="Start Time"
                  margin="dense"
                  name="startTime"
                  type="text"
                  value={selShift.startDateTime.format('DD MMM YYYY, HH:mm:ss A')}
                  variant="standard"
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled
                  fullWidth
                  label="End Time"
                  margin="dense"
                  name="endTime"
                  type="text"
                  value={selShift.endDateTime.format('DD MMM YYYY, HH:mm:ss A')}
                  variant="standard"
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled
                  fullWidth
                  label="Assigned Contact"
                  margin="dense"
                  name="assignedContact"
                  type="text"
                  value={
                    selShift.contactId && legacy.contacts[selShift.contactId]
                      ? legacy.contacts[selShift.contactId].name
                      : 'Unassigned'
                  }
                  variant="standard"
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TableContainer className={classes.tableHolder} component={Paper}>
                  <Table aria-label="table" className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>Assigned Contact Methods</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selShift.contactId &&
                        legacy.contacts[selShift.contactId] &&
                        legacy.contacts[selShift.contactId].contactMethods &&
                        legacy.contacts[selShift.contactId].contactMethods.map((item) => (
                          <TableRow key={item.value}>
                            <TableCell>
                              {' '}
                              {item.typeDescription &&
                              item.typeDescription?.toLowerCase() === 'cognisent'
                                ? 'Secure App'
                                : item.typeDescription}
                            </TableCell>
                            <TableCell>{item.value}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled
                  fullWidth
                  label="Contact Level"
                  margin="dense"
                  name="contactLevel"
                  type="text"
                  value={selShift.contactLevel}
                  variant="standard"
                />
              </FormControl>

              {selShift && selShift.assignable && (
                <FormControl className={classes.formControl} fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label1">New Assignment</InputLabel>
                  <Select
                    id="demo-simple-select1"
                    labelId="demo-simple-select-label1"
                    name="category"
                    onChange={handleChange}
                    value={assignedContact}
                  >
                    <MenuItem value="U">*Unassigned*</MenuItem>
                    {Object.keys(legacy.contacts).map((element) => (
                      <MenuItem key={element} value={element}>
                        {legacy.contacts[element].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {selShift && selShift.assignable && (
            <PortalButton color="primary" onClick={handleSave} variant="contained">
              Save
            </PortalButton>
          )}
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
}
