import { yellow, grey, green, blue, red } from '@mui/material/colors';

export const AlarmDateRangeDefaultDays = 7;
export const AlarmDateRangeMaxAllowedDays = 32;

export const AlarmImportOperationTypes = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  skip: 'skip',
};

export const AlarmObjectStruct = {
  id: 'id',
  receiver_id: 'receiver_id',
  created_timestamp: 'created_timestamp',
  original_timezone: 'original_timezone',
  trigger_timestamp: 'trigger_timestamp',
  dispatch_timestamp: 'dispatch_timestamp',
  deleted_timestamp: 'deleted_timestamp',
  attributes: 'attributes',
  batch_id: 'batch_id',
  roster_id: 'roster_id',
  message: 'message',
  primary_key: 'primary_key',
};

export const AlarmImportResponseViewPageColorCode = {
  greenish: green[400],
  bluish: blue[400],
  reddish: red[400],
  grayish: grey[200],
  yellowish: yellow[400],
  blackish: grey[700],
};

export const AlarmImportResponseViewToDisplayKeys = {
  [AlarmObjectStruct.receiver_id]: AlarmObjectStruct.receiver_id,
  [AlarmObjectStruct.original_timezone]: AlarmObjectStruct.original_timezone,
  [AlarmObjectStruct.trigger_timestamp]: AlarmObjectStruct.trigger_timestamp,
};

export const AlarmImportResponseViewDisplayNames = {
  [AlarmObjectStruct.receiver_id]: 'Receiver ID',
  [AlarmObjectStruct.original_timezone]: 'Original Timezone',
  [AlarmObjectStruct.trigger_timestamp]: 'Trigger Timestamp',
};

export const DispatchStatusOptionValues = {
  all: 'all',
  pending: 'pending',
  dispatched: 'dispatched',
};

export const DispatchStatusOptions = [
  { value: DispatchStatusOptionValues.all, label: 'All' },
  { value: DispatchStatusOptionValues.pending, label: 'Pending' },
  { value: DispatchStatusOptionValues.dispatched, label: 'Dispatched' },
];

export const TimeStampOptionValues = {
  [AlarmObjectStruct.trigger_timestamp]: AlarmObjectStruct.trigger_timestamp,
  [AlarmObjectStruct.created_timestamp]: AlarmObjectStruct.created_timestamp,
};

export const TimeStampOptions = [
  { value: TimeStampOptionValues[AlarmObjectStruct.created_timestamp], label: 'Created Timestamp' },
  { value: TimeStampOptionValues[AlarmObjectStruct.trigger_timestamp], label: 'Trigger Timestamp' },
];

export const ReceiverAlarmFirebaseDocumentStruct = {
  createdTimestampEpoch: 'createdTimestampEpoch',
  updatedTimestampEpoch: 'updatedTimestampEpoch',
  editInProgressTimestampEpoch: 'editInProgressTimestampEpoch',
  editInProgress: 'editInProgress',
  editorWebUserId: 'editorWebUserId',
};
