import { useEffect } from 'react';

const Features = () => {
  const isNotifyMD = () => {
    return window.location.host === 'secure2.notifymd.com';
  };

  useEffect(() => {
    try {
      // Apply overflow: hidden to the body when the component mounts
      document.body.style.overflow = 'hidden';
    } catch (error) {
      console.error('Error in Features.js', error);
    }

    // Clean up and remove the style when the component unmounts
    return () => {
      try {
        document.body.style.overflow = 'auto';
      } catch (error) {
        console.error('Error in Features.js unmount', error);
      }
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {isNotifyMD() ? (
        <iframe
          src="/features/notifymd/main.html"
          style={{ width: '100%', height: '100vh', border: 'none' }}
          title="NotifyMD Features"
        />
      ) : (
        <iframe
          src="/features/cognisent/main.html"
          style={{ width: '100%', height: '100vh', border: 'none' }}
          title="Cognisent Features"
        />
      )}
    </div>
  );
};

export default Features;
