import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../PortalApi';

export const firebaseSlice = createSlice({
  name: 'firebase',
  initialState: {
    firebaseCustomToken: null,
    firebaseCustomTokenIsValid: null,
    reAuthenticateFirebase: false,
  },
  reducers: {
    setFirebaseCustomToken: (state, action) => {
      state.firebaseCustomToken = action.payload;
    },
    setReAuthenticateFirebase: (state, action) => {
      state.reAuthenticateFirebase = action.payload;
    },
  },
});

export const { setFirebaseCustomToken, setReAuthenticateFirebase } = firebaseSlice.actions;
export const firebaseCustomTokenSelector = (state) => state.firebase.firebaseCustomToken;
export const reAuthenticateFirebaseSelector = (state) => state.firebase.reAuthenticateFirebase;

export function getFirebaseCustomToken({ receiverIds, uid }) {
  console.log('getFirebaseCustomToken on call', receiverIds, uid);
  return (dispatch) => {
    try {
      PortalApi.call(
        `/firebase/portal/getCustomToken`,
        { method: 'post', body: JSON.stringify({ receiverIds, uid }) },
        (customToken) => {
          dispatch(setFirebaseCustomToken(customToken));
        },
        (error, status, content) => {
          console.error(error, status, content);
          dispatch(setFirebaseCustomToken(null));
        },
      );
    } catch (error) {
      console.error('Error in getFirebaseCustomToken', error);
      dispatch(setFirebaseCustomToken(null));
    }
  };
}

export function verifyFirebaseCustomToken(idToken, callback) {
  console.log('verifyFirebaseCustomToken called');
  return (dispatch) => {
    try {
      PortalApi.call(
        `/firebase/portal/verifyToken`,
        { method: 'post', body: idToken },
        (decodedToken) => {
          console.log('decodedToken');
          if (decodedToken && decodedToken?.uid) {
            dispatch(setFirebaseCustomToken(idToken));
            if (callback) {
              callback(true);
            }
          } else {
            dispatch(setFirebaseCustomToken(null));
            if (callback) {
              callback(false);
            }
          }
        },
        (error, status, content) => {
          console.error(error, status, content);
          dispatch(setFirebaseCustomToken(null));
          callback(false);
        },
      );
    } catch (error) {
      console.error('Error in getFirebaseCustomToken', error);
      dispatch(setFirebaseCustomToken(null));
      callback(false);
    }
  };
}

export default firebaseSlice.reducer;
