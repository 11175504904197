import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { directorySelector, selAccountSelector, updateContactMethod } from '../accountSlice';
import { Collapse, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { portalUserSelector } from '../../../app/authSlice';
import { useTranslation } from 'react-i18next';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  small: `${PREFIX}-small`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellDarkTheme: `${PREFIX}-tableCellDarkTheme`,
  tableContainer: `${PREFIX}-tableContainer`,
  subTableHeader: `${PREFIX}-subTableHeader`,
  subTableCell: `${PREFIX}-subTableCell`,
  rowExpandedRoot: `${PREFIX}-rowExpandedRoot`,
  rowRoot: `${PREFIX}-rowRoot`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(0),
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 14,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.tableCell}`]: { padding: '0px 0px 0px 8px' },

  [`& .${classes.tableCellDarkTheme}`]: {
    padding: '0px 0px 0px 8px',
    color: 'white',
  },

  [`& .${classes.tableContainer}`]: {
    flexGrow: 1,
  },

  [`& .${classes.subTableHeader}`]: {
    background: theme.palette.secondary.light,
  },

  [`& .${classes.subTableCell}`]: {
    flex: 1,
    width: '33.3%',
    color: 'inherit',
  },

  [`& .${classes.rowExpandedRoot}`]: {
    background: theme.palette.primary.light,
    '& > *': {
      borderBottom: 'unset',
    },
    height: '52px',
  },

  [`& .${classes.rowRoot}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
    height: '52px',
  },
}));

export default function Directory() {
  const dispatch = useDispatch();
  const selAccount = useSelector(selAccountSelector);
  const portalUser = useSelector(portalUserSelector);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactMethod, setContactMethod] = useState({});
  const directory = useSelector(directorySelector);
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState({});
  const { t } = useTranslation();

  const [selectedTheme, setSelectedTheme] = useState(null);

  useEffect(() => {
    try {
      if (portalUser && portalUser.optionsJson) {
        const selectedTheme = JSON.parse(portalUser.optionsJson).theme;
        setSelectedTheme(selectedTheme);
      }
    } catch (error) {
      console.log('Error', error);
    }
  }, [portalUser]);

  const getDarThemeExpandedClassName = (row) => {
    return selectedTheme === 'dark' && expanded[row.id]
      ? classes.tableCellDarkTheme
      : classes.tableCell;
  };

  const handleExpandClick = (value) => {
    var newExpanded = { ...expanded };
    if (newExpanded[value]) {
      newExpanded[value] = !newExpanded[value];
    } else {
      newExpanded[value] = true;
    }
    setExpanded(newExpanded);
  };

  const handleChange = (event) => {
    if (event.target.value.length <= 10 && contactMethod.format === 'phone-std') {
      setValue(event.target.value);
    } else if (contactMethod.format === 'email') {
      setValue(event.target.value);
    }
  };

  const showUpdatePopup = (currentMethod, name) => {
    setContactName(name);
    setValue(currentMethod.value);
    setContactMethod(currentMethod);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveContactMethod = () => {
    dispatch(
      updateContactMethod({
        id: contactMethod.id,
        accountNumber: selAccount,
        value: value,
        requestorName: `WEB:${portalUser.login}`,
      }),
    );
    setOpen(false);
  };

  const handleExpandAll = () => {
    var newExpanded = {};
    if (!expandAll) {
      directory.forEach((element) => {
        newExpanded[element.id] = true;
      });
      setExpandAll(true);
    } else {
      directory.forEach((element) => {
        newExpanded[element.id] = false;
      });
      setExpandAll(false);
    }
    setExpanded(newExpanded);
  };

  return (
    <StyledGrid className={classes.root} container spacing={2}>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
        <PortalButton
          onClick={handleExpandAll}
          size="small"
          sx={{ mb: 1, alignItems: 'center', padding: '5px 100px' }}
          variant="contained"
        >
          {expandAll ? t('Collapse All') : t('Expand All')}
        </PortalButton>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="a dense table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('Contact Name')}</TableCell>
                <TableCell align="left">{t('Contact Method Count')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(directory) &&
                directory.map((row, index) => (
                  <Fragment key={index}>
                    <TableRow
                      className={expanded[row.id] ? classes.rowExpandedRoot : classes.rowRoot}
                      key={row.id}
                    >
                      <TableCell className={getDarThemeExpandedClassName(row)}>
                        {row.name}
                      </TableCell>
                      <TableCell align="left" className={getDarThemeExpandedClassName(row)}>
                        {row.contactMethods.length}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.contactMethods.length > 0 && (
                          <PortalIconButton
                            aria-expanded={expanded}
                            aria-label="show more"
                            className={getDarThemeExpandedClassName(row)}
                            color="primary"
                            onClick={() => handleExpandClick(row.id)}
                          >
                            {expanded[row.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </PortalIconButton>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ height: expanded[row.id] ? 'auto' : '0px' }}>
                      <TableCell colSpan={4} style={{ padding: expanded[row.id] ? '12px' : '0px' }}>
                        <Collapse in={expanded[row.id]} timeout="auto" unmountOnExit>
                          {row.contactMethods && row.contactMethods.length > 0 && (
                            <TableContainer className={classes.tableContainer} component={Paper}>
                              <Table aria-label="a dense table" size="small">
                                <TableHead className={classes.subTableHeader}>
                                  <TableRow>
                                    <TableCell
                                      align="left"
                                      className={classes.subTableCell}
                                      sx={{ pl: 4 }}
                                    >
                                      {t('Contact Type')}
                                    </TableCell>
                                    <TableCell align="left" className={classes.subTableCell}>
                                      {t('Contact Value')}
                                    </TableCell>
                                    <TableCell align="left" className={classes.subTableCell}>
                                      {t('Editable')}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row.contactMethods &&
                                    row.contactMethods.map((item) => (
                                      <TableRow hover key={item.id} sx={{ cursor: 'pointer' }}>
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                          sx={{ pl: 4 }}
                                        >
                                          {item.typeDescription &&
                                          item.typeDescription?.toLowerCase() === 'cognisent'
                                            ? 'Secure App'
                                            : item.typeDescription}
                                        </TableCell>
                                        <TableCell align="left">{item.value}</TableCell>
                                        <TableCell align="left">
                                          <PortalButton
                                            color={item.editable ? 'primary' : 'inherit'}
                                            disabled={!item.editable}
                                            onClick={() => showUpdatePopup(item, row.name)}
                                            size="small"
                                            sx={{ borderRadius: '50px' }}
                                            variant="outlined"
                                          >
                                            {t('Click To Edit')}
                                          </PortalButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
          onClose={handleClose}
          open={open}
        >
          <DialogTitle id="form-dialog-title">Edit Directory Value</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography> Contact Name : {contactName} </Typography>
              <Typography> Contact Method Type : {contactMethod.typeDescription} </Typography>
            </DialogContentText>

            <TextField
              autoFocus
              error={
                contactMethod.format === 'email'
                  ? !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                      value,
                    )
                  : value.length < 10
              }
              fullWidth
              label="Contact Method Value"
              margin="dense"
              name="methodValue"
              onChange={handleChange}
              required
              type={contactMethod.format}
              value={value}
            />
          </DialogContent>
          <DialogActions>
            <PortalButton
              color="primary"
              disabled={
                contactMethod.format === 'email'
                  ? !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                      value,
                    )
                  : !value || value.length < 10
              }
              onClick={saveContactMethod}
              variant="contained"
            >
              Save
            </PortalButton>
            <PortalButton color="secondary" onClick={handleClose} variant="contained">
              Cancel
            </PortalButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </StyledGrid>
  );
}
