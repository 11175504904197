// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { isLocalOrDevEnvironment } from '../utils/urls';
import { ref as databaseRef } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import firebaseConfiguration from './configuration';

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfiguration);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(firebaseApp);

// Export the initialized app and database
const database = getDatabase(firebaseApp);

// We have 2 firestore databases one for production(id: production) and one for testing (id: test)
const firestoreDb = getFirestore(firebaseApp, isLocalOrDevEnvironment() ? 'test' : 'production');

export { firebaseApp, database, auth, databaseRef, firestoreDb };
