import { useEffect } from 'react';
import { Grid } from '@mui/material';
import FuneralServiceList from '../accounts/funeralservice/index';
import {
  searchAccountsSelector,
  getFuneralServicesList,
  setSelectedSearchAccount,
  setSelAccount,
} from '../accounts/accountSlice';
import AccountLookup from './AccountLookup';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedProgressLoader from '../shared/CustomizedProgressLoader';

export default function ObitInformation() {
  const dispatch = useDispatch();
  const accountSearchResponse = useSelector(searchAccountsSelector);

  useEffect(() => {
    if (accountSearchResponse && accountSearchResponse.length > 0) {
      dispatch(setSelectedSearchAccount(accountSearchResponse[0].ACNT_NUM));
      dispatch(setSelAccount(accountSearchResponse[0].ACNT_NUM));
      dispatch(getFuneralServicesList(accountSearchResponse[0].ACNT_NUM, 0, 10, 'serviceDate'));
    } else {
      dispatch(setSelectedSearchAccount());
    }
  }, [dispatch, accountSearchResponse]);

  return (
    <Grid container>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <AccountLookup mode="search" />
      </Grid>
      <Grid item xs={12}>
        <FuneralServiceList />
      </Grid>
      <CustomizedProgressLoader />
    </Grid>
  );
}
