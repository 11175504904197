import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useTheme,
  Button,
  CircularProgress,
  InputAdornment,
  Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { domainDefaultsSelector } from '../../../brandSlice';
import {
  createPayment,
  createNewPayment,
  getPaymentsForAccount,
  getPaymentCardsForAccount,
  paymentCardsSelector,
  paymentsSelector,
  selAccountInfoSelector,
  loadingSelector,
} from '../accountSlice';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PaymentIcon from '@mui/icons-material/Payment';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'Payments';

const classes = {
  root: `${PREFIX}-root`,
  tableHead: `${PREFIX}-tableHead`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  table: `${PREFIX}-table`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  [`&.${classes.tableHead}`]: {
    background: theme.palette.primary.light,
  },
  [`&.${classes.tableHeadCell}`]: {
    color: '#FFFFFF',
  },
  [`&.${classes.table}`]: {
    textAlign: 'center',
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const Payments = (props) => {
  const content = useSelector(paymentsSelector);
  const account = useSelector(selAccountInfoSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const cards = useSelector(paymentCardsSelector);
  const brand = useSelector(domainDefaultsSelector);
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(0);
  const [cardNumber, setCardNumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [zip, setZip] = useState('');
  const [comment, setComment] = useState('');
  const loading = useSelector(loadingSelector);
  const { t } = useTranslation();

  const handleCardChange = (event) => {
    var item = event.target.value;
    if (item === '0') {
      setCardNumber('');
      setExpiration('');
      setName('');
      setStreetAddress('');
      setZip('');
      setType(item);
    } else {
      setCardNumber(item.CARD_NUM);
      setExpiration(item.EXPIRE_DATE);
      setName(item.HLDR_NAME_TXT);
      setStreetAddress(item.ADDRS_TXT);
      setZip(item.ZIP_NUM);
      setType(item);
    }
  };

  const handleChange = (event) => {
    switch (event.currentTarget?.name) {
      case 'cardNumber':
        setCardNumber(event.target.value);
        break;
      case 'expiration':
        setExpiration(event.target.value);
        break;
      case 'name':
        setName(event.target.value);
        break;
      case 'streetAddress':
        setStreetAddress(event.target.value);
        break;
      case 'zip':
        setZip(event.target.value);
        break;
      case 'amount':
        if (event.target.value !== '' && isNaN(parseFloat(event.target.value))) {
          return;
        }
        setAmount(parseFloat(event.target.value).toFixed(2));
        break;
      case 'comment':
        setComment(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      default:
        setType(event.target.value);
    }
  };

  useEffect(() => {
    if (props.account && account) {
      dispatch(getPaymentsForAccount(account.id, page === 0 ? 0 : page * rowsPerPage, rowsPerPage));
      dispatch(getPaymentCardsForAccount(account.number));
    }
  }, [dispatch, props.account, account, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log('newPage :: ', newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const makePayment = () => {
    var payment = {};
    payment.amount = parseFloat(amount);
    payment.comment = comment;
    payment.receiptEmail = email;
    payment.fromAddress = brand.ccEmail;

    if (type && type.ID) {
      payment.creditCardId = type.ID;
      dispatch(createPayment(account.number, payment, account.id));
    } else {
      payment.cardNumber = cardNumber;
      payment.expirationDate = expiration;
      payment.holderName = name;
      payment.holderAddress = streetAddress;
      payment.zip = zip;
      dispatch(createNewPayment(account.number, payment, account.id));
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledGrid className={classes.root} container spacing={2}>
      <Grid item xs={12}>
        {loading && <CircularProgress />}
        {content && content.METADATA && (
          <Typography color="primary" variant="subtitle1">
            Current Balance{' '}
            <NumericFormat
              displayType="text"
              prefix="$"
              thousandSeparator={true}
              value={content.METADATA.ACCOUNT.ACCOUNT_BALANCE}
            />
          </Typography>
        )}
        <Typography variant="caption">
          {t('Note: Balance does not include pending payments')}
        </Typography>
      </Grid>
      <Divider variant="middle" />
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="a dense table" className={classes.table} size="small">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Transaction Number')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Post Date')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Authorization')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('Payments')}
                </TableCell>
                <TableCell align="right" className={classes.tableHeadCell} sx={{ pr: 4 }}>
                  {t('Amount')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content && content.PAYMENTS && content.PAYMENTS.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12} scope="row" sx={{ textAlign: 'center' }}>
                    <Typography variant="overline">{t('no records found')}</Typography>
                  </TableCell>
                </TableRow>
              )}

              {content &&
                content.PAYMENTS &&
                content.PAYMENTS.map((row) => (
                  <TableRow hover key={row.PAYMENT_STEP_NUMBER}>
                    <TableCell align="center" component="th" scope="row">
                      {row.TRANS_NUM_FORMATTED}
                    </TableCell>
                    <TableCell align="center">
                      {row.POST_DATE
                        ? moment(row.POST_DATE, 'YYYYMMDDHHmmss').format('MMM DD,YYYY')
                        : 'Pending'}
                    </TableCell>
                    <TableCell align="center">{row.fat}</TableCell>
                    <TableCell align="center">{row.STATEMENT_TXT}</TableCell>
                    <TableCell align="right" sx={{ pr: 4 }}>
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale={true}
                        prefix="$"
                        thousandSeparator={true}
                        value={row.AMOUNT}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          ActionsComponent={TablePaginationActions}
          component="div"
          count={content && content.METADATA ? content.METADATA.TOTAL_PAYMENT_COUNT : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Grid>
      <Grid item xs={12}>
        {content &&
          content.METADATA &&
          content.METADATA.ACCOUNT &&
          content.METADATA.ACCOUNT.PAYMENT_PROCESSOR === 'STRIPE' && (
            <>
              <Typography variant="subtitle2">
                To provide more advanced features, we've moved Internet payments to an online
                payment processor powered by an industry leader Stripe.com. Click on the PayTrustNow
                logo to open the payment portal.
              </Typography>

              <a
                href={`${
                  content.METADATA.ACCOUNT.PAYMENT_URL
                }/portalApi/v2/transfer?accountId=${encodeURIComponent(
                  content.METADATA.ACCOUNT.ACCOUNT_ID,
                )}&bearer=${encodeURIComponent(localStorage.access_token)}`}
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt="paytrustnow"
                  className={classes.logo}
                  src="../paytrustnow.png"
                  style={{
                    width: '164px',
                    height: '69px',
                    borderRadius: '16px',
                    background: '#f3f3f3',
                  }}
                />
              </a>
            </>
          )}

        {content &&
          content.METADATA &&
          content.METADATA.ACCOUNT &&
          content.METADATA.ACCOUNT.PAYMENT_PROCESSOR === '' && (
            <PortalButton
              className={classes.button}
              color="primary"
              endIcon={<PaymentIcon>send</PaymentIcon>}
              onClick={() => setOpen(true)}
              variant="contained"
            >
              {t('Make Payment')}
            </PortalButton>
          )}

        <Dialog aria-labelledby="form-dialog-title" onClose={handleClose} open={open}>
          <DialogTitle id="form-dialog-title">{t('Make Payment')}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: 'red' }} />
            <FormControl className={classes.formControl} fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                name="type"
                onChange={handleCardChange}
                value={type.ID}
              >
                <MenuItem value="0">New Card</MenuItem>
                {cards &&
                  cards.length > 0 &&
                  cards.map((item) => (
                    <MenuItem key={item.ID} value={item}>
                      {item.CARD_NUM}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              error={
                !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                  email,
                )
              }
              fullWidth
              helperText="(receipt will be sent to this email address)"
              label="Email"
              margin="dense"
              name="email"
              onChange={handleChange}
              required
              type="text"
              value={email}
              variant="standard"
            />

            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
              label="Amount"
              margin="dense"
              name="amount"
              onChange={handleChange}
              required
              type="text"
              value={amount}
              variant="standard"
            />

            <TextField
              disabled={type !== '0'}
              fullWidth
              label="Credit Card Number"
              margin="dense"
              name="cardNumber"
              onChange={handleChange}
              required
              type="text"
              value={cardNumber}
              variant="standard"
            />

            <TextField
              disabled={type !== '0'}
              fullWidth
              label="Expiration Date (mm/yyyy)"
              margin="dense"
              name="expiration"
              onChange={handleChange}
              required
              type="text"
              value={expiration}
              variant="standard"
            />

            <TextField
              disabled={type !== '0'}
              fullWidth
              label="Name on Card"
              margin="dense"
              name="name"
              onChange={handleChange}
              required
              type="text"
              value={name}
              variant="standard"
            />

            <TextField
              disabled={type !== '0'}
              fullWidth
              label="Billing Street Address"
              margin="dense"
              name="streetAddress"
              onChange={handleChange}
              required
              type="text"
              value={streetAddress}
              variant="standard"
            />

            <TextField
              disabled={type !== '0'}
              fullWidth
              label="Billing Zip"
              margin="dense"
              name="zip"
              onChange={handleChange}
              required
              type="text"
              value={zip}
              variant="standard"
            />

            <TextField
              fullWidth
              label="Comment"
              margin="dense"
              multiline
              name="comment"
              onChange={handleChange}
              rows={4}
              type="text"
              value={comment}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <PortalButton
              color="primary"
              disabled={email === '' || cardNumber === '' || amount === 0 || zip === ''}
              onClick={makePayment}
            >
              Proceed to Verify
            </PortalButton>
            <PortalButton className="rtPBSave" color="primary" onClick={handleClose}>
              Close
            </PortalButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </StyledGrid>
  );
};

export default Payments;
