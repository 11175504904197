import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, TableBody, Typography } from '@mui/material';

const PREFIX = 'SentimentTopicsDetected';

const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  graphContainer: `${PREFIX}-graphContainer`,
};

const StyledRoot = styled('div')({
  [`& .${classes.tableContainer}`]: { boxShadow: '0 1px 1px 0 #4dd0e182', borderRadius: '5px' },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '16px' },
  [`& .${classes.graphContainer}`]: {
    backgroundColor: '#f8fdff',
    padding: '8px',
    borderRadius: '10px',
  },
});

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#47b14bcc',
  color: theme.palette.common.white,
  padding: '1px',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: '5px',
}));

const getTopics = (items) => {
  try {
    const topics = items.flatMap((i) => i.topic?.split('>') || []);
    return Array.from(new Set(topics)).join(',  ');
  } catch (error) {
    console.log(error);
    return [];
  }
};

export default function SentimentTopicsDetected({ topicsDetected }) {
  return (
    <StyledRoot>
      {topicsDetected && topicsDetected?.length > 0 && (
        <Box className={classes.graphContainer}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="Topics" className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell colSpan="2">
                    <Typography className={classes.sectionTitle}>Topics</Typography>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableBodyCell scope="row">{getTopics(topicsDetected)}</StyledTableBodyCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </StyledRoot>
  );
}
