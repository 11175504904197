import moment from 'moment';
import { RFC3339DateFormat } from '../dateUtil';

/**
 * @param {*} timestampValue
 * @param {*} timezone
 * @returns
 */
export const getRFCFormattedTriggeredTimestamp = (timestampValue) => {
  return moment(timestampValue).utc().format(RFC3339DateFormat);
};
