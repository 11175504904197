import {
  Button,
  Grid,
  Card,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardActions,
  CardContent,
  CircularProgress,
  Box,
} from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createChatAffiliation,
  deleteChatAffiliation,
  loadingSelector,
  chatAffiliationsSelector,
  getAvaialbleAffiliations,
  selAffiliationSelector,
  selAffiliationUsersSelector,
  getAffiliationUsers,
  deleteChatAffiliationUser,
} from './staffSlice';
import { useTheme, styled } from '@mui/material/styles';
import { CardHeader, TablePagination, InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import ImpersonateUser from './ImpersonateUser';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'ChatAffiliation';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  formControl: `${PREFIX}-formControl`,
  controlPaper: `${PREFIX}-controlPaper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardHeader: `${PREFIX}-cardHeader`,
  noRecordsRow: `${PREFIX}-noRecordsRow`,
  cardActions: `${PREFIX}-cardActions`,
  tableSecondaryHead: `${PREFIX}-tableSecondaryHead`,
  tableHead: `${PREFIX}-tableHead`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  selectedRow: `${PREFIX}-selectedRow`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.formControl}`]: {
    width: '200px',
  },

  [`& .${classes.controlPaper}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.noRecordsRow}`]: {
    textAlign: 'center',
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.tableSecondaryHead}`]: {
    background: theme.palette.secondary.main,
    color: 'black',
  },

  [`& .${classes.tableHead}`]: {
    background: theme.palette.primary.light,
    color: 'white',
  },

  [`& .${classes.tableHeadCell}`]: {
    color: '#FFFFFF',
  },

  [`& .${classes.selectedRow}`]: {
    background: theme.palette.secondary.light,
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //const socket = useContext(SocketContext);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ChatAffiliation(props) {
  const dispatch = useDispatch();
  const [affiliationName, setAffiliationName] = useState('');
  const [active, setActive] = useState('');

  const searchChatGroups = useSelector(chatAffiliationsSelector);
  const selAffliation = useSelector(selAffiliationSelector);
  const selAffliationUsers = useSelector(selAffiliationUsersSelector);
  const loading = useSelector(loadingSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addContent, setAddContent] = useState({});

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteContent, setDeleteContent] = useState({});
  const [searchUsersDialog, setSearchUsersDialog] = useState(false);

  const handleTextChange = (event) => {
    switch (event.target.name) {
      case 'affiliationName':
        setAffiliationName(event.target.value);
        break;
      case 'active':
        setActive(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAvaialbleAffiliations());
    if (selAffliation && selAffliation > 0) {
      dispatch(getAffiliationUsers(selAffliation));
    }
  }, [dispatch]);

  const handleAdd = (event, info) => {
    setAddDialogOpen(true);
    setAddContent(info);
  };

  const handleAddDialogConfirm = () => {
    dispatch(createChatAffiliation(affiliationName, 'Y'));
    setAddDialogOpen(false);
  };

  const handleAddDialogCancel = (event, info) => {
    setAddDialogOpen(false);
  };

  const handleDelete = (info) => {
    setDeleteDialogOpen(true);
    setDeleteContent(info);
  };

  const handledeleteDialogConfirm = () => {
    dispatch(deleteChatAffiliation(deleteContent.id));
    setDeleteDialogOpen(false);
  };

  const handledeleteDialogCancel = (event, info) => {
    setDeleteDialogOpen(false);
    setDeleteContent({});
  };

  const loadAffliationUsers = (affiliationId) => {
    dispatch(getAffiliationUsers(affiliationId));
  };

  const handleDeleteChatMember = (row) => {
    dispatch(deleteChatAffiliationUser(selAffliation, row.id));
  };

  return (
    <StyledRoot className={classes.root}>
      {loading && <CircularProgress color="secondary" />}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardHeader
              avatar={<GroupIcon />}
              className={classes.cardHeader}
              title={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <p>Secure Chat Access Group (SCAG)</p>
                  <PortalButton
                    color="primary"
                    onClick={() => setSearchUsersDialog(true)}
                    style={{ height: 'fit-content', alignSelf: 'center' }}
                    variant="contained"
                  >
                    Search Users
                  </PortalButton>
                </div>
              }
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
              {searchChatGroups && searchChatGroups.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="dense table" className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableHead}>
                        <TableCell align="center" className={classes.tableHeadCell}>
                          Chat Access Group Name
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadCell}>
                          Active
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadCell}>
                          Total Members
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadCell}>
                          Created On
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadCell} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchChatGroups.map((row) => (
                        <TableRow
                          className={row.id === selAffliation ? classes.selectedRow : classes.row}
                          key={row.number}
                          onClick={() => loadAffliationUsers(row.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.active}</TableCell>
                          <TableCell align="center">{row.count}</TableCell>
                          <TableCell align="center">
                            {row.creationDate
                              ? new Date(row.creationDate).toLocaleDateString()
                              : 'NA'}
                          </TableCell>
                          <TableCell align="right">
                            <PortalIconButton onClick={() => handleDelete(row)}>
                              <DeleteIcon />
                            </PortalIconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CardContent>
            <CardActions>
              <PortalButton color="primary" onClick={handleAdd} variant="contained">
                Add
              </PortalButton>
            </CardActions>
          </Card>
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handledeleteDialogCancel}
            open={deleteDialogOpen}
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete chat access group?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" />
            </DialogContent>

            <DialogActions>
              <PortalButton color="primary" onClick={handledeleteDialogConfirm} variant="contained">
                DELETE
              </PortalButton>
              <PortalButton
                autoFocus
                color="secondary"
                onClick={handledeleteDialogCancel}
                variant="contained"
              >
                Cancel
              </PortalButton>
            </DialogActions>
          </Dialog>

          <Dialog
            aria-describedby="add-dialog-description"
            aria-labelledby="add-dialog-title"
            onClose={handleAddDialogCancel}
            open={addDialogOpen}
          >
            <DialogTitle id="add-dialog-title">{'Enter Chat Access Group Details '}</DialogTitle>
            <DialogContent>
              <DialogContentText id="add-dialog-description" />
              <InputLabel htmlFor="standard-adornment-password">Access Group Name</InputLabel>
              <Input
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                fullWidth
                label="New Password"
                name="affiliationName"
                onChange={handleTextChange}
                type="text"
                value={affiliationName}
                variant="outlined"
              />
            </DialogContent>

            <DialogActions>
              <PortalButton
                autoFocus
                color="primary"
                onClick={handleAddDialogConfirm}
                variant="contained"
              >
                Save
              </PortalButton>
              <PortalButton color="secondary" onClick={handleAddDialogCancel} variant="contained">
                Cancel
              </PortalButton>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid item xs={6}>
          <Dialog
            aria-describedby="search-dialog-description"
            aria-labelledby="search-dialog-title"
            fullWidth
            maxWidth="xl"
            onClose={() => setSearchUsersDialog(false)}
            open={searchUsersDialog}
          >
            <DialogTitle id="search-dialog-title">{'Search and Add Users '}</DialogTitle>
            <DialogContent>
              <DialogContentText id="add-dialog-description" />
              <ImpersonateUser mode="search" selAffiliationId={selAffliation} />
            </DialogContent>

            <DialogActions>
              <PortalButton color="primary" onClick={() => setSearchUsersDialog(false)}>
                Close
              </PortalButton>
            </DialogActions>
          </Dialog>

          <Card className={classes.card}>
            <CardHeader
              avatar={<ContactsIcon />}
              className={classes.cardHeader}
              title={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <p>SCAG - Members</p>
                </div>
              }
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
              {selAffliation && selAffliationUsers.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="dense table" className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableSecondaryHead}>
                        <TableCell align="center" className={classes.tableHeadCell}>
                          First Name
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadCell}>
                          Last Name
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadCell}>
                          Username
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadCell} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selAffliationUsers.map((row) => (
                        <TableRow key={row.number} style={{ cursor: 'pointer' }}>
                          <TableCell align="center">{row.firstTxt}</TableCell>
                          <TableCell align="center">{row.lastTxt}</TableCell>
                          <TableCell align="center">{row.loginTxt}</TableCell>
                          <TableCell align="right">
                            <PortalIconButton onClick={() => handleDeleteChatMember(row)}>
                              <DeleteIcon />
                            </PortalIconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {selAffliation && selAffliationUsers.length === 0 && (
                <Typography>No users</Typography>
              )}
            </CardContent>
            <CardActions className={classes.cardActions}>
              {/* <PortalIconButton onClick={()=>setSearchUsersDialog(true)}><SearchIcon /></PortalIconButton> */}
              <TablePagination
                ActionsComponent={TablePaginationActions}
                component="div"
                count={selAffliationUsers ? selAffliationUsers.length : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}
