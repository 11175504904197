import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';

const PREFIX = 'SentimentContentSafetyDetected';

const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  graphContainer: `${PREFIX}-graphContainer`,
};

const StyledRoot = styled('div')({
  [`& .${classes.tableContainer}`]: { boxShadow: '0 1px 1px 0 #4dd0e182', borderRadius: '5px' },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '16px' },
  [`& .${classes.graphContainer}`]: {
    backgroundColor: '#f8fdff',
    padding: '8px',
    borderRadius: '10px',
  },
});

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FF3333',
  color: theme.palette.common.white,
  padding: '1px',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: '5px',
}));

const getContentSafetyWords = (items) => {
  try {
    const words = items.flatMap((i) => i.label);
    return Array.from(new Set(words)).join(',  ');
  } catch (error) {
    console.log(error);
    return [];
  }
};

export default function SentimentContentSafetyDetected({ contentSafetyDetected }) {
  return (
    <StyledRoot>
      {contentSafetyDetected && contentSafetyDetected?.length > 0 && (
        <Box className={classes.graphContainer}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="Content Safety" className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell colSpan="2">
                    <Typography className={classes.sectionTitle}>Content Safety</Typography>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableBodyCell component="th" scope="row">
                    {getContentSafetyWords(contentSafetyDetected)}
                  </StyledTableBodyCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </StyledRoot>
  );
}
