import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../PortalApi';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { setPortalSnackInfo } from '../../app/authSlice';
import { setCustomizedProgressLoading } from '../../slices/customizedProgressLoaderSlice';
import { set } from 'firebase/database';
import { getURLSearchParamsString } from '../../utils/query';

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    messages: null,
    messageStats: {
      escalations: 0,
      total: 0,
      followup: 0,
    },
    downloadList: [],
    selReceiver: 0,
    deliveryContacts: [],
    selRecFeatures: [],
    msgLoading: false,
    receivers: [],
    reminders: [],
    recordings: [],
    recordingDownloadList: [],
    followupResponse: '',
    replyResponse: '',
    clearEscalationResponse: '',
    forwardResponse: '',
    sendMessage: '',
    noteResponse: '',
    reminderResponse: '',
    lastMsgQuery: '',
    attachReceiverResponse: '',
    detachReceiverResponse: '',
    updateReceiverResponse: '',
    updateArchiveResponse: '',
    queryPattern: '',
    queryPatternCase: '',
    queryNoteAuthor: '',
    textMatchClearEscalationPhrase: null,
    focusedMessageId: '',
    clearEscalationUpdate: {},
    messageById: {},
    newMessage: {},
    liveUpdates: false,
    escalationMessageQuery: false,
    customToken: null, // null || matched || unmatched
  },
  reducers: {
    setUpdateReceiverResponse: (state, action) => {
      state.updateReceiverResponse = action.payload;
    },
    setDetachReceiverResponse: (state, action) => {
      state.attachReceiverResponse = action.payload;
    },
    setAttachReceiverResponse: (state, action) => {
      state.detachReceiverResponse = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setDeliveryContacts: (state, action) => {
      state.deliveryContacts = action.payload;
    },
    setSelReceiver: (state, action) => {
      state.selReceiver = action.payload;
    },
    setSelRecFeatures: (state, action) => {
      state.selRecFeatures = action.payload;
    },
    setReceivers: (state, action) => {
      state.receivers = action.payload;
    },
    setReminders: (state, action) => {
      state.reminders = action.payload;
    },
    setSendMessage: (state, action) => {
      state.sendMessage = action.payload;
    },
    setRecordings: (state, action) => {
      state.recordings = action.payload;
    },
    updateRecordings: (state, action) => {
      var newRecordings = [...state.recordings];
      newRecordings.map((element) => {
        if (element.id === action.payload.id) {
          element.audio = action.payload.audio;
          return element;
        }
        return element;
      });
      state.recordings = newRecordings;
      var newMessages = [...state.messages];
      newMessages.map((element) => {
        if (element.AUDIO_ID === action.payload.id) {
          element.audio = action.payload.audio;
          return element;
        }
        return element;
      });
      state.messages = newMessages;
    },
    setNoteResponse: (state, action) => {
      state.noteResponse = action.payload;
      if (action.payload.response && action.payload.response.result === 'SUCCESS') {
        var newMessages = state.messages;
        newMessages.forEach((element) => {
          if (
            element.ID === action.payload.ID &&
            action.payload.response &&
            action.payload.response.notes
          ) {
            element.notes = action.payload.response.notes;

            //  if(element.notes){
            //     element.notes.push({NOTE_TXT:"From: "+action.payload.from +" "+action.payload.note,ADD_DATE:moment().format("YYYYMMDDHHmmss"),ID:'',ADDED_BY:action.payload.from});
            //  }else{
            //    element.notes = [{NOTE_TXT:"From: "+action.payload.from +" "+action.payload.note,ADD_DATE:moment().format("YYYYMMDDHHmmss"),ID:'',ADDED_BY:action.payload.from}];
            //  }
          }
        });
        state.messages = newMessages;
      }
    },
    setForwardResponse: (state, action) => {
      state.forwardResponse = action.payload;
    },
    setReminderResponse: (state, action) => {
      state.reminderResponse = action.payload;
    },
    setMessageStats: (state, action) => {
      state.messageStats = action.payload;
    },
    setMsgLoading: (state, action) => {
      state.msgLoading = action.payload;
    },
    setReplyResponse: (state, action) => {
      state.replyResponse = action.payload;

      if (action.payload && action.payload.ID) {
        var newMessages = state.messages;
        var newStats = { escalations: 0, total: 0, followup: 0 };
        newMessages.forEach((element) => {
          if (element.ID === action.payload.ID) {
            element.ESCALATION_STATUS = action.payload.ESCALATION_STATUS;
            element.ESCALATION_CLEARED_ID = action.payload.ESCALATION_CLEARED_ID;
            element.ESCALATION_CLEARED_BY_NAME = action.payload.ESCALATION_CLEARED_BY_NAME;
            element.ESCALATION_CLEARED_BY_WEBUSER_NAME =
              action.payload.ESCALATION_CLEARED_BY_WEBUSER_NAME;
            element.ESCALATION_CLEARED_NORMALISED_NAME =
              action.payload.ESCALATION_CLEARED_NORMALISED_NAME;
            element.ESCALATION_CLEARED_DATE = action.payload.ESCALATION_CLEARED_DATE;
            element.CLEAR_ESCALATION_REPLY_STATUS =
              action.payload.CLEAR_ESCALATION_REPLY_STATUS || null;
          }
          if (element.ID === action.payload.ID && action.payload.deliveryDetails) {
            element.deliveryDetails = action.payload.deliveryDetails;
          }
          newStats.total += 1;
          if (element.FOLLOWUP_FLAG === 'Y') {
            newStats.followup += 1;
          }

          if (element.ESCALATION_STATUS === 'PENDING') {
            newStats.escalations += 1;
          }
        });
        state.messages = newMessages;
      }
    },
    setDownloadList: (state, action) => {
      state.downloadList = action.payload;
    },
    setRecordingDownloadList: (state, action) => {
      state.recordingDownloadList = action.payload;
    },
    setFollowupResponse: (state, action) => {
      state.followupResponse = action.payload;
      var newMessages = state.messages;
      var newStats = { escalations: 0, total: 0, followup: 0 };
      newMessages.forEach((element) => {
        if (element.ID === action.payload.id) {
          element.FOLLOWUP_FLAG = action.payload.value;
        }
        newStats.total += 1;
        if (element.FOLLOWUP_FLAG === 'Y') {
          newStats.followup += 1;
        }

        if (element.ESCALATION_STATUS === 'PENDING') {
          newStats.escalations += 1;
        }
      });
      state.messageStats = newStats;
      state.messages = newMessages;
    },
    setClearEscalationResponse: (state, action) => {
      state.clearEscalationResponse = action.payload;
      var newMessages = state.messages;
      var newStats = { escalations: 0, total: 0, followup: 0 };

      newMessages.forEach((element) => {
        if (element.ID === action.payload.ID) {
          element.ESCALATION_STATUS = action.payload.ESCALATION_STATUS;
          element.ESCALATION_CLEARED_ID = action.payload.ESCALATION_CLEARED_ID;
          element.ESCALATION_CLEARED_BY_NAME = action.payload.ESCALATION_CLEARED_BY_NAME;
          element.ESCALATION_CLEARED_BY_WEBUSER_NAME =
            action.payload.ESCALATION_CLEARED_BY_WEBUSER_NAME;
          element.ESCALATION_CLEARED_NORMALISED_NAME =
            action.payload.ESCALATION_CLEARED_NORMALISED_NAME;
          element.ESCALATION_CLEARED_DATE = action.payload.ESCALATION_CLEARED_DATE;
          element.CLEAR_ESCALATION_REPLY_STATUS =
            action.payload.CLEAR_ESCALATION_REPLY_STATUS || null;

          if (action.payload.deliveryDetails) {
            element.deliveryDetails = action.payload.deliveryDetails;
          }
        }

        newStats.total += 1;
        if (element.FOLLOWUP_FLAG === 'Y') {
          newStats.followup += 1;
        }

        if (element.ESCALATION_STATUS === 'PENDING') {
          newStats.escalations += 1;
        }
      });
      state.messageStats = newStats;
      state.messages = newMessages;
    },
    setLastMsgQuery: (state, action) => {
      state.lastMsgQuery = action.payload;
    },
    setUpdateArchiveResponse: (state, action) => {
      state.updateArchiveResponse = action.payload;
      if (action.payload.message === 'message archived') {
        var newMessages = [];
        var newStats = { escalations: 0, total: 0, followup: 0 };

        state.messages.forEach((element) => {
          if (element.ID === action.payload.ID) {
            element.ARCHIVED_DATE = new Date().toISOString();
          } else {
            newStats.total += 1;
            if (element.FOLLOWUP_FLAG === 'Y') {
              newStats.followup += 1;
            }

            if (element.ESCALATION_STATUS === 'PENDING') {
              newStats.escalations += 1;
            }
            newMessages.push(element);
          }
        });
        state.messageStats = newStats;
        state.messages = newMessages;
      }
    },
    setQueryPattern: (state, action) => {
      state.queryPattern = action.payload;
    },
    setQueryPatternCase: (state, action) => {
      state.queryPatternCase = action.payload;
    },
    setQueryNoteAuthor: (state, action) => {
      state.queryNoteAuthor = action.payload;
    },
    setTextMatchClearEscalationPhrase: (state, action) => {
      state.textMatchClearEscalationPhrase = action.payload;
    },
    setFocusedMessageId: (state, action) => {
      state.focusedMessageId = action.payload;
    },
    setClearEscalationUpdate: (state, action) => {
      state.clearEscalationUpdate = action.payload;
    },
    setMessageById: (state, action) => {
      state.messageById = action.payload;
      //console.log("status", action.payload.ESCALATION_STATUS)
      if (action.payload && action.payload.ID) {
        var newMessages = state.messages.filter((element) => {
          if (element.ID === action.payload.ID) {
            if (action.payload.ESCALATION_STATUS === 'CLEARED' && state.escalationMessageQuery) {
              // If ESCALATION_STATUS is cleared, remove the message
              return false;
            } else {
              // Update the existing message
              element.ESCALATION_STATUS = action.payload.ESCALATION_STATUS;
              element.ESCALATION_CLEARED_ID = action.payload.ESCALATION_CLEARED_ID;
              element.ESCALATION_CLEARED_BY_NAME = action.payload.ESCALATION_CLEARED_BY_NAME;
              element.ESCALATION_CLEARED_BY_WEBUSER_NAME =
                action.payload.ESCALATION_CLEARED_BY_WEBUSER_NAME;
              element.ESCALATION_CLEARED_NORMALISED_NAME =
                action.payload.ESCALATION_CLEARED_NORMALISED_NAME;
              element.ESCALATION_CLEARED_DATE = action.payload.ESCALATION_CLEARED_DATE;
              element.CLEAR_ESCALATION_REPLY_STATUS =
                action.payload.CLEAR_ESCALATION_REPLY_STATUS || null;
              if (action.payload.deliveryDetails) {
                element.deliveryDetails = action.payload.deliveryDetails;
              }
            }
          }
          return true;
        });
        // var newMessages = state.messages;

        // newMessages.forEach((element) => {
        //   if (element.ID === action.payload.ID) {
        //     element.ESCALATION_STATUS = action.payload.ESCALATION_STATUS;
        //     element.ESCALATION_CLEARED_ID = action.payload.ESCALATION_CLEARED_ID;
        //     element.ESCALATION_CLEARED_BY_NAME = action.payload.ESCALATION_CLEARED_BY_NAME;
        //     element.ESCALATION_CLEARED_BY_WEBUSER_NAME =
        //       action.payload.ESCALATION_CLEARED_BY_WEBUSER_NAME;
        //     element.ESCALATION_CLEARED_NORMALISED_NAME =
        //       action.payload.ESCALATION_CLEARED_NORMALISED_NAME;
        //     element.ESCALATION_CLEARED_DATE = action.payload.ESCALATION_CLEARED_DATE;
        //     element.CLEAR_ESCALATION_REPLY_STATUS =
        //       action.payload.CLEAR_ESCALATION_REPLY_STATUS || null;
        //   }
        //   if (element.ID === action.payload.ID && action.payload.deliveryDetails) {
        //     element.deliveryDetails = action.payload.deliveryDetails;
        //   }
        //   var newStats = { escalations: 0, total: 0, followup: 0 };
        //   newStats.total += 1;
        //   if (element.FOLLOWUP_FLAG === 'Y') {
        //     newStats.followup += 1;
        //   }

        //   if (element.ESCALATION_STATUS === 'PENDING') {
        //     newStats.escalations += 1;
        //   }
        // });

        var newStats = { escalations: 0, total: 0, followup: 0 };
        newMessages.forEach((element) => {
          newStats.total += 1;
          if (element.FOLLOWUP_FLAG === 'Y') {
            newStats.followup += 1;
          }
          if (element.ESCALATION_STATUS === 'PENDING') {
            newStats.escalations += 1;
          }
        });
        state.messageStats = newStats;
        //console.log("newStats", newStats)
        state.messages = newMessages;
      }
    },
    setLiveUpdates: (state, action) => {
      state.liveUpdates = action.payload;
    },
    setEscalationMessageQuery: (state, action) => {
      state.escalationMessageQuery = action.payload;
    },
    setCustomToken: (state, action) => {
      state.customToken = action.payload;
    },
    setNewMessage: (state, action) => {
      //console.log("action.payload", action.payload)
      if (!state.messages) {
        state.messages = [];
      }
      const existingMessageIndex = state.messages.findIndex(
        (message) => message.ID === action.payload.ID,
      );
      if (existingMessageIndex !== -1) {
        // Update the existing message
        state.messages[existingMessageIndex] = action.payload;
      } else if (action.payload.ID) {
        // if (element.FOLLOWUP_FLAG === 'Y') {
        //         newStats.followup += 1;
        //     }

        //       if (element.ESCALATION_STATUS === 'PENDING') {
        //         newStats.escalations += 1;
        //       }

        state.messages = [action.payload, ...state.messages];
      }
      // Add the new message
      if (action.payload && action.payload.ID) {
        var newMessages = state.messages;
        var newStats = { escalations: 0, total: 0, followup: 0 };
        newMessages.forEach((element) => {
          newStats.total += 1;
          if (element.FOLLOWUP_FLAG === 'Y') {
            newStats.followup += 1;
          }

          if (element.ESCALATION_STATUS === 'PENDING') {
            newStats.escalations += 1;
          }
        });
        state.messageStats = newStats;
        //console.log("newStats", newStats)
      }
      //state.newMessage = action.payload;
      // if (action.payload && action.payload.ID && state.messages) {
      //   state.messages = [action.payload, ...state.messages];
      // }
    },
  },
});

export const {
  setMessageStats,
  setUpdateReceiverResponse,
  setAttachReceiverResponse,
  setDetachReceiverResponse,
  setLastMsgQuery,
  setForwardResponse,
  setClearEscalationResponse,
  setReplyResponse,
  setFollowupResponse,
  setDeliveryContacts,
  setMsgLoading,
  setMessages,
  setSelReceiver,
  setReceivers,
  setReminders,
  setSendMessage,
  setRecordings,
  setNoteResponse,
  setReminderResponse,
  setSelRecFeatures,
  setDownloadList,
  updateRecordings,
  setRecordingDownloadList,
  setUpdateArchiveResponse,
  setQueryPattern,
  setQueryPatternCase,
  setQueryNoteAuthor,
  setTextMatchClearEscalationPhrase,
  setFocusedMessageId,
  setClearEscalationUpdate,
  setMessageById,
  setLiveUpdates,
  setNewMessage,
  setEscalationMessageQuery,
  setCustomToken,
} = messageSlice.actions;

// A selector

export const messagesSelector = (state) => state.message.messages;

export const selReceiverSelector = (state) => state.message.selReceiver;

export const receiversSelector = (state) => state.message.receivers;

export const remindersSelector = (state) => state.message.reminders;

export const recordingsSelector = (state) => state.message.recordings;

export const noteResponseSelector = (state) => state.message.noteResponse;

export const reminderResponseSelector = (state) => state.message.reminderResponse;

export const selRecFeaturesSelector = (state) => state.message.selRecFeatures;

export const msgLoadingSelector = (state) => state.message.msgLoading;

export const downloadListSelector = (state) => state.message.downloadList;

export const recordingDownloadListSelector = (state) => state.message.recordingDownloadList;

export const deliveryContactsSelector = (state) => state.message.deliveryContacts;

export const followupResponseSelector = (state) => state.message.followupResponse;

export const forwardResponseSelector = (state) => state.message.forwardResponse;

export const lastMsgQuerySelector = (state) => state.message.lastMsgQuery;

export const receiverAttachResponseSelector = (state) => state.message.attachReceiverResponse;

export const detachReceiverResponseSelector = (state) => state.message.detachReceiverResponse;

export const updateAttachResponseSelector = (state) => state.message.updateReceiverResponse;

export const messageStatsSelector = (state) => state.message.messageStats;
export const queryPatternSelector = (state) => state.message.queryPattern;
export const queryPatternCaseSelector = (state) => state.message.queryPatternCase;
export const queryNoteAuthorSelector = (state) => state.message.queryNoteAuthor;
export const textMatchClearEscalationPhraseSelector = (state) =>
  state.message.textMatchClearEscalationPhrase;

export const clearEscalationUpdateSelector = (state) => state.message.clearEscalationUpdate;

export const focusedMessageIdSelector = (state) => state.message.focusedMessageId;

export const messageByIdSelector = (state) => state.message.messageById;
export const liveUpdatesSelector = (state) => state.message.liveUpdates;
export const newMessageSelector = (state) => state.message.newMessage;
export const escalationMessageQuerySelector = (state) => state.message.escalationMessageQuery;
export const customTokenSelector = (state) => state.message.customToken;

export function getRemindersForReceiver(receiverId) {
  return (dispatch) => {
    dispatch(setReminders([]));
    try {
      PortalApi.call(
        `/reminders/${receiverId}`,
        { method: 'get' },
        (response) => {
          dispatch(setReminders(response));
        },
        (error, status, content) => {
          dispatch(setReminders([]));
        },
      );
    } catch (error) {
      dispatch(setReminders([]));
    }
  };
}

export function getLinkedDeliveryContacts() {
  return (dispatch) => {
    dispatch(setDeliveryContacts([]));
    try {
      PortalApi.call(
        '/users/contacts',
        { method: 'get' },
        (response) => {
          dispatch(setDeliveryContacts(response));
        },
        (error, status, content) => {
          dispatch(setDeliveryContacts([]));
        },
      );
    } catch (error) {
      dispatch(setDeliveryContacts([]));
    }
  };
}

export function updateFollowUp(messageId, followup, receiverId) {
  return (dispatch) => {
    dispatch(setFollowupResponse(''));
    try {
      PortalApi.call(
        `/message/${messageId}/followup/${followup}`,
        { method: 'get' },
        (response) => {
          dispatch(setFollowupResponse({ flag: 'success', id: messageId, value: followup }));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Followup updated' }));
        },
        (error, status, content) => {
          dispatch(setFollowupResponse('error'));
          //dispatch(getMessagesForUser(receiverId,moment.utc().subtract(7,'d').format("yyyyMMDDHHmmss"),moment.utc().format("yyyyMMDDHHmmss")))
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Followup update failed' }));
        },
      );
    } catch (error) {
      dispatch(setFollowupResponse('error'));
    }
  };
}

export function getMessagesForUser(receiverId, startDate, endDate, query) {
  return (dispatch) => {
    try {
      dispatch(setMsgLoading(true));
      dispatch(setMessages(null));
      if (!query) {
        query = `pattern=&patternCase=&queryTimeLimit=45000&messageLimit=50&startDate=${encodeURIComponent(
          startDate,
        )}&endDate=${encodeURIComponent(endDate)}`;
      }
      dispatch(setLastMsgQuery(`/message/receiver/${encodeURIComponent(receiverId)}/?${query}`));
      PortalApi.call(
        `/message/receiver/${encodeURIComponent(receiverId)}/?${query}`,
        { method: 'get' },
        (response) => {
          dispatch(setMessages(response.messages));
          var downloadList = [];
          var newStats = { escalations: 0, total: 0, followup: 0 };
          response.messages.forEach((element) => {
            downloadList.push(element.ID);
            newStats.total += 1;
            if (element.FOLLOWUP_FLAG === 'Y') {
              newStats.followup += 1;
            }

            if (element.ESCALATION_STATUS === 'PENDING') {
              newStats.escalations += 1;
            }
          });
          dispatch(setMessageStats(newStats));

          dispatch(setDownloadList(downloadList));
          dispatch(setMsgLoading(false));
        },
        (error, status, content) => {
          dispatch(setMsgLoading(false));
        },
      );
    } catch (error) {
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Could not load new messages,try again !',
        }),
      );
      dispatch(setMsgLoading(false));
    }
  };
}

export function startMessageDownload(checkedList, deliveryFlag) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    try {
      PortalApi.call(
        `/message/download/${encodeURIComponent(deliveryFlag)}`,
        { method: 'post', body: JSON.stringify(checkedList) },
        (response) => {
          saveAs(response, 'Messages.pdf', { autoBom: true });
          dispatch(setMsgLoading(false));
        },
        (error, status, content) => {
          dispatch(setMsgLoading(false));
        },
      );
    } catch (error) {
      dispatch(setMsgLoading(false));
    }
  };
}

export function getMessagesByContact(contactId, startDate, endDate, query) {
  return (dispatch) => {
    try {
      dispatch(setMessages(null));
      dispatch(setMsgLoading(true));
      if (!query) {
        query = `pattern=&patternCase=&queryTimeLimit=45000&messageLimit=50&startDate=${encodeURIComponent(
          startDate,
        )}&endDate=${encodeURIComponent(endDate)}`;
      }
      dispatch(
        setLastMsgQuery(
          `/message/receiver/${encodeURIComponent(null)}/?contactId=${contactId}&${query}`,
        ),
      );
      PortalApi.call(
        `/message/receiver/${encodeURIComponent(null)}/?contactId=${contactId}&${query}`,
        { method: 'get' },
        (response) => {
          dispatch(setMessages(response.messages));
          var downloadList = [];
          var newStats = { escalations: 0, total: 0, followup: 0 };
          response.messages.forEach((element) => {
            downloadList.push(element.ID);
            newStats.total += 1;
            if (element.FOLLOWUP_FLAG === 'Y') {
              newStats.followup += 1;
            }

            if (element.ESCALATION_STATUS === 'PENDING') {
              newStats.escalations += 1;
            }
          });
          dispatch(setMessageStats(newStats));
          dispatch(setDownloadList(downloadList));
          dispatch(setMsgLoading(false));
        },
        (error, status, content) => {
          dispatch(setMessages([]));
          dispatch(setMsgLoading(false));
        },
      );
    } catch (error) {
      dispatch(setMessages([]));
      dispatch(setMsgLoading(false));
    }
  };
}

export function sendMessage(message, receiverId) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    dispatch(setSendMessage(''));
    try {
      var msg = {
        receiver: receiverId,
        message: message,
      };
      PortalApi.call(
        '/message',
        { method: 'post', body: JSON.stringify(msg) },
        (stats) => {
          dispatch(setSendMessage(stats));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Message sent!' }));
        },
        (statusText, content, status) => {
          dispatch(setSendMessage(''));
          dispatch(setMsgLoading(false));
          try {
            if (content?.status?.toLowerCase()?.includes('no record found for the pager')) {
              dispatch(
                setPortalSnackInfo({
                  severity: 'error',
                  message: `No record found for the pager=[${receiverId}].`,
                }),
              );
            } else {
              dispatch(setPortalSnackInfo({ severity: 'error', message: content?.status }));
            }
          } catch (cError) {
            console.log('cError', cError);
            dispatch(
              setPortalSnackInfo({ severity: 'error', message: 'Oops, something went wrong.' }),
            );
          }
        },
      );
    } catch (error) {
      dispatch(setSendMessage(''));
      dispatch(setMsgLoading(false));
    }
  };
}

export function sendForwardMessage(
  message,
  receiverId,
  toAddress,
  fromAddress,
  forwardDomain,
  subject,
) {
  return (dispatch) => {
    dispatch(setForwardResponse(''));
    try {
      var msg = {
        receiverAccount: receiverId,
        message: message,
        sender: 'test',
        toAddress: toAddress,
        replyToAddress: '',
        fromAddress: fromAddress,
        forwardDomain: forwardDomain,
        subject: subject,
      };
      PortalApi.call(
        '/message/forward',
        { method: 'post', body: JSON.stringify(msg) },
        (stats) => {
          dispatch(setForwardResponse(stats));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Message forwarded' }));
        },
        (error, status, content) => {
          dispatch(setForwardResponse('FAILED'));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Forwarding message failed' }));
        },
      );
    } catch (error) {
      dispatch(setForwardResponse('FAILED'));
    }
  };
}

export function clearMessageEscalation(operator, port, messageId) {
  return (dispatch) => {
    dispatch(setClearEscalationResponse({}));
    dispatch(setClearEscalationUpdate({}));
    try {
      var msg = {
        operator: operator,
        port: port,
      };
      PortalApi.call(
        `/message/${messageId}/escalation`,
        { method: 'post', body: JSON.stringify(msg) },
        (stats) => {
          const esclationMessageStat = stats[0];
          dispatch(setClearEscalationResponse(stats[0]));
          if (esclationMessageStat['CLEAR_ESCALATION_REPLY_STATUS'] === 'PENDING_REPLY') {
            dispatch(
              setPortalSnackInfo({
                severity: 'warning',
                message:
                  'No need to clear escalation on your reply - our team is addressing your response.',
              }),
            );
          } else {
            dispatch(setPortalSnackInfo({ severity: 'success', message: 'Escalation cleared ' }));
            if (esclationMessageStat.ESCALATION_STATUS === 'CLEARED') {
              dispatch(
                setClearEscalationUpdate({
                  id: esclationMessageStat['ID'],
                  status: esclationMessageStat['ESCALATION_STATUS'],
                }),
              );
            }
          }
        },
        (error, status, content) => {
          dispatch(setClearEscalationResponse({}));
          dispatch(setClearEscalationUpdate({}));
          dispatch(
            setPortalSnackInfo({ severity: 'error', message: 'Could not clear escalation ' }),
          );
        },
      );
    } catch (error) {
      dispatch(setClearEscalationResponse({}));
      dispatch(setClearEscalationUpdate({}));
    }
  };
}

export function updateArchive(messageId, receiver, archiveFlag) {
  return (dispatch) => {
    // dispatch(setMsgLoading(true))
    dispatch(setUpdateArchiveResponse({}));
    try {
      var msg = {};
      PortalApi.call(
        `/message/${encodeURIComponent(messageId)}/archiveDC/${encodeURIComponent(archiveFlag)}`,
        { method: 'post', body: JSON.stringify(msg) },
        (stats) => {
          dispatch(setUpdateArchiveResponse({ ...stats, ID: messageId }));
          //dispatch(setMsgLoading(false))
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Message archived ' }));
        },
        (error, status, content) => {
          dispatch(setUpdateArchiveResponse({}));
          //dispatch(setMsgLoading(false))
          dispatch(
            setPortalSnackInfo({ severity: 'error', message: 'Could not archive message ' }),
          );
        },
      );
    } catch (error) {
      dispatch(setUpdateArchiveResponse({}));
      dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not archive message ' }));
      //dispatch(setMsgLoading(false))
    }
  };
}

export function sendReply(
  messageId,
  message,
  messageReply,
  pagerId,
  rosterId,
  accountNum,
  operator,
) {
  return (dispatch) => {
    if (messageReply) {
      dispatch(setReplyResponse(''));
      dispatch(setCustomizedProgressLoading(true));
      try {
        var msg = {
          message: message,
          messageReply: messageReply,
          pagerId: pagerId,
          rosterId: rosterId,
          accountNumber: accountNum,
          operator: operator,
          messageId: messageId,
        };
        PortalApi.call(
          '/message/reply',
          { method: 'post', body: JSON.stringify(msg) },
          (stats) => {
            if (stats && stats.length > 0) {
              dispatch(setReplyResponse(stats[0]));
            } else {
              dispatch(setReplyResponse({}));
            }
            dispatch(setPortalSnackInfo({ severity: 'success', message: 'Reply sent.' }));
            dispatch(setCustomizedProgressLoading(false));
            // stats[0] from response does not always send latest message data as background task requires few secs.
            // thus, we refetch message data for 3 seconds.
            setTimeout(async () => {
              try {
                const msgRes = await PortalApi.callPromise(`/message/${messageId}`);
                if (msgRes && msgRes.ok) {
                  const data = await msgRes?.json();
                  dispatch(setReplyResponse(data[0]));
                }
              } catch (pError) {
                console.error('Error', pError);
              }
            }, 5000);
          },
          (error, status, content) => {
            dispatch(setReplyResponse({}));
            dispatch(setPortalSnackInfo({ severity: 'error', message: 'Failed to send reply.' }));
            dispatch(setCustomizedProgressLoading(false));
          },
        );
      } catch (error) {
        dispatch(setReplyResponse({}));
        dispatch(setCustomizedProgressLoading(false));
      }
    }
  };
}

export function sendNote(messageId, from, note, receiver) {
  return (dispatch) => {
    //dispatch(setMsgLoading(true))
    dispatch(setNoteResponse(''));
    try {
      var msg = {
        receiver: receiver,
        note: `From: ${from}, ${note}`,
        addedBy: from,
      };
      PortalApi.call(
        `/message/${encodeURIComponent(messageId)}/note`,
        { method: 'post', body: JSON.stringify(msg) },
        (stats) => {
          if (stats.result === 'SUCCESS') {
            dispatch(setNoteResponse({ response: stats, ID: messageId, from: from, note: note }));
            dispatch(setPortalSnackInfo({ severity: 'success', message: 'Note saved' }));
            //dispatch(setLastMsgQuery("reload"))
          } else {
            dispatch(setPortalSnackInfo({ severity: 'error', message: stats.resultDescription }));
          }

          //dispatch(setMsgLoading(false))
        },
        (error, status, content) => {
          dispatch(setNoteResponse('ERROR'));
          // dispatch(setMsgLoading(false))
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not save a note' }));
        },
      );
    } catch (error) {
      dispatch(setNoteResponse('ERROR'));
      // dispatch(setMsgLoading(false))
    }
  };
}

export function getCustomToken(receiverIds) {
  //console.log("get custom token call")
  return (dispatch) => {
    //dispatch(setCustomToken(''));
    var data = {
      receiverIds: receiverIds,
    };
    try {
      PortalApi.call(
        `/users/customToken`,
        { method: 'post', body: JSON.stringify(data) },
        (stats) => {
          //console.log("stats", stats)
          dispatch(setCustomToken(stats.customToken));
        },
        (error, status, content) => {
          var errorData = error;
        },
      );
    } catch (error) {
      //dispatch(setReminderResponse(''));
    }
  };
}

export function createReminder(receiverId, body, rname) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    dispatch(setReminderResponse(''));
    try {
      PortalApi.call(
        `/reminders/${encodeURIComponent(receiverId)}/?requestorName=WEB:${rname}`,
        { method: 'post', body: JSON.stringify(body) },
        (stats) => {
          dispatch(setReminderResponse(stats));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Reminder created' }));
          dispatch(getRemindersForReceiver(receiverId));
        },
        (error, status, content) => {
          dispatch(setReminderResponse(''));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: status.message }));
        },
      );
    } catch (error) {
      dispatch(setReminderResponse(''));
    }
  };
}

export function updateReminder(receiverId, body, rname) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    dispatch(setReminderResponse(''));
    try {
      PortalApi.call(
        `/reminders/${encodeURIComponent(receiverId)}/?requestorName=WEB:${rname}`,
        { method: 'put', body: JSON.stringify(body) },
        (stats) => {
          dispatch(setReminderResponse(stats));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Reminder updated' }));
          dispatch(getRemindersForReceiver(receiverId));
        },
        (error, status, content) => {
          dispatch(setReminderResponse(''));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Error updating reminder' }));
        },
      );
    } catch (error) {
      dispatch(setReminderResponse(''));
    }
  };
}

export function deleteReminder(receiverId, body, rname) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    dispatch(setReminderResponse(''));
    try {
      PortalApi.call(
        `/reminders/${encodeURIComponent(receiverId)}/?requestorName=WEB:${rname}`,
        { method: 'delete', body: JSON.stringify(body) },
        (stats) => {
          dispatch(setReminderResponse(stats));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Reminder deleted' }));
          dispatch(getRemindersForReceiver(receiverId));
        },
        (error, status, content) => {
          dispatch(setReminderResponse(''));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Error deleting reminder' }));
        },
      );
    } catch (error) {
      dispatch(setReminderResponse(''));
    }
  };
}

export function getReceivers() {
  return (dispatch) => {
    dispatch(setReceivers([]));
    try {
      PortalApi.call(
        '/serviceitems/list',
        { method: 'get' },
        (response) => {
          dispatch(setReceivers(response));
          if (response.length > 0) {
            var selReceiver = 0;
            if (localStorage.getItem('lastSelReceiver')) {
              selReceiver = localStorage.getItem('lastSelReceiver');
            } else {
              selReceiver = response[0].number;
              localStorage.setItem('lastSelReceiverName', response[0].holderTxt);
            }
            dispatch(setSelReceiver(selReceiver));
            dispatch(getRemindersForReceiver(selReceiver));
            dispatch(
              getRecordings(
                selReceiver,
                moment.utc().subtract(1, 'd').format('DD-MMM-YY'),
                moment.utc().format('DD-MMM-YY'),
              ),
            );
          }
        },
        (error, status, content) => {
          dispatch(setReceivers([]));
        },
      );
    } catch (error) {
      dispatch(setReceivers([]));
    }
  };
}

export function getRecordings(receiverNumber, startDate, endDate) {
  return (dispatch) => {
    //dispatch(setRecordings([]))
    dispatch(setMsgLoading(true));
    try {
      PortalApi.call(
        `/recordings/list/${receiverNumber}?startDate=${encodeURIComponent(
          startDate,
        )}&endDate=${encodeURIComponent(endDate)}`,
        { method: 'get' },
        (response) => {
          var downloadList = [];
          response.forEach((element) => {
            if (downloadList.length < 100) {
              downloadList.push(element.id);
            }
          });
          dispatch(setRecordingDownloadList(downloadList));

          dispatch(setRecordings(response));
          dispatch(setMsgLoading(false));
        },
        (error, status, content) => {
          dispatch(setRecordings([]));
          dispatch(setMsgLoading(false));
        },
      );
    } catch (error) {
      dispatch(setRecordings([]));
      dispatch(setMsgLoading(false));
    }
  };
}

export function getRecordingById(recordingId) {
  return (dispatch) => {
    //dispatch(setRecordings([]))
    dispatch(setMsgLoading(true));
    try {
      PortalApi.call(
        `/recordings/${recordingId}/audio`,
        { method: 'get' },
        (response) => {
          dispatch(updateRecordings({ id: recordingId, audio: response }));
          dispatch(setMsgLoading(false));
        },
        (error, status, content) => {
          //dispatch(setRecordings([]))
          dispatch(setMsgLoading(false));
        },
      );
    } catch (error) {
      //dispatch(setRecordings([]))
      dispatch(setMsgLoading(false));
    }
  };
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

export function downloadRecordings(checkedList) {
  return (dispatch) => {
    //dispatch(setRecordings([]))
    dispatch(setMsgLoading(true));
    try {
      PortalApi.call(
        '/recordings/download',
        { method: 'post', body: JSON.stringify(checkedList) },
        (response) => {
          //let data = s2ab(response);
          saveAs(response, 'recordings.zip', { autoBom: true });
          dispatch(setMsgLoading(false));
        },
        (error, status, content) => {
          //dispatch(setRecordings([]))
          dispatch(setMsgLoading(false));
        },
      );
    } catch (error) {
      //dispatch(setRecordings([]))
      dispatch(setMsgLoading(false));
    }
  };
}

export function updateReceiver(receiver, nickname, displayOrder) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    try {
      dispatch(setUpdateReceiverResponse(''));
      PortalApi.call(
        `/serviceitems/${encodeURIComponent(receiver)}/updateAttach?nickName=${encodeURIComponent(
          nickname,
        )}`,
        { method: 'get' },
        (stats) => {
          dispatch(setUpdateReceiverResponse(stats));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Receiver updated ' }));
          dispatch(getReceivers());
        },
        (error, status, content) => {
          dispatch(setUpdateReceiverResponse('ERROR'));
          dispatch(
            setPortalSnackInfo({ severity: 'error', message: 'Failed to update receiver.' }),
          );
        },
      );
    } catch (error) {
      dispatch(setUpdateReceiverResponse('ERROR'));
    }
  };
}

export function attachReceiver(receiver, pin) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    try {
      dispatch(setAttachReceiverResponse(''));
      PortalApi.call(
        `/serviceitems/${encodeURIComponent(receiver)}/attach?password=${pin}`,
        { method: 'get' },
        (stats) => {
          dispatch(setAttachReceiverResponse(stats));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Receiver attached !' }));
          dispatch(getReceivers());
        },
        (error, content, status) => {
          dispatch(setMsgLoading(false));
          dispatch(setAttachReceiverResponse('ERROR'));
          console.log(error, '---', status, '---', content);
          dispatch(setPortalSnackInfo({ severity: 'error', message: content.message }));
        },
      );
    } catch (error) {
      dispatch(setMsgLoading(false));
      dispatch(setAttachReceiverResponse('ERROR'));
    }
  };
}

export function detachReceiver(receiver) {
  return (dispatch) => {
    dispatch(setMsgLoading(true));
    try {
      dispatch(setDetachReceiverResponse(''));
      PortalApi.call(
        `/serviceitems/${encodeURIComponent(receiver)}/deattach`,
        { method: 'delete' },
        (stats) => {
          dispatch(setDetachReceiverResponse(stats));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Receiver detached ' }));
          dispatch(getReceivers());
          dispatch(setMsgLoading(false));
        },
        (error, status, content) => {
          dispatch(setDetachReceiverResponse(''));
          dispatch(setMsgLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Failed to remove receiver' }));
        },
      );
    } catch (error) {
      dispatch(setDetachReceiverResponse(''));
      dispatch(setMsgLoading(false));
    }
  };
}

export function getMessageById(messageId) {
  return (dispatch) => {
    try {
      dispatch(setMessageById({}));
      PortalApi.call(
        `/message/${messageId}`,
        { method: 'get' },
        (response) => {
          // console.log("get message by Id", response);
          // if (response.status === 200) {
          //   //const stats = response.data;
          dispatch(setMessageById(response[0]));
          //}
          //console.log("get message by Id", response[0]);
        },
        (error, status, content) => {
          dispatch(setMessageById({}));
          console.error('Error', error);
        },
      );
    } catch (error) {
      dispatch(setMessageById({}));
      console.error('Error', error);
    }
  };
}

export function getMessageByRosterId(rosterId, receiverId) {
  return (dispatch) => {
    try {
      dispatch(setNewMessage({}));
      PortalApi.call(
        `/message/roster/${rosterId}?receiverId=${receiverId}`,
        { method: 'get' },
        (response) => {
          // console.log("get message by Id", response);
          // if (response.status === 200) {
          //   //const stats = response.data;
          dispatch(setNewMessage(response[0]));
          //}
          //console.log("get message by Id", response[0]);
        },
        (error, status, content) => {
          dispatch(setNewMessage({}));
          console.error('Error', error);
        },
      );
    } catch (error) {
      console.error('Error', error);
      // dispatch(setNewMessage({}));
    }
  };
}

export function determineIfTextMatchClearEscalationPhrase(text) {
  return (dispatch) => {
    dispatch(setTextMatchClearEscalationPhrase(null));
    dispatch(setCustomizedProgressLoading(true));
    try {
      PortalApi.call(
        `/message/escalation/replyTextMatchClearEscalationPhrase?term=${text}`,
        { method: 'get' },
        (response) => {
          dispatch(
            setTextMatchClearEscalationPhrase(
              response && response['matches'] ? 'matched' : 'unmatched',
            ),
          );
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          dispatch(setTextMatchClearEscalationPhrase(null));
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setTextMatchClearEscalationPhrase(null));
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export const getPortalUserReceiverIds = async () => {
  try {
    const res = await PortalApi.callPromise('/serviceitems/receiverIds');
    if (res && res.ok) {
      const receiverIds = await res?.json();
      if (receiverIds && Array.isArray(receiverIds) && receiverIds?.length > 0) {
        console.log('getPortalUserReceiverIds', receiverIds);
        return receiverIds;
      }
      return [];
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error:', error);
    return [];
  }
};

export function getSearchedReceivers(
  { accountId, receiverNum, receiverName, serviceNumOrHolderTxt },
  callback,
) {
  return (dispatch) => {
    const queryParams = getURLSearchParamsString({
      accountId,
      serviceNum: receiverNum,
      holderTxt: receiverName,
      serviceNumOrHolderTxt,
    });
    try {
      PortalApi.call(
        `/serviceitems/search?${queryParams}`,
        { method: 'get' },
        (response) => {
          if (callback) {
            callback(response);
          }
        },
        (error, status, content) => {
          if (callback) {
            callback('error');
          }
        },
      );
    } catch (error) {
      if (callback) {
        callback('error');
      }
    }
  };
}

export default messageSlice.reducer;
