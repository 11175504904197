import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Box,
  Toolbar,
  TablePagination,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Visibility from '@mui/icons-material/Visibility';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { portalUserSelector, setPortalSnackInfo } from '../../app/authSlice';
import {
  addVerifyCallerId,
  addVerifyCallerResponseSelector,
  getVerifiedCallerIdsList,
  verifiedCallerIdsSelector,
} from './staffSlice';
import AccountLookup from './AccountLookup';
import { searchAccountsSelector, selectedSearchAccountSelector } from '../accounts/accountSlice';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'VerifiedCallerIds';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function VerifiedCallerIds() {
  const dispatch = useDispatch();
  const portalUser = useSelector(portalUserSelector);
  const callerIds = useSelector(verifiedCallerIdsSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [chooseNumber, setChooseNumber] = useState('');
  const [type, setType] = useState('3');
  const account = useSelector(selectedSearchAccountSelector);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [selectedAccount, setSelectedAccount] = useState({});
  const [types, setTypes] = useState({
    1: 'Account',
    2: 'User',
    3: 'Company',
  });
  const accountSearchResponse = useSelector(searchAccountsSelector);
  const response = useSelector(addVerifyCallerResponseSelector);

  const handleChange = (event) => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'chooseNumber':
        setChooseNumber(value);
        break;
      case 'type':
        setType(value);
        break;
      default:
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const verifyCaller = () => {
    dispatch(addVerifyCallerId(phoneNumber, selectedAccountId, chooseNumber));
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getVerifiedCallerIdsList(page, rowsPerPage, ''));
  }, [dispatch, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (account && accountSearchResponse && accountSearchResponse.length > 0) {
      var currentAccount = accountSearchResponse.filter((element) => element.ACNT_NUM === account);
      if (currentAccount.length === 1) {
        setSelectedAccountId(currentAccount[0].ACCOUNT_ID);
        setSelectedAccount(currentAccount[0]);
        //setBuyOptionVisible(true)
      } else {
        //setBuyOptionVisible(false)
      }
    } else {
      //setBuyOptionVisible(false)
    }
  }, [account]);

  return (
    <StyledRoot className={classes.root}>
      {/* <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="table">
          <TableHead>
            <TableCell>Caller Id</TableCell>
            <TableCell>OTP</TableCell>
            <TableCell>Status</TableCell>
          </TableHead>
          <TableBody>
            {callerIds &&
              callerIds.content &&
              callerIds.content.map((row) => (
                <TableRow>
                  <TableCell scope="row">{row.phoneNumber}</TableCell>
                  <TableCell scope="row">{row.otp}</TableCell>
                  <TableCell scope="row">{row.status}</TableCell>
                </TableRow>
              ))}

            {callerIds &&
              callerIds.content &&
              callerIds.content.length === 0 && (
                <Typography> No records found</Typography>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={callerIds.totalElements ? callerIds.totalElements : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      /> */}

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">Add Verified Caller ID</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Choose Number</InputLabel>
            <Select
              fullWidth
              id="vc-type-labelselector"
              label="Choose Number"
              labelId="vc-type-label"
              name="chooseNumber"
              onChange={handleChange}
              value={chooseNumber}
            >
              {selectedAccount &&
                selectedAccount.twilioNumber &&
                selectedAccount.twilioNumber.split(',').map((val) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
            </Select>
            <TextField
              fullWidth
              label="Caller ID"
              name="phoneNumber"
              onChange={handleChange}
              value={phoneNumber}
            />
          </FormControl>

          {/* <FormControl className={classes.formControl}>
            <Select
              labelId="vc-type-label"
              id="vc-type-labelselector"
              name="type"
              value={type}
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value="1">1 - Account</MenuItem>
              <MenuItem value="2">2- User</MenuItem>
            </Select>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <PortalButton color="primary" onClick={verifyCaller} variant="contained">
            Save
          </PortalButton>
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>
      <AccountLookup mode="callmask" />
    </StyledRoot>
  );
}
