import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import { NonDisplayableEntities } from '../../../../utils/assemblyAIUtil';

const PREFIX = 'SentimentEntitiesDetected';

const classes = {
  tableContainer: `${PREFIX}-tableContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  graphContainer: `${PREFIX}-graphContainer`,
};

const StyledRoot = styled('div')({
  [`& .${classes.tableContainer}`]: { boxShadow: '0 1px 1px 0 #4dd0e182', borderRadius: '5px' },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '16px' },
  [`& .${classes.graphContainer}`]: {
    backgroundColor: '#f8fdff',
    padding: '8px',
    borderRadius: '10px',
  },
});

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#ff5722cc',
  color: theme.palette.common.white,
  padding: '1px',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: '1px 5px',
}));

const getEntitiesText = (item) => {
  try {
    return Array.from(new Set(item.map((obj) => obj.text))).join(', ');
  } catch {
    return '';
  }
};

const formatEntityType = (entityType) => {
  try {
    const words = entityType.split('_');
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
  } catch {
    return entityType;
  }
};

export default function SentimentEntitiesDetected({ entities }) {
  return (
    <StyledRoot>
      {entities && Object.keys(entities).length > 0 && (
        <Box className={classes.graphContainer}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="Entities" className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell colSpan="2">
                    <Typography className={classes.sectionTitle}>Entities</Typography>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(entities)
                  .filter((e) => !NonDisplayableEntities.includes(e))
                  .map((entity_type, index) => (
                    <TableRow key={index}>
                      <StyledTableBodyCell component="th" scope="row" style={{ fontWeight: '500' }}>
                        {formatEntityType(entity_type)}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        dangerouslySetInnerHTML={{
                          __html: getEntitiesText(entities[entity_type]),
                        }}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </StyledRoot>
  );
}
