import {
  and,
  collection,
  doc,
  limit,
  or,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { firestoreDb } from '../../firebaseConfig';

export const getFirestoreCollectionReferencePath = (path) => {
  return path;
};

/**
 * Creates a Firestore query with multiple where, orderBy clauses, limit value, and composite filters.
 * @param {object} collectionPath - The Firestore collection path
 * @param {Array<Array<string|number>>} whereClauses - An array of where clauses.
 *  Each clause should be an array with three elements: [field, operator, value]. eg.  ['field1', '==', 'value1'],
 * @param {Array<Array<string>>} orderByClauses - An array of orderBy clauses.
 * Each clause should be an array with two elements: [field, direction]. eg. ['field1', 'asc'],
 * @param {object} compositeFilter - An object representing composite filters with `and` and `or` keys.
 * example of compositeFilter structure:
 *{
    and: [
      {
        or: [
          ['field1', '==', 'value1'],
          ['field2', '>', 10],
        ],
      },
      ['field3', '!=', null],
    ],
  };
 * @param {number} limitValue - The maximum number of documents to return.
 * @returns {object} - The Firestore query.
 */
export const createFirestoreCollectionQuery = ({
  collectionPath,
  compositeFilterClauses = [],
  whereClauses = [],
  orderByClauses = [],
  limitValue,
}) => {
  let constraints = [];
  if (compositeFilterClauses && Object.keys(compositeFilterClauses).length > 0) {
    const compose = createCompositeFilter(compositeFilterClauses);
    if (compose) constraints = [...constraints, compose];
  } else {
    if (whereClauses && Array.isArray(whereClauses) && whereClauses.length > 0) {
      constraints = [...constraints, ...whereClauses.map((clause) => where(...clause))];
    }
  }

  if (orderByClauses && Array.isArray(orderByClauses) && orderByClauses.length > 0) {
    constraints = [...constraints, ...orderByClauses.map((clause) => orderBy(...clause))];
  }

  if (limitValue !== undefined) {
    constraints.push(limit(limitValue));
  }
  console.log('query constraints', constraints);
  return query(collection(firestoreDb, collectionPath), ...constraints);
};

/**
 * Creates a composite filter for Firestore queries.
 * @param {object} filter - An object representing composite filters with `and` and `or` keys.
 * @returns {object} - The Firestore composite filter.
 */
const createCompositeFilter = (filter) => {
  if (filter.and) {
    return and(
      ...filter.and.map((clause) => {
        if (Array.isArray(clause)) {
          return where(...clause);
        } else {
          return createCompositeFilter(clause);
        }
      }),
    );
  } else if (filter.or) {
    return or(
      ...filter.or.map((clause) => {
        if (Array.isArray(clause)) {
          return where(...clause);
        } else {
          return createCompositeFilter(clause);
        }
      }),
    );
  }
  return null;
};

/**
 * Generates a Firestore document reference.
 * @param {string} path - The path to the document (e.g., 'collectionName/docId').
 * @returns {object} - The Firestore document reference.
 */
export const getFirestoreDocRef = (path) => {
  return doc(firestoreDb, path);
};
/**
 * @param ref - The location to write to.
 * @param data - The data to be written (string, number, boolean, object,
 *   array, or null).
 * @returns A `Promise` resolved once the data has been successfully written
 */
export const setFirestoreDoc = (ref, data) => {
  return setDoc(ref, data, { merge: true });
};

/**
 * @param ref -  A reference to the document to update.
 * @param data - The data to be updated (string, number, boolean, object,
 *   array, or null).
 * @returns A `Promise` resolved once the data has been successfully written
 */
export const updateFirestoreDoc = (ref, data) => {
  return updateDoc(ref, data);
};
