import { Divider } from '@mui/material';

export default function PortalDivider({
  flexItem = true,
  orientation = 'horizontal',
  variant = 'middle',
  sx,
}) {
  return <Divider flexItem={flexItem} orientation={orientation} sx={{ ...sx }} variant={variant} />;
}
