import { styled } from '@mui/material/styles';
import ReceiverSettings from './ReceiverSettings';
import AccountSettings from './AccountSettings';
import { Grid } from '@mui/material';

const PREFIX = 'Settings';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export default function Settings() {
  return (
    <StyledGrid className={classes.root} container spacing={2}>
      <AccountSettings />
      <ReceiverSettings />
    </StyledGrid>
  );
}
