import DOMPurify from 'dompurify';

export default function PortalHighlightedText({ className, pattern, text }) {
  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          pattern && pattern?.length > 0
            ? text?.replace(
                new RegExp(pattern, 'gi'),
                '<span style="background-color:yellow;">$&</span>',
              )
            : text,
        ),
      }}
    />
  );
}
