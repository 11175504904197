import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { AlarmObjectStruct, formatAlarmTimestamp } from '../../../utils/alarmUtil';
import AlarmListTableActionColumn from './AlarmListTableActionColumn';
import { getFromNowOfDate } from '../../../utils/dateUtil';
import { PortalBootstrapTooltip, PortalHtmlTooltip } from '../../shared/components/PortalTooltip';
import PortalCopyClipboard from '../../shared/components/PortalCopyClipboard';

const ParentAlarmListTableActionColumn = ({ selectedRow }) => {
  return <AlarmListTableActionColumn selectedRow={selectedRow} />;
};

const getColumns = ({ columnsToRender, timezone }) => {
  const columns = [];
  if (!columnsToRender) {
    return [];
  }
  const addedFields = new Set();

  const addColumn = (column) => {
    if (!addedFields.has(column.field)) {
      columns.push(column);
      addedFields.add(column.field);
    }
  };

  if (Object.prototype.hasOwnProperty.call(columnsToRender, AlarmObjectStruct.created_timestamp)) {
    addColumn({
      field: AlarmObjectStruct.created_timestamp,
      headerName: 'Created At',
      description: 'Created Timestamp',
      type: 'string',
      flex: 1,
      valueGetter: (value) => formatAlarmTimestamp(value, timezone),
      renderCell: (params) => {
        const formattedValue = formatAlarmTimestamp(params.value, timezone);
        return (
          <PortalBootstrapTooltip title={formattedValue}>
            <span>{formattedValue}</span>
          </PortalBootstrapTooltip>
        );
      },
    });
  }
  if (Object.prototype.hasOwnProperty.call(columnsToRender, AlarmObjectStruct.original_timezone)) {
    addColumn({
      field: AlarmObjectStruct.original_timezone,
      headerName: 'Original Timezone',
      description: 'Original Timezone',
      type: 'string',
      flex: 0.75,
      renderCell: (params) => {
        const formattedValue = params.value;
        return (
          <PortalBootstrapTooltip title={formattedValue}>
            <span>{formattedValue}</span>
          </PortalBootstrapTooltip>
        );
      },
    });
  }
  if (Object.prototype.hasOwnProperty.call(columnsToRender, AlarmObjectStruct.trigger_timestamp)) {
    addColumn({
      field: AlarmObjectStruct.trigger_timestamp,
      headerName: 'Trigger Timestamp',
      description: 'Trigger Timestamp',
      type: 'string',
      flex: 1,
      valueGetter: (value) => getFromNowOfDate(value),
      renderCell: (params) => {
        const { row } = params;
        const value = row[AlarmObjectStruct.trigger_timestamp];
        const formattedValue = getFromNowOfDate(value);
        return (
          <PortalHtmlTooltip
            title={
              <Box
                sx={{ display: 'flex', flexDirection: 'column', paddingTop: 1, paddingBottom: 1 }}
              >
                <Typography>
                  <strong style={{ color: 'lightyellow' }}>User Timezone</strong>(
                  {row.original_timezone}): {formatAlarmTimestamp(value, row.original_timezone)}
                </Typography>
                <Typography>
                  <strong style={{ color: 'lightyellow' }}>My Timezone</strong>({timezone}):{' '}
                  {formatAlarmTimestamp(value, timezone)}
                </Typography>
                <Typography>
                  {' '}
                  <strong style={{ color: 'lightyellow' }}>UTC Timezone</strong> :{' '}
                  {formatAlarmTimestamp(value, 'UTC')}
                </Typography>
              </Box>
            }
          >
            <span>{formattedValue}</span>
          </PortalHtmlTooltip>
        );
      },
    });
  }
  if (Object.prototype.hasOwnProperty.call(columnsToRender, AlarmObjectStruct.dispatch_timestamp)) {
    addColumn({
      field: AlarmObjectStruct.dispatch_timestamp,
      headerName: 'Dispatch Timestamp',
      description: 'Dispatch Timestamp',
      type: 'string',
      flex: 1,
      valueGetter: (value) => (value ? formatAlarmTimestamp(value, timezone) : ''),
      renderCell: (params) => {
        const { value } = params;
        const formattedValue = value ? formatAlarmTimestamp(value, timezone) : '';
        return (
          <PortalHtmlTooltip title={formattedValue}>
            <span>{formattedValue}</span>
          </PortalHtmlTooltip>
        );
      },
    });
  }
  if (Object.prototype.hasOwnProperty.call(columnsToRender, AlarmObjectStruct.message)) {
    addColumn({
      field: AlarmObjectStruct.message,
      headerName: 'Message',
      description: 'Message',
      type: 'string',
      flex: 4,
      valueGetter: (value) => value,
      renderCell: (params) => {
        const { row } = params;
        let formattedValue = '';
        Object.values(row.attributes).forEach((attribute, index) => {
          const eachValue = `${attribute.display_name}: ${attribute.value}${index < Object.values(row.attributes)?.length - 1 ? ', ' : ''}`;
          formattedValue += eachValue;
        });
        return (
          <PortalHtmlTooltip
            title={
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {Object.values(row.attributes).map((attr) => (
                  <div key={attr.name}>
                    <Typography
                      sx={{
                        paddingBottom: 1,
                        paddingTop: 1,
                      }}
                    >
                      <strong style={{ color: 'lightyellow' }}>{attr.display_name}</strong>:{' '}
                      <span>{attr.value}</span>
                      <PortalCopyClipboard
                        fontSize="small"
                        sx={{ marginLeft: '5px' }}
                        text={attr.value}
                      />
                    </Typography>
                  </div>
                ))}
              </Box>
            }
          >
            <div>
              <PortalCopyClipboard
                fontSize="small"
                sx={{ marginRight: '5px' }}
                text={formattedValue}
              />
              {row.message}
            </div>
          </PortalHtmlTooltip>
        );
      },
    });
  }
  if (Object.prototype.hasOwnProperty.call(columnsToRender, 'actions')) {
    addColumn({
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      headerAlign: 'center',
      type: 'actions',
      renderCell: (params) => {
        return <ParentAlarmListTableActionColumn selectedRow={params.row} />;
      },
      flex: 1,
    });
  }
  return columns;
};

const CustomGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
};

export default function AlarmListDataGrid({ alarmList, columnsToRender, defaultTimezone }) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (alarmList && Array.isArray(alarmList) && alarmList?.length > 0) {
      setColumns(
        getColumns({
          columnsToRender: columnsToRender,
          defaultTimezone: defaultTimezone,
        }),
      );
    } else {
      setColumns([]);
    }
  }, [alarmList]);

  const getRowClassName = (params) => {
    return params?.row[AlarmObjectStruct.deleted_timestamp] ? 'deleted-alarm-row' : '';
  };

  return (
    <Box>
      {alarmList && Array.isArray(alarmList) && alarmList?.length > 0 && columns?.length > 0 ? (
        <DataGrid
          columns={columns}
          getRowClassName={getRowClassName}
          ignoreValueFormatterDuringExport={true}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
          localeText={{
            toolbarExportCSV: 'Export as CSV',
          }}
          pageSizeOptions={[25, 50, 100]}
          rows={alarmList}
          slots={{ toolbar: CustomGridToolbar }}
        />
      ) : null}
    </Box>
  );
}
