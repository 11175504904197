import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, TextField, Grid, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';

export default function PortalAsyncAutocomplete({
  autoHighlight = false,
  autoComplete = false,
  autoFocus = false,
  autoSelect = false,
  filterSelectedOptions = false,
  includeInputInList = false,
  fetchOptionsFunction,
  minLength = 3,
  renderOptionKey = 'id',
  renderOptionLabelKey = 'name',
  renderInput,
  renderInputLabel = 'Search',
  sx,
  noOptionsText = 'No results',
  helperText = '',
  onValueChange,
  fullWidth = true,
  getOptionLabel,
  renderOption,
}) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOptions = useCallback(
    debounce((request, callback) => {
      setLoading(true);
      fetchOptionsFunction(request, (data) => {
        setLoading(false);
        callback(data);
      });
    }, 400),
    [fetchOptionsFunction],
  );

  useEffect(() => {
    let active = true;

    if (inputValue.length < minLength) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchOptions({ input: inputValue, value: value }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchOptions, minLength]);

  const defaultGetOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    return option[renderOptionLabelKey]?.toString();
  };

  const defaultRenderOption = (props, option) => {
    const { key, ...optionProps } = props;
    return (
      <li key={option[renderOptionKey]} {...optionProps}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs>
            {option[renderOptionLabelKey]}
          </Grid>
        </Grid>
      </li>
    );
  };

  const defaultRenderInput = (params) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        helperText={helperText}
        label={renderInputLabel}
      />
    );
  };

  return (
    <Autocomplete
      autoComplete={autoComplete}
      autoHighlight={autoHighlight}
      autoSelect={autoSelect}
      filterOptions={(x) => x}
      filterSelectedOptions={filterSelectedOptions}
      fullWidth={fullWidth}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      includeInputInList={includeInputInList}
      loading={loading}
      noOptionsText={noOptionsText}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (onValueChange) {
          onValueChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={renderInput || defaultRenderInput}
      renderOption={renderOption || defaultRenderOption}
      sx={sx ? { ...sx } : {}}
    />
  );
}
