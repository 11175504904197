import { getCurrentEpochTime } from '../dateUtil';
import { FirestoreCollectionNames } from '../firebaseUtil';
import { ReceiverAlarmFirebaseDocumentStruct } from './constants';
import { getFirestoreDocRef, setFirestoreDoc } from '../firebaseUtil/firestore';

export const getReceiverAlarmFirestoreCollectionRefPath = (receiverId) => {
  return `${FirestoreCollectionNames.receivers}/${receiverId}/${FirestoreCollectionNames.alarms}`;
};

export const resetReceiverAlarmEditInProgressToFirestore = async ({ receiverId, alarmId }) => {
  await syncReceiverAlarmEditInProgressToFirestore({ receiverId, alarmId });
};

export const syncReceiverAlarmEditInProgressToFirestore = async ({
  receiverId,
  alarmId,
  portalUserId = null,
  editInProgress = false,
}) => {
  const path = `${getReceiverAlarmFirestoreCollectionRefPath(receiverId)}/${alarmId}`;
  const alarmsRef = getFirestoreDocRef(path);
  try {
    await setFirestoreDoc(alarmsRef, {
      [ReceiverAlarmFirebaseDocumentStruct.editInProgressTimestampEpoch]: getCurrentEpochTime(),
      [ReceiverAlarmFirebaseDocumentStruct.editInProgress]: editInProgress,
      [ReceiverAlarmFirebaseDocumentStruct.editorWebUserId]: portalUserId,
    });
  } catch (error) {
    console.error(`Error updating ${path}`, error);
  }
};

export const setAlarmFirestoreTriggerOnUpdateOfAlarm = async ({ receiverId, alarmId }) => {
  try {
    const alarmsRef = getFirestoreDocRef(
      `${getReceiverAlarmFirestoreCollectionRefPath(receiverId)}/${alarmId}`,
    );
    await setFirestoreDoc(alarmsRef, {
      [ReceiverAlarmFirebaseDocumentStruct.updatedTimestampEpoch]: getCurrentEpochTime(),
    });
  } catch (error) {
    console.error(`Error setting receiver alarms`, error);
  }
};

export const syncReceiverAlarmCreateToFirestore = async ({ receiverId, alarmId }) => {
  const path = `${getReceiverAlarmFirestoreCollectionRefPath(receiverId)}`;
  const alarmsRef = getFirestoreDocRef(
    `${getReceiverAlarmFirestoreCollectionRefPath(receiverId)}/${alarmId}`,
  );
  try {
    await setFirestoreDoc(alarmsRef, {
      [ReceiverAlarmFirebaseDocumentStruct.createdTimestampEpoch]: getCurrentEpochTime(),
    });
  } catch (error) {
    console.error(`Error creating ${path}`, error);
  }
};
