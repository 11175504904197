import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import {
  SelectedTextMessageSearchAccountSelector,
  createTextMessagesDashboard,
  editTextMessagesDashboard,
} from './textMessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, TextField } from '@mui/material';
import { RegexPatterns } from '../../../utils/regex';
import { searchAccountsSelector, selectedSearchAccountSelector } from '../accountSlice';
import { setCustomizedProgressLoading } from '../../../slices/customizedProgressLoaderSlice';
import { styled } from '@mui/material/styles';
import PortalButton from '../../shared/components/PortalButton';

const PREFIX = 'TextMessageDashboardSearchResult';

const classes = {
  root: `${PREFIX}-root`,
  closeButton: `${PREFIX}-closeButton`,
  textFieldGrid: `${PREFIX}-textFieldGrid`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(2),
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  [`& .${classes.textFieldGrid}`]: {
    marginTop: '20px',
  },
}));

export default function TextMessageEditDialog({ open, propagateClose }) {
  const dispatch = useDispatch();
  const selectedTextMessageSearchAccount = useSelector(SelectedTextMessageSearchAccountSelector);
  const [invalidPhoneNumberErrorText, setInvalidPhoneNumberErrorText] =
    useState('Enter 10 digit number');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [disableChange, setDisableChange] = useState(false);
  const account = useSelector(selectedSearchAccountSelector);
  const [selectedAccount, setSelectedAccount] = useState({});
  const accountSearchResponse = useSelector(searchAccountsSelector);

  useEffect(() => {
    if (account && accountSearchResponse && accountSearchResponse.length > 0) {
      var currentAccount = accountSearchResponse.filter((element) => element.ACNT_NUM === account);
      if (currentAccount.length === 1) {
        setSelectedAccount(currentAccount[0]);
      }
    }
  }, [account]);

  useEffect(() => {
    setPhoneNumber(selectedAccount?.PHONE_NUMBER ? selectedAccount?.PHONE_NUMBER : '');
    setDisableChange(!!selectedAccount?.PHONE_NUMBER);
  }, [selectedAccount]);

  const handleSave = () => {
    if (phoneNumber != '' && phoneNumber.length === 10) {
      propagateClose(true);
      dispatch(setCustomizedProgressLoading(true));
      const postData = {
        accountId: selectedAccount?.ACCOUNT_ID,
        phone: phoneNumber,
        verificationStatus: 'PENDING',
        accountNumber: selectedAccount.ACNT_NUM,
      };
      if (selectedAccount?.DASHBOARD_ID) {
        // TODO :do api call to edit the phone number here ???
        dispatch(editTextMessagesDashboard(selectedAccount?.DASHBOARD_ID, postData));
      } else {
        dispatch(createTextMessagesDashboard(postData));
      }
      dispatch(setCustomizedProgressLoading(false));
    } else {
      if (validateUSMobilePhoneNumber(phoneNumber) && phoneNumber !== '') {
        setInvalidPhoneNumberErrorText('Enter 10 digit number');
      } else {
        setInvalidPhoneNumberErrorText('Invalid US Phone Number.');
      }
    }
  };

  const handleClose = () => {
    propagateClose(true);
  };

  const handleTextFieldChange = (event) => {
    const target = event?.target;
    if (target) {
      const { name, value } = target;
      switch (name) {
        case 'phone_number':
          if (value.length <= 10) {
            setPhoneNumber(value);
          }
          break;
        default:
          break;
      }
    }
  };

  const validateUSMobilePhoneNumber = (phoneNumber) => {
    return RegexPatterns.USMobilePhoneNumber.test(phoneNumber);
  };

  return (
    <StyledRoot>
      <Dialog
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <MuiDialogTitle className={classes.root} disableTypography>
          <Typography variant="h6">Add Text Message Phone Number</Typography>
        </MuiDialogTitle>
        <MuiDialogContent
          dividers
          sx={{
            margin: 0,
            padding: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box p={1}>
                Account Number : <strong>{selectedAccount.ACNT_NUM}</strong>
              </Box>{' '}
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                Account Name: <strong>{selectedAccount.CMPNY_NAME_TXT}</strong>
              </Box>{' '}
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                Status: <strong>{selectedAccount.ACNT_STATUS_REF}</strong>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                Parent Line: <strong>{selectedAccount.tollFreeNumber}</strong>
              </Box>
            </Grid>
          </Grid>

          <Grid className={classes.textFieldGrid} container spacing={1}>
            <Grid item xs={6}>
              <Box p={1}>
                <TextField
                  disabled={disableChange}
                  error={invalidPhoneNumberErrorText != 'Enter 10 digit number'}
                  helperText={invalidPhoneNumberErrorText ? invalidPhoneNumberErrorText : ''}
                  id="phone_number"
                  label="Phone Number"
                  name="phone_number"
                  onChange={handleTextFieldChange}
                  type="number"
                  value={phoneNumber}
                  variant="standard"
                />
              </Box>
            </Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions
          sx={{
            margin: 0,
            padding: 4,
          }}
        >
          <PortalButton autoFocus color="secondary" onClick={handleClose} variant="contained">
            Cancel
          </PortalButton>
          <PortalButton
            autoFocus
            color="primary"
            disabled={disableChange}
            onClick={handleSave}
            variant="contained"
          >
            Save
          </PortalButton>
        </MuiDialogActions>
      </Dialog>
    </StyledRoot>
  );
}
