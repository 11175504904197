import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../../PortalApi';
import { getEncodedQueryParamString } from '../../../utils/query';
import { setCustomizedProgressLoading } from '../../../slices/customizedProgressLoaderSlice';
import { accountSearch } from '../accountSlice';
import { setPortalSnackInfo } from '../../../app/authSlice';

export const textMessageSlice = createSlice({
  name: 'textMessage',
  initialState: {
    dashboardList: {},
    selectedTextMessageSearchAccount: null,
    resetSearchParams: false,
  },
  reducers: {
    setTextMessageDashboardList: (state, action) => {
      state.dashboardList = action.payload;
    },
    setTextMessageDashboardSearchListResult: (state, action) => {
      state.dashboardSearchResultList = action.payload;
    },
    setSelectedTextMessageSearchAccount: (state, action) => {
      state.selectedTextMessageSearchAccount = action.payload;
    },
    setResetSearchParams: (state, action) => {
      state.resetSearchParams = action.payload;
    },
  },
});

export const {
  setTextMessageDashboardList,
  setSelectedTextMessageSearchAccount,
  setTextMessageDashboardSearchListResult,
  setResetSearchParams,
} = textMessageSlice.actions;

export const TextMessageDashboardListSelector = (state) => state.textMessage.dashboardList;
export const TextMessageDashboardSearchResultListSelector = (state) =>
  state.textMessage.dashboardSearchResultList;
export const SelectedTextMessageSearchAccountSelector = (state) =>
  state.textMessage.selectedTextMessageSearchAccount;
export const ResetSearchParamsSelector = (state) => state.textMessage.resetSearchParams;

export function getTextMessagesDashboardList({ page, pageSize, sort }) {
  return (dispatch) => {
    try {
      dispatch(setTextMessageDashboardList([]));
      dispatch(setCustomizedProgressLoading(true));
      let url = '/textrequest/dashboards';
      let queryParams = '';
      queryParams = getEncodedQueryParamString({
        phoneNumberNotNull: true,
        page: page ? page : 0,
        pageSize: pageSize ? pageSize : 100,
        sort: sort ? sort : '',
      });
      url = `${url}/?${queryParams}`;
      PortalApi.call(
        url,
        {
          method: 'GET',
        },
        (result) => {
          dispatch(setTextMessageDashboardList(result));
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          console.log(error, status, content);
          dispatch(setTextMessageDashboardList([]));
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setTextMessageDashboardList([]));
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export function searchTextMessagesDashboardList({
  accountName,
  accountNum,
  phoneNumber,
  page,
  pageSize,
  sort,
}) {
  return (dispatch) => {
    try {
      dispatch(setTextMessageDashboardSearchListResult([]));
      dispatch(setCustomizedProgressLoading(true));
      let url = '/textrequest/dashboards';
      let queryParams = '';
      queryParams = getEncodedQueryParamString({
        accountName,
        accountNum,
        phoneNumber,
        page: page ? page : 0,
        pageSize: pageSize ? pageSize : 100,
        sort: sort ? sort : '',
      });
      url = `${url}/?${queryParams}`;
      PortalApi.call(
        url,
        {
          method: 'GET',
        },
        (result) => {
          dispatch(setTextMessageDashboardSearchListResult(result));
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          console.log(error, status, content);
          dispatch(setTextMessageDashboardSearchListResult([]));
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setTextMessageDashboardSearchListResult([]));
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export function createTextMessagesDashboard({
  accountId,
  phone,
  name,
  verificationStatus,
  accountNumber,
}) {
  return (dispatch) => {
    try {
      dispatch(setCustomizedProgressLoading(true));
      const url = '/textrequest/dashboards/';
      PortalApi.call(
        url,
        {
          method: 'POST',
          body: JSON.stringify({ accountId, phone, name, verificationStatus }),
        },
        (result) => {
          dispatch(setResetSearchParams(true));
          dispatch(accountSearch('', accountNumber, '', '', ''));
        },
        (error, status, content) => {
          let message = 'Oops, something went wrong. Please try again.';
          try {
            if (error && error?.message) message = error?.message;
            else if (status && status?.message) message = status?.message;
          } catch (err) {
            console.error('Error ', err);
          }
          dispatch(setCustomizedProgressLoading(false));
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message,
            }),
          );
        },
      );
    } catch (error) {
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export function editTextMessagesDashboard(dashboardId, { accountId, phone }) {
  return (dispatch) => {
    try {
      dispatch(setCustomizedProgressLoading(true));
      const url = `/textrequest/dashboards/${dashboardId}`;
      PortalApi.call(
        url,
        {
          method: 'PUT',
          body: JSON.stringify({ accountId, phone }),
        },
        (result) => {
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          console.log(error, status, content);
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export default textMessageSlice.reducer;
