const sw = require('remove-stopwords');
const ZERO = 0;

export const WordCloudConstants = {
  wordCloud: 'word_cloud',
};

export const getWordClouds = (messages, msgKey) => {
  try {
    if (!messages) {
      return [];
    }
    if (messages && messages?.length === ZERO) {
      return [];
    }
    let strings = messages;
    if (typeof messages === 'object') {
      strings = messages?.map((item) => item[msgKey]).join(' ');
    }
    const removedString = sw.removeStopwords(
      strings
        .replace(/[.,/#!$%^&*;:{}=\-_`~()?]/g, '')
        .replace(/(?:\r\n|\r|\n)/g, ' ')
        .replace(/[0-9]/g, '')
        .replace(/\?/g, '')
        .split(' '),
    );
    const uniqueWords = Array.from(new Set(removedString));
    const resultToSend = uniqueWords.map((item) => ({
      text: item,
      value: strings
        .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
        .replace(/(?:\r\n|\r|\n)/g, ' ')
        .replace(/[0-9]/g, '')
        .replace(/\?/g, '')
        .split(' ')
        .filter((ele) => ele === item).length,
    }));
    return resultToSend;
  } catch (error) {
    console.error(error);
    return [];
  }
};
