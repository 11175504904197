import { Fragment } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  AlarmImportResponseViewDisplayNames,
  AlarmImportResponseViewToDisplayKeys,
  AlarmObjectStruct,
  formatAlarmTimestamp,
} from '../../../utils/alarmUtil';
import { alarmDetailsByIdSelector } from '../alarmSlice';
import { useSelector } from 'react-redux';
import { timezoneSelector } from '../../../app/authSlice';
import { getFromNowOfDate } from '../../../utils/dateUtil';

const formatValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value, null, 2);
  }
  return value;
};

const TriggerTimestampView = ({ triggerTimestamp, originalTimezone, portalUserTimezone }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 1, paddingBottom: 1 }}>
      {triggerTimestamp ? (
        <Typography>
          When? <strong>{getFromNowOfDate(triggerTimestamp)}</strong>
        </Typography>
      ) : null}
      {originalTimezone && triggerTimestamp ? (
        <Typography>
          User Timezone ({originalTimezone}):{' '}
          {formatAlarmTimestamp(triggerTimestamp, originalTimezone)}
        </Typography>
      ) : null}
      {triggerTimestamp && portalUserTimezone ? (
        <Typography>
          My Timezone ({portalUserTimezone}):{' '}
          {formatAlarmTimestamp(triggerTimestamp, portalUserTimezone)}
        </Typography>
      ) : null}

      {triggerTimestamp ? (
        <Typography>UTC Timezone : {formatAlarmTimestamp(triggerTimestamp, 'UTC')}</Typography>
      ) : null}
    </Box>
  );
};

const AttributesSection = ({ fieldKey, keyValue }) => {
  return (
    <Fragment key={fieldKey}>
      {Object.entries(keyValue || {})
        .sort(([, a], [, b]) => a?.display_order - b?.display_order)
        .map(([attrKey, attrValue]) => (
          <TableRow key={`after-${attrKey}`}>
            <TableCell>{attrValue.display_name}</TableCell>
            <TableCell>
              {formatValue(keyValue && keyValue[attrKey] ? keyValue[attrKey].value : '-')}
            </TableCell>
          </TableRow>
        ))}
    </Fragment>
  );
};

const MainFieldsSection = ({ fieldKey, keyValue, originalTimezone, portalUserTimezone }) => {
  return (
    <TableRow key={fieldKey}>
      <TableCell>{AlarmImportResponseViewDisplayNames[fieldKey] || fieldKey}</TableCell>
      <TableCell>
        {fieldKey === AlarmObjectStruct.trigger_timestamp ? (
          <TriggerTimestampView
            originalTimezone={originalTimezone}
            portalUserTimezone={portalUserTimezone}
            triggerTimestamp={keyValue}
          />
        ) : (
          formatValue(keyValue)
        )}
      </TableCell>
    </TableRow>
  );
};

const renderDetailView = (alarmDetail, portalUserTimezone) => {
  const keys = new Set([...Object.keys(alarmDetail)]);
  const rows = [];
  let attributesRow = null;

  Array.from(keys).forEach((fieldKey) => {
    const keyValue = alarmDetail[fieldKey];
    if (fieldKey === AlarmObjectStruct.attributes) {
      attributesRow = <AttributesSection fieldKey={fieldKey} key={fieldKey} keyValue={keyValue} />;
    } else {
      if (Object.keys(AlarmImportResponseViewToDisplayKeys).includes(fieldKey)) {
        rows.push(
          <MainFieldsSection
            fieldKey={fieldKey}
            key={fieldKey}
            keyValue={keyValue}
            originalTimezone={alarmDetail[AlarmObjectStruct.original_timezone]}
            portalUserTimezone={portalUserTimezone}
          />,
        );
      }
    }
  });

  if (attributesRow) {
    rows.push(attributesRow);
  }

  return rows;
};

const AlarmDetailView = () => {
  const alarmDetailsById = useSelector(alarmDetailsByIdSelector);
  const portalUserTimezone = useSelector(timezoneSelector);

  return (
    <Box>
      {alarmDetailsById ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Field</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderDetailView(alarmDetailsById, portalUserTimezone)}</TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  );
};

export default AlarmDetailView;
