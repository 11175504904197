import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import logger from 'redux-logger';
import { isLocalOrDevEnvironment } from '../utils/urls';

const store = configureStore({
  reducer: rootReducer,
  middleware:
    window.location.host === 'portal-v2.mapcom.local'
      ? (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
      : (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: !!isLocalOrDevEnvironment(),
});
// Hot reloading
// if (module.hot) {
//   // Enable Webpack hot module replacement for reducers
//   module.hot.accept('../reducers', () => {
//     store.replaceReducer(rootReducer());
//   });
// }

export default store;
