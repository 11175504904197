import { useEffect } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import Router from './Router';
import { useSelector, useDispatch } from 'react-redux';
import { fcmTokenSelector, setFcmToken } from './app/authSlice';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import getTheme from './theme';
import { portalUserSelector } from './app/authSlice';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import firebaseConfiguration from './firebaseConfig/configuration';

let messaging = '';

export default function App() {
  const dispatch = useDispatch();
  const portalUser = useSelector(portalUserSelector);
  const { isAuthenticated } = useAuth0();
  const fcmToken = useSelector(fcmTokenSelector);

  useEffect(() => {
    if (window.location.host != 'portal-v2.mapcom.local') {
      initializeFirebase();
    }
  }, [dispatch]);

  useEffect(() => {
    if (messaging && window.location.host != 'portal-v2.mapcom.local') {
      // TOD: update this logic to allow only prod
      processToken();
    }
  }, [messaging]);

  const initializeFirebase = async () => {
    const initialize = await isSupported();
    if (initialize) {
      const fapp = initializeApp(firebaseConfiguration);
      messaging = getMessaging(fapp);
    }
  };

  const processToken = () => {
    getToken(messaging, {
      vapidKey:
        'BIgnOC1OvvEbMRz07X0w7anx6fV-BfZ84zlAHHO1FjgXnedGks6pXTO9jxdfrP2NN3sK9XUyOjvlAcItbL-loOE',
    })
      .then((currentToken) => {
        if (currentToken) {
          dispatch(setFcmToken(currentToken));
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  /** this was work in progress for notification but currently do not possess any implemented. Thus, commented */
  /*
  useEffect(() => {
    if (isAuthenticated && fcmToken && fcmToken.length > 0 && portalUser && portalUser.id) {
      dispatch(saveFCMToken({ token: fcmToken, device: 'Browser', type: 'WEB', appName: 'PORTAL' }));
    }
  }, [dispatch, isAuthenticated, fcmToken, portalUser]);

  onMessage(messaging, payload => {
    if (payload && payload.body && payload.body === 'On call schedule summary') {
      dispatch(setPortalSnackInfo({ severity: 'success', message: 'Your file is ready to download' }));
    }
  });
  */

  return (
    <div>
      <ThemeProvider
        theme={
          portalUser && portalUser.optionsJson
            ? getTheme(JSON.parse(portalUser.optionsJson).theme)
            : theme
        }
      >
        <Router />
      </ThemeProvider>
    </div>
  );
}
