import moment from 'moment';
import {
  isDateWithinNextNYear,
  getUtcEndOfDayDateEpoch,
  getUtcStartOfDayDateEpoch,
  isRFCFormattedDateInPast,
  isValidateDate,
} from '../dateUtil';
import { AlarmObjectStruct } from './constants';
import { PortalDefaultTimezone } from '../timezone';
import { FileExtensions } from '../fileUtil/constants';

export const formatStartDateForListQuery = (date) => {
  return getUtcStartOfDayDateEpoch(date);
};

export const formatEndDateForListQuery = (date) => {
  return getUtcEndOfDayDateEpoch(date);
};

export const formatAlarmTimestamp = (dateTimeWithOffset, timezone) => {
  try {
    if (!dateTimeWithOffset) return '';
    return moment(dateTimeWithOffset)
      .utc()
      .tz(timezone ? timezone : PortalDefaultTimezone)
      .format('YYYY-MM-DD HH:mm:ss z');
  } catch {
    return dateTimeWithOffset;
  }
};

export const getImportAlarmTotalErrorCount = (importResponse) => {
  try {
    return importResponse.reduce((total, currentValue) => {
      return total + (currentValue?.errors?.length || 0);
    }, 0);
  } catch {
    return false;
  }
};

export const validateAlarmCreateOrEditForm = (payload) => {
  if (!payload[AlarmObjectStruct.receiver_id]) {
    return { isValid: false, errorMessage: 'Receiver is required.' };
  }
  if (!payload[AlarmObjectStruct.original_timezone]) {
    return { isValid: false, errorMessage: 'Original Timezone is required.' };
  }
  if (!payload[AlarmObjectStruct.trigger_timestamp]) {
    return { isValid: false, errorMessage: 'Trigger Timestamp is required.' };
  }
  if (!isValidateDate(payload[AlarmObjectStruct.trigger_timestamp])) {
    return { isValid: false, errorMessage: 'Trigger Timestamp is not valid.' };
  }
  if (isRFCFormattedDateInPast(payload[AlarmObjectStruct.trigger_timestamp])) {
    return { isValid: false, errorMessage: `Trigger Timestamp can't be in past.` };
  }
  if (!isDateWithinNextNYear(payload[AlarmObjectStruct.trigger_timestamp], 1)) {
    return { isValid: false, errorMessage: 'Trigger Timestamp should be within a year.' };
  }

  const { isValid, errorMessage } = validateAttributes(payload[AlarmObjectStruct.attributes]);
  return { isValid: isValid, errorMessage: errorMessage };
};

const validateAttributes = (attributes) => {
  try {
    for (const [key, attr] of Object.entries(attributes)) {
      if (!attr.value) {
        return { isValid: false, errorMessage: `${attr.display_name} is required.` };
      }
    }
    return { isValid: true, errorMessage: '' };
  } catch (error) {
    console.error('Error:', error);
    return { isValid: true, errorMessage: '' };
  }
};

export const getImportAlarmAcceptedFileTypes = () => {
  return `.${FileExtensions.csv},.${FileExtensions.xls},.${FileExtensions.xlsx}`;
};

export const getParsedAndStringifiedAlarmErrorMessage = (status, defaultErrorMsg) => {
  try {
    return JSON.parse(status?.message)?.error.join(', ');
  } catch (error) {
    console.log('error', error);
    return status?.message ? status.message : defaultErrorMsg;
  }
};

export const isReceiverAlarmSettingsValid = (receiverAlarmSettings) => {
  return (
    receiverAlarmSettings &&
    Object.keys(receiverAlarmSettings)?.length > 0 &&
    !!receiverAlarmSettings[AlarmObjectStruct.receiver_id]
  );
};
