import { useState } from 'react';
import PortalDialog from '../../shared/components/PortalDialog';
import AlarmSelectReceiverFilter from './AlarmSelectReceiverDialog';
import { setSelectedAccount, setSelectedReceiver } from '../alarmSlice';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import PortalButton from '../../shared/components/PortalButton';

const AlarmSelectReceiverButton = () => {
  const dispatch = useDispatch();
  const [openSelectReceiverDialog, setOpenSelectReceiverDialog] = useState(false);
  const [selReceiver, setSelReceiver] = useState(null);

  const handleOpenSelectReceiverDialog = () => {
    setSelReceiver(null);
    dispatch(setSelectedAccount(null));
    setOpenSelectReceiverDialog(true);
  };

  const handleSelectReceiverConfirmation = () => {
    dispatch(setSelectedReceiver(selReceiver));
    setOpenSelectReceiverDialog(false);
  };

  const handleCloseSelectReceiverDialog = () => {
    setOpenSelectReceiverDialog(false);
    resetSelectedAccountAndReceiver();
  };

  const handleAccountChange = (value) => {
    dispatch(setSelectedAccount(value));
  };

  const handleReceiverChange = (value) => {
    setSelReceiver(value);
  };

  const handleSearchByChange = (value) => {
    resetSelectedAccountAndReceiver();
  };

  const resetSelectedAccountAndReceiver = () => {
    setSelReceiver(null);
    dispatch(setSelectedAccount(null));
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PortalButton color="primary" onClick={handleOpenSelectReceiverDialog} variant="contained">
          Select
        </PortalButton>
      </Box>
      {openSelectReceiverDialog ? (
        <PortalDialog
          ComponentProps={{
            handleAccountChange: handleAccountChange,
            handleReceiverChange: handleReceiverChange,
            handleSearchByChange: handleSearchByChange,
          }}
          ComponentToRender={AlarmSelectReceiverFilter}
          actionItems={[
            {
              autoFocus: true,
              onClick: handleSelectReceiverConfirmation,
              color: 'primary',
              text: 'Select',
              variant: 'contained',
            },
            {
              autoFocus: false,
              onClick: handleCloseSelectReceiverDialog,
              color: 'secondary',
              text: 'Cancel',
              variant: 'contained',
            },
          ]}
          fullScreen={false}
          fullWidth={true}
          maxWidth="md"
          open={openSelectReceiverDialog}
          propagateOnClose={handleCloseSelectReceiverDialog}
          title="Select Receiver"
        />
      ) : null}
    </>
  );
};

export default AlarmSelectReceiverButton;
