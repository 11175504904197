import { useDispatch } from 'react-redux';
import PortalAsyncAutocomplete from '../PortalAsyncAutocomplete';
import { getSearchedAccounts } from '../../../accounts/accountSlice';
import { getAccountAutocompleteOptionLabel } from '../../../../utils/accountUtil';
import { Grid } from '@mui/material';

export default function AccountNumberSearch({ handleValueChange }) {
  const dispatch = useDispatch();
  const minLength = 4;

  const renderOptionKey = 'id';
  const renderOptionLabelKey = 'number';
  const orderByField = 'number';

  const fetchOptionsFunction = (request, callback) => {
    const accountNumber =
      request?.value && request?.value?.number ? request.value.number : request.input;
    dispatch(
      getSearchedAccounts({ accountNumber }, (data) => {
        if (data && Array.isArray(data) && data?.length > 0) {
          callback(data.sort((a, b) => a[orderByField] - b[orderByField]));
        } else {
          callback([]);
        }
      }),
    );
  };

  const renderOption = (props, option) => {
    const { key, ...optionProps } = props;
    return (
      <li key={option[renderOptionKey]} {...optionProps}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs>
            {getAccountAutocompleteOptionLabel(option)}
          </Grid>
        </Grid>
      </li>
    );
  };

  return (
    <PortalAsyncAutocomplete
      autoFocus={true}
      autoHighlight={true}
      fetchOptionsFunction={fetchOptionsFunction}
      filterSelectedOptions={true}
      getOptionLabel={getAccountAutocompleteOptionLabel}
      minLength={minLength}
      noOptionsText="No accounts found"
      onValueChange={handleValueChange}
      renderInputLabel={`Search Account by Number (Min ${minLength} chars)`}
      renderOption={renderOption}
      renderOptionKey={renderOptionKey}
      renderOptionLabelKey={renderOptionLabelKey}
    />
  );
}
