import { useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PhoneBook from './PhoneBook';
import Grid from '@mui/material/Grid';
import Tour from 'reactour';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { deliveryContactsSelector, messageStatsSelector } from '../messages/messageSlice';
import { Grow, Slide } from '@mui/material';
import MyMessages from './MyMessages';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import OncallSummaryJob from './OncallSummaryJob';
import { portalUserSelector } from '../../app/authSlice';
import { MessageStatList } from './MessageStatList';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'General';

const classes = {
  root: `${PREFIX}-root`,
  rootNoLogin: `${PREFIX}-rootNoLogin`,
  rootContainer: `${PREFIX}-rootContainer`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  link: `${PREFIX}-link`,
  paper: `${PREFIX}-paper`,
  helperText: `${PREFIX}-helperText`,
  mainText: `${PREFIX}-mainText`,
  captionText: `${PREFIX}-captionText`,
  welcome: `${PREFIX}-welcome`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },

  [`& .${classes.rootNoLogin}`]: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    background: '#000000',
    height: 'calc(100vh - 78px)',
    width: '100%',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundImage: theme.portalDefaults.background,
    backgroundRepeat: 'no-repeat',
  },

  [`& .${classes.rootContainer}`]: {
    flex: 1,
    justifyContent: 'center',
    marginTop: '16px',
    padding: theme.spacing(1),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: 'white',
  },

  [`& .${classes.paper}`]: {
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.helperText}`]: {
    fontSize: 20,
  },

  [`& .${classes.mainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'white',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.captionText}`]: {
    marginTop: '20px',
  },

  [`& .${classes.welcome}`]: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
}));

const General = (props) => {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useAuth0();
  const messageStats = useSelector(messageStatsSelector);
  const deliveryContacts = useSelector(deliveryContactsSelector);
  const portalUser = useSelector(portalUserSelector);
  const contactsRef = useRef(null);

  const closeTour = () => {
    setOpen(false);
  };

  const nextStep = (value) => {
    console.log('');
  };

  const startTour = (value) => {
    setOpen(true);
  };

  return (
    <StyledRoot className={classes.root}>
      {isAuthenticated && (
        <PortalIconButton onClick={startTour} style={{ position: 'absolute', top: 68, right: 20 }}>
          <LiveHelpIcon />
        </PortalIconButton>
      )}
      <Tour
        className={classes.helperText}
        disableFocusLock
        getCurrentStep={nextStep}
        isOpen={open}
        onRequestClose={closeTour}
        steps={[
          {
            selector: '[data-tour="rtAccountsNav"]',
            content:
              'Click here to view specific account information including usage and statements as well as to make a payment.',
          },
          {
            selector: '[data-tour="rtMessagingNav"]',
            content: 'Click here to view messages on specific receivers attached to your account.',
          },
          {
            selector: '[data-tour="rtHelpNav"]',
            content: 'Click here for a more in depth guide to your portal.',
          },
          {
            selector: '[data-tour="rtProfileNav"]',
            content: 'Use this User Profile link to customize your portal.',
          },
          {
            selector: '[data-tour="rttotal"]',
            content:
              'Based on the receiver selected, this is the total number of messages shown in the message query below.',
          },
          {
            selector: '[data-tour="rtescalations"]',
            content:
              'Based on the receiver selected from the messages tab, this is a count of how many messages are escalating.',
          },
          {
            selector: '[data-tour="rtfollowup"]',
            content:
              'Based on the receiver selected from the messages tab, this is a count of how many messages are flagged for follow up.',
          },
          {
            selector: '[data-tour="rtcontacts"]',
            content: 'This is a count of how many contacts your profile has.',
          },
          {
            selector: '.rtMyMessages',
            content: 'View the messages sent to your Secure App user.',
          },
          {
            position: 'top',
            selector: '.rtPhoneBook',
            content:
              'The Contact Section allows you to add specific receiver and email contacts in order to send or forward messages from your account.',
          },
          // {
          //   selector: '.rtPhoneBook',
          //   content: 'Contacts -  Add specific receiver/email contacts in order to send or forward messages from your account.',
          // },
          {
            selector: '.rtPhoneBook .rtPBAdd',
            content: 'Click the Plus Sign to add a new Contact.',
          },
          // {
          //   selector: '[data-tour="rtCreateContact"]',
          //   position:'bottom',
          //   content: 'Type in a name,type & email address or receiver number to create',
          // },
          {
            selector: '.rtPhoneBook .rtPBSendMsg',
            content: 'Click here to send a new message.',
          },
          {
            selector: '.rtPhoneBook .rtPBImportContact',
            content: "Click here to import contacts from another user's account.",
          },
          {
            selector: '.rtPhoneBook .rtPBShareContact',
            content:
              'Click here to generate a shared code to allow other user to import your contacts.',
          },
          // {
          //   position: 'top',
          //   selector: '[data-tour="rtQueryMsg"]',
          //   content: 'Click to explore filter/query options',
          //   highlightedSelectors: ['[data-tour="rtQueryMsg-parent"]'],
          //   mutationObservables: ['[data-tour="rtQueryMsg-parent"]'],
          // },
          // {
          //   position: 'top',
          //   selector: '[data-tour="rtDownloadMsg"]',
          //   content: 'Click to explore different options for downloading messages',
          //   highlightedSelectors: ['[data-tour="rtDownloadMsg"]','[data-tour="rtDownloadMsg-parent"]'],
          //   mutationObservables: ['[data-tour="rtDownloadMsg-parent"]'],
          // },
          // {
          //   selector: '[data-tour="rtECNotes"]',
          //   content: 'You can expand or collapse notes section for all messages',
          // },
          // {
          //   selector: '[data-tour="rtECDelivery"]',
          //   content: 'You can expand or collapse delivery information for all messages',
          // },
          // {
          //   selector: '[data-tour="rtUCMsgs"]',
          //   content: 'Check or Uncheck to cherry pick what you can download',
          // },
        ]}
      />
      <Grid
        className={isAuthenticated ? classes.rootContainer : classes.rootNoLogin}
        container
        spacing={2}
      >
        {!isAuthenticated && (
          <Grid className={classes.mainText} item md={12} xs={12}>
            <Slide in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <div>
                <Typography variant="h4">Professional Call Center & Phone</Typography>
                <Typography variant="h4">Answering Services</Typography>
              </div>
            </Slide>

            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <div style={{ marginTop: '16px' }}>
                <Typography className={classes.captionText} variant="CAPTION" {...props}>
                  WHETHER YOU'RE A SMALL BUSINESS OR FORTUNE 500, WE'RE DIALED IN, 24/7.
                </Typography>
              </div>
            </Grow>
          </Grid>
        )}

        {isAuthenticated && deliveryContacts && deliveryContacts.length > 0 && messageStats && (
          <MessageStatList
            contactsRef={contactsRef}
            messageStats={messageStats}
            messageStatsOptions={['total', 'escalations', 'followup', 'contacts']}
          />
        )}

        {isAuthenticated && deliveryContacts && deliveryContacts.length > 0 && (
          <Grid className="rtMyMessages" item md={12} xs={12}>
            <Paper className={classes.paper} elevation={2}>
              <MyMessages />
            </Paper>
          </Grid>
        )}

        {isAuthenticated && (
          <Grid className="rtPhoneBook" item md={12} xs={12}>
            <Slide direction="up" in mountOnEnter unmountOnExit>
              <Paper className={classes.paper} elevation={2} ref={contactsRef}>
                <PhoneBook />
              </Paper>
            </Slide>
          </Grid>
        )}

        {isAuthenticated &&
          portalUser &&
          portalUser.login &&
          (portalUser.login === 'KSWAMY' || portalUser.login === 'SUMMITONCALL') && (
            <Grid className="rtPhoneBook" item md={12} xs={12}>
              <Slide direction="up" in mountOnEnter unmountOnExit>
                <Paper className={classes.paper} elevation={2}>
                  <OncallSummaryJob />
                </Paper>
              </Slide>
            </Grid>
          )}
      </Grid>
    </StyledRoot>
  );
};

export default General;
