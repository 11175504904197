import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import {
  AlarmImportOperationTypes,
  AlarmImportResponseViewPageColorCode,
} from '../../../utils/alarmUtil';

const AlarmImportOperationSummary = ({ importResponse }) => {
  const operationCounts = importResponse.reduce((acc, result) => {
    acc[result.operation] = (acc[result.operation] || 0) + 1;
    return acc;
  }, {});

  const operations = [
    {
      type: AlarmImportOperationTypes.create,
      color: AlarmImportResponseViewPageColorCode.greenish,
    },
    { type: AlarmImportOperationTypes.delete, color: AlarmImportResponseViewPageColorCode.reddish },
    { type: AlarmImportOperationTypes.update, color: AlarmImportResponseViewPageColorCode.bluish },
    { type: AlarmImportOperationTypes.skip, color: AlarmImportResponseViewPageColorCode.blackish },
  ];

  return (
    <Box sx={{ marginBottom: 5 }}>
      <Typography sx={{ marginBottom: '10px' }} variant="h6">
        Operations Summary
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
        {operations.map((operation) => (
          <Grid item key={operation.type} md={3} sm={6} xs={12}>
            <Card sx={{ backgroundColor: operation.color, color: 'white' }}>
              <CardContent>
                <Typography component="div" variant="h5">
                  {operation?.type?.toUpperCase()}
                </Typography>
                <Typography variant="h4">{operationCounts[operation.type] || 0}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography component="i" variant="body1">
        Note: Operations are displayed in order of create, delete, edit, skip.
      </Typography>
    </Box>
  );
};

export default AlarmImportOperationSummary;
