import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MessageIcon from '@mui/icons-material/Message';
import MessageList from '../messages/MessageList';
import { Card, CardContent, CardHeader } from '@mui/material';
import {
  deliveryContactsSelector,
  getMessagesByContact,
  setSelRecFeatures,
} from '../messages/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PREFIX = 'MyMessages';

const classes = {
  card: `${PREFIX}-card`,
  cardHeader: `${PREFIX}-cardHeader`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.cardHeader}`]: {
    alignItems: 'flex-end',
    color: theme.palette.primary.main,
  },
}));

export default function MyMessages() {
  const dispatch = useDispatch();
  const contacts = useSelector(deliveryContactsSelector);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (contacts.length == 1) {
      dispatch(setSelRecFeatures(['WBMSGRSTCT']));
      dispatch(
        getMessagesByContact(
          contacts[0].CONTACT_ID,
          moment.utc().subtract(1, 'd').format('yyyyMMDDHHmmss'),
          moment.utc().format('yyyyMMDDHHmmss'),
        ),
      );
    }
  }, [contacts.length]);

  return (
    <StyledCard className={classes.card}>
      <CardHeader
        avatar={<MessageIcon />}
        className={classes.cardHeader}
        title={t('My Messages')}
        titleTypographyProps={{ variant: 'subtitle1' }}
      />
      <CardContent>
        {contacts && contacts.length === 1 && <MessageList type="contact" />}
      </CardContent>
    </StyledCard>
  );
}
