import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  alarmLoadingStateSelector,
  getAlarmDetailsByIdInBackground,
  receiverAlarmListSelector,
  receiverAlarmRealtimeUpdatedDocDataSelector,
  receiverAlarmSettingsSelector,
} from '../alarmSlice';
import { xxMapTimezoneSelector } from '../../../app/authSlice';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlarmObjectStruct, isReceiverAlarmSettingsValid } from '../../../utils/alarmUtil';
import AlarmListDataGrid from './AlarmListDataGrid';
import { ReceiverNoSettingsCode } from '../../../utils/receiverUtil/constants';

export default function AlarmListTable() {
  const dispatch = useDispatch();
  const receiverAlarmList = useSelector(receiverAlarmListSelector);
  const defaultTimezone = useSelector(xxMapTimezoneSelector);
  const [alarmList, setAlarmsList] = useState([]);
  const loadingState = useSelector(alarmLoadingStateSelector);
  const receiverAlarmSettings = useSelector(receiverAlarmSettingsSelector);

  const receiverAlarmRealtimeUpdatedDocData = useSelector(
    receiverAlarmRealtimeUpdatedDocDataSelector,
  );

  const { t } = useTranslation();

  useEffect(() => {
    populateAlarmList();
  }, [receiverAlarmList]);

  /**On receiving the realtime updated receiver alarms,
   * we check if given updated alarms are in alarms list
   * If yes, we refetch those alarms by id and update the list
   * */
  useEffect(() => {
    try {
      if (
        receiverAlarmRealtimeUpdatedDocData &&
        Object.keys(receiverAlarmRealtimeUpdatedDocData).length > 0
      ) {
        if (
          receiverAlarmList &&
          receiverAlarmList.length > 0 &&
          receiverAlarmList.find((alarm) => alarm.id === receiverAlarmRealtimeUpdatedDocData.id)
        ) {
          dispatch(
            getAlarmDetailsByIdInBackground(receiverAlarmRealtimeUpdatedDocData.id, (res) => {
              if (res && res.id) {
                setAlarmsList((prevList) => {
                  return prevList.map((alarm) => {
                    if (alarm.id === receiverAlarmRealtimeUpdatedDocData.id) {
                      return res;
                    }
                    return alarm;
                  });
                });
              }
            }),
          );
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [receiverAlarmRealtimeUpdatedDocData]);

  const populateAlarmList = () => {
    if (receiverAlarmList && Array.isArray(receiverAlarmList) && receiverAlarmList?.length > 0) {
      setAlarmsList(receiverAlarmList);
    } else {
      setAlarmsList([]);
    }
  };

  return (
    <Box>
      {loadingState?.loadingReceiverAlarmList ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}

      {alarmList && Array.isArray(alarmList) && alarmList?.length > 0 ? (
        <AlarmListDataGrid
          alarmList={alarmList}
          columnsToRender={{
            [AlarmObjectStruct.created_timestamp]: true,
            [AlarmObjectStruct.original_timezone]: true,
            [AlarmObjectStruct.trigger_timestamp]: true,
            [AlarmObjectStruct.dispatch_timestamp]: true,
            [AlarmObjectStruct.message]: true,
            actions: true,
          }}
          defaultTimezone={defaultTimezone}
        />
      ) : null}

      {!loadingState?.loadingReceiverAlarmList &&
      alarmList &&
      Array.isArray(alarmList) &&
      alarmList?.length === 0 ? (
        isReceiverAlarmSettingsValid(receiverAlarmSettings) ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography>{t('no records found')}</Typography>
          </Box>
        ) : receiverAlarmSettings === ReceiverNoSettingsCode ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography component="i" variant="h6">
              Please contact support to enable this feature.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography component="i" variant="h6">
              Please select a receiver to view alarms.
            </Typography>
          </Box>
        )
      ) : null}
    </Box>
  );
}
