import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import CallCountChart from './CallCountChart';
import ReceiverSummary from './ReceiverSummary';
import Metric from './Metric';
import TimeUsageChart from './TimeUsageChart';
import { CircularProgress, IconButton, Popover, Typography } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { getUsageForAccount, usageSelector, selAccountSelector } from '../accountSlice';
import AccountSummary from './AccountSummary';
import { useTranslation } from 'react-i18next';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'Usage';

const classes = {
  root: `${PREFIX}-root`,
  cardHeader: `${PREFIX}-cardHeader`,
  card: `${PREFIX}-card`,
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  fab: `${PREFIX}-fab`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(0),
  },

  [`& .${classes.cardHeader}`]: {
    color: '#ff5d5d',
    background: '#f7f7f7',
  },

  [`& .${classes.card}`]: {
    spacing: theme.spacing(2),
    marginTop: 10,
    marginRight: 5,
  },

  [`& .${classes.paper}`]: {},

  [`& .${classes.title}`]: {
    background: '#e0e0e0',
  },

  [`& .${classes.fab}`]: {},
}));

const Usage = () => {
  const selAccount = useSelector(selAccountSelector);
  const usage = useSelector(usageSelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(1);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().subtract(7, 'd').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges) => {
    var current = selectionRange;
    console.log(ranges, '*****');
    current[0].startDate = ranges.selection.startDate;
    current[0].endDate = ranges.selection.endDate;
    setSelectionRange(current);
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      setAnchorEl(false);
      setCount(count + 1);
    }
  };

  useEffect(() => {
    if (selAccount !== 0) {
      dispatch(
        getUsageForAccount(
          selAccount,
          moment(selectionRange[0].startDate).format('MM-DD-YYYY'),
          moment(selectionRange[0].endDate).format('MM-DD-YYYY'),
        ),
      );
    }
  }, [dispatch, selAccount, count, selectionRange]);

  return (
    <StyledGrid className={classes.root} container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="caption">
          {selectionRange[0].startDate.toDateString()} - {selectionRange[0].endDate.toDateString()}
        </Typography>
        <PortalIconButton
          aria-describedby={id}
          aria-label="upload picture"
          className={classes.fab}
          component="button"
          onClick={handleClick}
          variant="contained"
        >
          <DateRangeIcon />
        </PortalIconButton>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          id={id}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DateRangePicker
            inputRanges={[]}
            onChange={handleSelect}
            ranges={selectionRange}
            showMonthAndYearPickers={true}
            showSelectionPreview={true}
          />
        </Popover>
      </Grid>

      {usage && !usage.metrics && <CircularProgress />}

      {usage &&
        usage.metrics &&
        Object.keys(usage.metrics).map((item, index) => (
          <Grid item key={index} md={4} xs={12}>
            <Metric
              automated={usage.metrics[item].values['ivr']}
              callCenter={usage.metrics[item].values['calls']}
              text={item}
              type={item}
            />
          </Grid>
        ))}

      {usage && usage.charts && (
        <Grid item md={12} xs={12}>
          <TimeUsageChart chartData={usage.charts.timeUsageChart} />
        </Grid>
      )}

      {usage && usage.charts && (
        <Grid item md={12} xs={12}>
          <CallCountChart chartData={usage.charts.callCountChart} />
        </Grid>
      )}

      {/* {callChartData.series && widgets['Minutes']['General Time Based'] >0 &&  
            <Grid item xs={12} md={12}>
                 <DailyTable daily={usage.byDay} />
            </Grid>
            }
            */}

      {usage && usage.accountSummary && (
        <Grid item md={12} xs={12}>
          <AccountSummary summary={usage.accountSummary} />
        </Grid>
      )}

      {usage.summary && (
        <Grid item md={12} xs={12}>
          <ReceiverSummary summary={usage.summary} />
        </Grid>
      )}
    </StyledGrid>
  );
};

export default Usage;
