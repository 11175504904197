import { useEffect, useState } from 'react';
import { Box, TextField, FormControl, CircularProgress } from '@mui/material';
import {
  alarmDetailsByIdSelector,
  alarmLoadingStateSelector,
  receiverAlarmSettingsSelector,
  selectedReceiverSelector,
} from '../alarmSlice';
import { useSelector } from 'react-redux';
import PortalTimezoneSelect from '../../shared/components/PortalTimezoneSelect';
import PortalDateTimePicker from '../../shared/components/PortalDateTimePicker';
import moment from 'moment';
import {
  AlarmObjectStruct,
  getRFCFormattedTriggeredTimestamp,
  syncReceiverAlarmEditInProgressToFirestore,
} from '../../../utils/alarmUtil';
import { portalUserSelector } from '../../../app/authSlice';
import { isLocalOrDevEnvironment } from '../../../utils/urls';

const AlarmCreateEditOrView = ({ createEditOrViewType, propagateFormDataChange }) => {
  const selectedReceiver = useSelector(selectedReceiverSelector);
  const receiverAlarmSettings = useSelector(receiverAlarmSettingsSelector);
  const loadingState = useSelector(alarmLoadingStateSelector);
  const portUser = useSelector(portalUserSelector);
  const alarmDetailsById = useSelector(alarmDetailsByIdSelector);
  const [attributes, setAttributes] = useState({});
  const [originalTimezone, setOriginalTimezone] = useState(null);
  const [triggerTimestamp, setTriggerTimestamp] = useState(null);

  useEffect(() => {
    if (alarmDetailsById && (createEditOrViewType === 'edit' || createEditOrViewType === 'view')) {
      setOriginalTimezone(alarmDetailsById[AlarmObjectStruct.original_timezone]);
      setTriggerTimestamp(moment(alarmDetailsById[AlarmObjectStruct.trigger_timestamp]));
      setAttributes(alarmDetailsById[AlarmObjectStruct.attributes]);
      // TODO: we will come back to this later when we implement logic for real time subscription
      if (createEditOrViewType === 'edit') {
        if (isLocalOrDevEnvironment()) {
          syncReceiverAlarmEditInProgressToFirestore({
            receiverId: selectedReceiver?.number,
            alarmId: alarmDetailsById?.id,
            portalUserId: portUser.id,
            editInProgress: true,
          });
        }
      }
    }
  }, [alarmDetailsById]);

  useEffect(() => {
    handleFormDataChange();
  }, [selectedReceiver, originalTimezone, triggerTimestamp]);

  useEffect(() => {
    if (createEditOrViewType === 'create') {
      if (receiverAlarmSettings && receiverAlarmSettings?.alarm_attributes) {
        const alarmAttributes = receiverAlarmSettings.alarm_attributes;
        const updatedAttributes = Object.keys(alarmAttributes).reduce((acc, atrItem) => {
          acc[atrItem] = { ...alarmAttributes[atrItem], value: '' };
          return acc;
        }, {});
        setAttributes(updatedAttributes);
      } else {
        setAttributes({});
      }
    }
  }, [receiverAlarmSettings]);

  const handleAttributeChange = (attributeName, newFieldValue) => {
    const toUpdateAttributes = JSON.parse(JSON.stringify(attributes));
    Object.keys(toUpdateAttributes).forEach((attName) => {
      if (attributeName === attName && toUpdateAttributes[attName]) {
        toUpdateAttributes[attName]['value'] = newFieldValue;
      }
    });
    setAttributes(toUpdateAttributes);
    handleFormDataChange(toUpdateAttributes);
  };

  const handleTimezoneChange = (value) => {
    setOriginalTimezone(value);
  };

  const handleTriggerTimestampChange = (value) => {
    if (value) {
      setTriggerTimestamp(value);
    } else {
      setTriggerTimestamp(null);
    }
  };

  const handleFormDataChange = (toUpdateAttributes) => {
    if (propagateFormDataChange) {
      propagateFormDataChange({
        [AlarmObjectStruct.receiver_id]: selectedReceiver?.number,
        [AlarmObjectStruct.original_timezone]: originalTimezone,
        [AlarmObjectStruct.trigger_timestamp]: getRFCFormattedTriggeredTimestamp(
          triggerTimestamp,
          originalTimezone,
        ),
        attributes: toUpdateAttributes ? toUpdateAttributes : attributes,
      });
    }
  };

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '20px' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexWrap: 'wrap' }}>
        <FormControl fullWidth variant="outlined">
          <PortalTimezoneSelect
            disabled={createEditOrViewType === 'view'}
            propagateTimezoneChange={handleTimezoneChange}
            required={true}
            timezoneValue={originalTimezone}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <PortalDateTimePicker
            dateValue={triggerTimestamp}
            disablePast={true}
            disabled={createEditOrViewType === 'view'}
            label="Trigger Timestamp"
            maxDate={moment().add(1, 'year')}
            minDate={moment()}
            propagateDateChange={handleTriggerTimestampChange}
            required={true}
            timezone={originalTimezone}
          />
        </FormControl>
      </Box>
      {loadingState?.loadingCreateReceiverAlarm ||
      loadingState?.loadingEditAlarmById ||
      loadingState?.loadingGetAlarmDetailsById ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}

      {attributes &&
        Object.values(attributes)?.length > 0 &&
        Object.values(attributes)
          .sort((a, b) => a?.display_order - b?.display_order)
          .map((atrItem) => {
            return (
              <TextField
                disabled={createEditOrViewType === 'view'}
                key={`${atrItem?.name}`}
                label={`${atrItem?.display_name}`}
                onChange={(e) => handleAttributeChange(atrItem?.name, e.target.value)}
                required={true}
                value={atrItem?.value}
              />
            );
          })}
    </Box>
  );
};

export default AlarmCreateEditOrView;
