const firebaseConfiguration = {
  apiKey: 'AIzaSyByA9tSpESY2Tu7HGU1KiGwofd7Y8__9nU',
  authDomain: 'map-customer-portal.firebaseapp.com',
  databaseURL: 'https://map-customer-portal-default-rtdb.firebaseio.com',
  projectId: 'map-customer-portal',
  storageBucket: 'map-customer-portal.appspot.com',
  messagingSenderId: '989136367781',
  appId: '1:989136367781:web:2f7601cfda1f796ff51d4c',
  measurementId: 'G-1Y4W757E44',
};

export default firebaseConfiguration;
