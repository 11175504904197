import { Fragment } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { receiverAlarmImportResponseSelector, receiverAlarmSettingsSelector } from '../alarmSlice';
import { useSelector } from 'react-redux';
import {
  AlarmImportOperationTypes,
  AlarmImportResponseViewDisplayNames,
  AlarmImportResponseViewPageColorCode,
  AlarmImportResponseViewToDisplayKeys,
  AlarmObjectStruct,
} from '../../../utils/alarmUtil';
import AlarmImportOperationSummary from './AlarmImportOperationSummary';

const formatValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value, null, 2);
  }
  return value;
};

const AttributesSection = ({ fieldKey, operation, beforeValue, afterValue, style }) => {
  return (
    <Fragment key={fieldKey}>
      {operation === AlarmImportOperationTypes.delete
        ? Object.entries(beforeValue || {})
            .sort(([, a], [, b]) => a?.display_order - b?.display_order)
            .map(([attrKey, attrValue]) => (
              <TableRow key={`after-${attrKey}`}>
                <TableCell>{attrValue.display_name}</TableCell>
                <TableCell>
                  {formatValue(
                    beforeValue && beforeValue[attrKey] ? beforeValue[attrKey].value : '-',
                  )}
                </TableCell>
                <TableCell style={operation === AlarmImportOperationTypes.create ? style : {}}>
                  -
                </TableCell>
              </TableRow>
            ))
        : Object.entries(afterValue || {})
            .sort(([, a], [, b]) => a?.display_order - b?.display_order)
            .map(([attrKey, attrValue]) => (
              <TableRow key={`after-${attrKey}`}>
                <TableCell>{attrValue.display_name}</TableCell>
                <TableCell>
                  {operation === AlarmImportOperationTypes.update &&
                  beforeValue &&
                  beforeValue[attrKey] &&
                  beforeValue[attrKey].value !== attrValue.value ? (
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: AlarmImportResponseViewPageColorCode.reddish,
                      }}
                    >
                      {beforeValue[attrKey].value}
                    </span>
                  ) : (
                    formatValue(
                      beforeValue && beforeValue[attrKey] ? beforeValue[attrKey].value : '-',
                    )
                  )}
                </TableCell>
                <TableCell style={operation === AlarmImportOperationTypes.create ? style : {}}>
                  {operation === AlarmImportOperationTypes.update &&
                  beforeValue &&
                  beforeValue[attrKey] &&
                  beforeValue[attrKey].value !== attrValue.value ? (
                    <span style={{ color: AlarmImportResponseViewPageColorCode.greenish }}>
                      {attrValue.value}
                    </span>
                  ) : (
                    attrValue.value
                  )}
                </TableCell>
              </TableRow>
            ))}
    </Fragment>
  );
};

const MainFieldsSection = ({ fieldKey, operation, beforeValue, afterValue, style }) => {
  return (
    <TableRow key={fieldKey}>
      <TableCell>{AlarmImportResponseViewDisplayNames[fieldKey] || fieldKey}</TableCell>
      <TableCell>
        {operation === AlarmImportOperationTypes.update &&
        beforeValue !== undefined &&
        beforeValue !== afterValue ? (
          <span
            style={{
              textDecoration: 'line-through',
              color: AlarmImportResponseViewPageColorCode.reddish,
            }}
          >
            {formatValue(beforeValue)}
          </span>
        ) : formatValue(beforeValue) !== undefined ? (
          formatValue(beforeValue)
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell style={operation === AlarmImportOperationTypes.create ? style : {}}>
        {operation === AlarmImportOperationTypes.update &&
        afterValue !== undefined &&
        beforeValue !== afterValue ? (
          <span style={{ color: AlarmImportResponseViewPageColorCode.greenish }}>
            {formatValue(afterValue)}
          </span>
        ) : formatValue(afterValue) !== undefined ? (
          formatValue(afterValue)
        ) : (
          '-'
        )}
      </TableCell>
    </TableRow>
  );
};

const renderDiff = (before, after, operation) => {
  const keys = new Set([...Object.keys(before), ...Object.keys(after)]);
  const rows = [];
  let attributesRow = null;

  Array.from(keys).forEach((fieldKey) => {
    const beforeValue = before[fieldKey];
    const afterValue = after[fieldKey];
    let style = {};

    if (beforeValue !== afterValue) {
      if (beforeValue === undefined) {
        style = { color: AlarmImportResponseViewPageColorCode.greenish }; // New value
      } else if (afterValue === undefined) {
        style = { backgroundColor: AlarmImportResponseViewPageColorCode.reddish }; // Deleted value
      } else {
        style = { backgroundColor: AlarmImportResponseViewPageColorCode.bluish }; // Updated value
      }
    }

    if (fieldKey === AlarmObjectStruct.attributes) {
      attributesRow = (
        <AttributesSection
          afterValue={afterValue}
          beforeValue={beforeValue}
          fieldKey={fieldKey}
          key={fieldKey}
          operation={operation}
          style={style}
        />
      );
    } else {
      if (Object.keys(AlarmImportResponseViewToDisplayKeys).includes(fieldKey)) {
        rows.push(
          <MainFieldsSection
            afterValue={afterValue}
            beforeValue={beforeValue}
            fieldKey={fieldKey}
            key={fieldKey}
            operation={operation}
            style={style}
          />,
        );
      }
    }
  });

  if (attributesRow) {
    rows.push(attributesRow);
  }

  return rows;
};

const AlarmOperationErrors = ({ errors }) => {
  return errors && errors?.length > 0 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      {errors?.map((error) => (
        <Alert key={error} severity="error">
          {error}
        </Alert>
      ))}
    </Box>
  ) : null;
};

const getAccordionSummaryTitle = (result, receiverAlarmSettings) => {
  let title = '';
  let afterOrBeforeKey = 'after';
  try {
    switch (result?.operation) {
      case AlarmImportOperationTypes.create:
      case AlarmImportOperationTypes.skip:
      case AlarmImportOperationTypes.update:
        afterOrBeforeKey = 'after';
        break;
      case AlarmImportOperationTypes.delete:
        afterOrBeforeKey = 'before';
        break;
    }
    title = receiverAlarmSettings?.stager_settings?.primary_keys
      ?.map((primary_key) => {
        return result && result[afterOrBeforeKey]
          ? ` | ${result[afterOrBeforeKey]['attributes'][primary_key]['display_name']} : ${result[afterOrBeforeKey]['attributes'][primary_key]['value']}`
          : '';
      })
      .join(' | ');
  } catch (e) {
    console.error('Error:', e);
  }
  return title;
};

const AlarmOperationAccordionSummary = ({ result, receiverAlarmSettings }) => {
  return (
    <Typography component="div">
      Operation:{' '}
      <Typography
        component="span"
        style={{
          fontWeight: 'bold',
          color:
            result?.operation === AlarmImportOperationTypes.create
              ? AlarmImportResponseViewPageColorCode.greenish
              : result?.operation === AlarmImportOperationTypes.delete
                ? AlarmImportResponseViewPageColorCode.reddish
                : result?.operation === AlarmImportOperationTypes.update
                  ? AlarmImportResponseViewPageColorCode.bluish
                  : 'black',
        }}
      >
        {result?.operation?.toUpperCase()}
      </Typography>
      {getAccordionSummaryTitle(result, receiverAlarmSettings)}
    </Typography>
  );
};

const AlarmOperationDiffViewer = ({ importResponse, receiverAlarmSettings }) => {
  const operationOrder = {
    [AlarmImportOperationTypes.create]: 1,
    [AlarmImportOperationTypes.delete]: 2,
    [AlarmImportOperationTypes.update]: 3,
    [AlarmImportOperationTypes.skip]: 4,
  };

  const sortedImportResponse = [...importResponse].sort((a, b) => {
    return operationOrder[a.operation] - operationOrder[b.operation];
  });

  return (
    <>
      {sortedImportResponse.map((result, index) => (
        <Accordion key={index} sx={{ marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <AlarmOperationAccordionSummary
                receiverAlarmSettings={receiverAlarmSettings}
                result={result}
              />
              <AlarmOperationErrors errors={result?.errors} />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Field</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Before</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>After</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderDiff(result.before || {}, result.after || {}, result.operation)}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const AlarmImportResponseView = () => {
  const receiverAlarmImportResponse = useSelector(receiverAlarmImportResponseSelector);
  const receiverAlarmSettings = useSelector(receiverAlarmSettingsSelector);

  return (
    <Box>
      {receiverAlarmImportResponse &&
      Array.isArray(receiverAlarmImportResponse) &&
      receiverAlarmImportResponse?.length > 0 ? (
        <>
          <AlarmImportOperationSummary importResponse={receiverAlarmImportResponse} />
          <AlarmOperationDiffViewer
            importResponse={receiverAlarmImportResponse}
            receiverAlarmSettings={receiverAlarmSettings}
          />
        </>
      ) : null}
    </Box>
  );
};

export default AlarmImportResponseView;
