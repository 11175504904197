import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import '../../../InitializeFusionCharts';
import ReactFC from 'react-fusioncharts';

const PREFIX = 'SentimentPieChart';

const classes = {
  flexRow: `${PREFIX}-flexRow`,
  graphContainer: `${PREFIX}-graphContainer`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.flexRow}`]: (props) =>
    props?.flexRow || {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(1),
    },

  [`& .${classes.graphContainer}`]: (props) =>
    props?.graphContainer || {
      backgroundColor: '#f8fdff',
      borderRadius: '10px',
    },
}));

const defaultChartConfigs = {
  type: 'pie3d',
  width: '30%',
  height: 400,
  dataFormat: 'json',
  dataSource: {
    chart: {
      caption: '',
      subCaption: 'Sentiment Analysis',
      enableSmartLabels: '0',
      startingAngle: '0',
      showPercentValues: '1',
      decimals: '1',
      useDataPlotColorForLabels: '1',
      theme: 'fusion',
    },
    data: [],
  },
};

export default function SentimentPieChart({
  sentimentCountsBySpeaker,
  overallSentimentCounts,
  colorCodePerSpeaker,
  overallCaption,
  displayOverallSentiment,
  styles,
  customChartConfigs,
}) {
  const getChartConfig = (speaker, index) => {
    const defaultConfig = customChartConfigs ? customChartConfigs : defaultChartConfigs;
    const config = {
      ...defaultConfig,
      width: displayOverallSentiment ? '30%' : '48%',
      dataSource: {
        ...defaultConfig.dataSource,
        chart: {
          ...defaultConfig.dataSource.chart,
          caption: `Speaker ${speaker?.trim()}`,
          captionFontColor:
            colorCodePerSpeaker && colorCodePerSpeaker[speaker]
              ? colorCodePerSpeaker[speaker?.trim()]
              : '#000000',
        },
        data: sentimentCountsBySpeaker[speaker?.trim()]
          ? sentimentCountsBySpeaker[speaker?.trim()]?.sort((a, b) => b.value - a.value)
          : [],
      },
    };
    return config;
  };

  const getOverallChartConfig = (overallCaption) => {
    const defaultConfig = customChartConfigs ? customChartConfigs : defaultChartConfigs;
    const config = {
      ...defaultConfig,
      dataSource: {
        ...defaultConfig.dataSource,
        chart: {
          ...defaultConfig.dataSource.chart,
          caption: overallCaption ? overallCaption : '',
        },
        data: overallSentimentCounts
          ? overallSentimentCounts?.sort((a, b) => b.value - a.value)
          : [],
      },
    };
    return config;
  };

  return (
    <StyledRoot>
      {((sentimentCountsBySpeaker && Object.keys(sentimentCountsBySpeaker)?.length > 0) ||
        (overallSentimentCounts && overallSentimentCounts?.length > 0)) && (
        <Grid className={classes.flexRow} container>
          {sentimentCountsBySpeaker &&
            Object.keys(sentimentCountsBySpeaker)?.length > 0 &&
            Object.keys(sentimentCountsBySpeaker).map((speaker, index) => {
              return (
                <Box
                  className={classes.graphContainer}
                  key={index}
                  style={{
                    paddingRight:
                      index !== Object.keys(sentimentCountsBySpeaker)?.length ? '10px' : '0',
                  }}
                >
                  <ReactFC {...getChartConfig(speaker?.trim(), index)} />
                </Box>
              );
            })}
          {displayOverallSentiment &&
            overallSentimentCounts &&
            overallSentimentCounts?.length > 0 && (
              <Box className={classes.graphContainer}>
                <ReactFC {...getOverallChartConfig(overallCaption)} />
              </Box>
            )}
        </Grid>
      )}
    </StyledRoot>
  );
}
