import CircularProgress from '@mui/material/CircularProgress';
import { CustomizedProgressLoadingSelector } from '../../slices/customizedProgressLoaderSlice';
import { useSelector } from 'react-redux';

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.25)', // Adjust the background color and opacity as needed
  },
};

export default function CustomizedProgressLoader({ type, color, disableBackground }) {
  const loading = useSelector(CustomizedProgressLoadingSelector);
  return (
    loading && (
      <div style={{ ...styles.container, zIndex: disableBackground ? 9999999 : 'auto' }}>
        {(type === 'circular' || !type) && <CircularProgress color={color ? color : 'primary'} />}
      </div>
    )
  );
}
