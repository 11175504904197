import moment from 'moment';

export const formatSecondsToHHMMssSS = (seconds) => {
  return moment.utc(moment.duration(seconds, 'seconds')?.asMilliseconds()).format('HH:mm:ss.SS');
};

export const formatMilliSecondsToHHMMssSS = (milliseconds) => {
  return moment.utc(moment.duration(milliseconds)?.asMilliseconds()).format('HH:mm:ss.SS');
};

export const formatToRFC3339 = (date) => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const getUtcStartOfDayDateEpoch = (startDate) => {
  return moment(startDate).startOf('day').utc().valueOf();
};

export const getUtcEndOfDayDateEpoch = (endDate) => {
  return moment(endDate).endOf('day').utc().valueOf();
};

export const getFromNowOfDate = (date) => {
  return moment(date).fromNow();
};

export const getEpochTimeNSecondsAgo = (seconds) => {
  return moment().subtract(seconds, 'seconds').valueOf();
};

export const getEpochTimeNMinutesAgo = (minutes) => {
  return moment().subtract(minutes, 'minutes').valueOf();
};

export const getEpochTimeNHoursAgo = (hours) => {
  return moment().subtract(hours, 'hours').valueOf();
};

export const getCurrentEpochTime = () => {
  return moment().valueOf();
};

export const isValidateDate = (date) => {
  return moment(date).isValid();
};

/**
 *
 * @param {*} date RFC formatted date
 * @returns
 */
export const isRFCFormattedDateInPast = (date) => {
  return moment(date).isBefore(moment());
};

export const isDateWithinNextNYear = (date, year = 1) => {
  const now = moment();
  const oneYearFromNow = moment().add(year, 'year');
  return moment(date).isBetween(now, oneYearFromNow);
};
