import { useSelector } from 'react-redux';
import { xxMapTimezonesSelector } from '../../../app/authSlice';
import { InputLabel, MenuItem, Select } from '@mui/material';

const PortalTimezoneSelect = ({
  id = 'portalTimezoneSelect',
  timezoneValue,
  propagateTimezoneChange,
  disabled,
  displayTimezoneCodeOrNameType,
  label = 'Timezone',
  labelId = 'timezone',
  required = false,
}) => {
  const timezoneOptions = useSelector(xxMapTimezonesSelector);
  const handleTimezoneChange = (event) => {
    propagateTimezoneChange(event.target.value);
  };

  return (
    <>
      <InputLabel id={labelId} required={required}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        id={id}
        label={label}
        labelId={labelId}
        onChange={handleTimezoneChange}
        value={timezoneValue || ''}
      >
        {timezoneOptions && Array.isArray(timezoneOptions) && timezoneOptions.length > 0
          ? timezoneOptions.map((item) => (
              <MenuItem key={item.timezoneCode} value={item.timezoneCode}>
                {displayTimezoneCodeOrNameType === 'timezoneCode' ? item.timezoneCode : item.name}
              </MenuItem>
            ))
          : null}
      </Select>
    </>
  );
};

export default PortalTimezoneSelect;
PortalTimezoneSelect.displayName = 'PortalTimezoneSelect';
