import { Fragment, useEffect, useState } from 'react';
import {
  CardActions,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  FormControl,
  Tooltip,
  TextField,
  Button,
  MenuItem,
  CardHeader,
  Typography,
  CircularProgress,
} from '@mui/material';
import { timezonesSelector, getTimezones } from '../../app/authSlice';
import {
  createPortalUser,
  createUserSelector,
  loadingSelector,
  searchEmail,
  searchUsername,
  verifyEmailSelector,
  verifyUserNameSelector,
} from './staffSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setPortalSnackInfo } from '../../app/authSlice';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PortalButton from '../shared/components/PortalButton';

const PREFIX = 'OnboardingUsers';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  grid: `${PREFIX}-grid`,
  cardTop: `${PREFIX}-cardTop`,
  small: `${PREFIX}-small`,
  cardBottom: `${PREFIX}-cardBottom`,
  cardBottomHeader: `${PREFIX}-cardBottomHeader`,
  cardBottomBody: `${PREFIX}-cardBottomBody`,
  item: `${PREFIX}-item`,
  formControlBtn: `${PREFIX}-formControlBtn`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.card}`]: {
    flexGrow: 1,
    padding: 0,
    margin: theme.spacing(1),
    borderRadius: '16px',
  },

  [`& .${classes.cardContent}`]: {
    padding: theme.spacing(2),
    paddingBottom: '0px !important',
  },

  [`& .${classes.grid}`]: {
    padding: 0,
  },

  [`& .${classes.cardTop}`]: {
    minHeight: theme.spacing(6),
    maxHeight: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    background: '#01adc200',
    padding: theme.spacing(2),
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.cardBottom}`]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#edf5f6',
  },

  [`& .${classes.cardBottomHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.cardBottomBody}`]: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  [`& .${classes.item}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

  [`& .${classes.formControlBtn}`]: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'stretch',
    flexDirection: 'row',
  },
}));

export default function OnboardingUsers() {
  const dispatch = useDispatch();
  const [timezone, setTimezone] = useState('');
  const timezones = useSelector(timezonesSelector);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [staff, setStaff] = useState('N');
  const loading = useSelector(loadingSelector);
  const createUserResponse = useSelector(createUserSelector);
  const verifyUserName = useSelector(verifyUserNameSelector);
  const verifyEmail = useSelector(verifyEmailSelector);
  const [userNameChanged, setUserNameChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [windowsLogin, setWindowsLogin] = useState('');
  const [erpLogin, setErpLogin] = useState('');
  const [passwordComplexFlag1, setPasswordComplexFlag1] = useState(false);
  const [passwordComplexFlag2, setPasswordComplexFlag2] = useState(false);
  const [passwordComplexFlag3, setPasswordComplexFlag3] = useState(false);
  const [idpChecked, setIdpChecked] = useState(false);

  useEffect(() => {
    dispatch(getTimezones());
  }, [dispatch]);

  const checkPasswordStrength = (password) => {
    const n = password.length;
    let hasLower = false,
      hasUpper = false,
      hasDigit = false,
      specialChar = false;
    const specialChars = /[(!@#$%^&*)]/;

    if (password.toUpperCase() != password) hasLower = true;
    if (password.toLowerCase() != password) hasUpper = true;
    if (/[0-9]/.test(password)) hasDigit = true;
    if (specialChars.test(password)) specialChar = true;

    if (hasDigit && hasLower && hasUpper) {
      setPasswordComplexFlag1(true);
    } else {
      setPasswordComplexFlag1(false);
    }
    if (specialChar) {
      setPasswordComplexFlag2(true);
    } else {
      setPasswordComplexFlag2(false);
    }
    if (password.length > 7) {
      setPasswordComplexFlag3(true);
    } else {
      setPasswordComplexFlag3(false);
    }
  };

  const handleCreate = (choice) => {
    if (password !== confirmPassword) {
      return;
    }
    if (
      email.length > 0 &&
      !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(email)
    ) {
      return;
    }
    if (login && email && login.toUpperCase() === email.toUpperCase()) {
      dispatch(
        setPortalSnackInfo({ severity: 'error', message: 'Username and email cannot be same' }),
      );
      return;
    }
    if (firstName.length > 50) {
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'First Name should have between 1 and 50 characters',
        }),
      );
      return;
    }
    if (lastName.length > 50) {
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'First Name should have between 1 and 50 characters',
        }),
      );
      return;
    }

    if (staff === 'Y' && windowsLogin.length === 0) {
      dispatch(setPortalSnackInfo({ severity: 'error', message: 'Staff needs windows login' }));
      return;
    }

    if (staff === 'Y' && erpLogin.length === 0) {
      dispatch(setPortalSnackInfo({ severity: 'error', message: 'Staff needs erp login' }));
      return;
    }

    var user = {
      login: login.toUpperCase(),
      password: password === '' ? 'Test12$34' : password,
      firstName: firstName,
      lastName: lastName,
      email: email.toUpperCase(),
      timeZone: timezone,
      staffFlag: staff,
      options: '{"userlanguage":"en","theme":"blue"}',
    };
    if (staff === 'Y') {
      user.windowsLogin = windowsLogin;
      user.erpLogin = erpLogin;
    }
    dispatch(
      createPortalUser(user, (result) => {
        if (result && result === 'success') {
          resetUserForm();
        }
      }),
    );
  };

  const resetUserForm = () => {
    setLogin('');
    setUserNameChanged(false);
    setPassword('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setEmailChanged(false);
    setStaff('N');
    setWindowsLogin('');
    setErpLogin('');
    setTimezone('');
    setWindowsLogin('');
    setErpLogin('');
    setConfirmPassword('');
    setIdpChecked(false);
    setLogin('');
    setPasswordComplexFlag1(false);
    setPasswordComplexFlag2(false);
    setPasswordComplexFlag3(false);
  };

  const handleVerifyUsername = () => {
    dispatch(searchUsername(login));
    setUserNameChanged(false);
  };
  const handleVerifyEmail = () => {
    dispatch(searchEmail(email));
    setEmailChanged(false);
  };

  const handleBlurChange = (event) => {
    switch (event.target.name) {
      case 'login':
        dispatch(searchUsername(login));
        setUserNameChanged(false);
        break;
      case 'email':
        dispatch(searchEmail(email));
        setEmailChanged(false);
    }
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'login':
        setLogin(event.target.value.replace(/\s/g, ''));
        setUserNameChanged(true);
        break;
      case 'password':
        checkPasswordStrength(event.target.value);
        setPassword(event.target.value);
        break;
      case 'firstName':
        setFirstName(event.target.value);
        break;
      case 'lastName':
        setLastName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        setEmailChanged(true);
        break;
      case 'staff':
        setStaff(event.target.value);
        setWindowsLogin('');
        setErpLogin('');
        break;
      case 'timezone':
        setTimezone(event.target.value);
        break;
      case 'windowsLogin':
        setWindowsLogin(event.target.value);
        break;
      case 'erpLogin':
        setErpLogin(event.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(event.target.value);
        break;
      case 'idpCheck':
        setIdpChecked(!idpChecked);
        break;
      default:
        setLogin(event.target.value);
    }
  };

  const OPTIONS = {
    lowercase: 'abcdefghijklmnopqrstuvwxyz',
    uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    numbers: '1234567890',
    specialCharacters: '(!@#$%^&*)',
  };

  const generatePassword = (length, options = {}) => {
    const optionsKeysLength = Object.keys(options).length;

    if (optionsKeysLength === 0) {
      throw new Error('NOT_ENOUGH_OPTIONS');
    }

    if (optionsKeysLength > length) {
      throw new Error('PASSWORD_TOO_SHORT');
    }

    const password = [];
    let characters = '';

    for (const property in options) {
      characters += OPTIONS[property];
      password.push(randomChar(OPTIONS[property]));
    }

    for (let i = optionsKeysLength; i < length; i++) {
      password.push(randomChar(characters));
    }

    return shuffleArray(password).join('');
  };

  const randomChar = (string) => string[Math.floor(Math.random() * string.length)];
  const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);

  return (
    <StyledGrid className={classes.root} container>
      <Grid item lg={12} md={12}>
        <Card className={classes.card}>
          <CardHeader
            subheader={
              <div>
                <Typography style={{ color: 'red' }}>
                  {password !== confirmPassword
                    ? '* New password & confirm password should match'
                    : ''}
                </Typography>
                <Typography style={{ color: 'red' }}>
                  {login.length > 0 &&
                    login.length < 5 &&
                    '* Username should have a minimum of 5 characters'}
                </Typography>
                <Typography style={{ color: 'red' }}>
                  {password.length > 0 &&
                    password.length < 8 &&
                    '* Password should have a minimum of 8 characters'}
                </Typography>
                <Typography style={{ color: 'red' }}>
                  {email.length > 0 &&
                    !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                      email,
                    ) &&
                    '* Invalid Email Format'}
                </Typography>
              </div>
            }
            title="Create new user (ERP + AUTH0)"
          />
          <CardContent className={classes.cardContent}>
            {loading && <CircularProgress color="secondary" />}
            <FormControl className={classes.formControl} fullWidth>
              <List>
                <ListItem>
                  <ListItemIcon>
                    {passwordComplexFlag1 ? (
                      <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    ) : (
                      <HighlightOffIcon style={{ color: 'red' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Minimum of one lower case (a-z), one upper case (A-Z) and one number (0-9)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {passwordComplexFlag2 ? (
                      <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    ) : (
                      <HighlightOffIcon style={{ color: 'red' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Minimum of one special character (!@#$%^&*)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {passwordComplexFlag3 ? (
                      <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    ) : (
                      <HighlightOffIcon style={{ color: 'red' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Must have 8 characters in length (Max length supported is 50 characters)" />
                </ListItem>
              </List>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {login.length > 4 &&
                        verifyUserName === 'Username is already used' &&
                        !userNameChanged && (
                          <Typography style={{ display: 'flex', color: 'red' }}>
                            Username Exists
                            <ErrorIcon style={{ marginLeft: 10, color: 'red' }} />
                          </Typography>
                        )}
                      {login.length > 4 &&
                        verifyUserName === 'Username is good' &&
                        !userNameChanged && (
                          <Typography style={{ display: 'flex', color: 'green' }}>
                            Username Verified
                            <CheckCircleIcon style={{ marginLeft: 10, color: 'green' }} />
                          </Typography>
                        )}
                      {login.length > 4 && (
                        <Tooltip placement="bottom" title="Click here to verify if Username exits">
                          <PortalButton
                            onClick={handleVerifyUsername}
                            style={{ padding: 0 }}
                            tabIndex="-1"
                            variant="contained"
                          >
                            Verify
                          </PortalButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                helperText="Limit: 50"
                inputProps={{ maxLength: 50 }}
                label="Username"
                margin="dense"
                name="login"
                onBlur={login.length > 4 && handleBlurChange}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
                type="text"
                value={login}
                variant="standard"
              />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        email,
                      ) &&
                        verifyEmail === 'Email is already used' &&
                        !emailChanged && (
                          <Typography style={{ display: 'flex', color: 'red' }}>
                            Email Exists
                            <ErrorIcon style={{ marginLeft: 10, color: 'red' }} />
                          </Typography>
                        )}
                      {/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        email,
                      ) &&
                        verifyEmail === 'Email is good' &&
                        !emailChanged && (
                          <Typography style={{ display: 'flex', color: 'green' }}>
                            Email Verified
                            <CheckCircleIcon style={{ marginLeft: 10, color: 'green' }} />
                          </Typography>
                        )}
                      {/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        email,
                      ) && (
                        <Tooltip placement="bottom" title="Click here to verify if Email exits">
                          <PortalButton
                            onClick={handleVerifyEmail}
                            style={{ padding: 0 }}
                            tabIndex="-1"
                            variant="contained"
                          >
                            Verify
                          </PortalButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
                error={
                  email.length > 0 &&
                  !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    email,
                  )
                }
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                onBlur={
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    email,
                  ) && handleBlurChange
                }
                onChange={handleChange}
                required
                type="email"
                value={email}
                variant="standard"
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={idpChecked}
                  color="primary"
                  name="idpCheck"
                  onChange={handleChange}
                />
              }
              label="SSO User"
            />
            {!idpChecked && (
              <FormControl className={classes.formControlBtn} fullWidth>
                <TextField
                  disabled={
                    verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'
                  }
                  inputProps={{ maxLength: 50 }}
                  label="Password"
                  margin="dense"
                  name="password"
                  onChange={handleChange}
                  required
                  style={{ width: '100%' }}
                  type="text"
                  value={password}
                  variant="standard"
                />
                <PortalButton
                  onClick={() => {
                    var pwd = '';
                    pwd = generatePassword(8, {
                      lowercase: true,
                      uppercase: true,
                      numbers: true,
                      specialCharacters: true,
                    });
                    checkPasswordStrength(pwd);
                    setPassword(pwd);
                    setConfirmPassword(pwd);
                  }}
                  sx={{ ml: 1 }}
                  tabIndex="-1"
                  variant="contained"
                >
                  Generate
                </PortalButton>
              </FormControl>
            )}
            {!idpChecked && (
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  disabled={
                    verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'
                  }
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label="Confirm Password"
                  margin="dense"
                  name="confirmPassword"
                  onChange={handleChange}
                  required
                  type="text"
                  value={confirmPassword}
                  variant="standard"
                />
              </FormControl>
            )}

            <FormControl className={classes.formControl} fullWidth>
              <TextField
                disabled={verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'}
                fullWidth
                helperText="Limit: 50"
                inputProps={{ maxLength: 50 }}
                label="First Name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                type="text"
                value={firstName}
                variant="standard"
              />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <TextField
                disabled={verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'}
                fullWidth
                helperText="Limit: 50"
                inputProps={{ maxLength: 50 }}
                label="Last Name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                type="text"
                value={lastName}
                variant="standard"
              />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <TextField
                disabled={verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'}
                fullWidth
                label="Staff"
                margin="dense"
                name="staff"
                onChange={handleChange}
                required
                select
                type="text"
                value={staff}
                variant="standard"
              >
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="N">No</MenuItem>
              </TextField>
            </FormControl>

            {staff === 'Y' && (
              <>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    disabled={
                      verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'
                    }
                    fullWidth
                    helperText="Limit: 50"
                    inputProps={{ maxLength: 50 }}
                    label="Windows Login"
                    margin="dense"
                    name="windowsLogin"
                    onChange={handleChange}
                    required
                    type="text"
                    value={windowsLogin}
                    variant="standard"
                  />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    disabled={
                      verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'
                    }
                    fullWidth
                    helperText="Limit: 50"
                    inputProps={{ maxLength: 50 }}
                    label="ERP Login"
                    margin="erpLogin"
                    name="erpLogin"
                    onChange={handleChange}
                    required
                    type="text"
                    value={erpLogin}
                    variant="standard"
                  />
                </FormControl>
              </>
            )}

            <TextField
              className={classes.textField}
              disabled={verifyEmail !== 'Email is good' || verifyUserName !== 'Username is good'}
              fullWidth
              id="demo-simple-select"
              label="Timezone"
              labelId="demo-simple-select-label"
              name="timezone"
              onChange={handleChange}
              required
              select
              value={timezone}
              variant="standard"
            >
              {timezones &&
                timezones.map((item) => (
                  <MenuItem key={item.KEY_TXT} value={item.KEY_TXT}>
                    {item.DESCRIPTION_TXT}
                  </MenuItem>
                ))}
            </TextField>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              mb: 2,
            }}
          >
            <PortalButton
              disabled={
                login.length < 5 ||
                (idpChecked
                  ? !idpChecked
                  : password.length < 8 ||
                    confirmPassword.length < 8 ||
                    password !== confirmPassword ||
                    password === '' ||
                    confirmPassword === '' ||
                    confirmPassword !== password ||
                    !passwordComplexFlag1 ||
                    !passwordComplexFlag2 ||
                    !passwordComplexFlag3) ||
                email.length === 0 ||
                timezone === '' ||
                !/^[#a-zA-Z0-9.!$%&'*+/=?^_`{|}~-]+@[#a-zA-Z0-9-]+(?:\.[#a-zA-Z0-9-]+)*$/.test(
                  email,
                ) ||
                verifyUserName === 'Username is already used' ||
                verifyEmail === 'Email is already used' ||
                emailChanged ||
                userNameChanged
              }
              onClick={() => handleCreate('Create')}
              size="large"
              variant="contained"
            >
              Create
            </PortalButton>
          </CardActions>
        </Card>
      </Grid>

      <Grid item lg={6} md={12} />
    </StyledGrid>
  );
}
