import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  brandNewFeaturesSelector,
  getBrandNewFeatures,
  updateBrandNewFeatures,
} from './staffSlice';
import { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Autocomplete,
  Typography,
  IconButton,
  FormControl,
  DialogActions,
  Collapse,
  TextField,
  InputLabel,
  Select,
  TableSortLabel,
  MenuItem,
  Popover,
  Tooltip,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardActions,
  CardContent,
  CardHeader,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';
import { timezoneSelector } from '../../app/authSlice';

const PREFIX = 'NewFeatures';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  formControl: `${PREFIX}-formControl`,
  controlPaper: `${PREFIX}-controlPaper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardHeader: `${PREFIX}-cardHeader`,
  noRecordsRow: `${PREFIX}-noRecordsRow`,
  cardActions: `${PREFIX}-cardActions`,
  tableSecondaryHead: `${PREFIX}-tableSecondaryHead`,
  tableHead: `${PREFIX}-tableHead`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  selectedRow: `${PREFIX}-selectedRow`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.formControl}`]: {
    width: '200px',
  },

  [`& .${classes.controlPaper}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.noRecordsRow}`]: {
    textAlign: 'center',
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.tableSecondaryHead}`]: {
    background: theme.palette.secondary.main,
    color: 'black',
  },

  [`& .${classes.tableHead}`]: {
    background: theme.palette.primary.light,
    color: 'white',
  },

  [`& .${classes.tableHeadCell}`]: {
    color: '#FFFFFF',
  },

  [`& .${classes.selectedRow}`]: {
    background: theme.palette.secondary.light,
  },
}));
const NewFeatures = () => {
  const dispatch = useDispatch();
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().subtract(7, 'd').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [domainTextValue, setDomainTextValue] = useState('');
  const id = open ? 'simple-popover' : undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const brandNewFeatures = useSelector(brandNewFeaturesSelector);
  const timeZone = useSelector(timezoneSelector);
  useEffect(() => {
    dispatch(getBrandNewFeatures());
  }, []);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  console.log(`brandnew features length${brandNewFeatures.length}`);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges) => {
    var current = selectionRange;
    current[0].startDate = ranges.selection.startDate;
    current[0].endDate = ranges.selection.endDate;
    setSelectionRange(current);
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      setAnchorEl(false);
    }
  };
  const handleUpdateBrandNewFeatures = () => {
    var brandNewFeaturesBody = {
      domainText: domainTextValue.domainText,
      startDate: moment(selectionRange[0].startDate)
        .tz(timeZone)
        .tz('America/New_York')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      endDate: moment(selectionRange[0].endDate)
        .tz(timeZone)
        .tz('America/New_York')
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
    };
    dispatch(updateBrandNewFeatures(brandNewFeaturesBody));
  };

  useEffect(() => {
    console.log('brandNewFeatures', brandNewFeatures);
    if (brandNewFeatures && brandNewFeatures.length > 0 && domainTextValue === '') {
      setDomainTextValue(brandNewFeatures[0]);
    }
  }, [brandNewFeatures]);

  return (
    <StyledRoot className={classes.root}>
      <Grid
        columns={{ xs: 12 }}
        container
        spacing={{ xs: 4, md: 4 }}
        style={{ padding: 16, justifyContent: 'center' }}
      >
        <Grid item md={3} sm={4} xs={2}>
          {brandNewFeatures && brandNewFeatures.length > 0 && (
            <FormControl fullWidth>
              <Autocomplete
                data-tour="rtSelAcct"
                fullWidth
                getOptionLabel={(option) => (option ? option.domainText : 'No Account Affiliated')}
                id="select-account-autocomplete"
                onChange={(event, newValue) => {
                  // handleAccountChange(newValue);
                  // setAcAccount(newValue);
                  setDomainTextValue(newValue);
                }}
                options={
                  brandNewFeatures && brandNewFeatures?.length > 0
                    ? brandNewFeatures.slice().sort((a, b) => {
                        const nameA = a?.domainText;
                        const nameB = b?.domainText;
                        return nameA.localeCompare(nameB);
                      })
                    : []
                }
                renderInput={(params) => {
                  if (params) {
                    return (
                      <TextField {...params} label={t('Select Brand Domain')} variant="outlined" />
                    );
                  }
                  return null;
                }}
                value={domainTextValue}
                //value={acAccount}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item md={3} sm={4} xs={2}>
          <FormControl fullWidth style={{ paddingLeft: 30 }} variant="standard">
            {/* <InputLabel htmlFor="date-input">Date Range</InputLabel> */}
            <Typography style={{ alignContent: 'center' }}>Date Range</Typography>
            {/* </FormControl>
                </Grid>
                <Grid item md={3} sm={4} xs={2}>
                    <FormControl className={classes.formDateControl} fullWidth variant="standard"> */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ alignContent: 'center' }} variant="caption">
                {selectionRange[0].startDate.toDateString()} -{' '}
                {selectionRange[0].endDate.toDateString()}
              </Typography>
              <PortalIconButton
                aria-describedby={id}
                aria-label="upload picture"
                className={classes.fab}
                component="button"
                id="date-input"
                onClick={handleClick}
                variant="contained"
              >
                <DateRangeIcon />
              </PortalIconButton>
            </div>
          </FormControl>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            id={id}
            onClose={handleClose}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <DateRangePicker
              inputRanges={[]}
              onChange={handleSelect}
              ranges={selectionRange}
              showMonthAndYearPickers={true}
              showSelectionPreview={true}
            />
          </Popover>
        </Grid>
        <Grid item md={3} sm={4} style={{ alignContent: 'center' }} xs={2}>
          <PortalButton color="primary" onClick={handleUpdateBrandNewFeatures} variant="outlined">
            Update
          </PortalButton>
        </Grid>
      </Grid>

      <Card className={classes.card}>
        <CardHeader
          // avatar={<GroupIcon />}
          className={classes.cardHeader}
          title="Brand Features Visibility"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <CardContent>
          {brandNewFeatures && brandNewFeatures.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="dense table" className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      Brand Domain
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      Start Date
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      End Date
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      Updated on
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      Updated by
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brandNewFeatures.map(
                    (row) =>
                      row &&
                      row.webUser &&
                      row.webUser.login && (
                        <TableRow key={row.id}>
                          <TableCell align="center">{row.domainText}</TableCell>
                          <TableCell align="center">
                            {moment(row.startDate).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.endDate).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')}
                          </TableCell>
                          <TableCell align="center">
                            {row.updatedDate
                              ? new Date(row.updatedDate).toLocaleDateString()
                              : 'NA'}
                          </TableCell>
                          <TableCell align="center">{row?.webUser?.login}</TableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </StyledRoot>
  );
};

export default NewFeatures;
