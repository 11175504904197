import { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setVoicemailSortKey, setVoicemailSortOrder } from './voicemailSlice';

export default function IvrMessageSort() {
  const dispatch = useDispatch();
  const [sortKey, setSortKey] = useState('creationDate');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleSortKeyChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setSortKey(value);
    } else {
      setSortKey('creationDate');
    }
  };
  const handleSortOrderChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setSortOrder(value);
    } else {
      setSortOrder('desc');
    }
  };

  useEffect(() => {
    dispatch(setVoicemailSortKey(sortKey));
  }, [sortKey]);

  useEffect(() => {
    dispatch(setVoicemailSortOrder(sortOrder));
  }, [sortOrder]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 5,
      }}
    >
      <FormControl size="small" sx={{ minWidth: '200px' }} variant="filled">
        <InputLabel id="sortKey">Sort By </InputLabel>
        <Select
          id="voicemail-sortKey"
          labelId="sortKey"
          name="sortKey"
          onChange={handleSortKeyChange}
          value={sortKey}
        >
          {[
            { key: 'creationDate', label: 'Created Date' },
            { key: 'mailboxNumber', label: 'Mailbox Number' },
          ].map((item) => (
            <MenuItem key={`sort-${item.key}`} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl size="small" sx={{ minWidth: '200px' }} variant="filled">
        <InputLabel id="sortOrder">Sorting Order </InputLabel>
        <Select
          id="voicemail-sortOrder"
          labelId="sortOrder"
          name="sortOrder"
          onChange={handleSortOrderChange}
          value={sortOrder}
        >
          {['desc', 'asc'].map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
