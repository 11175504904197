import { Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Grow, Slide, Fade } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { isLocalhost } from '../../utils/urls';

const PREFIX = 'LandingPage';

const classes = {
  root: `${PREFIX}-root`,
  rootContainer: `${PREFIX}-rootContainer`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  link: `${PREFIX}-link`,
  paper: `${PREFIX}-paper`,
  mainText: `${PREFIX}-mainText`,
  acenaMainText: `${PREFIX}-acenaMainText`,
  notifymdMainText: `${PREFIX}-notifymdMainText`,
  parkwayMessageMainText: `${PREFIX}-parkwayMessageMainText`,
  signiusMainText: `${PREFIX}-signiusMainText`,
  townlineMainText: `${PREFIX}-townlineMainText`,
  advantageAnsweringMainText: `${PREFIX}-advantageAnsweringMainText`,
  clementineMainText: `${PREFIX}-clementineMainText`,
  portCityMainText: `${PREFIX}-portCityMainText`,
  aplvMainText: `${PREFIX}-aplvMainText`,
  captionText: `${PREFIX}-captionText`,
  welcome: `${PREFIX}-welcome`,
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    background: '#000000',
    height: 'calc(100vh - 78px)',
    width: '100%',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundImage: theme.portalDefaults.background,
    backgroundRepeat: 'no-repeat',
  },

  [`& .${classes.rootContainer}`]: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: 'white',
    background: theme.palette.primary.main,
  },

  [`& .${classes.paper}`]: {
    elevation: 2,
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.mainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'white',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.acenaMainText}`]: {
    display: 'flex',
    width: '54%',
    maxWidth: '324px',
    flexDirection: 'column',
    textAlign: 'left',
    paddingLeft: '10%',
    color: 'black',
    marginTop: '100px',
  },

  [`& .${classes.notifymdMainText}`]: {
    display: 'flex',
    width: '54%',
    maxWidth: '900px',
    flexDirection: 'column',
    textAlign: 'left',
    paddingLeft: '10%',
    color: 'black',
    marginTop: '100px',
  },

  [`& .${classes.parkwayMessageMainText}`]: {
    display: 'flex',
    width: '54%',
    maxWidth: '900px',
    flexDirection: 'column',
    textAlign: 'left',
    paddingLeft: '10%',
    color: 'black',
    marginTop: '100px',
  },

  [`& .${classes.signiusMainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'white',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.townlineMainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'black',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.advantageAnsweringMainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'black',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.clementineMainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'black',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.portCityMainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'black',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.aplvMainText}`]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'black',
    marginTop: '200px',
    padding: theme.spacing(5),
  },

  [`& .${classes.captionText}`]: {
    marginTop: '20px',
    color: '#ffffff94',
  },

  [`& .${classes.welcome}`]: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },

  [`& .${classes.card}`]: {
    borderRadius: theme.spacing(3),
    margin: theme.spacing(2),
  },

  [`& .${classes.media}`]: {
    height: 200,
  },
}));

export default function LandingPage(props) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = `${isLocalhost() ? 'http' : 'https'}://${window.location.host}/home`;
    }
  }, [dispatch, isAuthenticated]);

  return (
    <StyledRoot className={classes.root}>
      <Grid className={classes.rootContainer} container spacing={4}>
        {window.location.host === 'secure2.mapcommunications.com' && (
          <>
            <Grid className={classes.mainText} item md={12} xs={12}>
              <Slide in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                <div>
                  <Typography variant="h4">
                    Professional Call Center & Phone Answering Services
                  </Typography>
                  <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                    <div style={{ marginTop: '16px' }}>
                      <Typography className={classes.captionText} variant="subtitle1" {...props}>
                        WHETHER YOU'RE A SMALL BUSINESS OR FORTUNE 500, WE'RE DIALED IN, 24/7.
                      </Typography>
                    </div>
                  </Grow>
                </div>
              </Slide>
            </Grid>

            {/* <Grid item xs={12} style={{display:'flex',justifyContent:'center',flexDirection:'row',flexWrap:'wrap',margin:'auto'}}>
  
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image="https://www.mapcommunications.com/wp-content/uploads/2015/09/live-calls.png"
                      title="Live Calls"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Live Calls
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Watch a live receptionist now and hear the difference for yourself!
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <PortalButton size="small" color="primary">
                    View Live Calls
                    </PortalButton>
                  </CardActions>
                </Card>
                </Grow>
  
                <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 4000 } : {})}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image="https://www.mapcommunications.com/wp-content/uploads/2015/09/Map-Communications-Facility.png"
                      title="Live Calls"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                      Our Facilities and Employees
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Request a tour of a MAP call center and meet our awesome team.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <PortalButton size="small" color="primary">
                      Schedule Visit
                    </PortalButton>
                  </CardActions>
                </Card>
                </Grow>
  
                <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 5000 } : {})}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image="https://www.mapcommunications.com/wp-content/uploads/2015/09/24-7-services.png"
                      title="24/7"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                      24/7 Services
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Get in touch with one of our professional answering service representatives.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <PortalButton size="small" color="primary">
                      Request Info
                    </PortalButton>
                  </CardActions>
                </Card>
                </Grow>
  
              </Grid> */}
          </>
        )}

        {window.location.host === 'secure2.dchoice.com' && (
          <Grid className={classes.mainText} item md={12} xs={12}>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h2">
                Director's Choice Funeral Home Answering Service
              </Typography>
            </Grow>
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h3">
                {' '}
                - Your Dedicated Deathcare Communication Specialists
              </Typography>
            </Fade>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <Typography style={{ marginTop: '20px' }} variant="h6">
                Available 24/7 to support the families you serve with expertise and compassion
              </Typography>
            </Grow>
          </Grid>
        )}

        {window.location.host === 'secure2.clementineanswers.com' && (
          <Grid className={classes.mainText} item md={12} xs={12}>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h2" style={{fontWeight:500}}>CARRY YOUR
              BUSINESS FURTHER</Typography>
          </Grow> */}
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h3">
                CARRY YOUR BUSINESS FURTHER
              </Typography>
            </Fade>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <Typography style={{ marginTop: '20px' }} variant="h6">
                The revolutionary live answering service. Built to be there, so you don't have to
              </Typography>
            </Grow>
          </Grid>
        )}

        {window.location.host === 'secure2.tigertel.com' && (
          <Grid className={classes.mainText} item md={12} xs={12}>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h2" style={{fontWeight:500}}>CARRY YOUR
              BUSINESS FURTHER</Typography>
          </Grow> */}
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h3">
                Professional Call Centre & 24/7 Live Answering Service
              </Typography>
            </Fade>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <Typography style={{ marginTop: '20px' }} variant="h6">
                World-class phone answering solutions provided by the #1 team of expert
                receptionists.
              </Typography>
            </Grow>
          </Grid>
        )}

        {window.location.host === 'secure2.livevoice.com' && (
          <Grid className={classes.mainText} item md={12} xs={12}>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h2" style={{fontWeight:500}}>CARRY YOUR
              BUSINESS FURTHER</Typography>
          </Grow> */}
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h2">
                24/7 Live Business Phone Answering Service
              </Typography>
            </Fade>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <Typography style={{ marginTop: '20px' }} variant="h6">
                Answering Services | Virtual Receptionist | Call Center
              </Typography>
            </Grow>
          </Grid>
        )}

        {window.location.host === 'secure2.acena.com' && (
          <Grid className={classes.acenaMainText} item md={3} xs={12}>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h4">
                Call Center & Answering Services
              </Typography>
            </Grow>
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                {' '}
                Acena is one of the nation’s premier providers of Call Center & Answering Service
                solutions.
              </Typography>
            </Fade>
          </Grid>
        )}

        {/* {window.location.host === 'secure2.notifymd.com' && (
          <Grid item xs={12} md={3} className={classes.notifymdMainText}>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography variant="h4" style={{ fontWeight: 500, marginBottom: 20 }}>
                Connecting patients and physicians one notification at a time.
              </Typography>
            </Grow>
            <Fade in={true} direction="right" style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                HIPAA-Compliant Medical Answering Services and Healthcare Communication Solutions
              </Typography>
            </Fade>
          </Grid>
        )} */}

        {window.location.host === 'secure2.signius.com' && (
          <Grid className={classes.signiusMainText} item md={12} xs={12}>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <Typography style={{ marginTop: '20px' }} variant="h5">
                Flexible, Responsive
              </Typography>
            </Grow>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h2" style={{fontWeight:500}}>24/7 ANSWERING SERVICE SOLUTIONS</Typography>
          </Grow> */}
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h3">
                24/7 ANSWERING SERVICE SOLUTIONS
              </Typography>
            </Fade>
          </Grid>
        )}

        {window.location.host === 'secure2.flatrateansweringservice.com' && (
          <Grid className={classes.notifymdMainText} item md={3} xs={12}>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h3" style={{fontWeight:500,marginBottom:20}}>Connecting patients and physicians one notification at a time.</Typography>
          </Grow>
          <Fade in={true} direction="right" style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h5"  style={{fontWeight:500}}>HIPAA-Compliant Medical Answering Services and Healthcare Communication Solutions</Typography>
          </Fade> */}
          </Grid>
        )}

        {window.location.host === 'secure2.aplv.com' && (
          <Grid className={classes.aplvMainText} item md={12} xs={12}>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h2">
                Answer Plus Las Vegas
              </Typography>
            </Grow>
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h4">
                We’re always open! Phone coverage from Vegas to New York
              </Typography>
            </Fade>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
          <Typography variant="h6"  style={{marginTop:'20px'}}>We’re always open! Phone coverage from Vegas to New York</Typography>
          </Grow>  */}
          </Grid>
        )}

        {window.location.host === 'secure2.parkwaymessage.com' && (
          <Grid className={classes.parkwayMessageMainText} item md={3} xs={12}>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h3" style={{fontWeight:500,marginBottom:20}}>Connecting patients and physicians one notification at a time.</Typography>
          </Grow> */}
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h4">
                Award-Winning Live Answering Services. HIPAA Compliant 40+ Years of Experience.
                Superior Nationwide Solutions
              </Typography>
            </Fade>
          </Grid>
        )}

        {window.location.host === 'secure2.townlinetas.com' && (
          <Grid className={classes.townlineMainText} item md={12} xs={12}>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h2" style={{fontWeight:500}}>TownLine answering services has the experience & tools necessary to fulfill your communication needs.</Typography>
          </Grow> */}
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h4">
                TownLine answering services has the experience & tools necessary to fulfill your
                communication needs
              </Typography>
            </Fade>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
              <Typography style={{ marginTop: '20px' }} variant="h5">
                No matter what business you are in, you’ll find your one-stop source for all your
                medical answering, order taking, and help desk coverage services with TownLine TAS.
              </Typography>
            </Grow>
          </Grid>
        )}

        {/* {(window.location.host ==='secure2.executel.biz'|| window.location.host ==='localhost:3000' ) &&  <Grid item xs={12} md={12} className={classes.townlineMainText}>
        
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h3" style={{fontWeight:500}}></Typography>
          </Grow>
          <Fade in={true} direction="right" style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h3"  style={{fontWeight:500}}></Typography>
          </Fade>
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
          <Typography variant="h3"  style={{marginTop:'20px'}}>Executel means excellence in live call answering + call center solutions</Typography>
          </Grow> 
      </Grid>} */}

        {window.location.host === 'secure2.advantage-plus.com' && (
          <Grid className={classes.advantageAnsweringMainText} item md={12} xs={12}>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h2" style={{fontWeight:500}}>TownLine answering services has the experience & tools necessary to fulfill your communication needs.</Typography>
          </Grow> */}
            <Fade direction="right" in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Typography style={{ fontWeight: 500 }} variant="h3">
                We answer your calls, so you can answer your calling.
              </Typography>
            </Fade>
            {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
          <Typography variant="h5"  style={{marginTop:'20px'}}>No matter what business you are in, you’ll find your one-stop source for all your medical answering, order taking, and help desk coverage services with TownLine TAS.</Typography>
          </Grow>  */}
          </Grid>
        )}

        {/* {(window.location.host ==='secure2.wagnercommunications.net'  || window.location.host ==='portal-v2.mapcom.local') && <div item xs={12} md={3} className={classes.mainText}>
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h4" style={{fontWeight:500,marginBottom:20}}>Wagner Communications has invested in the best technology available for the call center industry. AMTELCO's patented, award-winning Infinity call center system is specifically designed to provide call centers with the most reliable service, technology and support, which enables us to offer service that is second to none. Infinity offers state-of-the-art messaging and voice processing technologies, true open architecture, advanced digital switching technology and complete statistics.
  </Typography>
          </Grow>
          <Fade in={true} direction="right" style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <Typography variant="h5"  style={{fontWeight:500}}>Infinity provides tools like automatic call distribution, voice processing, text messaging, PBX capabilities and digital switching to allow us to create specialized customer resource centers.</Typography>
          </Fade>
      </div>
      } */}

        {/* add more brand based home page content */}
      </Grid>

      {/* <svg xmlns="http://www.w3.org/2000/svg"  width="100vw" viewBox="0 0 1440 220"><path fill="#00000012" fill-opacity="1" d="M0,224L40,234.7C80,245,160,267,240,261.3C320,256,400,224,480,224C560,224,640,256,720,266.7C800,277,880,267,960,250.7C1040,235,1120,213,1200,181.3C1280,149,1360,107,1400,85.3L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg> */}

      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220"><path fill="#273036" fill-opacity="0.2" d="M0,64L60,69.3C120,75,240,85,360,101.3C480,117,600,139,720,160C840,181,960,203,1080,197.3C1200,192,1320,160,1380,144L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg> */}

      {/* <svg xmlns="http://www.w3.org/2000/svg"  width="100%" viewBox="0 0 1440 320"><path fill="#273036" fill-opacity="0.3" d="M0,96L24,96C48,96,96,96,144,122.7C192,149,240,203,288,224C336,245,384,235,432,218.7C480,203,528,181,576,186.7C624,192,672,224,720,240C768,256,816,256,864,240C912,224,960,192,1008,170.7C1056,149,1104,139,1152,138.7C1200,139,1248,149,1296,144C1344,139,1392,117,1416,106.7L1440,96L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"></path></svg> */}
    </StyledRoot>
  );
}
