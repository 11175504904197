import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { timezoneSelector } from '../../../app/authSlice';
import PortalDivider from '../../shared/components/PortalDivider';
import moment from 'moment-timezone';
import SentimentIcon from './SentimentIcon';
import AssemblyAISentimentAnalysis from './assemblyAi/AssemblyAISentimentAnalysis';
import SentimentWordClouds from './SentimentWordClouds';
import { WordCloudConstants, getWordClouds } from '../../../utils/wordCloud';
import { parseOracleClobDataToJson } from '../../../utils/oracleClob';
import SentimentPieChart from './assemblyAi/SentimentPieChart';
import {
  selectedSentimentMessageSelector,
  setSelectedSentimentMessage,
  wordCloudPatternSelector,
} from '../sentimentMessageSlice';
import DOMPurify from 'dompurify';
import PortalButton from '../../shared/components/PortalButton';
import { SentimentAnalysisPlatform } from '../../../utils/sentimentUtil';
import { SpeakerWiseColorCodes, getSentimentOverallCount } from '../../../utils/assemblyAIUtil';

const PREFIX = 'SentimentMessage';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  grid: `${PREFIX}-grid`,
  cardTop: `${PREFIX}-cardTop`,
  small: `${PREFIX}-small`,
  cardBottom: `${PREFIX}-cardBottom`,
  cardBottomHeader: `${PREFIX}-cardBottomHeader`,
  actions: `${PREFIX}-actions`,
  eachField: `${PREFIX}-eachField`,
  fontBold: `${PREFIX}-fontBold`,
  callSummary: `${PREFIX}-callSummary`,
  positiveSentiment: `${PREFIX}-positiveSentiment`,
  negativeSentiment: `${PREFIX}-negativeSentiment`,
  neutralSentiment: `${PREFIX}-neutralSentiment`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  viewButtonsWrapper: `${PREFIX}-viewButtonsWrapper`,
  viewButtons: `${PREFIX}-viewButtons`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    transition: 'all .1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    marginTop: '10px',
    marginBottom: '10px',
    flexGrow: 1,
  },

  [`& .${classes.card}`]: {
    flexGrow: 1,
    padding: 0,
    borderRadius: '16px',
    boxShadow:
      '0px 2px 2px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 1px rgb(0 172 193 / 11%)',
    '&:hover': {
      boxShadow:
        '0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 51%)',
    },
  },

  [`& .${classes.cardContent}`]: {
    padding: 0,
    paddingBottom: '0px !important',
  },

  [`& .${classes.grid}`]: {
    padding: 0,
    boxShadow: 'none',
  },

  [`& .${classes.cardTop}`]: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    background: '#01adc200',
    padding: theme.spacing(1),
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.cardBottom}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: 'white',
  },

  [`& .${classes.cardBottomHeader}`]: {
    display: 'flex',
    flexGrow: 1,
    fontStyle: 'italic',
    alignContent: 'baseline',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '4px',
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    background: '#f9f9f97a',
    color: 'black',
  },

  [`& .${classes.eachField}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

  [`& .${classes.fontBold}`]: {
    fontWeight: 700,
  },

  [`& .${classes.callSummary}`]: {
    marginTop: '15px',
    fontSize: '15px',
  },

  [`& .${classes.positiveSentiment}`]: {
    color: 'green',
    paddingRight: theme.spacing(1),
    fontWeight: 700,
  },

  [`& .${classes.negativeSentiment}`]: {
    color: 'red',
    fontWeight: 700,
  },

  [`& .${classes.neutralSentiment}`]: {
    color: 'black',
    fontWeight: 700,
  },

  [`& .${classes.appBar}`]: {
    position: 'relative',
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.viewButtonsWrapper}`]: {
    margin: '20px 0 0 0',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.viewButtons}`]: {
    fontSize: '14px',
    marginRight: '20px',
    padding: '4px 10px',
    borderRadius: '10px',
  },
}));

const VerDivider = () => {
  return (
    <PortalDivider
      orientation="vertical"
      sx={{
        marginLeft: '8px',
        marginRight: '8px',
      }}
    />
  );
};

export default function SentimentMessage({ size, message, sentimentAnalysisPlatform }) {
  const dispatch = useDispatch();
  const pattern = useSelector(wordCloudPatternSelector);
  const defaultTimezone = useSelector(timezoneSelector);
  const [sentimentValue, setSentimentValue] = useState('');
  const [messageCallSummary, setMessageCallSummary] = useState('');
  const [overallSentimentCounts, setOverallSentimentCounts] = useState([]);
  const [openSentimentAnalysis, setOpenSentimentAnalysis] = useState(false);
  const [openWordCloud, setOpenWordCloud] = useState(false);
  const [componentToRenderProps, setComponentToRenderProps] = useState(false);
  const [colorCodePerSpeaker, setColorCodePerSpeaker] = useState({});
  const selectedSentimentMessage = useSelector(selectedSentimentMessageSelector);

  useEffect(() => {
    switch (sentimentAnalysisPlatform) {
      case SentimentAnalysisPlatform.assembly_ai_sentiment_analysis:
        setSentimentParametersFromAssemblyAi();
        break;
      case SentimentAnalysisPlatform.aws_sentiment_analysis:
        setSentimentParametersFromAws();
        break;
    }
    closeOpenedSectionOnMessageChange();
  }, [message]);

  /**
   * on message change, we close the existing opened sections (needed on receiver change)
   */
  const closeOpenedSectionOnMessageChange = () => {
    try {
      if (openSentimentAnalysis) {
        handleClose(SentimentAnalysisPlatform.assembly_ai_sentiment_analysis);
      }
      if (openWordCloud) {
        handleClose(WordCloudConstants.wordCloud);
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const setSentimentParametersFromAssemblyAi = () => {
    setMessageCallSummary(message?.Summary);
    try {
      if (message && message.SentimentAnalysis && message?.SentimentAnalysis?.length > 0) {
        const overallSentimentCounts = getSentimentOverallCount(message?.SentimentAnalysis);
        setOverallSentimentCounts(overallSentimentCounts);
        try {
          // finds out highest between positive or negative sentiment
          const highestSentimentValue = overallSentimentCounts
            .filter((item) => item.label !== 'NEUTRAL') // Filter out "NEUTRAL"
            .sort((a, b) => b.value - a.value)[0]; // Sort by value in descending order
          setSentimentValue(highestSentimentValue ? highestSentimentValue['label'] : '');
          setOverallSentimentCounts(overallSentimentCounts);
        } catch (error) {
          console.error('Error', error);
        }
      } else {
        setOverallSentimentCounts([]);
        setSentimentValue('');
      }
    } catch (error) {
      console.error('Error', error);
    }

    try {
      const colorCodes = getPerSpeakerColorCodes(message.Transcript);
      setColorCodePerSpeaker(colorCodes);
    } catch {
      setColorCodePerSpeaker({});
    }
  };

  const getPerSpeakerColorCodes = (transcripts) => {
    const uniqSpeakers = [...new Set(transcripts.map((item) => item?.speaker?.trim()))];
    const speakerColorCodes = {};
    uniqSpeakers.forEach((key, index) => {
      speakerColorCodes[key] = SpeakerWiseColorCodes[index];
    });
    return speakerColorCodes;
  };

  const setSentimentParametersFromAws = () => {
    console.log('');
  };
  /**
   *
   * @param {*} type
   * if given type is already open, close it
   * and if not already open, set the props, close other opened types and open it
   */
  const handleOpen = (type) => {
    let props = {};
    dispatch(setSelectedSentimentMessage(message));
    switch (type) {
      case SentimentAnalysisPlatform.assembly_ai_sentiment_analysis:
        if (openSentimentAnalysis) {
          handleClose(type);
        } else {
          props = getAssemblyAiProps();
          setComponentToRenderProps(props);
          handleClose(WordCloudConstants.wordCloud);
          setOpenSentimentAnalysis(true);
        }
        break;
      case SentimentAnalysisPlatform.aws_sentiment_analysis:
        if (openSentimentAnalysis) {
          handleClose(type);
        } else {
          props = getAwsSentimentAnalysisProps();
          setComponentToRenderProps(props);
          handleClose(WordCloudConstants.wordCloud);
          setOpenSentimentAnalysis(true);
        }
        break;
      case WordCloudConstants.wordCloud:
        if (openWordCloud) {
          handleClose(type);
        } else {
          props = getMessageWordCloudProps();
          setComponentToRenderProps(props);
          handleClose(SentimentAnalysisPlatform.assembly_ai_sentiment_analysis);
          handleClose(SentimentAnalysisPlatform.aws_sentiment_analysis);
          setOpenWordCloud(true);
        }
        break;
    }
  };

  const handleClose = (type) => {
    switch (type) {
      case SentimentAnalysisPlatform.assembly_ai_sentiment_analysis:
      case SentimentAnalysisPlatform.aws_sentiment_analysis:
        setOpenSentimentAnalysis(false);
        break;
      case WordCloudConstants.wordCloud:
        setOpenWordCloud(false);
        break;
    }
  };

  const getAssemblyAiProps = () => {
    return { message, overallSentimentCounts, colorCodePerSpeaker };
  };

  const getAwsSentimentAnalysisProps = () => {
    return {
      Categories: parseOracleClobDataToJson(message.CATEGORIES),
      ConversationCharacteristics: parseOracleClobDataToJson(message.CONVO_CHARACTERISTICS),
      Transcript: parseOracleClobDataToJson(message.TRANSCRIPT),
      LanguageCode: message.LANGUAGE_CODE,
    };
  };

  const getMessageWordCloudProps = () => {
    let words = [];
    let messages = [];
    let msgKey = '';
    switch (sentimentAnalysisPlatform) {
      case SentimentAnalysisPlatform.assembly_ai_sentiment_analysis:
        messages = message.Transcript;
        msgKey = 'text';
        words = getWordClouds(message.Summary);
        break;
      case SentimentAnalysisPlatform.aws_sentiment_analysis:
        messages = parseOracleClobDataToJson(message.TRANSCRIPT)?.map((item) => {
          return { Content: item.Content, speaker: item.ParticipantRole };
        });
        msgKey = 'Content';
        words = getWordClouds(messages, msgKey);
        break;
    }
    return { words, messages, msgKey, displayNote: true, colorCodePerSpeaker };
  };

  const getCustomSentimentChartConfig = () => {
    return {
      type: 'doughnut2d',
      width: '25%',
      height: 200,
      dataFormat: 'json',
      dataSource: {
        chart: {
          caption: '',
          subCaption: 'Overall Sentiment',
          enableSmartLabels: '0',
          startingAngle: '0',
          showPercentValues: '0',
          decimals: '1',
          useDataPlotColorForLabels: '0',
          showLegend: '1',
          showLabels: '0',
          showValues: '0',
          captionPadding: 0,
          chartLeftMargin: 0,
          chartRightMargin: 0,
          chartTopMargin: 0,
          chartBottomMargin: 0,
          theme: 'fusion',
        },
        data: [],
      },
    };
  };

  return (
    <StyledGrid className={classes.root} item md={size ? size : 12} xs={12}>
      <Card
        className={classes.card}
        sx={
          selectedSentimentMessage && selectedSentimentMessage?._id === message?._id
            ? {
                boxShadow:
                  '0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 5px rgb(0 172 193 / 51%)',
              }
            : {}
        }
      >
        <CardContent className={classes.cardContent}>
          <Grid className={classes.grid} container spacing={0}>
            <Grid item xs={9}>
              <Grid className={classes.cardTop}>
                {parseInt(message.SequenceNumber) > 0 && (
                  <Avatar className={classes.small}>{message.SequenceNumber}</Avatar>
                )}
                {parseInt(message.SequenceNumber) > 0 && <VerDivider />}
                {message.PagerNumber && (
                  <Typography className={classes.eachField} variant="body1">
                    {' '}
                    ID : {message.PagerNumber}
                  </Typography>
                )}
                {message.PagerNumber && <VerDivider />}
                {message.HolderName && (
                  <Typography className={classes.eachField} variant="body1">
                    {' '}
                    {message.HolderName}
                  </Typography>
                )}
                {message.HolderName && <VerDivider />}
                {message.ReceivedDate && (
                  <Typography className={classes.eachField} variant="body1">
                    {moment
                      .tz(message.ReceivedDate, 'YYYYMMDDHHmmss', 'America/New_York')
                      .tz(defaultTimezone)
                      .format('MMM DD,YYYY hh:mm A z')}
                  </Typography>
                )}
                {message && sentimentValue && <VerDivider />}
                {message && sentimentValue && <SentimentIcon sentiment={sentimentValue} />}
              </Grid>
              <Grid className={classes.cardBottom}>
                <div className={classes.cardBottomHeader}>
                  {messageCallSummary && (
                    <Typography className={classes.callSummary} variant="body2">
                      <span className={classes.fontBold}>CALL SUMMARY: </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            pattern && pattern.length > 0
                              ? messageCallSummary.replace(
                                  new RegExp(pattern, 'gi'),
                                  '<span style="background-color:yellow;font-weight:bold;font-size:14">$&</span>',
                                )
                              : messageCallSummary,
                          ),
                        }}
                      />
                    </Typography>
                  )}
                  <div className={classes.viewButtonsWrapper}>
                    <PortalButton
                      className={classes.viewButtons}
                      color="secondary"
                      onClick={() => handleOpen(WordCloudConstants.wordCloud)}
                      variant="outlined"
                    >
                      {openWordCloud ? 'Hide' : 'View'} Word Cloud{' '}
                    </PortalButton>

                    <PortalButton
                      className={classes.viewButtons}
                      color="primary"
                      onClick={() => handleOpen(sentimentAnalysisPlatform)}
                      variant="outlined"
                    >
                      {openSentimentAnalysis ? 'Hide' : 'View'} Sentiment Analysis
                    </PortalButton>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              {sentimentAnalysisPlatform ===
                SentimentAnalysisPlatform.assembly_ai_sentiment_analysis && (
                <SentimentPieChart
                  customChartConfigs={getCustomSentimentChartConfig()}
                  displayOverallSentiment={true}
                  overallSentimentCounts={overallSentimentCounts}
                  styles={{
                    graphContainer: {
                      backgroundColor: 'transparent',
                      padding: '0',
                    },
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {openSentimentAnalysis &&
                sentimentAnalysisPlatform ===
                  SentimentAnalysisPlatform.assembly_ai_sentiment_analysis && (
                  <AssemblyAISentimentAnalysis
                    {...componentToRenderProps}
                    displayTranscription={false}
                  />
                )}
              {openWordCloud && (
                <SentimentWordClouds {...componentToRenderProps} displayTranscription={false} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </StyledGrid>
  );
}
