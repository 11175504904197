import { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TASK_STATUS, TaskStatusColorCode } from '../../../utils/taskTracker';

export default function TaskTypeFilter({ selectedTaskStatuses, propagateCheckboxStateChange }) {
  const handleChange = (event) => {
    const change = { ...selectedTaskStatuses, [event.target.name]: event.target.checked };
    propagateCheckboxStateChange(change);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedTaskStatuses[TASK_STATUS.ACTIVE]}
            name={TASK_STATUS.ACTIVE}
            onChange={handleChange}
            style={{ color: TaskStatusColorCode[TASK_STATUS.ACTIVE] }}
          />
        }
        label={
          <span style={{ color: TaskStatusColorCode[TASK_STATUS.ACTIVE] }}>
            {TASK_STATUS.ACTIVE}
          </span>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedTaskStatuses[TASK_STATUS.PAUSED]}
            name={TASK_STATUS.PAUSED}
            onChange={handleChange}
            style={{ color: TaskStatusColorCode[TASK_STATUS.PAUSED] }}
          />
        }
        label={
          <span style={{ color: TaskStatusColorCode[TASK_STATUS.PAUSED] }}>
            {TASK_STATUS.PAUSED}
          </span>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedTaskStatuses[TASK_STATUS.COMPLETED]}
            name={TASK_STATUS.COMPLETED}
            onChange={handleChange}
            style={{ color: TaskStatusColorCode[TASK_STATUS.COMPLETED] }}
          />
        }
        label={
          <span style={{ color: TaskStatusColorCode[TASK_STATUS.COMPLETED] }}>
            {TASK_STATUS.COMPLETED}
          </span>
        }
      />
    </FormGroup>
  );
}
