import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
  TableSortLabel,
  Typography,
  Box,
} from '@mui/material';
import Button from '@mui/material/Button';
import {
  accountSelector,
  detachAccount,
  attachAccount,
  updateAccount,
  loadingSelector,
} from '../accounts/accountSlice';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { portalUserSelector } from '../../app/authSlice';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'AccountSettings';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  card: `${PREFIX}-card`,
  cardActions: `${PREFIX}-cardActions`,
  tableRow: `${PREFIX}-tableRow`,
  activeRow: `${PREFIX}-activeRow`,
  cardHeader: `${PREFIX}-cardHeader`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },

  [`& .${classes.card}`]: {
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.tableRow}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.activeRow}`]: {
    background: theme.palette.primary.light,
    cursor: 'pointer',
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function AccountSettings() {
  const dispatch = useDispatch();
  const accounts = useSelector(accountSelector);
  const loading = useSelector(loadingSelector);
  const [accountSortKey, setAccountSortKey] = useState('id');
  const [accountSortOrder, setAccountSortOrder] = useState('asc');
  const portalUser = useSelector(portalUserSelector);
  const [mode, setMode] = useState('add');
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [accountNumber, setAccountNumber] = useState('');
  const [tollFreeNumber, setTollFreeNumber] = useState('');

  const [statementNumber, setStatementNumber] = useState('');
  const [billingZip, setBillingZip] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [contextError, setContextError] = useState('');

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteEntry, setDeleteEntry] = useState(0);
  const [selAccount, setSelAccount] = useState({});
  const [nickname, setNickname] = useState('');
  const { t } = useTranslation();

  const handleSelect = (value) => {
    setSelAccount(value);
    setNickname(value.nickName ? value.nickName : '');
    setMode('edit');
    setOpen(true);
  };

  const handleChange = (event) => {
    switch (event.currentTarget?.name) {
      case 'accountNumber':
        if (event.target.value !== '' && isNaN(parseInt(event.target.value))) {
          return;
        }
        setAccountNumber(event.target.value);
        break;
      case 'tollFreeNumber':
        if (event.target.value !== '' && isNaN(parseInt(event.target.value))) {
          return;
        }
        setTollFreeNumber(event.target.value);
        break;
      case 'nickname':
        if (event.target.value.length > 50) {
          return;
        }
        setNickname(event.target.value);
        break;
      case 'statementNumber':
        setStatementNumber(event.target.value);
        break;
      case 'billingZip':
        setBillingZip(event.target.value);
        break;
      case 'billingLastName':
        setBillingLastName(event.target.value);
        break;
      case 'billingPhone':
        setBillingPhone(event.target.value);
        break;
      default:
        setTollFreeNumber(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (mode) => {
    setOpen(true);
    setAccountNumber('');
    setTollFreeNumber('');
    setMode(mode);
  };

  const updateNickname = () => {
    dispatch(updateAccount(selAccount.number, nickname));
    setOpen(false);
  };

  const addNewAccount = () => {
    if (portalUser.staffFlag === 'N') {
      if (accountNumber && accountNumber.length === 0) {
        return;
      }
      if (tollFreeNumber && tollFreeNumber.length === 0) {
        return;
      }
      if (
        (billingZip && billingLastName) ||
        (billingZip && billingPhone) ||
        (billingLastName && billingPhone)
      ) {
        setContextError('');
      } else {
        setContextError(
          'Enter any two of Billing 5-Digit Zip or Last Name of Billing Contact or Billing Contact Voice Number',
        );
        return;
      }
    }
    dispatch(
      attachAccount(
        accountNumber,
        tollFreeNumber,
        statementNumber,
        billingZip,
        billingLastName,
        billingPhone,
      ),
    );
    setOpen(false);
  };

  const createSortHandler = (key, type) => {
    if (type === 'Account') {
      if (accountSortKey === key) {
        setAccountSortOrder(accountSortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setAccountSortOrder('asc');
      }
      setAccountSortKey(key);
    }
  };

  const handleDelete = (event, value) => {
    setDeleteOpen(true);
    setDeleteEntry(value);
  };
  const closeDeletePopup = () => {
    setDeleteOpen(false);
  };

  const confirmDelete = () => {
    dispatch(detachAccount(deleteEntry));
    setDeleteOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledGrid item md={6} xs={12}>
      {loading && <CircularProgress />}

      <Card className={classes.card}>
        <CardHeader
          avatar={<AccountBoxIcon />}
          className={classes.cardHeader}
          title={t('Account Snapshot')}
        />
        <CardContent>
          <TableContainer dense>
            <Table aria-label="a dense table" className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {[
                    { name: t('Account#'), key: 'number' },
                    { name: t('Name'), key: 'name' },
                    { name: t('Nickname'), key: 'nickName' },
                  ].map((item) => (
                    <TableCell align="left" className={classes.multiColumnHeader} key={item.key}>
                      {' '}
                      <TableSortLabel
                        active={accountSortKey === item.key}
                        classes={{ active: classes.active, icon: classes.icon }}
                        direction={accountSortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler(item.key, 'Account')}
                      >
                        {item.name}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts &&
                  accounts.length > 0 &&
                  accounts
                    .slice()
                    .sort((a, b) =>
                      accountSortOrder === 'asc'
                        ? a[accountSortKey] > b[accountSortKey]
                          ? 1
                          : -1
                        : b[accountSortKey] > a[accountSortKey]
                          ? 1
                          : -1,
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value.id}`;
                      return (
                        <TableRow
                          className={
                            selAccount && selAccount.id && selAccount.id === value.id
                              ? classes.tableRow
                              : classes.tableRow
                          }
                          hover
                          key={value.id}
                        >
                          {/* <TableCell align="left" >{value.order}</TableCell> */}
                          <TableCell align="left">{value.number}</TableCell>
                          <TableCell align="left">{value.name}</TableCell>
                          <TableCell align="left">
                            <PortalButton
                              onClick={() => handleSelect(value)}
                              variant={value.nickName ? 'outlined' : 'outlined'}
                            >
                              {value.nickName ? value.nickName : <EditIcon />}
                            </PortalButton>
                          </TableCell>
                          <TableCell align="left">
                            <PortalButton color="secondary">
                              <DeleteIcon onClick={(event) => handleDelete(event, value.number)} />
                            </PortalButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <div>
            <PortalButton color="secondary" onClick={() => handleOpen('add')}>
              <AddIcon />
            </PortalButton>
            {/* <PortalButton color="secondary" onClick={handleUp}><ArrowUpwardIcon /></PortalButton>
                        <PortalButton color="secondary" onClick={handleDown}><ArrowDownwardIcon /></PortalButton> */}
            {/* <PortalButton color="secondary" onClick={() => handleOpen("edit")}>Change NickName</PortalButton> */}
            {/* <PortalButton color="secondary" >Sort By Name </PortalButton>
                        <PortalButton color="secondary" >Sort By Account# </PortalButton> */}
          </div>

          <div>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              component="div"
              count={accounts ? accounts.length : 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </div>
        </CardActions>
      </Card>

      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="form-dialog-title">
          {mode === 'edit' ? t('Set Nickname') : t('Attach Billing Account')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'red' }}>
            {mode === 'add' &&
              portalUser &&
              portalUser.staffFlag === 'Y' &&
              accountNumber.length === 0 &&
              tollFreeNumber.length === 0 &&
              'Please enter account number or toll free number to attach.'}

            {mode === 'add' &&
              portalUser &&
              portalUser.staffFlag === 'N' &&
              (accountNumber.length === 0 || tollFreeNumber.length === 0) &&
              'Please enter account number and toll free number to attach.'}

            {mode === 'edit' && nickname.length === 0 && 'Please enter nickname'}

            {contextError}
          </DialogContentText>

          {mode === 'add' && (
            <TextField
              fullWidth
              label={t('Account Number')}
              margin="dense"
              name="accountNumber"
              onChange={handleChange}
              type="text"
              value={accountNumber}
            />
          )}

          {mode === 'add' && (
            <TextField
              fullWidth
              label={t('Toll Free Number or Line Number')}
              margin="dense"
              name="tollFreeNumber"
              onChange={handleChange}
              type="text"
              value={tollFreeNumber}
            />
          )}

          {/* {mode === 'add' && portalUser && portalUser.staffFlag ==='N' && <TextField

                    margin="dense"
                    name="statementNumber"
                    label="Statement Number"
                    type="text"
                    value={statementNumber}
                    onChange={handleChange}
                    fullWidth

                    />
                    } */}

          {portalUser && portalUser.staffFlag === 'N' && (
            <Typography>Enter any two of the following</Typography>
          )}

          {mode === 'add' && portalUser && portalUser.staffFlag === 'N' && (
            <TextField
              fullWidth
              label={t('Billing 5 digit Zip')}
              margin="dense"
              name="billingZip"
              onChange={handleChange}
              type="number"
              value={billingZip}
            />
          )}

          {mode === 'add' && portalUser && portalUser.staffFlag === 'N' && (
            <TextField
              fullWidth
              label={t('Last Name of Billing Contact')}
              margin="dense"
              name="billingLastName"
              onChange={handleChange}
              type="text"
              value={billingLastName}
            />
          )}

          {mode === 'add' && portalUser && portalUser.staffFlag === 'N' && (
            <TextField
              fullWidth
              label={t('Billing Contact Voice Number')}
              margin="dense"
              name="billingPhone"
              onChange={handleChange}
              type="text"
              value={billingPhone}
            />
          )}

          {mode === 'edit' && (
            <TextField
              fullWidth
              label={t('Nickname')}
              margin="dense"
              name="nickname"
              onChange={handleChange}
              type="text"
              value={nickname}
            />
          )}
        </DialogContent>
        <DialogActions>
          {mode === 'add' && (
            <PortalButton
              color="primary"
              disabled={tollFreeNumber.length === 0 && accountNumber.length === 0}
              onClick={addNewAccount}
              variant="contained"
            >
              {t('Attach')}
            </PortalButton>
          )}
          {mode === 'edit' && (
            <PortalButton color="primary" onClick={updateNickname} variant="contained">
              {t('Save')}
            </PortalButton>
          )}
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            {t('Cancel')}
          </PortalButton>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title1"
        fullWidth={true}
        maxWidth="sm"
        onClose={closeDeletePopup}
        open={deleteOpen}
      >
        <DialogTitle id="form-dialog-title1">{t('Detach Account')}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to detach this account?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <PortalButton color="primary" onClick={confirmDelete} variant="contained">
            {t('Detach')}
          </PortalButton>
          <PortalButton color="secondary" onClick={closeDeletePopup} variant="contained">
            {t('Cancel')}
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledGrid>
  );
}
