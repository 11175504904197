import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
FusionCharts.options.license({
  key: '3lB2lsF-11E1G4E1B2B5C4C3C2C1C1D4F3H3C-22ogF4E2hnI-8bwC1E3frcC1G4B1A8D6C4E4G3H3B1A18A17B7B9B6C6zegD3J2A9oA5A1E3bapA1A7A4C-16ziG2H3H1rjvC8B2E6C2C3H2G3A3C6D6B5iacH3H3JD5uwfB2C1C1J-7nA2AC3C1zduH4B3B7A2B2C2D1D4C1F2B11C1D1E4m==',
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
