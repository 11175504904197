import { Fragment, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TASK_STATUS } from '../../../utils/taskTracker';
import { CustomizedReactDnd } from '../../shared/CustomizedReactDnd';
import { useDispatch } from 'react-redux';
import { setTaskTrackerStatusOrder } from '../staffSlice';

export default function TaskTrackerSort({
  sortOrder,
  sortKey,
  classes,
  handleChange,
  existingTaskStatusOrder,
}) {
  const dispatch = useDispatch();
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    const list = [];
    Object.keys(existingTaskStatusOrder).forEach((key, index) => {
      list.push({ id: index + 1, text: key });
    });
    if (list.length > 0) {
      setItemList(list);
    } else {
      setItemList([
        {
          id: 1,
          text: TASK_STATUS.ACTIVE,
        },
        {
          id: 2,
          text: TASK_STATUS.PAUSED,
        },
        {
          id: 3,
          text: TASK_STATUS.COMPLETED,
        },
      ]);
    }
  }, [existingTaskStatusOrder]);

  const handleTrackStatusOrderChange = (changedOrders) => {
    try {
      const taskStatusOrder = {};
      (changedOrders ?? []).forEach((item, index) => {
        taskStatusOrder[item['text']] = index;
      });
      dispatch(setTaskTrackerStatusOrder(taskStatusOrder));
    } catch (error) {
      console.error('Error', error);
    }
  };

  return (
    <>
      {itemList && itemList?.length > 0 && (
        <Box>
          <InputLabel id="taskStatusOrder" style={{ marginBottom: '10px', fontSize: '15px' }}>
            Task Status Order
          </InputLabel>
          <DndProvider backend={HTML5Backend}>
            <CustomizedReactDnd
              cardStyle={{
                border: '1px solid gray',
                borderRadius: '10px',
                padding: '0.5rem 1rem',
                marginBottom: '.5rem',
                backgroundColor: '#edf0f4',
                cursor: 'move',
              }}
              containerStyle={{
                width: 400,
              }}
              itemList={itemList}
              propagateCardChange={handleTrackStatusOrderChange}
            />
          </DndProvider>
        </Box>
      )}

      <FormControl className={classes.formControl} fullWidth variant="standard">
        <InputLabel id="sortOrder">Sorting Order </InputLabel>
        <Select
          id="demo-simple-select"
          labelId="sortOrder"
          name="sortOrder"
          onChange={handleChange}
          value={sortOrder}
        >
          {['desc', 'asc'].map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl} fullWidth variant="standard">
        <InputLabel id="sortKey">Sort By </InputLabel>
        <Select
          id="demo-simple-select"
          labelId="sortKey"
          name="sortKey"
          onChange={handleChange}
          value={sortKey}
        >
          {[
            { key: 'id', label: 'Task Id' },
            { key: 'taskType', label: 'Task Type' },
            { key: 'creation', label: 'Created Date' },
            { key: 'completed', label: 'Completion Date' },
            { key: 'accountNumber', label: 'Account Number' },
            { key: 'dueDate', label: 'Due Date' },
          ].map((item) => (
            <MenuItem key={`sort-${item.key}`} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
