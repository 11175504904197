import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PortalDialog from '../../shared/components/PortalDialog';
import AlarmCreateEditOrView from '../createEditView/AlarmCreateEditOrView';
import {
  alarmLoadingStateSelector,
  deleteAlarmById,
  editAlarmById,
  getAlarmDetailsById,
  receiverAlarmRealtimeEditInProgressDocDataListSelector,
  setAlarmDetailsById,
  setRefetchReceiverAlarmList,
} from '../alarmSlice';
import {
  portalUserSelector,
  setPortalSnackInfo,
  xxMapTimezoneSelector,
} from '../../../app/authSlice';
import { PortalBootstrapTooltip } from '../../shared/components/PortalTooltip';
import { isLocalOrDevEnvironment } from '../../../utils/urls';
import LockIcon from '@mui/icons-material/Lock';
import {
  ReceiverAlarmFirebaseDocumentStruct,
  AlarmObjectStruct,
  resetReceiverAlarmEditInProgressToFirestore,
  setAlarmFirestoreTriggerOnUpdateOfAlarm,
  validateAlarmCreateOrEditForm,
} from '../../../utils/alarmUtil';
import AlarmDetailView from '../createEditView/AlarmDetailView';
import DeleteAlarmConfirmationView from './DeleteAlarmConfirmationView';

export default function AlarmListTableActionColumn({ selectedRow }) {
  const dispatch = useDispatch();
  const loadingState = useSelector(alarmLoadingStateSelector);
  const portalUser = useSelector(portalUserSelector);
  const timezone = useSelector(xxMapTimezoneSelector);
  const [openEditAlarmDialog, setOpenEditAlarmDialog] = useState(false);
  const [openViewAlarmDetailDialog, setOpenViewAlarmDetailDialog] = useState(false);
  const [openDeleteAlarmConfirmationDialog, setOpenDeleteAlarmConfirmationDialog] = useState(false);
  const receiverAlarmRealtimeEditInProgressDocDataList = useSelector(
    receiverAlarmRealtimeEditInProgressDocDataListSelector,
  );
  const [payload, setPayload] = useState({});

  const handleOpenAlarmDetailDialog = (id) => {
    fetchAlarmDetailsById(id);
    setOpenViewAlarmDetailDialog(true);
  };

  const handleOpenEditAlarmDialog = (id) => {
    fetchAlarmDetailsById(id);
    setOpenEditAlarmDialog(true);
  };

  const fetchAlarmDetailsById = (id) => {
    if (id) {
      dispatch(getAlarmDetailsById(id));
    } else {
      dispatch(setAlarmDetailsById(null));
    }
  };

  const handleEditAlarmConfirmation = () => {
    const { isValid, errorMessage } = validateAlarmCreateOrEditForm(payload);
    if (isValid) {
      dispatch(
        editAlarmById(selectedRow.id, payload, (res) => {
          if (res === 'success') {
            setOpenEditAlarmDialog(false);
            // TODO: we have to undo this from client and do it from backend
            if (isLocalOrDevEnvironment()) {
              setAlarmFirestoreTriggerOnUpdateOfAlarm({
                receiverId: selectedRow.receiver_id,
                alarmId: selectedRow.id,
              });
            }
            resetReceiverAlarmEditInProgressToFirestore({
              receiverId: selectedRow.receiver_id,
              alarmId: selectedRow.id,
            });
          }
        }),
      );
    } else {
      dispatch(setPortalSnackInfo({ severity: 'error', message: errorMessage }));
    }
  };

  const handleFormDataChange = (data) => {
    setPayload(data);
  };

  const handleOpenDeleteAlarmConfirmationDialog = (id) => {
    setOpenDeleteAlarmConfirmationDialog(true);
  };

  const handleCloseEditAlarmDialog = () => {
    setOpenEditAlarmDialog(false);
    resetReceiverAlarmEditInProgressToFirestore({
      receiverId: selectedRow[AlarmObjectStruct.receiver_id],
      alarmId: selectedRow[AlarmObjectStruct.id],
    });
  };

  const handleCloseViewAlarmDetailDialog = () => {
    setOpenViewAlarmDetailDialog(false);
  };

  const handleCloseDeleteAlarmConfirmationDialog = () => {
    setOpenDeleteAlarmConfirmationDialog(false);
  };

  const handleDeleteAlarmConfirmation = () => {
    if (selectedRow.id) {
      dispatch(
        deleteAlarmById(selectedRow.id, (res) => {
          if (res === 'success') {
            dispatch(setRefetchReceiverAlarmList(true));
            setOpenDeleteAlarmConfirmationDialog(false);
          }
        }),
      );
    }
  };

  const setSomeOneElseIsEditingThisAlarm = (alarmId) => {
    try {
      const docData = receiverAlarmRealtimeEditInProgressDocDataList.find(
        (item) => item.id === alarmId,
      );
      if (!docData) return false;
      return (
        docData[ReceiverAlarmFirebaseDocumentStruct.editInProgress] &&
        docData[ReceiverAlarmFirebaseDocumentStruct.editorWebUserId] !== portalUser?.id
      );
    } catch {
      return false;
    }
  };

  const isAlarmNonDeletable = (selectedAlarm) => {
    return (
      (selectedAlarm && selectedAlarm[AlarmObjectStruct.dispatch_timestamp]) ||
      (selectedAlarm && selectedAlarm[AlarmObjectStruct.deleted_timestamp])
    );
  };

  const isAlarmNonEditable = (selectedAlarm) => {
    return (
      (selectedAlarm && selectedAlarm[AlarmObjectStruct.dispatch_timestamp]) ||
      (selectedAlarm && selectedAlarm[AlarmObjectStruct.deleted_timestamp])
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {setSomeOneElseIsEditingThisAlarm(selectedRow.id) ? (
          <IconButton color="primary">
            <PortalBootstrapTooltip title="Someone else is editing this alarm.">
              <LockIcon />
            </PortalBootstrapTooltip>
          </IconButton>
        ) : (
          <PortalBootstrapTooltip
            arrow
            title={
              isAlarmNonEditable(selectedRow) ? `Dispatched/Deleted alarm can't be edited.` : 'Edit'
            }
          >
            <Typography component="span">
              <IconButton
                color="primary"
                disabled={isAlarmNonEditable(selectedRow)}
                onClick={() => handleOpenEditAlarmDialog(selectedRow.id)}
              >
                <EditIcon />
              </IconButton>
            </Typography>
          </PortalBootstrapTooltip>
        )}

        <PortalBootstrapTooltip arrow title="View">
          <IconButton color="secondary" onClick={() => handleOpenAlarmDetailDialog(selectedRow.id)}>
            <VisibilityIcon />
          </IconButton>
        </PortalBootstrapTooltip>

        <PortalBootstrapTooltip
          arrow
          title={
            isAlarmNonDeletable(selectedRow)
              ? `Dispatched/Deleted alarm can't be deleted.`
              : 'Delete'
          }
        >
          <Typography component="span">
            <IconButton
              color="error"
              disabled={isAlarmNonDeletable(selectedRow)}
              onClick={() => handleOpenDeleteAlarmConfirmationDialog(selectedRow.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>
        </PortalBootstrapTooltip>
      </Box>

      {openEditAlarmDialog && (
        <PortalDialog
          ComponentProps={{
            propagateFormDataChange: handleFormDataChange,
            createEditOrViewType: 'edit',
          }}
          ComponentToRender={AlarmCreateEditOrView}
          actionItems={[
            {
              autoFocus: true,
              onClick: handleEditAlarmConfirmation,
              color: 'primary',
              text: 'Edit Alarm',
              variant: 'contained',
              disabled:
                loadingState?.loadingEditAlarmById || loadingState?.loadingGetAlarmDetailsById,
            },
            {
              autoFocus: false,
              onClick: handleCloseEditAlarmDialog,
              color: 'secondary',
              text: 'Cancel',
              variant: 'contained',
              disabled: loadingState?.loadingEditAlarmById,
            },
          ]}
          fullScreen={false}
          fullWidth={true}
          maxWidth="lg"
          open={openEditAlarmDialog}
          propagateOnClose={handleCloseEditAlarmDialog}
          showAppBar={false}
          title="Edit Alarm"
        />
      )}

      {openViewAlarmDetailDialog && (
        <PortalDialog
          ComponentProps={{
            createEditOrViewType: 'view',
          }}
          ComponentToRender={AlarmDetailView}
          actionItems={[
            {
              autoFocus: false,
              onClick: handleCloseViewAlarmDetailDialog,
              color: 'secondary',
              text: 'Cancel',
              variant: 'contained',
            },
          ]}
          fullScreen={false}
          fullWidth={true}
          maxWidth="lg"
          open={openViewAlarmDetailDialog}
          propagateOnClose={handleCloseViewAlarmDetailDialog}
          showAppBar={false}
          title="Alarm Details"
        />
      )}

      {openDeleteAlarmConfirmationDialog && (
        <PortalDialog
          ComponentToRender={() => {
            return <DeleteAlarmConfirmationView selectedRow={selectedRow} timezone={timezone} />;
          }}
          actionItems={[
            {
              autoFocus: true,
              onClick: handleDeleteAlarmConfirmation,
              color: 'error',
              text: 'Delete',
              variant: 'contained',
            },
            {
              autoFocus: false,
              onClick: handleCloseDeleteAlarmConfirmationDialog,
              color: 'secondary',
              text: 'Cancel',
              variant: 'contained',
            },
          ]}
          fullScreen={false}
          maxWidth="lg"
          open={openDeleteAlarmConfirmationDialog}
          propagateOnClose={handleCloseDeleteAlarmConfirmationDialog}
          showAppBar={false}
          title="Delete Alarm"
        />
      )}
    </>
  );
}
