import { styled } from '@mui/material/styles';

import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import PortalButton from '../../shared/components/PortalButton';
import {
  getReceiverAlarmList,
  getReceiverAlarmSettings,
  receiverAlarmSettingsSelector,
  refetchReceiverAlarmListSelector,
  selectedReceiverSelector,
  setReceiverAlarmList,
  setRefetchReceiverAlarmList,
} from '../alarmSlice';
import PortalDateRangePicker from '../../shared/components/PortalDateRangePicker';
import moment from 'moment';
import { getDefaultDateRangeObject } from '../../../utils/dateUtil';
import {
  AlarmDateRangeDefaultDays,
  AlarmObjectStruct,
  DispatchStatusOptions,
  DispatchStatusOptionValues,
  formatEndDateForListQuery,
  formatStartDateForListQuery,
  isReceiverAlarmSettingsValid,
  TimeStampOptions,
  TimeStampOptionValues,
} from '../../../utils/alarmUtil';
import AlarmCreateButton from '../createEditView/AlarmCreateButton';
import AlarmImportButton from '../import/AlarmImportButton';
import PortalDivider from '../../shared/components/PortalDivider';
import AlarmSelectReceiverDialog from './AlarmSelectReceiverButton';
import { getReceiverAutocompleteOptionLabel } from '../../../utils/receiverUtil';

const PREFIX = 'AlarmMainFilter';

const classes = {};

const StyledGrid = styled(Grid)(({ theme }) => ({}));

const getDefaultSelectionDateRange = () => {
  return getDefaultDateRangeObject(
    moment().subtract(AlarmDateRangeDefaultDays, 'd').toDate(),
    new Date(),
  );
};

export default function AlarmMainFilter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refetchAlarmList = useSelector(refetchReceiverAlarmListSelector);
  const selectedReceiver = useSelector(selectedReceiverSelector);
  const receiverAlarmSettings = useSelector(receiverAlarmSettingsSelector);

  const [dispatchStatus, setDispatchStatus] = useState(DispatchStatusOptionValues?.pending);
  const [searchByTimestamp, setSearchByTimestamp] = useState(
    TimeStampOptionValues.created_timestamp,
  );
  const [dateRange, setDateRange] = useState(getDefaultSelectionDateRange());

  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };

  useEffect(() => {
    if (refetchAlarmList) {
      fetchReceiverAlarmList(false);
      dispatch(setRefetchReceiverAlarmList(false));
    }
  }, [refetchAlarmList]);

  useEffect(() => {
    if (selectedReceiver && selectedReceiver?.number) {
      fetchReceiverAlarmList();
      dispatch(getReceiverAlarmSettings(selectedReceiver?.number));
    }
  }, [selectedReceiver]);

  const fetchReceiverAlarmList = (resetExistingList = false, qParams = {}) => {
    if (selectedReceiver && selectedReceiver?.number) {
      const { dStatus, dRange, sTimestamp } = qParams;
      dispatch(
        getReceiverAlarmList(
          selectedReceiver?.number,
          getQueryParams({
            dStatus: dStatus || dispatchStatus,
            dRange: dRange || dateRange,
            sTimestamp: sTimestamp || searchByTimestamp,
          }),
          resetExistingList,
        ),
      );
    } else {
      dispatch(setReceiverAlarmList([]));
    }
  };
  const handleDispatchStatusChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setDispatchStatus(value);
    } else {
      setDispatchStatus(DispatchStatusOptionValues.pending);
    }
  };

  const handleSearchByTimestampChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setSearchByTimestamp(value);
    } else {
      setSearchByTimestamp(TimeStampOptionValues.none);
    }
  };

  const getQueryParams = ({ dStatus, dRange, sTimestamp }) => {
    let queryParams = { receiverId: selectedReceiver?.number };
    if (dStatus === DispatchStatusOptionValues.pending) {
      queryParams = { ...queryParams, pending: 'Y' };
    } else if (dStatus === DispatchStatusOptionValues.dispatched) {
      queryParams = { ...queryParams, pending: 'N' };
    }
    if (dRange && dRange?.startDate && dRange?.endDate && dRange?.startDate != dRange?.endDate) {
      queryParams = { ...queryParams, ...getDateParams(dRange, sTimestamp) };
    } else {
      queryParams = { ...queryParams, ...getDateParams(dRange) };
    }
    return queryParams;
  };

  const getDateParams = (dRange, timestampField) => {
    let dateParams = {};
    const range = dRange || dateRange;
    switch (timestampField) {
      case TimeStampOptionValues.created_timestamp:
        dateParams = {
          createdStart: formatStartDateForListQuery(range.startDate),
          createdEnd: formatEndDateForListQuery(range.endDate),
        };
        break;
      case TimeStampOptionValues.trigger_timestamp:
        dateParams = {
          triggerStart: formatStartDateForListQuery(range.startDate),
          triggerEnd: formatEndDateForListQuery(range.endDate),
        };
        break;
      default:
        dateParams = {
          createdStart: formatStartDateForListQuery(range.startDate),
          createdEnd: formatEndDateForListQuery(range.endDate),
        };
    }
    return dateParams;
  };

  const handleSearch = () => {
    fetchReceiverAlarmList();
  };

  const handleResetSearch = () => {
    setDispatchStatus(DispatchStatusOptionValues.pending);
    setSearchByTimestamp(TimeStampOptionValues.created_timestamp);
    const defaultDateRange = getDefaultSelectionDateRange();
    setDateRange(defaultDateRange);
    fetchReceiverAlarmList(false, {
      dStatus: DispatchStatusOptionValues.pending,
      dRange: defaultDateRange,
      sTimestamp: TimeStampOptionValues.created_timestamp,
    });
  };

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 2,
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormControl variant="outlined">
                <TextField
                  InputProps={{
                    readOnly: true,
                    type: 'text',
                    startAdornment: (
                      <InputAdornment position="start">
                        {selectedReceiver
                          ? getReceiverAutocompleteOptionLabel(selectedReceiver)
                          : 'Please select a receiver'}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <AlarmSelectReceiverDialog />
                      </InputAdornment>
                    ),
                  }}
                  label="Selected receiver"
                />
              </FormControl>
              <FormControl sx={{ minWidth: 200 }} variant="outlined">
                <InputLabel id="dispatchStatus">Dispatch Status</InputLabel>
                <Select
                  id="dispatchStatus"
                  label="Dispatch Status"
                  onChange={handleDispatchStatusChange}
                  value={dispatchStatus}
                >
                  {DispatchStatusOptions.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 225 }} variant="outlined">
                <InputLabel id="searchByTimestamp">Search by Timestamp</InputLabel>
                <Select
                  id="searchByTimestamp"
                  label="Search by Timestamp"
                  onChange={handleSearchByTimestampChange}
                  value={searchByTimestamp}
                >
                  {TimeStampOptions.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {searchByTimestamp !== TimeStampOptionValues.none ? (
                <>
                  <PortalDateRangePicker
                    dateRange={dateRange}
                    maxAllowedDays={32}
                    propagateDateRangeChange={handleDateRangeChange}
                  />
                  {dateRange ? (
                    <Typography sx={{ alignSelf: 'center' }} variant="caption">
                      {moment(dateRange.startDate).startOf('day').format('MMM DD, YYYY hh:mm A z')}{' '}
                      - {moment(dateRange.endDate).endOf('day').format('MMM DD, YYYY hh:mm A z')}
                    </Typography>
                  ) : null}
                </>
              ) : null}
              <PortalButton
                color="primary"
                disabled={!isReceiverAlarmSettingsValid(receiverAlarmSettings)}
                onClick={handleSearch}
                variant="contained"
              >
                Search
              </PortalButton>
              <PortalButton
                className={classes.resetButton}
                color="secondary"
                disabled={!isReceiverAlarmSettingsValid(receiverAlarmSettings)}
                onClick={handleResetSearch}
                variant="contained"
              >
                Reset
              </PortalButton>

              {isReceiverAlarmSettingsValid(receiverAlarmSettings) ? (
                <>
                  <PortalDivider
                    orientation="vertical"
                    sx={{
                      marginLeft: 4,
                      marginRight: 4,
                    }}
                    variant="fullwidth"
                  />
                  <AlarmImportButton />
                  <AlarmCreateButton />
                </>
              ) : null}
            </Box>
          </CardContent>
        </Card>{' '}
      </Grid>
    </StyledGrid>
  );
}
