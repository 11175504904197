import { Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { Fragment } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import moment from 'moment';
import { timezoneSelector } from '../../app/authSlice';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

const PREFIX = 'Note';

const classes = {
  root: `${PREFIX}-root`,
  small: `${PREFIX}-small`,
  noteIconArea: `${PREFIX}-noteIconArea`,
  inline: `${PREFIX}-inline`,
  avatar: `${PREFIX}-avatar`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignContent: 'baseline',
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    //marginTop:theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.noteIconArea}`]: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(3),
    background: 'antiquewhite',
    width: theme.spacing(15),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    alignContent: 'baseline',
  },

  [`& .${classes.inline}`]: {
    fontStyle: 'italic',
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Note({ content }) {
  const defaultTimezone = useSelector(timezoneSelector);

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" className={classes.avatar}>
            {content.ADDED_BY.substring(0, 2).toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary=""
          secondary={
            <>
              {/* <Typography
                  variant="body1"
                  className={classes.inline}
                  color="textPrimary"
                >
                  From : {content.ADDED_BY}
                </Typography> */}
              {/* <Typography
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                 {content.NOTE_TXT}
                </Typography> */}

              <StyledRoot
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(content.NOTE_TXT),
                }}
                style={{ whiteSpace: 'pre-wrap', color: 'black' }}
              />

              <Typography variant="caption">
                {' '}
                {moment
                  .tz(content.ADD_DATE, 'YYYYMMDDHHmmss', 'America/New_York')
                  .tz(defaultTimezone)
                  .format('MMM DD,YYYY hh:mm A z')}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider component="li" variant="inset" />
    </>
  );
  // return <div className={classes.root}>

  //     <div className={classes.noteIconArea}>
  //     <NotesIcon></NotesIcon>
  //     {/* <Avatar className={classes.small} >{content.ADDED_BY.substring(0, 2).toUpperCase()}</Avatar> */}
  //     <Typography>{content.ADDED_BY.toUpperCase()}</Typography>
  //     </div>

  //     <Typography variant="body1">{content.NOTE_TXT}</Typography>
  //     <Typography variant="subtitle2"> {moment(content.ADD_DATE, "YYYYMMDDHHmmss").tz(defaultTimezone).format("YYYY-MM-DD HH:mm:ss z")}</Typography>
  //     <Divider variant="inset" />
  // </div>
}
