export const XLSXWritingOptionBookType = {
  xlsx: 'xlsx',
  xlsm: 'xlsm',
  xlsb: 'xlsb',
  xls: 'xls',
  xla: 'xla',
  biff8: 'biff8',
  biff5: 'biff5',
  biff2: 'biff2',
  xlml: 'xlml',
  ods: 'ods',
  fods: 'fods',
  csv: 'csv',
  txt: 'txt',
  sylk: 'sylk',
  html: 'html',
  dif: 'dif',
  rtf: 'rtf',
  prn: 'prn',
  eth: 'eth',
};

export const XLSXWritingOptionType = {
  base64: 'base64',
  binary: 'binary',
  buffer: 'buffer',
  file: 'file',
  array: 'array',
  string: 'string',
};

export const FileMimeTypes = {
  textPlain: 'text/plain',
  textHtml: 'text/html',
  applicationPdf: 'application/pdf',
  applicationJson: 'application/json',
  applicationXml: 'application/xml',
  imageJpeg: 'image/jpeg',
  imagePng: 'image/png',
  imageGif: 'image/gif',
  audioMpeg: 'audio/mpeg',
  audioWav: 'audio/wav',
  videoMp4: 'video/mp4',
  videoQuicktime: 'video/quicktime',
  applicationVndMsExcel: 'application/vnd.ms-excel',
  applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  applicationVndMsPowerpoint: 'application/vnd.ms-powerpoint',
  applicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  applicationMsword: 'application/msword',
  applicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  applicationZip: 'application/zip',
  applicationOctetStream: 'application/octet-stream',
};

export const FileExtensions = {
  txt: 'txt',
  html: 'html',
  pdf: 'pdf',
  json: 'json',
  xml: 'xml',
  jpeg: 'jpeg, jpg',
  png: 'png',
  gif: 'gif',
  mp3: 'mp3',
  wav: 'wav',
  mp4: 'mp4',
  mov: 'mov',
  xls: 'xls',
  xlsx: 'xlsx',
  ppt: 'ppt',
  pptx: 'pptx',
  doc: 'doc',
  docx: 'docx',
  zip: 'zip',
  bin: 'bin',
  csv: 'csv',
  ods: 'ods',
  fods: 'fods',
  sylk: 'sylk',
  dif: 'dif',
  rtf: 'rtf',
  prn: 'prn',
  eth: 'eth',
};
