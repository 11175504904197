import { Grid } from '@mui/material';
import CustomizedProgressLoader from '../shared/CustomizedProgressLoader';
import AccountLookup from './AccountLookup';
import PortalSnackBar from '../ui/PortalSnackBar';

export default function TextMessageDashboard() {
  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <TextMessageDashboardList /> */}
      </Grid>
      <Grid item xs={12}>
        <AccountLookup mode="callmask" />
      </Grid>
      {/* <Grid item xs={12}>
        <TextMessageDashboardSearch />
      </Grid>{' '}
      <Grid item xs={12}>
        <TextMessageDashboardSearchResult />
      </Grid> */}
      {/* redners snackbar globally */}
      <PortalSnackBar />
      {/* redners progress bar globally */}
      <CustomizedProgressLoader />
    </Grid>
  );
}
