import { Grid } from '@mui/material';
import PortalAutocomplete from '../PortalAutocomplete';
import { useEffect, useState } from 'react';
import { getSearchedReceivers } from '../../../messages/messageSlice';
import { useDispatch } from 'react-redux';
import { getReceiverAutocompleteOptionLabel } from '../../../../utils/receiverUtil';

export default function AccountReceiverSearch({ selectedAccount, handleValueChange }) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const renderOptionKey = 'id';
  const renderOptionLabelKey = 'holderTxt';
  const orderByField = 'number';

  useEffect(() => {
    dispatch(
      getSearchedReceivers({ accountId: selectedAccount.id }, (data) => {
        if (data && Array.isArray(data) && data?.length > 0) {
          setOptions(data.sort((a, b) => a[orderByField] - b[orderByField]));
        } else {
          setOptions([]);
        }
      }),
    );
  }, [selectedAccount]);

  const renderOption = (props, option) => {
    const { key, ...optionProps } = props;
    return (
      <li key={option[renderOptionKey]} {...optionProps}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs>
            {getReceiverAutocompleteOptionLabel(option)}
          </Grid>
        </Grid>
      </li>
    );
  };

  return (
    <PortalAutocomplete
      autoFocus={true}
      autoHighlight={true}
      getOptionLabel={getReceiverAutocompleteOptionLabel}
      noOptionsText="No receivers found"
      onValueChange={handleValueChange}
      options={options}
      renderInputLabel="Select Receiver"
      renderOption={renderOption}
      renderOptionKey={renderOptionKey}
      renderOptionLabelKey={renderOptionLabelKey}
    />
  );
}
