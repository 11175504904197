/**
 * NOTE: db stores the value by replacing ' as ||, thus we need to replace || with ' at the end
 * eg. David's house is restored in db as David||s house
 */
export const parseOracleClobDataToJson = (clobData) => {
  try {
    const formattedJson = JSON.parse(
      `${clobData}`.toString().replace(/'/g, '"').replace(/\|\|/g, "'"),
    );
    return formattedJson;
  } catch (e) {
    console.error('Error', e, clobData);
    return clobData;
  }
};
