import { forwardRef } from 'react';
import { TableRow } from '@mui/material';

const PortalTableRow = forwardRef(({ sx, ...props }, ref) => {
  return (
    <TableRow
      {...props}
      ref={ref}
      sx={{
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.02)',
        },
        ...sx,
      }}
    />
  );
});

export default PortalTableRow;
PortalTableRow.displayName = 'PortalTableRow';
