import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import {
  Autocomplete,
  ButtonGroup,
  Card,
  CardHeader,
  Grid,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  getIvrMessages,
  refetchVoicemailsSelector,
  setIvrMessages,
  setRefetchVoicemails,
  setSelectedVoicemailLineNumber,
  setVoicemailGridDisplayType,
} from './voicemailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { accountSelector, selAccountSelector } from '../accountSlice';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ListIcon from '@mui/icons-material/List';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PortalButton from '../../shared/components/PortalButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  getUtcFormattedEndOfDayDate,
  getUtcFormattedStartOfDayDate,
} from '../../../utils/ivrMessageUtil';

const PREFIX = 'VoiceMailMainFilter';

const classes = {};

const StyledGrid = styled(Grid)(({ theme }) => ({}));

export default function VoiceMailMainFilter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lineNumbers, setLineNumbers] = useState([]);
  const [displayType, setDisplayType] = useState('grid');
  const selAccount = useSelector(selAccountSelector);
  const refetchVoicemails = useSelector(refetchVoicemailsSelector);
  const accounts = useSelector(accountSelector);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectionDateRange, setSelectionDateRange] = useState([
    {
      startDate: moment().subtract(1, 'd').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [dateRangeError, setDateRangeError] = useState('');
  const [selectedLineNumber, setSelectedLineNumber] = useState(null);
  const openDateRange = Boolean(anchorEl);
  const id = openDateRange ? 'dateRange-popover' : undefined;

  useEffect(() => {
    if (refetchVoicemails) {
      dispatch(setRefetchVoicemails(false));
      getSelectedLineNumberMessages();
    }
  }, [refetchVoicemails]);

  useEffect(() => {
    setDateRangeError('');
    dispatch(setIvrMessages([]));
  }, []);

  useEffect(() => {
    getSelectedLineNumberMessages();
  }, [selectedLineNumber]);

  useEffect(() => {
    dispatch(setVoicemailGridDisplayType(displayType));
  }, [displayType]);

  useEffect(() => {
    if (accounts && accounts?.length > 0 && selAccount) {
      const currentAccount = accounts.find((element) => element.number === parseInt(selAccount));
      if (
        currentAccount &&
        currentAccount?.serviceItems &&
        currentAccount?.serviceItems?.length > 0
      ) {
        const voiceMailLineNumbers = currentAccount?.serviceItems?.filter(
          (item) => item?.type === 'ANSWR' && item?.status === 'ACT',
        );
        setLineNumbers(voiceMailLineNumbers);
        if (voiceMailLineNumbers && voiceMailLineNumbers?.length > 0) {
          setSelectedLineNumber(voiceMailLineNumbers[0]);
        }
      } else {
        setLineNumbers([]);
        setSelectedLineNumber(null);
      }
    }
  }, [accounts, selAccount]);

  const handleDatePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDisplayChange = (value) => {
    setDisplayType(value);
  };

  const handleDateChange = (ranges) => {
    const current = selectionDateRange;
    if (moment(ranges.selection.endDate).diff(moment(ranges.selection.startDate), 'days') > 7) {
      setDateRangeError('Cannot load more than 7 days');
      return;
    }
    setDateRangeError('');

    current[0].startDate = ranges.selection.startDate;
    current[0].endDate = ranges.selection.endDate;
    setSelectionDateRange(current);
    if (
      selectedLineNumber &&
      selectedLineNumber?.number &&
      ranges.selection.startDate !== ranges.selection.endDate
    ) {
      setAnchorEl(false);
      dispatch(
        getIvrMessages(
          selectedLineNumber.number,
          getUtcFormattedStartOfDayDate(ranges.selection.startDate),
          getUtcFormattedEndOfDayDate(ranges.selection.endDate),
        ),
      );
    }
  };

  const getSelectedLineNumberMessages = () => {
    if (selectedLineNumber && selectedLineNumber?.number) {
      dispatch(setSelectedVoicemailLineNumber(selectedLineNumber));
      fetchIvrMessages(selectedLineNumber.number);
    }
  };

  const fetchIvrMessages = (lineNumber) => {
    if (lineNumber && selectionDateRange && selectionDateRange?.length > 0) {
      const startDate = getUtcFormattedStartOfDayDate(selectionDateRange[0].startDate);
      const endDate = getUtcFormattedEndOfDayDate(selectionDateRange[0].endDate);
      dispatch(getIvrMessages(lineNumber, startDate, endDate));
    } else {
      dispatch(getIvrMessages([]));
    }
  };

  const handleLineNumberChange = (value) => {
    setSelectedLineNumber(value);
  };

  const handleRefreshIvrMessages = () => {
    if (selectedLineNumber && selectedLineNumber.number) {
      fetchIvrMessages(selectedLineNumber.number);
    }
  };

  return (
    <>
      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={<PhoneCallbackIcon />}
              className={classes.cardHeader}
              title={
                <Grid className={classes.headHolder} container spacing={1}>
                  <Grid item md={5} xs={12}>
                    {lineNumbers && (
                      <Autocomplete
                        data-tour="rtSelLineNumber"
                        fullWidth
                        getOptionLabel={(option) => option.number || ''}
                        id="select-lineNumber-autocomplete"
                        onChange={(event, newValue) => {
                          handleLineNumberChange(newValue);
                        }}
                        options={
                          lineNumbers && lineNumbers?.length > 0
                            ? lineNumbers.slice().sort((a, b) => a.number)
                            : []
                        }
                        renderInput={(params) => {
                          if (params) {
                            return (
                              <TextField
                                {...params}
                                label={t('Select LineNumber')}
                                variant="outlined"
                              />
                            );
                          }
                        }}
                        style={{ flex: 1, marginRight: '8px', background: 'white' }}
                        value={selectedLineNumber}
                      />
                    )}
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <ButtonGroup color="primary">
                      <IconButton
                        aria-label="upload picture"
                        color={displayType === 'grid' ? 'secondary' : 'primary'}
                        component="span"
                        onClick={() => handleDisplayChange('grid')}
                        size="medium"
                        variant="contained"
                      >
                        <Tooltip disableFocusListener title="Grid">
                          <ViewComfyIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        aria-label="upload picture"
                        color={displayType === 'list' ? 'secondary' : 'primary'}
                        component="span"
                        onClick={() => handleDisplayChange('list')}
                        size="medium"
                        variant="contained"
                      >
                        <Tooltip disableFocusListener title="List">
                          <ListIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton
                        aria-describedby={id}
                        aria-label="upload picture"
                        className={classes.fab}
                        color="white"
                        component="button"
                        onClick={handleDatePopoverClick}
                        variant="contained"
                      >
                        <DateRangeIcon />
                      </IconButton>

                      <Typography sx={{ alignSelf: 'center' }} variant="caption">
                        {moment(selectionDateRange[0].startDate)
                          .startOf('day')
                          .format('MMM DD, YYYY hh:mm A z')}{' '}
                        -{' '}
                        {moment(selectionDateRange[0].endDate)
                          .endOf('day')
                          .format('MMM DD, YYYY hh:mm A z')}
                      </Typography>
                    </ButtonGroup>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <PortalButton
                      component="span"
                      onClick={handleRefreshIvrMessages}
                      startIcon={<RefreshIcon />}
                      transition={{ type: 'spring', stiffness: 500 }}
                      variant="contained"
                    >
                      {t('Retrieve Voicemails')}
                    </PortalButton>
                  </Grid>
                </Grid>
              }
            />
          </Card>{' '}
        </Grid>
      </StyledGrid>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={id}
        onClose={() => {
          setAnchorEl(null);
        }}
        open={openDateRange}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          inputRanges={[]}
          onChange={handleDateChange}
          ranges={selectionDateRange}
          showMonthAndYearPickers={true}
          showSelectionPreview={true}
        />
        <Typography color="error" sx={{ textAlign: 'center' }} variant="subtitle1">
          {dateRangeError}
        </Typography>
      </Popover>
    </>
  );
}
