import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { sentimentMessagesSelector } from '../sentimentMessageSlice';
import SentimentMessage from './SentimentMessage';

const PREFIX = 'SentimentMessageList';

const classes = {
  root: `${PREFIX}-root`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  buttonItem: `${PREFIX}-buttonItem`,
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  noRecords: `${PREFIX}-noRecords`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },

  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    justifyContent: 'start',
    gap: theme.spacing(2),
  },

  [`& .${classes.buttonItem}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.section}`]: { marginBottom: '20px' },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '18px' },
  [`& .${classes.noRecords}`]: { display: 'flex', justifyContent: 'center' },
}));

export default function SentimentMessageList({ sentimentAnalysisPlatform }) {
  const messages = useSelector(sentimentMessagesSelector);

  const { t } = useTranslation();
  const [msgs, setMsgs] = useState([]);

  useEffect(() => {
    setMsgs(messages);
  }, [messages]);

  return (
    <StyledRoot className={classes.root}>
      <Grid container>
        {msgs &&
          msgs?.length > 0 &&
          msgs.map((item, key) => (
            <SentimentMessage
              expand
              key={key}
              message={item}
              sentimentAnalysisPlatform={sentimentAnalysisPlatform}
              staffMode={false}
            />
          ))}

        {msgs && msgs?.length === 0 && (
          <Grid item xs={12}>
            <Box className={classes.noRecords}>
              <Typography variant="overline">{t('no records found')}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </StyledRoot>
  );
}
