import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import {
  setPortalSnackOpen,
  portalSnackInfoSelector,
  portalSnackOpenSelector,
} from '../../app/authSlice';

export default function PortalSnackBar() {
  const dispatch = useDispatch();
  const open = useSelector(portalSnackOpenSelector);
  const info = useSelector(portalSnackInfoSelector);

  const handleClose = () => {
    dispatch(setPortalSnackOpen(false));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={info && info.autoHideDuration ? info.autoHideDuration : 10000}
      onClose={handleClose}
      open={open}
    >
      <Alert
        onClose={handleClose}
        severity={info && info.severity ? info.severity : 'success'}
        sx={{ boxShadow: 'none' }}
        variant="filled"
      >
        {info.message}
      </Alert>
    </Snackbar>
  );
}
