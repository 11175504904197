import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import MessageRetrieval from './MessageRetrieval';
import ContractCalculator from './ContractCalculator';
import StaffAccounts from './StaffAccounts';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import AccountLookup from './AccountLookup';
import ImpersonateUser from './ImpersonateUser';
import OnboardingUsers from './OnboardingUsers';
import ObitInformation from './ObitInformation';
import ChatAffiliation from './ChatAffiliation';
import CallMasking from './CallMasking';
import NewFeatures from './NewFeatures';
import VerifiedCallerIds from './VerifiedCallerIds';
import { useSelector } from 'react-redux';
import { portalUserSelector } from '../../app/authSlice';
import TextMessageDashboard from './TextMessageDashboard';
import TaskTracker from './taskTracker';
import { ITTeamPortalUserLogins, allowOnlyITTeamPortalUserLogins } from '../../utils/urls';
import { uniqBy } from 'lodash';
import PortalTab from '../shared/components/PortalTab';
import Alarms from '../alarms';

const PREFIX = 'Staff';

const classes = {
  root: `${PREFIX}-root`,
  tab: `${PREFIX}-tab`,
  tabLabel: `${PREFIX}-tabLabel`,
  tabPanelHolder: `${PREFIX}-tabPanelHolder`,
  dropdown: `${PREFIX}-dropdown`,
  controls: `${PREFIX}-controls`,
  paper: `${PREFIX}-paper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardHeader: `${PREFIX}-cardHeader`,
  link: `${PREFIX}-link`,
  selected: `${PREFIX}-selected`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minWidth: 100,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },

  [`& .${classes.tab}`]: {
    flexGrow: theme.spacing(1),
    margin: theme.spacing(0),
  },

  [`& .${classes.tabLabel}`]: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },

  [`& .${classes.tabPanelHolder}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.controls}`]: {
    width: '100vw',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.cardHeader}`]: {
    margin: 0,
    padding: 10,
    background: '#e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: 'white',
  },

  [`& .${classes.selected}`]: {
    textDecoration: 'none',
    color: 'yellow',
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Paper style={{ flexGrow: 1, width: '100%' }}>{children}</Paper>}
    </div>
  );
};

const tabIdentifier = {
  messageRetrieval: 'messageRetrieval',
  contractCalculator: 'contractCalculator',
  staffAccounts: 'staffAccounts',
  accountLookup: 'accountLookup',
  taskTracker: 'taskTracker',
  onboardUsers: 'onboardUsers',
  manageUsers: 'manageUsers',
  obitInformation: 'obitInformation',
  chatAccessGroup: 'chatAccessGroup',
  masking: 'masking',
  callMasking: 'callMasking',
  verifiedCallerIDs: 'verifiedCallerIDs',
  alarms: 'alarms',
};

const tabOptions = {
  messageRetrieval: {
    identifier: tabIdentifier.messageRetrieval,
    label: 'Message Retrieval',
    tabPanelComponent: MessageRetrieval,
    componentProps: {},
    displayOrder: 0,
  },
  contractCalculator: {
    identifier: tabIdentifier.contractCalculator,
    label: 'Contract Calculator',
    tabPanelComponent: ContractCalculator,
    componentProps: {},
    displayOrder: 1,
  },
  staffAccounts: {
    identifier: tabIdentifier.staffAccounts,
    label: 'Staff Accounts',
    tabPanelComponent: StaffAccounts,
    componentProps: { type: 'receiver' },
    displayOrder: 2,
  },
  accountLookup: {
    identifier: tabIdentifier.accountLookup,
    label: 'Account Lookup',
    tabPanelComponent: AccountLookup,
    componentProps: {},
    displayOrder: 3,
  },
  taskTracker: {
    identifier: tabIdentifier.taskTracker,
    label: 'Task Tracker',
    tabPanelComponent: TaskTracker,
    componentProps: {},
    displayOrder: 4,
  },
  onboardUsers: {
    identifier: tabIdentifier.onboardUsers,
    label: 'Onboard Users',
    tabPanelComponent: OnboardingUsers,
    componentProps: {},
    displayOrder: 5,
  },
  manageUsers: {
    identifier: tabIdentifier.manageUsers,
    label: 'Manage Users',
    tabPanelComponent: ImpersonateUser,
    componentProps: {},
    displayOrder: 6,
  },
  obitInformation: {
    identifier: tabIdentifier.obitInformation,
    label: 'Obit Information',
    tabPanelComponent: ObitInformation,
    componentProps: {},
    displayOrder: 7,
  },
  chatAccessGroup: {
    identifier: tabIdentifier.chatAccessGroup,
    label: 'Chat Access Group',
    tabPanelComponent: ChatAffiliation,
    componentProps: {},
    displayOrder: 8,
  },
  masking: {
    identifier: tabIdentifier.masking,
    label: 'Masking',
    tabPanelComponent: TextMessageDashboard,
    componentProps: {},
    displayOrder: 9,
  },
  callMasking: {
    identifier: tabIdentifier.callMasking,
    label: 'Call masking',
    tabPanelComponent: CallMasking,
    componentProps: {},
    displayOrder: 10,
  },
  verifiedCallerIDs: {
    identifier: tabIdentifier.verifiedCallerIDs,
    label: 'Verified Caller IDs',
    tabPanelComponent: VerifiedCallerIds,
    componentProps: {},
    displayOrder: 11,
  },
  newFeatures: {
    identifier: tabIdentifier.newFeatures,
    label: 'Features',
    tabPanelComponent: NewFeatures,
    componentProps: {},
    displayOrder: 11,
  },
  alarms: {
    identifier: tabIdentifier.alarms,
    label: 'Alarms',
    tabPanelComponent: Alarms,
    componentProps: {},
    displayOrder: 12,
  },
};

const Staff = () => {
  const [value, setValue] = useState(0);
  const portalUser = useSelector(portalUserSelector);
  const [tabsToRender, setTabsToRender] = useState([]);

  const getDefaultTabsToRender = () => {
    const defaultTabs = [
      tabOptions.messageRetrieval,
      tabOptions.contractCalculator,
      tabOptions.staffAccounts,
      tabOptions.accountLookup,
      tabOptions.taskTracker,
      tabOptions.onboardUsers,
      tabOptions.manageUsers,
      tabOptions.obitInformation,
      tabOptions.chatAccessGroup,
      tabOptions.masking,
      tabOptions.alarms,
    ];

    // Conditionally add the newFeatures tab based on the portalUser login
    if (
      portalUser.login === 'SALLURI' ||
      portalUser.login === 'KSWAMY' ||
      portalUser.login === 'VDANI' ||
      portalUser.login === 'TESTCASE105' ||
      portalUser.login === 'SSARNAVSKIY' ||
      portalUser.login === 'RJUSTIS' ||
      portalUser.login === 'LSIBLEY' ||
      portalUser.login === 'JCAMPBELL1' ||
      portalUser.login === 'CMOSCHELLA' ||
      portalUser.login === 'MREBLIN'
    ) {
      defaultTabs.push(tabOptions.newFeatures);
    }

    return defaultTabs;
  };

  useEffect(() => {
    const toUpdateTabs = getDefaultTabsToRender();
    setTabsToRender(
      uniqBy(
        toUpdateTabs.sort((tab1, tab2) => tab1.displayOrder - tab2.displayOrder),
        'identifier',
      ),
    );
  }, []);

  useEffect(() => {
    const toUpdateTabs = getDefaultTabsToRender();
    if (
      allowOnlyITTeamPortalUserLogins(portalUser?.login, [
        ITTeamPortalUserLogins.KSWAMY,
        ITTeamPortalUserLogins.SALLURI,
        ITTeamPortalUserLogins.VDANI,
        ITTeamPortalUserLogins.TESTCASE105,
      ])
    ) {
      // TODO: we are not currently display CallMasking and verifiedCallerIDs, uncomment this to render
      // toUpdateTabs = [...toUpdateTabs, tabOptions.callMasking, tabOptions.verifiedCallerIDs];
    }
    setTabsToRender(
      uniqBy(
        toUpdateTabs.sort((tab1, tab2) => tab1.displayOrder - tab2.displayOrder),
        'identifier',
      ),
    );
  }, [portalUser]);

  const handleTabChange = (selectedTab, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledGrid className={classes.root} container>
      <Grid className={classes.tabPanelHolder} item xs={12}>
        <AppBar position="static">
          <Tabs
            aria-label="staffs menus"
            indicatorColor="secondary"
            scrollButtons="auto"
            textColor="primary"
            value={value}
            variant="scrollable"
          >
            {tabsToRender?.map((tab, index) => (
              <PortalTab
                className={classes.tabLabel}
                component={NavLink}
                key={tab.identifier}
                label={tab.label}
                onClick={() => handleTabChange(tab, index)}
              />
            ))}
          </Tabs>
        </AppBar>
        {tabsToRender?.map((tab, index) => (
          <TabPanel className={classes.tab} index={index} key={tab.identifier} value={value}>
            <tab.tabPanelComponent {...tab.componentProps} />
          </TabPanel>
        ))}
      </Grid>
    </StyledGrid>
  );
};
export default Staff;
