import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import { setPortalSnackInfo } from '../../../app/authSlice';

const PortalCopyClipboard = ({ sx, fontSize, color, text }) => {
  const dispatch = useDispatch();
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(setPortalSnackInfo({ message: 'Copied', severity: 'info' }));
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <ContentCopyIcon
      color={color}
      fontSize={fontSize}
      onClick={handleCopyToClipboard}
      sx={{
        cursor: 'pointer',
        ...sx,
      }}
    />
  );
};

export default PortalCopyClipboard;
PortalCopyClipboard.displayName = 'PortalCopyClipboard';
