import Tab from '@mui/material/Tab';
import { forwardRef } from 'react';

const PortalTab = forwardRef(({ sx, ...props }, ref) => {
  return (
    <Tab
      sx={{
        transition: 'box-shadow 0.2s ease-in-out, border-bottom 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
          borderBottom: '1px solid #ffb74d',
        },
        ...sx,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default PortalTab;
PortalTab.displayName = 'PortalTab';
