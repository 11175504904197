import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stepper,
  Step,
  StepButton,
  Button,
  ButtonGroup,
  FormControl,
  TextField,
  Tooltip,
  InputAdornment,
  Card,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LoopIcon from '@mui/icons-material/Loop';
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import {
  availableProductsSelector,
  getAvailableProducts,
  getTaxforProduct,
  getZip,
  setTaxes,
  taxesSelector,
  zipSelector,
} from './staffSlice';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PortalButton from '../shared/components/PortalButton';

const PREFIX = 'ContractCalculator';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  quoteControls: `${PREFIX}-quoteControls`,
  productMenu: `${PREFIX}-productMenu`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  tableHeader: `${PREFIX}-tableHeader`,
  tableBody: `${PREFIX}-tableBody`,
  quoteItemHolder: `${PREFIX}-quoteItemHolder`,
  quoteHolder: `${PREFIX}-quoteHolder`,
  formControl: `${PREFIX}-formControl`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.quoteControls}`]: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  [`& .${classes.productMenu}`]: {
    maxHeight: '200px',
    overflow: 'auto',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    borderRadius: '20px',
    flexGrow: 1,
  },

  [`& .${classes.table}`]: {
    borderRight: '2px',
  },

  [`& .${classes.tableHeader}`]: {
    background: theme.palette.secondary.main,
    color: 'white',
  },

  [`& .${classes.tableBody}`]: {
    background: '#fdfdfd',
  },

  [`& .${classes.quoteItemHolder}`]: {
    maxHeight: '500px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.light,
      outline: '1px solid slategrey',
    },
  },

  [`& .${classes.quoteHolder}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ContractCalculator = () => {
  const dispatch = useDispatch();
  const [selProductType, setSelProductType] = useState('');
  const [baseProductSelected, setBaseProductSelected] = useState(false);
  const [selProductQuantity, setSelProductQuantity] = useState({});
  const [availableProducts, setAvailableProducts] = useState([]);
  const [prepay, setPrepay] = useState('');
  const [override, setOverride] = useState(false);
  const [zip, setZip] = useState('');
  const [taxItems, setTaxItems] = useState({});
  const taxes = useSelector(taxesSelector);
  const zipinfo = useSelector(zipSelector);
  const [open, setOpen] = useState(false);
  const [selItem, setSelItem] = useState({});
  const [newPlanPrice, setNewPlanPrice] = useState(0);

  const [productTypes] = useState([
    'Service Plans',
    'Account Enhancements',
    'Line Enhancements',
    'Hardware Purchase',
    'Hardware Enhancement',
    'Rental',
    'Airtime',
    'Administrative Fee',
  ]);

  const handleZipChange = (event) => {
    setZip(event.target.value);
    if (event.target.value.length === 5) {
      dispatch(getZip(event.target.value));
    } else if (event.target.value === '') {
      dispatch(getZip(23456));
    }
  };

  const [summaryFlag, setSummaryFlag] = useState(1);

  useEffect(() => {
    dispatch(getAvailableProducts());
  }, [dispatch]);

  const products = useSelector(availableProductsSelector);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [summary, setSummary] = useState({
    Subtotal: 0,
    SubtotalRec: 0,
    SubtotalNonRec: 0,
    Tax: 0,
    Total: 0,
    Prepayment: 0,
  });

  const reset = () => {
    setSummary({
      Subtotal: 0,
      SubtotalRec: 0,
      SubtotalNonRec: 0,
      Tax: 0,
      Total: 0,
      Prepayment: 0,
    });
    setSelProductQuantity({});
    setZip(0);
    setPrepay(0);
    setOverride(false);
    setTaxes({});
    setSelectedPlans([]);
  };

  const overridePrepay = (event) => {
    setPrepay(event.target.value);
  };

  useEffect(() => {
    if (taxes && taxes.id) {
      var newTaxItems = { ...taxItems };
      var newSummary = {
        Subtotal: 0,
        SubtotalRec: 0,
        SubtotalNonRec: 0,
        Tax: 0,
        Total: 0,
        Prepayment: override ? parseFloat(prepay) : 0,
      };
      newTaxItems[taxes.id] = taxes.tax;
      setTaxItems(newTaxItems);
      selectedPlans.forEach((item) => {
        var itemQuantity = selProductQuantity[item.id] ? selProductQuantity[item.id] : 1;
        newSummary.Subtotal += item.stndPriceUsd * itemQuantity;

        if (item.recurringFlag === 'Y') {
          newSummary.SubtotalRec += item.stndPriceUsd * itemQuantity;
        } else {
          newSummary.SubtotalNonRec += item.stndPriceUsd * itemQuantity;
        }

        if (newTaxItems[item.id]) {
          newSummary.Tax += newTaxItems[item.id];
          newSummary.Total += newTaxItems[item.id];
        }
        if (item.recurringFlag === 'Y' && !override) {
          newSummary.Prepayment += item.stndPriceUsd * itemQuantity;
        }
      });

      newSummary.Total += newSummary.Subtotal + newSummary.Prepayment;
      setSummary(newSummary);
    }
  }, [taxes, override, prepay]);

  useEffect(() => {
    selectedPlans.forEach((item) => {
      if (!taxItems[item.id] && zip.length === 5) {
        dispatch(getTaxforProduct(item.id, zip, selProductQuantity[item.id], item.stndPriceUsd));
      } else {
        dispatch(
          getTaxforProduct(
            item.id,
            zip ? zip : '23456',
            selProductQuantity[item.id],
            item.stndPriceUsd,
          ),
        );
      }
    });
  }, [selectedPlans, selProductQuantity, summaryFlag]);

  useEffect(() => {
    if (zipinfo.ZIP) {
      setTaxItems({});
      var newSummary = {
        Subtotal: 0,
        Tax: 0,
        Total: 0,
        Prepayment: override ? parseFloat(prepay) : 0,
      };
      setSummary(newSummary);
      setSummaryFlag(summaryFlag + 1);
    }
  }, [zipinfo]);

  //add new product line item
  const handleProductClick = (item) => {
    var currentQuantity = selProductQuantity;
    if (!currentQuantity[item.id]) {
      if (item.productCodeClassType !== 'ACTSR') {
        const newChecked = [...selectedPlans];
        currentQuantity[item.id] = 1;
        newChecked.push(item);
        setSelProductQuantity(currentQuantity);
        setSelectedPlans(newChecked);
      } else if (item.productCodeClassType === 'ACTSR' && !baseProductSelected) {
        setBaseProductSelected(true);
        const newChecked = [...selectedPlans];
        currentQuantity[item.id] = 1;
        newChecked.push(item);

        products
          .filter(
            (e) =>
              e.productCodeClassType === 'CFEES' &&
              e.productCodeSubClassType === item.productCodeSubClassType,
          )
          .forEach((element) => {
            newChecked.push(element);
            currentQuantity[element.id] = 1;
          });

        setSelProductQuantity(currentQuantity);
        setSelectedPlans(newChecked);
      } else if (item.productCodeClassType === 'ACTSR' && baseProductSelected) {
        const newChecked = [];
        newChecked.push(item);
        currentQuantity[item.id] = 1;
        selectedPlans.forEach((element) => {
          if (!element.productCodeClassType === 'ACTSR') {
            newChecked.push(element);
          } else {
            delete currentQuantity[element.id];
          }
        });

        products
          .filter(
            (e) =>
              e.productCodeClassType === 'CFEES' &&
              e.productCodeSubClassType === item.productCodeSubClassType,
          )
          .forEach((element) => {
            newChecked.push(element);
            currentQuantity[element.id] = 1;
          });

        setSelProductQuantity(currentQuantity);
        setSelectedPlans(newChecked);
      }
    }
  };

  const removeSelPlan = (id) => {
    const current = [...selectedPlans];
    var currentQuantity = selProductQuantity;
    var currentTaxes = { ...taxItems };
    var newProducts = [];
    current.forEach((item) => {
      if (item.id !== id) {
        newProducts.push(item);
      }
    });
    delete currentQuantity[id];
    delete currentTaxes[id];
    setTaxItems(currentTaxes);
    setSelProductQuantity(currentQuantity);
    setSelectedPlans(newProducts);
  };

  const handleClose = () => {
    setOpen(false);
    setSelItem({});
    setNewPlanPrice(0);
  };

  const updatePlanPrice = (id, price) => {
    const current = [...selectedPlans];
    const newProducts = [];
    current.forEach((element) => {
      var newElement = { ...element };
      if (newElement.id === id) {
        newElement.stndPriceUsd = parseFloat(price).toFixed(2);
      }
      newProducts.push(newElement);
    });
    setSelectedPlans(newProducts);
    handleClose();
  };

  const showPriceChange = (item) => {
    setOpen(true);
    setSelItem(item);
    setNewPlanPrice(parseFloat(item.stndPriceUsd).toFixed(2));
  };

  const updatePlanQuantity = (id, flag) => {
    var currentTaxes = { ...taxItems };
    var currentQuantity = selProductQuantity;
    if (currentQuantity[id]) {
      if (flag === '+') {
        currentQuantity[id] += 1;
      } else {
        currentQuantity[id] -= 1;
      }
    }

    delete currentTaxes[id];
    setTaxItems(currentTaxes);
    setSelProductQuantity(currentQuantity);
    setSummaryFlag(summaryFlag + 1);
  };

  const handleStep = (step) => () => {
    setSelProductType(step);
    var newproducts;
    switch (step) {
      case 0:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'ACTSR';
        });

        setAvailableProducts(newproducts);
        break;
      case 1:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'ACTEN';
        });
        setAvailableProducts(newproducts);
        break;
      case 2:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'LINEN';
        });
        setAvailableProducts(newproducts);
        break;
      case 3:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'HARDP';
        });
        setAvailableProducts(newproducts);
        break;
      case 4:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'HRDEN';
        });
        setAvailableProducts(newproducts);
        break;
      case 5:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'HRDRN';
        });
        setAvailableProducts(newproducts);
        break;
      case 6:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'ASERV';
        });
        setAvailableProducts(newproducts);
        break;
      case 7:
        newproducts = products.filter((item) => {
          return item.productCodeClassType === 'ADMIN';
        });
        setAvailableProducts(newproducts);
        break;
      default:
        setAvailableProducts(products);
        break;
    }
  };

  return (
    <StyledRoot className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ pt: 3, pb: 3 }}>
            <Stepper activeStep={selProductType} alternativeLabel nonLinear>
              {productTypes.map((label, index) => {
                const stepProps = {};
                const buttonProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton onClick={handleStep(index)} {...buttonProps}>
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </Card>

          <List className={classes.productMenu}>
            {availableProducts &&
              availableProducts.map((item) => (
                <Fragment key={item.id}>
                  <ListItem button onClick={() => handleProductClick(item)}>
                    <ListItemAvatar>
                      <AddCircleIcon color="secondary" size="small" />
                    </ListItemAvatar>
                    <ListItemText primary={`$${item.stndPriceUsd.toFixed(2)} ${item.descTxt}`} />
                  </ListItem>
                </Fragment>
              ))}
          </List>
        </Grid>
        <Grid item style={{ justifyContent: 'center' }} xs={12}>
          <Paper className={classes.paper} elevation={2}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="primary" variant="h6">
                Price Quote
              </Typography>
              <PortalButton color="secondary" onClick={reset} variant="contained">
                Reset
              </PortalButton>
            </Box>
            <Box className={classes.quoteControls}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  fullWidth
                  label="Prepayment"
                  margin="dense"
                  name="prepay"
                  onChange={overridePrepay}
                  type="number"
                  value={prepay}
                  variant="standard"
                />
              </FormControl>

              <PortalButton
                color="secondary"
                onClick={() => {
                  if (override) {
                    setPrepay(0);
                  }
                  setOverride(!override);
                }}
                variant={override ? 'contained' : 'outlined'}
              >
                {override ? 'Clear' : 'Override'}
              </PortalButton>
            </Box>

            <Box className={classes.quoteControls}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  helperText={
                    zip !== '0' && zipinfo && zipinfo.CITY && `${zipinfo.CITY},${zipinfo.STATE}`
                  }
                  label="Zipcode"
                  margin="dense"
                  name="zip"
                  onChange={handleZipChange}
                  type="number"
                  value={zip}
                  variant="standard"
                />
              </FormControl>
            </Box>

            <Grid className={classes.quoteHolder} container>
              <Grid className={classes.quoteItemHolder} item xs={12}>
                <TableContainer>
                  <Table aria-label="table dense" className={classes.table} size="small">
                    <TableHead className={classes.tableHeader}>
                      {/* <TableRow color="primary">
                                            <TableCell align="center" colSpan={4}>Items</TableCell>
                                        </TableRow> */}
                      <TableRow color="secondary">
                        <TableCell align="center">Product</TableCell>
                        <TableCell align="center">Unit Price</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Recurring</TableCell>
                        <TableCell align="center">Nonrecurring</TableCell>
                        <TableCell align="center" />
                        <TableCell align="center">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {selectedPlans.length === 0 && (
                        <TableRow rowSpan={3}>
                          <TableCell align="center" colSpan={4}>
                            No plans selected
                          </TableCell>
                        </TableRow>
                      )}
                      {selectedPlans &&
                        selectedPlans.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell align="center">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignContent: 'flex-start',
                                }}
                              >
                                <Typography variant="overline">{item.descTxt}</Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {item.recurringFlag === 'Y' ? (
                                    <Tooltip title="Recurring">
                                      <Typography variant="caption">
                                        <LoopIcon />
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Applied once">
                                      <Typography variant="caption">
                                        <RepeatOneIcon />
                                      </Typography>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <PortalButton onClick={() => showPriceChange(item.id)}>
                                {`$${item.stndPriceUsd}`}
                              </PortalButton>
                            </TableCell>

                            {/* {(item.productCodeClassType === 'ACTSR' || item.productCodeClassType === 'CFEES') && <TableCell align="center"><Typography variant="caption">1</Typography></TableCell>} */}
                            <TableCell align="center">
                              <ButtonGroup
                                aria-label="text primary button group"
                                color="primary"
                                contained
                              >
                                <PortalButton
                                  color="primary"
                                  onClick={() => updatePlanQuantity(item.id, '+')}
                                >
                                  +
                                </PortalButton>
                                <PortalButton>{selProductQuantity[item.id]}</PortalButton>
                                <PortalButton
                                  color="primary"
                                  onClick={() => updatePlanQuantity(item.id, '-')}
                                >
                                  -
                                </PortalButton>
                              </ButtonGroup>
                            </TableCell>
                            <TableCell align="center">
                              {`$${(item.recurringFlag === 'Y'
                                ? selProductQuantity[item.id] * item.stndPriceUsd
                                : 0
                              ).toFixed(2)}`}
                            </TableCell>
                            <TableCell align="center">
                              {`$${(item.recurringFlag === 'N'
                                ? selProductQuantity[item.id] * item.stndPriceUsd
                                : 0
                              ).toFixed(2)}`}
                            </TableCell>
                            <TableCell align="center">
                              <PortalButton onClick={() => removeSelPlan(item.id)}>
                                <CancelIcon />
                              </PortalButton>
                            </TableCell>
                            <TableCell align="right">
                              {`$${(selProductQuantity[item.id] * item.stndPriceUsd).toFixed(2)}`}
                            </TableCell>
                          </TableRow>
                        ))}

                      {summary && (
                        <>
                          <TableRow>
                            <TableCell colSpan={2} rowSpan={4} />
                            <TableCell>Subtotal</TableCell>
                            <TableCell align="center">
                              {`$${
                                summary.SubtotalRec
                                  ? parseFloat(summary.SubtotalRec).toFixed(2)
                                  : '0.00'
                              }`}
                            </TableCell>
                            <TableCell align="center">
                              {`$${
                                summary.SubtotalNonRec
                                  ? parseFloat(summary.SubtotalNonRec).toFixed(2)
                                  : '0.00'
                              }`}
                            </TableCell>
                            <TableCell />
                            <TableCell align="right">
                              {`$${parseFloat(summary.Subtotal).toFixed(2)}`}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={4}>Taxes</TableCell>
                            <TableCell align="right">
                              {`$${parseFloat(summary.Tax).toFixed(2)}`}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={4}>Prepayment</TableCell>
                            <TableCell align="right">
                              {summary && `$${parseFloat(summary.Prepayment).toFixed(2)}`}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={4}>Contract Total</TableCell>
                            <TableCell align="right" style={{ fontWeight: 'bold' }}>
                              {`$${parseFloat(summary.Total).toFixed(2)}`}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        aria-labelledby="form-dialog-title1"
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="form-dialog-title1">Edit Price</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            autocomplete={false}
            fullWidth
            id="name"
            label="Price"
            margin="dense"
            onChange={(event) => setNewPlanPrice(event.target.value)}
            type="number"
            value={newPlanPrice}
          />
        </DialogContent>
        <DialogActions>
          <PortalButton
            color="primary"
            onClick={() => updatePlanPrice(selItem, newPlanPrice)}
            variant="contained"
          >
            Update
          </PortalButton>
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
};
export default ContractCalculator;
