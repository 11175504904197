import moment from 'moment';
const DateFormat = 'YYYY-MM-DD HH:mm:ss';

export const getMailboxDropdownOption = (ivrMessage) => {
  return `${ivrMessage?.mailboxDetail?.mailboxNumber} - ${ivrMessage?.mailboxDetail?.mailboxHolderName}`;
};

export const getUtcFormattedStartOfDayDate = (startDate) => {
  return moment(startDate).startOf('day').utc().format(DateFormat);
};

export const getUtcFormattedEndOfDayDate = (endDate) => {
  return moment(endDate).endOf('day').utc().format(DateFormat);
};
