export default function DCChat() {
  const chatframe = `<iframe src="https://chat.dchoice.com/chat/ecea/?org=btn&amp;pg="${escape(
    document.location.href,
  )} width="500" height="500"></iframe>`;
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: chatframe }} />
    </div>
  );
}
